import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getMaterialToPriceLists } from "api/actions/price/material_to_price_list";
import EditableList from "components/universal/editable_list";
import Subtitle from "components/universal/subtitle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "api/hooks/apiHook";
import Header from "components/universal/header";
import AddMaterial from "../dialog/create//material_to_price_list";
import MaterialToPriceListItem from "../row/material_to_price_list_item";
import { islandStyle } from "styles/style";
import { MaterialToPriceList } from "api/types/price";
import { MATERIAL } from "api/types/update";

interface Props {
    start_update: (type: number, data: MaterialToPriceList) => void
}

export default function PriceListMaterial({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { materials } = useAppSelector((state) => state.price);

    const handle_update = (update_item: MaterialToPriceList) => {
        start_update(MATERIAL, update_item);
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getMaterialToPriceLists(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Materials"
                    endProps={
                        <AddMaterial />} />
                <Header
                    items={[
                        {text: "Name", width: 4}, 
                        {text: "Area", width: 3},
                        {text: "Thickness", width: 5}]} />
                <EditableList 
                    items={materials}
                    Item={MaterialToPriceListItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}