/// ACTIONS ///
import { Action } from "api/action_types/region";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/region";
/// TYPES ///
import { Region } from "api/types/region";
import { SubRegion } from "api/types/region";

interface State {
  sub_regions: SubRegion[],
  sub_region: SubRegion,
  regions: Region[],
  region: Region
  loading: boolean,
  error: string
}

const initialState = {
  sub_regions: [],
  sub_region: {},
  regions: [],
  region: {},
  loading: false,
  error: ""
}

export const regionReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_SUB_REGIONS:
      return {
        ...state,
        sub_regions: action.sub_regions,
        loading: false,
        error: ""
      }
    case ActionType.GET_SUB_REGION:
      return {
        ...state,
        sub_region: action.sub_region,
        loading: false,
        error: ""
      }
    case ActionType.GET_REGIONS:
      return {
        ...state,
        regions: action.regions,
        loading: false,
        error: ""
      }
    case ActionType.GET_REGION:
      return {
        ...state,
        region: action.region,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_REGION:
      return {
        ...state,
        regions: [...state.regions, action.region],
        loading: false,
        error: ""
      }
    case ActionType.UPDATE_REGION:
      return {
        ...state,
        regions: [...state.regions.filter(region => region.id !== action.region.id, action.region)],
        loading: false,
        error: ""
      }
    case ActionType.DELETE_REGION:
      return {
        ...state,
        regions: [...state.regions.filter(region => region.id !== action.id)]
      }
    case ActionType.REGION_ERROR:
      return {
        ...state,
        error: action.error
      }
    case ActionType.REGION_PENDING:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
}



