import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getStoneCountertopRemovals } from "api/actions/price/countertop_removal";
import { getLaminateCountertopRemovals } from "api/actions/price/countertop_removal";
import Subtitle from "components/universal/subtitle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CreateCountertopRemoval from "../dialog/create/countertop";
import StoneCountertopRemovalItem from "../row/stone_countertop_removal";
import LaminateCountertopRemovalItem from "../row/laminate_countertop_removal";
import Header from "components/universal/header";
import TitleSpacer from "components/universal/title_spacer";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { LaminateCountertopRemoval } from "api/types/price";
import { StoneCountertopRemoval } from "api/types/price";
import { LAMINATE_COUNTERTOP_REMOVAL } from "api/types/update";
import { STONE_COUNTERTOP_REMOVAL } from "api/types/update";

interface Props {
    start_update: (type: number, data: StoneCountertopRemoval) => void
}

export default function PriceListCountertopRemovals({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { stone_countertop_removals } = useAppSelector((state) => state.price);
    const { laminate_countertop_removals } = useAppSelector((state) => state.price);

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getStoneCountertopRemovals(Number(id)));
            dispatch(getLaminateCountertopRemovals(Number(id)));
        }
    }, [id, dispatch]);

    const handle_stone_countertop_update = (update_item: StoneCountertopRemoval) => {
        start_update(STONE_COUNTERTOP_REMOVAL, update_item);
    }

    const handle_laminate_countertop_update = (update_item: LaminateCountertopRemoval) => {
        start_update(LAMINATE_COUNTERTOP_REMOVAL, update_item);
    }

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Countertop Removal"
                    endProps={
                        stone_countertop_removals.length > 0 && laminate_countertop_removals.length > 0 ?
                        <TitleSpacer /> :
                        <CreateCountertopRemoval />
                    } />
                <Header 
                    items={[
                        {text: "Type", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <Grid item xs={12}>
                    {
                        stone_countertop_removals.length > 0 ?
                        <StoneCountertopRemovalItem 
                            index={0}
                            item={stone_countertop_removals[0]}
                            start_update={handle_stone_countertop_update}/> :
                        null
                    }
                    
                </Grid>
                <Grid item xs={12}>
                    {
                        laminate_countertop_removals.length > 0 ?
                        <LaminateCountertopRemovalItem 
                            index={0}
                            item={laminate_countertop_removals[0]}
                            start_update={handle_laminate_countertop_update} /> :
                        null
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}
