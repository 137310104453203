import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { Profile } from "api/types/authentication";
import { resetPassword } from "api/actions/authentication";
import { updateAccount } from "api/actions/account";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import StringField from "components/universal/textfield/stringfield";
import SectionHeader from "components/universal/section_header";
import PasswordIcon from '@mui/icons-material/Password';
import { JSONIsEmpty } from "functions/value";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { black_color } from "styles/style";
import { secondary_color } from "styles/style";

export default function AccountProfile() {
    const dispatch = useAppDispatch();
    const { account } = useAppSelector((state) => state.account);
    const [profile, setProfile] = useState<Profile>({});
    const [password_reset_open, setPasswordResetOpen] = useState<boolean>(false);

    const update = (key: string, value: string) => {
        setProfile({...profile, [key]: value});
    }

    const save = () => {
        dispatch(updateAccount(profile, account.id));
        setProfile({});
    }

    const reset = () => {
        dispatch(resetPassword(account.email));
        setPasswordResetOpen(true);
    }

    return (
        <Stack>
            <SectionHeader 
                text="Profile"
                variant="outlined"
                show_icon={true}
                FirstIcon={PasswordIcon}
                tooltip="Password Reset"
                first_icon_action={reset} />
            <Stack direction="row" spacing={0.5} sx={{paddingTop: 1}}>
                <StringField init_value={account.first_name} data_key="first_name" label="First Name" update={update}/>
                <StringField init_value={account.last_name} data_key="last_name" label="Last Name" update={update} />
            </Stack>
            <StringField init_value={account.business_name} data_key="business_name" label="Business Name" update={update} />
            <StringField init_value={account.address} data_key="address" label="Address" update={update} />
            <StringField init_value={account.city} data_key="city" label="City" update={update} />
            <StringField init_value={account.province} data_key="province" label="Province" update={update} />
            <StringField init_value={account.postal_code} data_key="postal_code" label="Postal Code" update={update} />
            <StringField init_value={account.email} data_key="email" label="Email" update={update} />
            <StringField init_value={account.phone_number} data_key="phone_number" label="Phone Number" update={update} />
            <Button 
                size="small"
                sx={{marginTop: 2, backgroundColor: JSONIsEmpty(profile) ? primary_color : secondary_color}}
                onClick={save}
                variant="contained">
                Save Profile
            </Button>
            <Snackbar 
                open={password_reset_open}
                autoHideDuration={5000}
                message={`Password resent sent to ${account.email}`}>
                <Alert icon={false} sx={{backgroundColor: black_color, color: white_color}} onClose={() => setPasswordResetOpen(false)}>
                    Password resent sent to {account.email}
                </Alert>
            </Snackbar>
        </Stack>
    )
}