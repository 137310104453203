import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { BumpIn } from "api/types/price";

interface Props {
    index: number,
    item: BumpIn,
    start_update: (update_item: BumpIn) => void,
    setLocked: (locked: boolean) => void
}

export default function BumpInEditableItem({index, item, start_update, setLocked}: Props) {
    const [bump_in_update, setBumpInUpdate] = useState<BumpIn>(item);

    const saveBoolean = (key: string, value: boolean) => {
        const new_bump_in: BumpIn = {...bump_in_update, [key]: value};
        setBumpInUpdate(new_bump_in);
        start_update(new_bump_in);
    }

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_bump_in: BumpIn = {...bump_in_update, [key]: Number(value)};
            setBumpInUpdate(new_bump_in);
            start_update(new_bump_in);
        }
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
