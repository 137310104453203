import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { FullArc } from "api/types/price";
import { url_prefix } from 'settings';

const full_arc_url: string = "full_arc";

export const getFullArcs = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_FULL_ARCS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${full_arc_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_FULL_ARCS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_FULL_ARCS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createFullArc = (full_arc: FullArc) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;
        
        dispatch({
            type: ActionType.CREATE_FULL_ARC_PENDING,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${full_arc_url}/`;

        try {
            const { data } = await axios.post(url, full_arc, config);
            dispatch({
                type: ActionType.CREATE_FULL_ARC_SUCCESS,
                payload: data,
            });
        } catch (err) {
            if (err instanceof AxiosError) {
                dispatch({
                    type: ActionType.CREATE_FULL_ARC_FAIL,
                    payload: err.message,
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    };
};

export const updateFullArc = (fullArc: FullArc, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_FULL_ARC_PENDING,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        try {
            const { data } = await axios.patch(`${url_prefix}${full_arc_url}/${id}/`, fullArc, config);
            dispatch({
                type: ActionType.UPDATE_FULL_ARC_SUCCESS,
                payload: data,
            });
        } catch (err) {
            if (err instanceof AxiosError) {
                dispatch({
                    type: ActionType.UPDATE_FULL_ARC_FAIL,
                    payload: err.message,
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    };
};

export const updateLocalFullArc = (full_arc: FullArc) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_FULL_ARC,
            payload: full_arc
        });
    }
}