import { Fragment } from "react";
import Typography from "@mui/material/Typography";

interface Props {
    active: boolean,
    title: string,
    description: string
}

export default function AccountWarning({active, title, description}){
    return (
        <Fragment>
            {
                active ?
                <Fragment>
                    <Typography variant="body2" color="error" sx={{marginTop: 1}}>
                        {title}
                    </Typography>
                    <Typography variant="body2">
                        {description}
                    </Typography>
                </Fragment> :
                null
            }
        </Fragment>
    )
}