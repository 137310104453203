import { StoneCountertopRemoval } from "api/types/price";
import { LaminateCountertopRemoval } from "api/types/price";

export enum ActionType {
    GET_STONE_COUNTERTOP_REMOVAL_SUCCESS = "GET_STONE_COUNTERTOP_REMOVAL_SUCCESS",
    GET_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS = "GET_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS",
    CREATE_STONE_COUNTERTOP_REMOVAL_SUCCESS = "CREATE_STONE_COUNTERTOP_REMOVAL_SUCCESS",
    UPDATE_STONE_COUNTERTOP_REMOVAL_SUCCESS = "UPDATE_STONE_COUNTER_REMOVAL_SUCCESS",
    UPDATE_LOCAL_STONE_COUNTERTOP_REMOVAL = "UPDATE_LOCAL_STONE_COUNTERTOP_REMOVAL",
    CREATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS = "CREATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS",
    UPDATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS = "UPDATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS",
    UPDATE_LOCAL_LAMINATE_COUNTERTOP_REMOVAL = "UPDATE_LOCAL_LAMINATE_COUNTERTOP_REMOVAL",
    COUNTERTOP_REMOVAL_FAIL = "COUNTERTOP_REMOVAL_FAIL",
    COUNTERTOP_REMOVAL_PENDING = "COUNTERTOP_REMOVAL_PENDING"
}

interface getStoneCountertopRemovalSuccess {
    type: ActionType.GET_STONE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: StoneCountertopRemoval[]
}

interface getLaminateCountertopRemovalSuccess {
    type: ActionType.GET_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: LaminateCountertopRemoval[]
}

interface createStoneCountertopRemovalSuccess {
    type: ActionType.CREATE_STONE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: StoneCountertopRemoval
}

interface updateStoneCountertopRemovalSuccess {
    type: ActionType.UPDATE_STONE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: StoneCountertopRemoval
}

interface updateLocalStoneCountertopRemoval {
    type: ActionType.UPDATE_LOCAL_STONE_COUNTERTOP_REMOVAL,
    payload: StoneCountertopRemoval
}

interface createLaminateCountertopRemovalSuccess {
    type: ActionType.CREATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: LaminateCountertopRemoval
}

interface updateLaminateCountertopRemovalSuccess {
    type: ActionType.UPDATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
    payload: LaminateCountertopRemoval
}

interface updateLocalLaminateCountertopRemoval {
    type: ActionType.UPDATE_LOCAL_LAMINATE_COUNTERTOP_REMOVAL,
    payload: LaminateCountertopRemoval
}

interface countertopRemovalFail {
    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
    payload: string
}

interface countertopRemovalPending {
    type: ActionType.COUNTERTOP_REMOVAL_PENDING
}

export type Action =
    getStoneCountertopRemovalSuccess |
    getLaminateCountertopRemovalSuccess |
    createStoneCountertopRemovalSuccess | 
    updateStoneCountertopRemovalSuccess | 
    updateLocalStoneCountertopRemoval |
    createLaminateCountertopRemovalSuccess | 
    updateLaminateCountertopRemovalSuccess | 
    updateLocalLaminateCountertopRemoval |
    countertopRemovalFail | 
    countertopRemovalPending;
