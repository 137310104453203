import { WaterfallRange } from "api/types/price";
import PlainRangeItem from "./shared/range_item";

interface Props {
  item: WaterfallRange
}

export default function WaterfallRangePlainItem({ item }: Props) {
  return (
    <PlainRangeItem
      any_range={item.any_range ? item.any_range : false}
      rate="$/LIN FT"
      price={item.price ? item.price : 0}
      measurement={item.height ? item.height : 0} />
  )
}
