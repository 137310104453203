import { grey_color } from "styles/style";
import { dark_grey_color } from "styles/style";

const icon_height: number = 44;
const icon_width: number = 44;

export const dashboardItemIconStyle = {
    height: icon_height,
    width: icon_width
}

export const dashboardItemSvgIconStyle = {
    height: icon_height,
    width: icon_width,
    color: grey_color
}

export const dashboardItemTitleStyle = {
    fontSize: 17,
    color: dark_grey_color,
    fontWeight: 'bold'
}

export const dashboardItemSubtitleStyle = {
    color: dark_grey_color,
    marginLeft: 0.1
}