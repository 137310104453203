import axios from 'axios';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/material_import';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from 'api/action_types/material_import';
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from 'settings';

const upload_instance_url: string = "upload_instance";
const import_material_url: string = "import_material";

export const getUploadInstances = (
    status?: string,
    uploader_last_name?: string,
    uploaded_after?: string,
    uploaded_before?: string
) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPLOAD_INSTANCE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${upload_instance_url}/?`;

        if(status && status !== ""){
            url+=`status=${status}&`;
        }

        if(uploader_last_name && uploader_last_name !== ""){
            url+=`uploader_last_name=${uploader_last_name}&`;
        }

        if(uploaded_after && uploaded_after !== ""){
            url+=`uploaded_after=${uploaded_after}&`;
        }

        if(uploaded_before && uploaded_before !== ""){
            url+=`uploaded_before=${uploaded_before}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_UPLOAD_INSTANCES_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {   
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPLOAD_INSTANCE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const getUploadInstance = (id: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPLOAD_INSTANCE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${upload_instance_url}/${id}/?`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_UPLOAD_INSTANCE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {   
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPLOAD_INSTANCE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const uploadMaterialFile = (file: any) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPLOAD_INSTANCE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${user.token}`
            }
        }

        let form_data = new FormData();
        form_data.append('file', file);

        let url = `${url_prefix}${import_material_url}/`;

        try
        {
            const {data} = await axios.post(url, form_data, config);
        
            dispatch({
                type: ActionType.MATERIAL_UPLOAD_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {   
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPLOAD_INSTANCE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}