import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { login } from "api/actions/authentication";
import { storeToken } from "api/actions/authentication";
import { getUser } from "api/actions/authentication";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { validateEmail } from "functions/value";
import { error_color, text_primary } from "styles/style";
import { forgot_password_url } from "components/navigation/endpoints";

export default function LoginPage() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const [password, setPassword] = useState<string>("");
    const { user } = useAppSelector((state) => state.authentication);
    const { error } = useAppSelector((state) => state.authentication);
    const { loading } = useAppSelector((state) => state.authentication);
    const [email, setEmail] = useState<string>("");
    const [email_error, setEmailError] = useState<string>("");
    const [password_error, setPasswordError] = useState<string>("");
    const [email_has_error, setEmailHasError] = useState<boolean>(false);
    const [password_has_error, setPasswordHasError] = useState<boolean>(false);

    const token = queryParameters.get("ut");

    useEffect(() => {
        if(token && !user.id){
            dispatch(storeToken(token));
            dispatch(getUser(token));
        }
    }, []);

    const handleEmail = (input: string) => {
        setEmail(input);
        setEmailError("");
        setEmailHasError(false);
    }

    const handleLoginPassword = (input: string) => {
        setPassword(input);
        setPasswordError("");
        setPasswordHasError(false);
    }

    const handleLogin = () => {
        let error: boolean = false;
        if(!validateEmail(email)){
            setEmailError("Please enter a valid email");
            setEmailHasError(true);
            error = true;
        }

        if(password === "" || !password){
            setPasswordError("Please enter a password");
            setPasswordHasError(true);
            error = true;
        }

        if(error){
            return;
        }

        dispatch(login({
            email: email,
            password: password
        }));
    }

    if(user.id){
        navigate("/", {replace: true});
    }

    const keyDown = (e: any) => {
        if(e.key === "Enter"){
            e.preventDefault();
            handleLogin();
        }
    }

    return (
        <Box sx={{padding: "20vh"}} onKeyDown={keyDown}>
            <Stack>
                <Typography variant="h6" sx={{paddingLeft: 4.5, paddingBottom: error || password_error ? 0 : 3, color: text_primary}}>
                    Login
                </Typography>
                {
                    email_has_error ?
                    <Typography variant="body2" sx={{color: error_color}}>
                        {email_error}
                    </Typography> :
                    null
                }
                {
                    password_has_error || error ?
                    <Typography variant="body2" sx={{color: error_color, marginLeft: 4.5, paddingBottom: 0.5}}>
                        {error !== "" ? error : password_error}
                    </Typography> :
                    null
                }
                <TextField 
                    type="email"
                    sx={{marginLeft: 3, marginBottom: 1, width: 400}}
                    size="small"
                    error={email_has_error}
                    onChange={(e) => handleEmail(e.target.value)}
                    label="Email" />
                <TextField 
                    type="password"
                    sx={{marginLeft: 3, marginBottom: 1.5, width: 400}}
                    size="small"
                    error={password_has_error}
                    onChange={(e) => handleLoginPassword(e.target.value)}
                    label="Password" />
                <Box display="flex" justifyContent="flex-end" sx={{width: 400, marginLeft: 3.5, marginBottom: 1.5}}>
                    <Button sx={{
                        textTransform: "none",
                        fontSize: 12,
                        paddingTop: 0,
                        paddingBottom: 0,
                        justifyContent: "flex-end"
                    }}
                        onClick={() => navigate(`/${forgot_password_url}`, {replace: true})}>
                        Forgot Password?
                </Button>
                </Box>
                {
                    !loading ?
                    <Button 
                        onClick={handleLogin}
                        variant="contained" 
                        sx={{width: 150, marginLeft: 3}}>
                        Login
                    </Button> :
                    <Box sx={{width: 150, marginLeft: 3}} display="flex" justifyContent="center">
                        <CircularProgress size={24}/>
                    </Box>
                }
                
            </Stack>
        </Box>
    );
}
