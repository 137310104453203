import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { BumpOut } from "api/types/price";

interface Props {
    index: number,
    item: BumpOut,
    start_update: (update_item: BumpOut) => void,
    setLocked: (locked: boolean) => void
}

export default function BumpOutEditableItem({index, item, start_update, setLocked}: Props) {
    const [bump_out_update, setBumpOutUpdate] = useState<BumpOut>(item);

    const saveBoolean = (key: string, value: boolean) => {
        const new_bump_out: BumpOut = {...bump_out_update, [key]: value};
        setBumpOutUpdate(new_bump_out);
        start_update(new_bump_out);
    }

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_bump_out: BumpOut = {...bump_out_update, [key]: Number(value)};
            setBumpOutUpdate(new_bump_out);
            start_update(new_bump_out);
        }
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
