import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { gridSpacing } from "styles/style";

interface Props {
    enabled: boolean,
    setEnabled: (key: string, enabled: boolean) => void,
    taxable: boolean,
    setTaxable: (key: string, taxable: boolean) => void,
    grid_width?: number
}

export default function Checkboxes(
    {
        enabled,
        setEnabled,
        taxable,
        setTaxable,
        grid_width
    }: Props
) {
    return(
        <React.Fragment>
            <Grid item xs={grid_width ? grid_width / 2 : 2} sx={gridSpacing}>
                <Checkbox
                    sx={{marginLeft: 0.5}}
                    size="small"
                    onChange={(e) => setEnabled("enabled", e.target.checked)}
                    defaultChecked={enabled} />
            </Grid>
            <Grid item xs={grid_width ? grid_width / 2 : 2} sx={gridSpacing}>
                <Checkbox 
                    sx={{marginLeft: 0.5}}
                    size="small"
                    onChange={(e) => setTaxable("taxable", e.target.checked)}
                    defaultChecked={taxable} />
            </Grid>
        </React.Fragment>
    )
}
