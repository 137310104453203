import { User } from 'api/types/authentication';
import { Action } from 'api/action_types/account';
import { ActionType } from 'api/action_types/account';

interface State {
    accounts: User[],
    account: User
    loading: boolean,
    confirm_email_sending: boolean,
    confirm_email_sent: boolean,
    error: string
}

const initialState = {
    accounts: [],
    account: {},
    loading: false,
    confirm_email_sending: false,
    confirm_email_sent: false,
    error: ""
}

export const accountReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.CLEAR_EMAIL_CONFIRMATION:
            return {
                ...state,
                confirm_email_sending: false,
                confirm_email_sent: false
            }
        case ActionType.RESEND_CONFIRMATION_COMPLETE:
            return {
                ...state,
                confirm_email_sending: false,
                confirm_email_sent: true
            }
        case ActionType.CLEAR_ACCOUNT:
            return {
                ...state,
                account: {}
            }
        case ActionType.GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                accounts: action.payload,
                loading: false,
                error: ""
            }
        case ActionType.GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.payload,
                loading: false,
                error: ""
            } 
        case ActionType.UPDATE_ACCOUNT:
            return {
                ...state,
                account: action.payload,
                loading: false,
                error: ""
            }
        case ActionType.RESEND_CONFIRMATION_PENDING:
            return {
                ...state,
                confirm_email_sending: true,
                confirm_email_sent: false
            }
        case ActionType.ACCOUNT_PENDING:
            return {
                ...state
            }
        case ActionType.RESEND_CONFIRMATION_ERROR:
            return {
                ...state,
                confirm_email_sending: false,
                confirm_email_sent: false,
                error: action.payload
            }
        case ActionType.ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}



