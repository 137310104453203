import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/material";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/material";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Material } from "api/types/material";
import { url_prefix } from "settings";

const material_url: string = "material";

export const getMaterials = (type?: string, sub_region?: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${material_url}/?`;

    if (type) {
      url += `type=${type}&`;
    }

    if (sub_region) {
      url += `sub_region=${sub_region}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_MATERIALS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const getMaterial = (id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${material_url}/${id}?`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_MATERIAL,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const createMaterial = (material: Material) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${material_url}/`;

    try {
      const { data } = await axios.post(url, material, config);

      dispatch({
        type: ActionType.CREATE_MATERIAL,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateMaterial = (material: Material, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING,
      payload: material
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${material_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, material, config);

      dispatch({
        type: ActionType.UPDATE_MATERIAL,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const deleteMaterial = (id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${material_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_MATERIAL,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const setMaterial = (material: Material) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MATERIAL,
      payload: material
    });
  }
}

export const clearMaterials = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CLEAR_MATERIALS
    });
  }
}
