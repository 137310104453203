/// REACT ///
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { uploadMaterialFile } from "api/actions/material_import";
/// MUI ///
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default function CreateUpload() {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<any>();

  const handleImage = (e: any) => {
    setFile(e.target.files[0]);
  }

  const submit = () => {
    dispatch(uploadMaterialFile(file));
  }

  return (
    <Stack>
      <input
        type="file"
        name="image"
        onChange={(e) => handleImage(e)} />
      <Button onClick={submit} variant="outlined" sx={{ marginTop: "10px" }}>
        Submit
      </Button>
    </Stack>
  )
}
