import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";
import { User } from "api/types/authentication";
import { ChangePassword } from "api/types/authentication";
import { url_prefix } from "settings";
import { RootState } from "api/reducers";

const login_url: string = "auth/login";
const register_url: string = "registration";
const profile_url: string = "profile";
const change_password_url: string = "change_password";
const reset_password_url: string = "password_reset";

export const login = (user: User) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.LOGIN_PENDING
        });

        const config = {
            headers: {
              "Content-Type":"application/json"
            }
        }

        const url = `${url_prefix}${login_url}`;

        try
        {
            const {data} = await axios.post(url, user, config);
            
            dispatch({
                type: ActionType.LOGIN_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if( err instanceof Error){
                dispatch({
                    type: ActionType.LOGIN_FAIL,
                    payload: "The username/password is not correct"
                });
            }
        }
    }
}

export const getUser = (token: string) => {
    return async(dispatch: Dispatch<Action>) => {

        dispatch({
            type: ActionType.UPDATE_PROFILE_PENDING
        });

        const config = {
            headers: {
              "Content-Type":"application/json",
              "Authorization": `Token ${token}`
            }
        }

        const url = `${url_prefix}${profile_url}/0/?token_only=true&`;

        try
        {
            const {data} = await axios.get(url, config);
            
            dispatch({
                type: ActionType.GET_PROFILE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if( err instanceof Error){
                dispatch({
                    type: ActionType.UPDATE_PROFILE_FAIL,
                    payload: "The username/password is not correct"
                });
            }
        }
    }
}

export const register = (new_user: User) => {
    return async(dispatch: Dispatch<Action>, getState: () => RootState) => {
        const { user } = getState().authentication;
        dispatch({
            type: ActionType.REGISTER_PENDING
        });

        const config = {
            headers: {
                "Content-Type":"multipart/form-data",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${register_url}/`;

        try
        {
            const {data} = await axios.post(url, new_user, config);

            dispatch({
                type: ActionType.REGISTER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError && err.response && err.response.status === 400){
                dispatch({
                    type: ActionType.REGISTER_FAIL,
                    payload: err.response.data.error,
                    offer_reset: true
                });
            }
            else{
                dispatch({
                    type: ActionType.REGISTER_FAIL,
                    payload: "An unknown error has occurred please contact the administrator",
                    offer_reset: false
                });
            }
        }
    }
}

export const changePassword = (change_password: ChangePassword) => {
    return async(dispatch: Dispatch<Action>, getState: () => RootState) => {
        const { user } = getState().authentication;
        dispatch({
            type: ActionType.CHANGE_PASSWORD_PENDING
        });

        const config = {
            headers: {
                "Content-Type":"multipart/form-data",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${change_password_url}/`;

        try
        {
            const {data} = await axios.post(url, change_password, config);

            dispatch({
                type: ActionType.CHANGE_PASSWORD,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError && err.response && (err.response.status === 400 || err.response.status === 401)){
                let error: string = "";
                if(err.response.data.old_password){
                    error = err.response.data.old_password;
                }
                else if(err.response.data.password){
                    error = err.response.data.password;
                }
                else if(err.response.data.password2){
                    error = err.response.data.password2;
                }
                else{
                    error = err.response.data;
                }

                dispatch({
                    type: ActionType.CHANGE_PASSWORD_FAIL,
                    payload: error
                });
            }
            else{
                dispatch({
                    type: ActionType.CHANGE_PASSWORD_FAIL,
                    payload: "An unknown error has occurred please contact the administrator"
                });
            }
        }
    }
}

export const resetPassword = (email: string) => {
    return async(dispatch: Dispatch<Action>) => {

        const config = {
            headers: {
              "Content-Type": "application/json"
            }
        }

        const url = `${url_prefix}${reset_password_url}/?email=${email}&`;

        try
        {
            await axios.post(url, {}, config);
        }
        catch(err){}
    }
}

export const clearErrors = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOGIN_PENDING
        });
    }
}

export const logout = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOGOUT_SUCCESS
        })
    }
}

export const authFailed = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.AUTH_FAILED
        });
    }
}

export const updateProfile = (profile: User, id: number, distance?: boolean) => {
    return async(dispatch: Dispatch<Action>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_PROFILE_PENDING
        });

        const config = {
            headers: {
                "Content-Type":"multipart/form-data",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${profile_url}/${id}/?`;

        if(distance){
            url+= "distance=true&";
        }

        try
        {
            const {data} = await axios.patch(url, profile, config);

            dispatch({
                type: ActionType.UPDATE_PROFILE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if( err instanceof Error){
                dispatch({
                    type: ActionType.UPDATE_PROFILE_FAIL,
                    payload: err.message
                });
            }
        }
    }
}

export const getSuppliers = () => {
    return async(dispatch: Dispatch<Action>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_SUPPLIERS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${profile_url}/?supplier=true&`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_SUPPLIERS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if( err instanceof Error){
                dispatch({
                    type: ActionType.GET_SUPPLIERS_FAIL,
                    payload: err.message
                });
            }    
        }
    }
}

export const resetRegistrationComplete = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.RESET_REGISTRATION_COMPLETE
        });
    }
}

export const updateLocalUser = (user: User) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_USER,
            payload: user
        });
    }
}

export const setRegistrationComplete = (complete: boolean) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_REGISTRATION_COMPLETE,
            payload: complete
        });
    }
}

export const storeToken = (token: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STORE_TOKEN,
            payload: token
        });
    }
}
