import React from "react";
import { useState } from "react";
import { createRadiusCorner } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { RadiusCorner } from "api/types/price";
import { RadiusCornerRange } from "api/types/price";
import CreateBasicRange from "../shared_components/create_range";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { empty_radius_corner } from "values/empty/price";
import { empty_radius_corner_range } from "values/empty/price";

export default function CreateRadiusCorner() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [radius_corner, setRadiusCorner] = useState<RadiusCorner>(empty_radius_corner);
    const [ranges, setRanges] = useState<RadiusCornerRange[]>([{...empty_radius_corner_range, any_range: true}]);
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setRadiusCorner({...radius_corner, [key]: value});
        setError("");
        setHasError(false);
    }

    const saveBoolean = (key: string, value: boolean) => {
        setRadiusCorner({...radius_corner, [key]: value});
        setError("");
        setHasError(false);
    }

    const handleRangePrice = (index: number, price: string) => {
        if(isNaN(Number(price)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ?
                {...range, price: Number(price)} :
                range
            )]);
        }
    }

    const handleRangeMeasurement = (index: number, radius: string) => {
        if(isNaN(Number(radius)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ? 
                {...range, radius: Number(radius)} :
                range
            )]);
        }
    }

    const addRange = () => {
        setRanges([{...empty_radius_corner_range, radius: 0}, ...ranges]);
    }

    const handleCreate = () => {
        if(!price_list.id){
            setError("An unknown error has occurred. Please contact the administrator");
            setHasError(true);
            return;
        }

        dispatch(createRadiusCorner({...radius_corner, price_list: price_list.id, ranges: ranges}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setRadiusCorner(empty_radius_corner);
        setRanges(([{...empty_radius_corner_range, any_range: true}]));
        setError("");
        setHasError(false);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <CreateBasicRange 
                title="Create Radius Corner"
                open={open}
                error={error}
                has_error={has_error}
                ranges={ranges}
                close={handleClose}
                create={handleCreate}
                saveString={saveString}
                saveBoolean={saveBoolean}
                handleRangeMeasurement={handleRangeMeasurement}
                handleRangePrice={handleRangePrice}
                addRange={addRange} />
        </React.Fragment>
    )
}
