import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { tableHeaderStyle } from 'styles/style';
import { tableHeaderItemStyle } from "styles/style";
import { tableHeaderTypographyStyle } from "styles/style";

export default function UploadInstanceHeader() {
  return (
    <Grid
      sx={[tableHeaderStyle]}
      container
      item
      xs={12}>
      <Grid item xs={3} sx={tableHeaderItemStyle}>
        <Typography variant="body2" sx={tableHeaderTypographyStyle}>
          Upload Date
        </Typography>
      </Grid>
      <Grid item xs={3} sx={tableHeaderItemStyle}>
        <Typography variant="body2" sx={tableHeaderTypographyStyle}>
          Uploaded By
        </Typography>
      </Grid>
      <Grid item xs={6} sx={tableHeaderItemStyle}>
        <Box display="flex" justifyContent="flex-end">
          <Typography align="right" variant="body2" sx={tableHeaderTypographyStyle}>
            Status
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
