import { SelectItem } from "api/types/my_ui";
export const FABRICATOR: string = "Fabricator";
export const DEALER: string = "Dealer";

export const ACCOUNT_CHOICES: SelectItem[] = [
    {
        label: FABRICATOR,
        key: FABRICATOR,
        value: FABRICATOR
    },
    {
        label: DEALER,
        key: DEALER,
        value: DEALER
    }
]