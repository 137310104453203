import { SelectItem } from "api/types/my_ui";
export const YES: string = "Yes";
export const NO: string = "No";

export const BOOLEAN_CHOICES: SelectItem[] = [
    {
        label: YES,
        key: YES,
        value: YES
    },
    {
        label: NO,
        key: NO,
        value: NO
    }
]