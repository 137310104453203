import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { MaterialToPriceList } from 'api/types/price';
import { url_prefix } from 'settings';

const material_to_price_list_url: string = 'material_to_price_list';

export const getMaterialToPriceLists = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${material_to_price_list_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createMaterialToPriceList = (material_to_price_list: MaterialToPriceList) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${material_to_price_list_url}/`;

        try
        {
            const {data} = await axios.post(url, material_to_price_list, config);

            dispatch({
                type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateMaterialToPriceList = (material_to_price_list: MaterialToPriceList, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${material_to_price_list_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, material_to_price_list, config);

            dispatch({
                type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const deleteMaterialToPriceList = (id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        if(id === 0){
            return;
        }

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${material_to_price_list_url}/${id}/`;

        try
        {
            const {data} = await axios.delete(url, config);

            dispatch({
                type: ActionType.DELETE_MATERIAL_TO_PRICE_LIST,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const updateLocalMaterialToPriceList = (material_to_price_list: MaterialToPriceList) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_MATERIAL_TO_PRICE_LIST,
            payload: material_to_price_list
        });
    }
}