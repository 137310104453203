import React from "react";
import { useState } from "react";
import { createApplianceEdge } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { ApplianceEdge } from "api/types/price";
import { PriceDetailError } from "api/types/price";
import { PriceDetailHasError } from "api/types/price";
import CreateBasic from "../shared_components/create_basic";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { empty_appliance_edge } from "values/empty/price";
import { empty_error } from "values/empty/price";
import { empty_has_error } from "values/empty/price";

export default function CreateApplianceEdge() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [appliance_edge, setApplianceEdge] = useState<ApplianceEdge>(empty_appliance_edge);
  const [errors, setErrors] = useState<PriceDetailError>(empty_error);
  const [has_errors, setHasErrors] = useState<PriceDetailHasError>(empty_has_error);
  const { price_list } = useAppSelector((state) => state.price);

  const saveString = (key: string, value: string) => {
    setApplianceEdge({ ...appliance_edge, [key]: value });
    setErrors({ ...errors, [key]: "" });
    setHasErrors({ ...has_errors, [key]: false });
  }

  const saveNumber = (key: string, value: string) => {
    if (isNaN(Number(value)) === false) {
      setApplianceEdge({ ...appliance_edge, [key]: Number(value) });
      setErrors({ ...errors, [key]: "" });
      setHasErrors({ ...has_errors, [key]: false });
    }
  }

  const saveBoolean = (key: string, value: boolean) => {
    setApplianceEdge({ ...appliance_edge, [key]: value });
    setErrors({ ...errors, [key]: "" });
    setHasErrors({ ...has_errors, [key]: false });
  }

  const handleCreate = () => {
    let error = false;
    let errors: PriceDetailError = empty_error;
    let has_errors: PriceDetailHasError = empty_has_error;

    if (has_errors.price || appliance_edge.price === 0) {
      errors = {
        ...errors,
        price: "Please enter a number for the price"
      }
      has_errors = {
        ...has_errors,
        price: true
      }
      error = true;
    }

    if (!price_list.id) {
      errors = {
        ...errors,
        price_list: "An unknown error has occurred. Please contact the administrator"
      }
      error = true;
    }

    if (error) {
      setErrors(errors);
      setHasErrors(has_errors);
      return;
    }

    dispatch(createApplianceEdge({ ...appliance_edge, price_list: price_list.id }));
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setApplianceEdge(empty_appliance_edge);
    setErrors(empty_error);
    setHasErrors(empty_has_error);
  }

  return (
    <React.Fragment>
      <IconButton onClick={(e) => setOpen(true)}>
        <AddCircleIcon
          color="primary" />
      </IconButton>
      <CreateBasic
        title="Create Appliance Edge Price"
        open={open}
        errors={errors}
        has_errors={has_errors}
        close={() => setOpen(false)}
        create={handleCreate}
        saveString={saveString}
        saveBoolean={saveBoolean}
        saveNumber={saveNumber} />
    </React.Fragment>
  )
}
