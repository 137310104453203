import { CooktopCutoutRange } from "api/types/price";
import PlainRangeItem from "./shared/range_item";

interface Props {
    item: CooktopCutoutRange
}

export default function CooktopCutoutRangePlainItem({item}: Props) {
    return(
        <PlainRangeItem 
            any_range={item.any_range ? item.any_range : false}
            rate="$"
            price={item.price ? item.price : 0}
            measurement={item.measurement ? item.measurement : 0} />
    )
}
