/// HOOKS ///
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// EVENTS ///
import { ChangeEvent } from "react";
/// TYPES ///
import { MaterialToPriceList } from "api/types/price";
import { PriceDetailError } from "api/types/price";
import { PriceDetailHasError } from "api/types/price";
/// ACTIONS ///
import { createMaterialToPriceList } from "api/actions/price";
/// COMPONENTS ///
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Fragment } from "react";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
/// ICONS ///
import AddCircleIcon from '@mui/icons-material/AddCircle';
/// STYLES ///
import { dialogContentStyle } from "styles/style";
/// COLORS ///
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
/// EMPTY VALUES ///
import { empty_error } from "values/empty/price";
import { empty_has_error } from "values/empty/price";
import { empty_material_to_price_list } from "values/empty/price";
/// VALUES ///
import { getMaterialTypeDisplay } from "values/material";
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM12 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { MM6 } from "values/material";
import { MM8 } from "values/material";
import { MM20 } from "values/material";
import { MM30 } from "values/material";


export default function AddMaterial() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [material_to_price_list, setMaterialToPriceList] = useState<MaterialToPriceList>(empty_material_to_price_list);
  const [errors, setErrors] = useState<PriceDetailError>(empty_error);
  const [has_errors, setHasErrors] = useState<PriceDetailHasError>(empty_has_error);
  const [show_material_thickness, setShowMaterialThickness] = useState<boolean>(false);
  const [show_material, setShowMaterial] = useState<boolean>(false);
  const [show_all, setShowAll] = useState<boolean>(true);
  const { price_list } = useAppSelector((state) => state.price);
  const { materials } = useAppSelector((state) => state.material);

  const saveString = (key: string, value: string) => {
    setMaterialToPriceList({ ...material_to_price_list, [key]: value });
    setErrors({ ...errors, [key]: "" });
    setHasErrors({ ...has_errors, [key]: false });
  }

  const saveBoolean = (key: string, value: boolean) => {
    if (key === "all_material") {
      setMaterialToPriceList({ ...material_to_price_list, [key]: value, by_material_thickness: false, by_material: false })
    }
    else if (key === "by_material") {
      setMaterialToPriceList({ ...material_to_price_list, [key]: value, by_material_thickness: false, all_material: false });
    }
    else if (key === "by_material_thickness") {
      setMaterialToPriceList({ ...material_to_price_list, [key]: value, by_material: false, all_material: false });
    }

    setErrors({ ...errors, [key]: "" });
    setHasErrors({ ...has_errors, [key]: false });
  }

  const handleApplySelection = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.value === "all_material") {
      setShowMaterial(false);
      setShowMaterialThickness(false);
      setShowAll(true);
      saveBoolean("all_material", true);
    }
    else if (event.target.value === "material_type") {
      setShowMaterial(false);
      setShowMaterialThickness(true);
      setShowAll(false);
      saveBoolean("by_material_thickness", true);
    }
    else if (event.target.value === "material") {
      setShowMaterial(true);
      setShowMaterialThickness(false);
      setShowAll(false);
      saveBoolean("by_material", true);
    }
  };

  const handleCreate = () => {
    let error = false;
    let errors: PriceDetailError = empty_error;
    let has_errors: PriceDetailHasError = empty_has_error;

    if (material_to_price_list.by_material_thickness && (
      material_to_price_list.thickness === null ||
      material_to_price_list.thickness === undefined ||
      material_to_price_list.thickness === ""
    )) {

      errors = {
        ...errors,
        material_type: "Please select a material thickness"
      }
      has_errors = {
        ...has_errors,
        material_type: true
      }
      error = true;
    }

    if (material_to_price_list.by_material && (
      material_to_price_list.material === null ||
      material_to_price_list.material === undefined ||
      material_to_price_list.material === 0
    )) {
      errors = {
        ...errors,
        material: "Please select a material"
      }
      has_errors = {
        ...has_errors,
        material: true
      }
      error = true;
    }

    if (!price_list.id) {
      errors = {
        ...errors,
        price_list: "An unknown error has occurred. Please contact the administrator"
      }
      error = true;
    }

    if (error) {
      setErrors(errors);
      setHasErrors(has_errors);
      return;
    }

    dispatch(createMaterialToPriceList({ ...material_to_price_list, price_list: price_list.id }));
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setMaterialToPriceList(empty_material_to_price_list);
    setShowMaterial(false);
    setShowMaterialThickness(false);
    setShowAll(true);
    setErrors(empty_error);
    setHasErrors(empty_has_error);
  }

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <AddCircleIcon
          color="primary" />
      </IconButton>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Add Material to Price List</DialogTitle>
        <DialogContent sx={dialogContentStyle}>
          <FormControl fullWidth>
            <FormHelperText error id="price_list_error">{errors.price_list}</FormHelperText>
            <RadioGroup
              aria-labelledby="splash-group-application"
              defaultValue="all_material"
              name="splash-group-application"
              onChange={(e) => handleApplySelection(e)}>
              <FormControlLabel
                value="all_material"
                control={<Radio size="small" />}
                label={`All ${getMaterialTypeDisplay(price_list.material_type)}`} />
              <FormControlLabel value="material_type" control={<Radio size="small" />} label="Material Height" />
              <TextField
                SelectProps={{
                  MenuProps: {
                    sx: { height: "400px" },
                  },
                }}
                sx={{ display: show_material_thickness ? 'block' : 'none' }}
                fullWidth
                size="small"
                variant="outlined"
                select
                error={has_errors.height}
                helperText={errors.height}
                defaultValue={CM2}
                id="material_thickness"
                label="Material Height"
                onChange={(e) => saveString("height", e.target.value)}>
                <MenuItem key={CM2} value={CM2}>
                  {getMaterialHeightDisplay(CM2)}
                </MenuItem>
                <MenuItem key={CM3} value={CM3}>
                  {getMaterialHeightDisplay(CM3)}
                </MenuItem>
                <MenuItem key={CM12} value={CM12}>
                  {getMaterialHeightDisplay(CM12)}
                </MenuItem>
                <MenuItem key={CM1} value={CM1}>
                  {getMaterialHeightDisplay(CM1)}
                </MenuItem>
                <MenuItem key={CM60} value={CM60}>
                  {getMaterialHeightDisplay(CM60)}
                </MenuItem>
                <MenuItem key={MM6} value={MM6}>
                  {getMaterialHeightDisplay(MM6)}
                </MenuItem>
                <MenuItem key={MM8} value={MM8}>
                  {getMaterialHeightDisplay(MM8)}
                </MenuItem>
                <MenuItem key={MM20} value={MM20}>
                  {getMaterialHeightDisplay(MM20)}
                </MenuItem>
                <MenuItem key={MM30} value={MM30}>
                  {getMaterialHeightDisplay(MM30)}
                </MenuItem>
              </TextField>
              <FormControlLabel value="material" control={<Radio size="small" />} label="Material" />
              <TextField
                SelectProps={{
                  MenuProps: {
                    sx: { height: "400px" },
                  },
                }}
                sx={{
                  display: show_material || show_all ? "block" : "none",
                  visibility: show_all ? "hidden" : "visible"
                }}
                fullWidth
                size="small"
                variant="outlined"
                select
                error={has_errors.material}
                helperText={errors.material}
                id="material"
                label="Material"
                defaultValue=""
                onChange={(e) => saveString("material", e.target.value)}>
                <MenuItem key={""} value={""}>
                  {"      "}
                </MenuItem>
                {
                  materials.map(material => {
                    return (
                      <MenuItem
                        key={material.id}
                        value={material.id}>
                        {material.name} {getMaterialHeightDisplay(material.thickness)}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
