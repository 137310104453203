/// REACT ///
import { Fragment } from "react";
/// HOOKS ///
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// TYPES ///
import { WaterfallRange } from "api/types/price";
import { Waterfall } from "api/types/price";
/// ACTIONS ///
import { createWaterfall } from "api/actions/price";
/// MUI ///
import IconButton from "@mui/material/IconButton";
/// COMPONENTS ///
import CreateBasicRange from "../shared_components/create_range";
/// ICONS ///
import AddCircleIcon from '@mui/icons-material/AddCircle';
/// VALUES ///
import { empty_waterfall } from "values/empty/price";
import { empty_waterfall_range } from "values/empty/price";

export default function CreateWaterfall() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [waterfall, setWaterfall] = useState<Waterfall>(empty_waterfall);
  const [error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);
  const [ranges, setRanges] = useState<WaterfallRange[]>([{ ...empty_waterfall_range, any_range: true }]);
  const { price_list } = useAppSelector((state) => state.price);

  const saveString = (key: string, value: string) => {
    setWaterfall({ ...waterfall, [key]: value });
    setError("");
    setHasError(false);
  }

  const saveBoolean = (key: string, value: boolean) => {
    setWaterfall({ ...waterfall, [key]: value });
    setError("");
    setHasError(false);
  }

  const handleRangePrice = (index: number, price: string) => {
    if (isNaN(Number(price)) === false) {
      setRanges([...ranges.map((range, inner_index) => inner_index === index ?
        { ...range, price: Number(price) } :
        range
      )]);
    }
  }

  const handleRangeMeasurement = (index: number, height: string) => {
    if (isNaN(Number(height)) === false) {
      setRanges([...ranges.map((range, inner_index) => inner_index === index ?
        { ...range, radius: Number(height) } :
        range
      )]);
    }
  }

  const addRange = () => {
    setRanges([{ ...empty_waterfall, height: 0 }, ...ranges]);
  }

  const handleCreate = () => {
    if (!price_list.id) {
      setError("An unknown error has occurred. Please contact the administrator");
      setHasError(true);
      return;
    }

    dispatch(createWaterfall({ ...waterfall, price_list: price_list.id, ranges: ranges }));
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setWaterfall(empty_waterfall);
    setRanges([{ ...empty_waterfall_range, any_range: true }]);
  }

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <AddCircleIcon
          color="primary" />
      </IconButton>
      <CreateBasicRange
        title="Create Waterfall Corner"
        open={open}
        error={error}
        has_error={has_error}
        ranges={ranges}
        close={handleClose}
        create={handleCreate}
        saveString={saveString}
        saveBoolean={saveBoolean}
        handleRangeMeasurement={handleRangeMeasurement}
        handleRangePrice={handleRangePrice}
        addRange={addRange} />
    </Fragment>
  )
}
