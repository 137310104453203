import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getBugs } from "api/actions/feedback";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import BugRow from "./row/bug";
import Title from "components/universal/title";
import List from "components/universal/list";
import CreateBugComponent from "./dialog/create_bug";
import BugHeader from "./header/bug";
import BugFilterComponent from "./filter/bug";
import { mainContentAreaStyle } from "styles/style";
import { islandStyle } from "styles/style";
import { default_pagination_length } from "styles/table";

export default function BugsComponent() {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const { bugs } = useAppSelector((state) => state.feedback);

    useEffect(() => {
        dispatch(getBugs());
    }, [dispatch]);

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return(
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between" alignContent="center">
                <Grid item xs={12}>
                    <Title 
                        title="Bugs"
                        update_pending={false}
                        start_times={[]}
                        update={() => void 0}
                        endProps={<CreateBugComponent />} />
                </Grid>
                <Grid item xs={8.5}>
                    <Paper sx={[islandStyle]}>
                        <Grid container>
                            <BugHeader />
                            <List 
                                items={bugs.slice((page - 1) * default_pagination_length, page * default_pagination_length)}
                                Item={BugRow}
                                />
                            <Grid item xs={12} sx={{marginTop: 3}}>
                                <Box display="flex" justifyContent="center">
                                    <Pagination
                                        onChange={changePage} 
                                        count={
                                            bugs.length > default_pagination_length ? 
                                            Math.ceil(bugs.length / default_pagination_length) : 
                                            1
                                        } shape="rounded" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={[islandStyle]}>
                        <BugFilterComponent />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}