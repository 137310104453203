import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/account";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/account";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { resend_email_confirmation_url } from "./endpoints";

export const resendEmailConfirmation = (email: string) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        dispatch({
            type: ActionType.RESEND_CONFIRMATION_PENDING
        })
        const config = {
            headers: {
                "Content-Type":"multipart/form-data",
            }
        }

        let url = `${url_prefix}${resend_email_confirmation_url}/?email=${email}&`;

        try
        {
            await axios.post(url, {}, config);
            dispatch({
                type: ActionType.RESEND_CONFIRMATION_COMPLETE
            })
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.ACCOUNT_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}

export const clearEmailConfirmation = () => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CLEAR_EMAIL_CONFIRMATION
        });
    }
}