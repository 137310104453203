import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getInsideRadiusCorners } from "api/actions/price/inside_radius_corner";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateInsideRadiusCorner } from "../dialog/create";
import InsideRadiusCornerItem from "../row/inside_radius_corner";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { InsideRadiusCorner } from "api/types/price";
import { INSIDE_RADIUS_CORNER } from "api/types/update";
import { INSIDE_RADIUS_CORNER_RANGE } from "api/types/update";

interface Props {
    start_update: (type: number, data: InsideRadiusCorner) => void
}
export default function PriceListInsideRadiusCorners({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { inside_radius_corners } = useAppSelector((state) => state.price);

    const handle_update = (update_item: InsideRadiusCorner) => {
        if("radius" in update_item){
            start_update(INSIDE_RADIUS_CORNER_RANGE, update_item);
        }
        else{
            start_update(INSIDE_RADIUS_CORNER, update_item);
        }
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getInsideRadiusCorners(Number(id)));
        }
    }, [id, dispatch]);
    
    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Inside Radius Corners"
                    endProps={
                        <CreateInsideRadiusCorner />} />
                <EditableList 
                    items={inside_radius_corners}
                    Item={InsideRadiusCornerItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}
