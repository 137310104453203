export const primary_color: string = "#0063fe";
export const secondary_color: string = "#f6be41";
export const third_color: string = "#58a55d";
export const error_color: string = "#ec503e";
export const well_color: string = "#f6f8fa";
export const marketing_well_color: string = "#e9effe";
export const text_primary: string = "#333333";
export const text_secondary: string = "#202528";
export const white_color: string = "#ffffff";
export const black_color: string = "#323232";
export const grey_color: string = "#888888";
export const dark_grey_color: string = "#757575";
export const light_grey_color: string = "#D4D4D4";
export const transparent_color: string = "rgba(52, 52, 52, 0)";

export const mainContentAreaStyle = {
    marginLeft: 7.5,
    paddingLeft: 4.5,
    paddingRight: 4.5,
    paddingTop: 12,
    paddingBottom: 2,
    backgroundColor: well_color,
    minHeight: "100vh"
}

export const toolBarStyle = {
    paddingLeft: 1.75,
    paddingRight: 1.75,
    backgroundColor: white_color
}

export const textFieldStyle = {
    marginTop: 1
}

export const tableHeaderStyle = {
    padding: 1
}

export const tableHeaderItemStyle = {
    cursor: "pointer"
}

export const tableHeaderTypographyStyle = {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center"
}

export const filterItemStyle = {
    marginTop: 2
}

export const rowItemEverySecondStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.035)"
}

export const rowItemStyle = {
    padding: 1,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.02)"
    }
}

export const rowUnselectedItemStyle = {
    padding: 1
}

export const poRowItemStyle = {
    paddingLeft: 1, 
    paddingRight: 1
}

export const islandStyle = {
    borderRadius: 0,
    padding: 3,
    paddingTop: 2.5
}

export const gridSpacing = {
    marginTop: 0.75,
    marginBottom: 0.75,
    paddingRight: 1
}

export const gridItemStyle = {
    padding: 4,
    paddingTop: 8,
    paddingBottom: 12,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 4
}

export const listHeaderStyle = {
}

export const dialogContentStyle = {
    marginTop: 2
}

export const accordionIconStyle = {
    "& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root:hover": { backgroundColor: "#DADADA" }
}

export const listIndentStyle = {
    marginLeft: 5,
    marginBottom: 0.5
}