import Grid from "@mui/material/Grid";
import { ReactElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UpdateTimer from "components/universal/save_timer";
import { UpdateTime } from "api/types/update";
import { islandStyle } from "styles/style";

interface Props {
    title: string,
    subtitle?: string,
    update_pending: boolean,
    start_times: UpdateTime[],
    update: (update_time: UpdateTime) => void
    endProps: ReactElement
}

export default function Title({title, subtitle, update_pending, start_times, update, endProps}: Props) {
    return(
        <Grid container alignItems="center" sx={[islandStyle, {paddingTop: 0}]}>
            <Grid item xs={4}>
                <Typography variant="h3">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={4.5}>
                <Box display="flex" justifyContent="flex-end">
                    {endProps}
                </Box>
            </Grid>
            <Grid item xs={3.5} sx={{height: 25}}>
                <Box display="flex" justifyContent="flex-end" sx={{height: "100%"}}>
                {
                    update_pending ? 
                    <UpdateTimer 
                        fixed={true}
                        start_times={start_times}
                        update={update} /> :
                    null
                }
                </Box>
            </Grid>
            <Grid item xs={12}>
                {
                    subtitle && subtitle !== "" ?
                    <Typography variant="body2" sx={{paddingLeft: 0.25, fontWeight: 'regular'}}>
                        {subtitle}
                    </Typography> :
                    null
                }
            </Grid>
        </Grid>
    )
}


