/// HOOKS ///
import { useState } from "react";
/// MUI ///
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
/// TYPES ///
import { Color } from "api/types/material";
/// STYLES ///
import { gridSpacing } from "styles/style";

interface Props {
  start_update: (color: Color) => void,
  color: Color,
  setLocked: (locked: boolean) => void
}

export default function ColorEditingItem({ start_update, color, setLocked }: Props) {
  const [color_update, setColorUpdate] = useState<Color>(color);

  const saveString = (key: string, value: string) => {
    const new_color: Color = { ...color_update, [key]: value };
    setColorUpdate(new_color);
    start_update(new_color);
  }

  const saveNumber = (key: string, value: string) => {
    if (isNaN(Number(value)) === false) {
      const new_color: Color = { ...color_update, [key]: Number(value) };
      setColorUpdate(new_color);
      start_update(new_color);
    }
  }

  const saveBoolean = (key: string, value: boolean) => {
    const new_color: Color = { ...color_update, [key]: value };
    setColorUpdate(new_color);
    start_update(new_color);
  }

  return (
    <Grid container item>
      <Grid item xs={4} sx={gridSpacing}>
        <TextField
          onFocus={() => setLocked(true)}
          onBlur={() => setLocked(false)}
          onChange={(e) => saveString("name", e.target.value)}
          size="small"
          variant="standard"
          defaultValue={color.name}>
        </TextField>
      </Grid>
      <Grid item xs={2} sx={gridSpacing}>
        <TextField
          onFocus={() => setLocked(true)}
          onBlur={() => setLocked(false)}
          onChange={(e) => saveNumber("price", e.target.value)}
          size="small"
          type="number"
          variant="standard"
          defaultValue={color.price}>
        </TextField>
      </Grid>
      <Grid item xs={2} sx={gridSpacing}>
        <TextField
          onFocus={() => setLocked(true)}
          onBlur={() => setLocked(false)}
          onChange={(e) => saveNumber("length", e.target.value)}
          size="small"
          type="number"
          variant="standard"
          defaultValue={color.length}>
        </TextField>
      </Grid>
      <Grid item xs={2} sx={gridSpacing}>
        <TextField
          onFocus={() => setLocked(true)}
          onBlur={() => setLocked(false)}
          onChange={(e) => saveNumber("width", e.target.value)}
          size="small"
          type="number"
          variant="standard"
          defaultValue={color.width}>
        </TextField>
      </Grid>
      <Grid item xs={2} sx={gridSpacing}>
        <Checkbox
          sx={{ width: 15, height: 15, marginLeft: 2 }}
          onChange={(e) => saveBoolean("enabled", e.target.checked)}
          defaultChecked={color.enabled} />
      </Grid>
    </Grid>
  )
}
