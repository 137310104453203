import { primary_color } from "styles/style";
import { white_color } from "styles/style";

export const containedHeaderBackground = {
    backgroundColor: primary_color,
    padding: 0.5,
    marginBottom: 0.5
}

export const containedHeaderText = {
    color: white_color
}

export const outlinedHeaderBackground = {
    padding: 0.5,
    marginBottom: 0.5
}

export const outlinedHeaderText = {
    color: primary_color
}

export const iconStyle = {
    width: 22,
    height: 22
}