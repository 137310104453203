import { BumpInArc } from "api/types/price";

export enum ActionType {
    GET_BUMP_IN_ARCS_PENDING="GET_BUMP_IN_ARCS_PENDING",
    GET_BUMP_IN_ARCS_SUCCESS="GET_BUMP_IN_ARCS_SUCCESS",
    GET_BUMP_IN_ARCS_FAIL="GET_BUMP_IN_ARCS_FAIL",
    CREATE_BUMP_IN_ARC_PENDING = "CREATE_BUMP_IN_ARC_PENDING",
    CREATE_BUMP_IN_ARC_SUCCESS = "CREATE_BUMP_IN_ARC_SUCCESS",
    CREATE_BUMP_IN_ARC_FAIL = "CREATE_BUMP_IN_ARC_FAIL",
    UPDATE_BUMP_IN_ARC_PENDING = "UPDATE_BUMP_IN_ARC_PENDING",
    UPDATE_BUMP_IN_ARC_SUCCESS = "UPDATE_BUMP_IN_ARC_SUCCESS",
    UPDATE_BUMP_IN_ARC_FAIL = "UPDATE_BUMP_IN_ARC_FAIL",
    UPDATE_LOCAL_BUMP_IN_ARC = "UPDATE_LOCAL_BUMP_IN_ARC"
}

interface getBumpInArcsFail {
    type: ActionType.GET_BUMP_IN_ARCS_FAIL,
    payload: string
}

interface getBumpInArcsSuccess {
    type: ActionType.GET_BUMP_IN_ARCS_SUCCESS,
    payload: BumpInArc[]
}

interface getBumpInArcsPending {
    type: ActionType.GET_BUMP_IN_ARCS_PENDING
}

interface createBumpInArcPending {
  type: ActionType.CREATE_BUMP_IN_ARC_PENDING,
}

interface createBumpInArcSuccess {
  type: ActionType.CREATE_BUMP_IN_ARC_SUCCESS,
  payload: BumpInArc
}

interface createBumpInArcFail {
  type: ActionType.CREATE_BUMP_IN_ARC_FAIL,
  payload: string
}

interface updateBumpInArcPending {
  type: ActionType.UPDATE_BUMP_IN_ARC_PENDING,
}

interface updateBumpInArcSuccess {
  type: ActionType.UPDATE_BUMP_IN_ARC_SUCCESS,
  payload: BumpInArc
}

interface updateBumpInArcFail {
  type: ActionType.UPDATE_BUMP_IN_ARC_FAIL,
  payload: string
}

interface updateLocalBumpInArc {
    type: ActionType.UPDATE_LOCAL_BUMP_IN_ARC,
    payload: BumpInArc
}

export type Action =
    getBumpInArcsFail |
    getBumpInArcsPending |
    getBumpInArcsSuccess |
    createBumpInArcPending | 
    createBumpInArcSuccess | 
    createBumpInArcFail | 
    updateBumpInArcPending | 
    updateBumpInArcSuccess | 
    updateBumpInArcFail |
    updateLocalBumpInArc;
