import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getAccount } from "api/actions/account";
import { clearAccount } from "api/actions/account";
import { getStripeCustomer } from "api/actions/billing";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { AccountProfile } from "./section";
import { AccountStripePortal } from "./section";
import { AccountStripeSubscriptions } from "./section";
import { AccountStatus } from "./section";
import { AccountGeo } from "./section";
import { AccountWarnings } from "./section";
import Title from "components/universal/title";
import Steppers from "components/universal/stepper";
import { mainContentAreaStyle } from "styles/style";
import { islandStyle } from "styles/style";
import { is_account_setup_steps } from "values/steps";

export default function Account() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { account } = useAppSelector((state) => state.account);
    const { loading } = useAppSelector((state) => state.account);
    const { session } = useAppSelector((state) => state.billing);

    const getCurrentStep = (): number => {
        if(!account.customer_id || account.customer_id === ""){
            return 0;
        }
        else if(!account.current_price || account.current_price === "" || !account.current_product || account.current_product === ""){
            return 1;
        }
        else if(!account.payment_initialized){
            return 2;
        }
        else if(account.pending_account_confirmation){
            return 3;
        }
        else if(!account.subscription_active){
            return 4;
        }
        return 5;
    }

    useEffect(() => {
        dispatch(getAccount(id));
        dispatch(getStripeCustomer());
        return () => {
            dispatch(clearAccount());
        };
    }, []);

    if(session && session.session_url){
        window.location.href = session.session_url;
    }

    return (
        <Box sx={[mainContentAreaStyle]}>
            <Grid container justifyContent="space-between">
                <Title 
                    title="Account" 
                    subtitle={`${account.is_fabricator ? "Fabricator" : ""} ${account.is_distributor ? "Dealer" : ""}`}
                    update_pending={false}
                    start_times={[]}
                    update={() => void 0}
                    endProps={loading ? <CircularProgress  /> : <div></div>} />
                <Grid item xs={8.5}>
                    <Paper sx={[islandStyle]}>
                        {
                            account.id ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <AccountWarnings />
                                </Grid>
                                <Grid item xs={5.5}>
                                    <AccountProfile />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={5.5}>
                                    <AccountStatus />
                                </Grid>
                                <Grid item xs={12}>
                                    <AccountGeo />
                                </Grid>
                                <Grid item xs={12}>
                                    <AccountStripePortal />
                                </Grid>
                            </Grid> :
                            null
                        }
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={[islandStyle]}>
                        <Steppers current_step={getCurrentStep()} steps={is_account_setup_steps}/>
                    </Paper>
                </Grid>
            </Grid> 
        </Box>
    )
}