import MaterialToPriceListEditableItem from "./editable/material";
import MaterialToPriceListPlainItem from "./plain/material";
import Grid from "@mui/material/Grid";
import { MaterialToPriceList } from "api/types/price";

interface Props {
    index: number,
    item: MaterialToPriceList,
    hovered: number,
    setHovered: (index: number) => void
}

export default function MaterialToPriceListItem({index, item, hovered, setHovered}: Props) {
    return(
        <Grid container item onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)}>
            {
                index === hovered ?
                <MaterialToPriceListEditableItem
                    index={index}
                    item={item} /> :
                <MaterialToPriceListPlainItem
                    index={index}
                    item={item} />
            }
        </Grid>
    )
}
