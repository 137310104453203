import React from "react";
import { useState } from "react";
import { createFinishedEdge } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { FinishedEdge } from "api/types/price";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ApplicationScope from "components/price/dialog/shared_components/application_scope";
import Options from "components/price/dialog/shared_components/options";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { empty_finished_edge } from "values/empty/price";
import { NONE } from "values/values";
import { DOUBLE } from "values/values";
import { TRIPLE } from "values/values";

export default function CreateFinishedEdge() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [finished_edge, setFinishedEdge] = useState<FinishedEdge>(empty_finished_edge);
    const [error, setError] = useState<string>("");
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setFinishedEdge({...finished_edge, [key]: value});
        setError("");
    }

    const saveBoolean = (key: string, value: boolean) => {
        setFinishedEdge({...finished_edge, [key]: value});
        setError("");
    }

    const handleCreate = () => {
        if(!price_list.id){
            setError("An unknown error has occurred. Please contact the administrator");
            return;
        }

        dispatch(createFinishedEdge({...finished_edge, price_list: price_list.id}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setFinishedEdge(empty_finished_edge);
        setError("");
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={open}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Finished Edge</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth sx={{paddingTop: 1}}>
                        <FormHelperText error id="error">{error}</FormHelperText>
                        <ApplicationScope saveString={saveString} />
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="small"
                            variant="outlined"
                            select
                            defaultValue={NONE}
                            id="lamination"
                            label="Lamination"
                            onChange={(e) => saveString("lamination", e.target.value)}>
                                <MenuItem key={NONE} value={NONE}>
                                    {"None"}
                                </MenuItem>
                                <MenuItem key={DOUBLE} value={DOUBLE}>
                                    {"Double"}
                                </MenuItem>
                                <MenuItem key={TRIPLE} value={TRIPLE}>
                                    {"Triple"}
                                </MenuItem>
                        </TextField>
                        <Options 
                            saveBoolean={saveBoolean} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
