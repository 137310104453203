import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";
import { store } from "api/store";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MainRouter from "components/navigation/router";
import { STRIPE_PUBLIC_KEY } from "settings";
import "./App.css";
/// THEMES ///
import { theme } from "themes";

export default function App() {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <Box>
            <MainRouter />
          </Box>
        </Elements>
      </ThemeProvider>
    </Provider>
  )
}
