import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getSinkCutouts } from "api/actions/price/sink_cutout";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateSinkCutout } from "../dialog/create";
import SinkCutoutItem from "../row/sink_cutout";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { SinkCutout } from "api/types/price";
import { SINK_CUTOUT } from "api/types/update";
import { SINK_CUTOUT_RANGE } from "api/types/update";

interface Props {
    start_update: (type: number, data: SinkCutout) => void
}
export default function PriceListSinkCutouts({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { sink_cutouts } = useAppSelector((state) => state.price);

    const handle_update = (update_item: SinkCutout) => {
        if("measurement" in update_item){
            start_update(SINK_CUTOUT_RANGE, update_item)
        }
        else{
            start_update(SINK_CUTOUT, update_item);
        }
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getSinkCutouts(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Sink Cutouts"
                    endProps={
                        <CreateSinkCutout />} />
                <Header 
                    items={[
                        {text: "Type/Range", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={sink_cutouts}
                    Item={SinkCutoutItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}
