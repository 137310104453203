import Name from "../shared/name";
import Checkboxes from "./shared/checkboxes";
import Grid from "@mui/material/Grid";
import List from "components/universal/list";
import InsideRadiusCornerRangePlainItem from "./inside_radius_corner_range";
import { InsideRadiusCorner } from "api/types/price";
import { getRowSecondStyle } from 'values/style';
import { getMaterialHeightDisplay } from "values/material";
import BasicPlainRangeItem from "./shared/basic_range";

interface Props {
    index: number,
    item: InsideRadiusCorner
}

export default function InsideRadiusCornerPlainItem({index, item}: Props) {

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <BasicPlainRangeItem 
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false}/>
            <List 
                items={item.ranges ? item.ranges: []}
                Item={InsideRadiusCornerRangePlainItem}/>
        </Grid>
    )
}
