/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getSubRegions } from "api/actions/region";
/// TYPES ///
import { SubRegion } from "api/types/region";
/// MUI ///
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
/// TITLE ///
import Title from "components/universal/title";
/// STYLES ///
import { mainContentAreaStyle } from "styles/style";
/// URLS ///
import { material_url } from "components/navigation/endpoints";

export default function MaterialSubRegions() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sub_regions } = useAppSelector((state) => state.region);

  useEffect(() => {
    dispatch(getSubRegions());
  }, [dispatch]);

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title="Material SubRegions"
            update_pending={false}
            start_times={[]}
            update={() => void 0}
            endProps={<div></div>} />
        </Grid>
        {
          sub_regions.map((subr: SubRegion) => {
            return (
              <Grid item xs={12}>
                <Button onClick={() => navigate(`/${material_url}/${subr.id}`)}>
                  {subr.name}
                </Button>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}
