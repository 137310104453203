import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getApplianceEdges } from "api/actions/price/appliance_edge";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import CreateApplianceEdge from "../dialog/create/appliance_edge";
import ApplianceEdgeItem from "../row/appliance_edge";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { ApplianceEdge } from "api/types/price";
import { APPLIANCE_EDGE } from "api/types/update";

interface Props {
    start_update: (type: number, data: ApplianceEdge) => void
}
export default function PriceListApplianceEdge({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { appliance_edges } = useAppSelector((state) => state.price);

    const handle_update = (update_item: ApplianceEdge) => {
        start_update(APPLIANCE_EDGE, update_item);
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getApplianceEdges(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Appliance Edges"
                    endProps={
                        <CreateApplianceEdge />} />
                <Header 
                    items={[
                        {text: "Height", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={appliance_edges}
                    Item={ApplianceEdgeItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}
