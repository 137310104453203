import TextField from "@mui/material/TextField";
import { textFieldStyle } from "styles/style";

interface Props {
    init_value: string,
    data_key: string,
    label: string,
    update: (key: string, value: string) => void
}

export default function StringField({init_value, data_key, label, update}: Props){
    return (
        <TextField 
            fullWidth
            defaultValue={init_value}
            label={label}
            sx={textFieldStyle}
            id={data_key}
            onChange={(e) => update(data_key, e.target.value)}></TextField>
    )
}