import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/feedback';
import { ActionType } from 'api/action_types/feedback';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { CreateBugLog } from 'api/types/feedback';
import { url_prefix } from 'settings';

const bug_log_url: string = 'bug_log';

export const createBugLog = (bug_log: CreateBugLog) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.BUG_LOG_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bug_log_url}/`;

        try
        {
            const {data} = await axios.post(url, {...bug_log, user: user.id}, config);

            dispatch({
                type: ActionType.CREATE_BUG_LOG_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.BUG_LOG_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}