import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/price";
import { ActionType } from "api/action_types/price";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Miter } from "api/types/price";
import { url_prefix } from "settings";

const miter_url: string = "miter";

export const getMiters = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_MITERS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${miter_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_MITERS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_MITERS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createMiter = (miter: Miter) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_MITER_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${miter_url}/`;

        try
        {
            const {data} = await axios.post(url, miter, config);

            dispatch({
                type: ActionType.CREATE_MITER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_MITER_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateMiter = (miter: Miter, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_MITER_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${miter_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, miter, config);

            dispatch({
                type: ActionType.UPDATE_MITER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_MITER_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateLocalMiter = (miter: Miter) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_MITER,
            payload: miter
        });
    }
}