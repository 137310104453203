import { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { Color } from "api/types/material";
import { gridSpacing } from "styles/style";

interface Props {
    color: Color
}

export default function ColorItemPlain({color}: Props) {
    return(
        <Fragment>
            <Grid item xs={4} sx={gridSpacing}>
                <Typography variant="body2" sx={{marginLeft: 0.2}}>
                    {color.name}
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={gridSpacing}>
                <Typography variant="body2" sx={{marginLeft: 0.2}}>
                    ${color.price}
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={gridSpacing}>
                <Typography variant="body2" sx={{marginLeft: 0.2}}>
                    {color.length}
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={gridSpacing}>
                <Typography variant="body2" sx={{marginLeft: 0.2}}>
                    {color.width}
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={gridSpacing}>
                <Checkbox 
                    sx={{height: 15, width: 15, marginLeft: 2}}
                    disabled
                    defaultChecked={color.enabled} />
            </Grid>
        </Fragment>
    )
}
