import React from "react";
import { useState } from "react";
import { createBumpInArc } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { BumpInArc } from "api/types/price";
import { PriceDetailError } from "api/types/price";
import { PriceDetailHasError } from "api/types/price";
import CreateBasic from "../shared_components/create_basic";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { empty_bump_in_arc } from "values/empty/price";
import { empty_has_error } from "values/empty/price";
import { empty_error } from "values/empty/price";

export default function CreateBumpInArc() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [bump_in_arc, setBumpInArc] = useState<BumpInArc>(empty_bump_in_arc);
    const [errors, setErrors] = useState<PriceDetailError>(empty_error);
    const [has_errors, setHasErrors] = useState<PriceDetailHasError>(empty_has_error);
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setBumpInArc({...bump_in_arc, [key]: value});
        setErrors({...errors, [key]: ""});
        setHasErrors({...has_errors, [key]: false});
    }

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            setBumpInArc({...bump_in_arc, [key]: Number(value)});
            setErrors({...errors, [key]: ""});
            setHasErrors({...has_errors, [key]: false});
        }
    }

    const saveBoolean = (key: string, value: boolean) => {
        setBumpInArc({...bump_in_arc, [key]: value});
        setErrors({...errors, [key]: ""});
        setHasErrors({...has_errors, [key]: false});
    }

    const handleCreate = () => {
        let error = false;
        let errors: PriceDetailError = empty_error;
        let has_errors: PriceDetailHasError = empty_has_error;

        if(has_errors.price || bump_in_arc.price === 0){
            errors = {
                ...errors,
                price: "Please enter a number for the price"
            }
            has_errors = {
                ...has_errors,
                price: true
            }
            error = true;
        }

        if(!price_list.id){
            errors = {
                ...errors,
                price_list: "An unknown error has occurred. Please contact the administrator"
            }
            error = true;
        }

        if(error){
            setErrors(errors);
            setHasErrors(has_errors);
            return;
        }

        dispatch(createBumpInArc({...bump_in_arc, price_list: price_list.id}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setBumpInArc(empty_bump_in_arc);
        setErrors(empty_error);
        setHasErrors(empty_has_error)
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <CreateBasic 
                title="Create Bump In Arc Price" 
                open={open}
                errors={errors}
                has_errors={has_errors}
                close={() => setOpen(false)}
                create={handleCreate}
                saveString={saveString}
                saveBoolean={saveBoolean}
                saveNumber={saveNumber}/>
        </React.Fragment>
    )
}
