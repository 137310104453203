import { useRef } from "react";
import { useEffect } from "react";
import { UpdateTime } from "api/types/update";
import HashLoader from "animation/hash_loader";
import { primary_color } from "styles/style";
import { secondary_color } from "styles/style";
import { third_color } from "styles/style";
import { error_color } from "styles/style";

interface Props {
    start_times: UpdateTime[],
    update: (data: UpdateTime) => void,
    fixed?: boolean,
    size?: number,
    font_size?: number,
    update_text?: string
}

export default function UpdateTimer({start_times, update, update_text="Saving", size=24, font_size=16, fixed}: Props) {
    useInterval(() => {
        const time = Math.round(new Date().getTime() / 1000);
        for(let i = 0; i < start_times.length; i++){
            if(time - start_times[i].time > 1){
                update(start_times[i]);
            }
        }
    }, 1000); 
    
    function useInterval(callback: () => void, delay: number) {
        const savedCallback = useRef<() => void>();
    
        useEffect(() => {
            if(savedCallback){
                savedCallback.current = callback;
            }
        }, [callback]);
      
        useEffect(() => {
          function tick() {
            if(savedCallback && savedCallback.current){
                savedCallback.current();
            }
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
    }
    
    return (
        <HashLoader 
            fixed={fixed}
            color_1={primary_color}
            color_2={secondary_color}
            color_3={third_color}
            color_4={error_color} 
            font_size={font_size}
            text={update_text}
            size={size} />
    );
}