import { BumpedOutCorner } from "api/types/price";

export enum ActionType {
    GET_BUMPED_OUT_CORNERS_PENDING="GET_BUMPED_OUT_CORNERS_PENDING",
    GET_BUMPED_OUT_CORNERS_SUCCESS="GET_BUMPED_OUT_CORNERS_SUCCESS",
    GET_BUMPED_OUT_CORNERS_FAIL="GET_BUMPED_OUT_CORNERS_FAIL",
    CREATE_BUMPED_OUT_CORNER_PENDING = "CREATE_BUMPED_OUT_CORNER_PENDING",
    CREATE_BUMPED_OUT_CORNER_SUCCESS = "CREATE_BUMPED_OUT_CORNER_SUCCESS",
    CREATE_BUMPED_OUT_CORNER_FAIL = "CREATE_BUMPED_OUT_CORNER_FAIL",
    UPDATE_BUMPED_OUT_CORNER_PENDING = "UPDATE_BUMPED_OUT_CORNER_PENDING",
    UPDATE_BUMPED_OUT_CORNER_SUCCESS = "UPDATE_BUMPED_OUT_CORNER_SUCCESS",
    UPDATE_BUMPED_OUT_CORNER_FAIL = "UPDATE_BUMPED_OUT_CORNER_FAIL",
    UPDATE_LOCAL_BUMP_OUT_CORNER = "UPDATE_LOCAL_BUMP_OUT_CORNERS_CORNER"
}

interface getBumpedOutCornersFail {
    type: ActionType.GET_BUMPED_OUT_CORNERS_FAIL,
    payload: string
}

interface getBumpedOutCornersSuccess {
    type: ActionType.GET_BUMPED_OUT_CORNERS_SUCCESS,
    payload: BumpedOutCorner[]
}

interface getBumpedOutCornersPending {
    type: ActionType.GET_BUMPED_OUT_CORNERS_PENDING
}
  
interface createBumpedOutCornerPending {
    type: ActionType.CREATE_BUMPED_OUT_CORNER_PENDING,
}
  
interface createBumpedOutCornerSuccess {
    type: ActionType.CREATE_BUMPED_OUT_CORNER_SUCCESS,
    payload: BumpedOutCorner
}
  
interface createBumpedOutCornerFail {
    type: ActionType.CREATE_BUMPED_OUT_CORNER_FAIL,
    payload: string
}

interface updateBumpedOutCornerPending {
    type: ActionType.UPDATE_BUMPED_OUT_CORNER_PENDING,
}
  
interface updateBumpedOutCornerSuccess {
    type: ActionType.UPDATE_BUMPED_OUT_CORNER_SUCCESS,
    payload: BumpedOutCorner[];
}
  
interface updateBumpedOutCornerFail {
    type: ActionType.UPDATE_BUMPED_OUT_CORNER_FAIL,
    payload: string
}

interface updateLocalBumpedOutCorner {
    type: ActionType.UPDATE_LOCAL_BUMP_OUT_CORNER,
    payload: BumpedOutCorner
}

export type Action = 
    getBumpedOutCornersFail |
    getBumpedOutCornersPending |
    getBumpedOutCornersSuccess |
    createBumpedOutCornerFail |
    createBumpedOutCornerPending |
    createBumpedOutCornerSuccess |
    updateBumpedOutCornerFail |
    updateBumpedOutCornerPending |
    updateBumpedOutCornerSuccess |
    updateLocalBumpedOutCorner;
  