import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { FeedbackFilter } from "api/types/feedback";
import { getFeedbacks } from "api/actions/feedback";
import { UpdateTime } from "api/types/update";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import UpdateTimer from "components/universal/save_timer";
import { filterItemStyle } from "styles/style";
import { getResolutionChoiceDisplay } from "values/feedback";
import { getFeedbackTypeDisplay } from "values/feedback";
import { ACCEPTED } from "values/feedback";
import { REJECTED } from "values/feedback";
import { TRACKER_CREATED } from "values/feedback";
import { SUBMITTED } from "values/feedback";
import { BUG } from "values/feedback";
import { FEATURE } from "values/feedback";
import { OTHER } from "values/feedback";
import { tableHeaderStyle } from "styles/style";

export default function FeedbackFilterComponent() {
    const dispatch = useAppDispatch();
    const [feedback_filter, setFeedbackFilter] = useState<FeedbackFilter>({});
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_time, setStartTime] = useState<UpdateTime[]>([]);

    const filter = () => {
        dispatch(getFeedbacks(feedback_filter));
        setUpdatePending(false);
        setStartTime([]);
    }

    const handleResolution = (new_resolution: string) => {
        setUpdatePending(true);
        if(new_resolution === ""){
            if(feedback_filter.resolution){
                const {resolution, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, resolution: new_resolution});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDescription = (new_description: string) => {
        setUpdatePending(true);
        setFeedbackFilter({...feedback_filter, description: new_description});
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleFeedbackType = (new_feedback_type: string) => {
        setUpdatePending(true);
        if(new_feedback_type === ""){
            if(feedback_filter.feedback_type){
                const {feedback_type, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, feedback_type: new_feedback_type});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleResolved = (new_resolved: string) => {
        setUpdatePending(true);
        if(new_resolved === ""){
            if(feedback_filter.resolved){
                const {resolved, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            if(new_resolved === "Resolved"){
                setFeedbackFilter({...feedback_filter, resolved: true});
            }
            else if(new_resolved === "Not Resolved"){
                setFeedbackFilter({...feedback_filter, resolved: false});
            }
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDateAddedAfter = (new_date_added_after: string) => {
        setUpdatePending(true);
        if(!new_date_added_after || new_date_added_after === ""){
            if(feedback_filter.date_added_after){
                const {date_added_after, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, date_added_after: new_date_added_after});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDateAddedBefore = (new_date_added_before: string) => {
        setUpdatePending(true);
        if(!new_date_added_before || new_date_added_before === ""){
            if(feedback_filter.date_added_before){
                const {date_added_before, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, date_added_before: new_date_added_before});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDateResolvedAfter = (new_date_resolved_after: string) => {
        setUpdatePending(true);
        if(!new_date_resolved_after || new_date_resolved_after === ""){
            if(feedback_filter.date_resolved_after){
                const {date_resolved_after, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, date_resolved_after: new_date_resolved_after});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDateResolvedBefore = (new_date_resolved_before: string) => {
        setUpdatePending(true);
        if(!new_date_resolved_before || new_date_resolved_before === ""){
            if(feedback_filter.date_resolved_before){
                const {date_resolved_before, ...rest} = feedback_filter;
                setFeedbackFilter(rest);
            }
        }
        else{
            setFeedbackFilter({...feedback_filter, date_resolved_before: new_date_resolved_before});
        }
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    return(
        <Grid 
            container 
            sx={[tableHeaderStyle, {paddingRight: 8}]}
            alignItems="center">
            <Grid item xs={10}>
                <Typography variant="body2">
                    <b>Filter</b>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {
                    update_pending ? 
                    <UpdateTimer 
                        update_text="Loading"
                        font_size={14}
                        size={12}
                        fixed={false}
                        start_times={start_time}
                        update={filter} /> :
                    null
                }
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        id="description"
                        label="Description Search"
                        onChange={(e) => handleDescription(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        select
                        id="resolution"
                        label="Resolution"
                        defaultValue=""
                        onChange={(e) => handleResolution(e.target.value)}>
                            <MenuItem key={""} value={""}>
                                {"         "}
                            </MenuItem>
                            <MenuItem key={ACCEPTED} value={ACCEPTED}>
                                {getResolutionChoiceDisplay(ACCEPTED)}
                            </MenuItem>
                            <MenuItem key={REJECTED} value={REJECTED}>
                                {getResolutionChoiceDisplay(REJECTED)}
                            </MenuItem>
                            <MenuItem key={TRACKER_CREATED} value={TRACKER_CREATED}>
                                {getResolutionChoiceDisplay(TRACKER_CREATED)}
                            </MenuItem>
                            <MenuItem key={SUBMITTED} value={SUBMITTED}>
                                {getResolutionChoiceDisplay(SUBMITTED)}
                            </MenuItem>
                    </TextField>
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        select
                        id="feedback_type"
                        label="Type"
                        defaultValue=""
                        onChange={(e) => handleFeedbackType(e.target.value)}>
                            <MenuItem key={""} value={""}>
                                {"         "}
                            </MenuItem>
                            <MenuItem key={BUG} value={BUG}>
                                {getFeedbackTypeDisplay(BUG)}
                            </MenuItem>
                            <MenuItem key={FEATURE} value={FEATURE}>
                                {getFeedbackTypeDisplay(FEATURE)}
                            </MenuItem>
                            <MenuItem key={OTHER} value={OTHER}>
                                {getFeedbackTypeDisplay(OTHER)}
                            </MenuItem>
                    </TextField>
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        select
                        id="resolved"
                        label="Status"
                        defaultValue=""
                        onChange={(e) => handleResolved(e.target.value)}>
                            <MenuItem key={""} value={""}>
                                {"         "}
                            </MenuItem>
                            <MenuItem key={"Resolved"} value={"Resolved"}>
                                Resolved
                            </MenuItem>
                            <MenuItem key={"Not Resolved"} value={"Not Resolved"}>
                                Not Resolved
                            </MenuItem>
                    </TextField>
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Added After"
                        id="date_added_after"
                        onChange={(e) => handleDateAddedAfter(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Added Before"
                        id="date_added_before"
                        onChange={(e) => handleDateAddedBefore(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        label="Date Resolved After"
                        variant="outlined"
                        id="date_resolved_after"
                        onChange={(e) => handleDateResolvedAfter(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Resolved Before"
                        id="date_resolved_before"
                        onChange={(e) => handleDateResolvedBefore(e.target.value)} />
                </FormControl>
            </Grid>
        </Grid>
    )
}