import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { deleteSinkCutoutRange } from "api/actions/price";
import { SinkCutoutRange } from "api/types/price";
import RangeEditableItem from "./shared/range_item";

interface Props {
    index: number,
    last: boolean,
    item: SinkCutoutRange,
    start_update: (update_item: SinkCutoutRange) => void,
    setLocked: (locked: boolean) => void
}

export default function SinkCutoutRangeEditableItem({last, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [sink_cutout_range_update, setSinkCutoutRangeUpdate] = useState<SinkCutoutRange>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_sink_cutout_range: SinkCutoutRange = {...sink_cutout_range_update, [key]: Number(value)};
            setSinkCutoutRangeUpdate(new_sink_cutout_range);
            start_update(new_sink_cutout_range);
        }
    }

    const handleDelete = () => {
        if(item.id){
            dispatch(deleteSinkCutoutRange(item.id));
        }
    }

    return(
        <RangeEditableItem 
            price={item.price ? item.price : 0}
            rate="$"
            measurement={item.measurement ? item.measurement : 0}
            measurement_key="measurement"
            any_range={item.any_range ? item.any_range : false}
            saveNumber={saveNumber}
            deleteRange={handleDelete}
            setLocked={setLocked} />
    )
}
