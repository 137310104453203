import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { gridSpacing } from "styles/style";

interface Props {
    price: number,
    width: number
}

export default function Price({price, width}: Props) {

    return(
        <Grid item xs={width} sx={gridSpacing}>
            <Typography variant="body2"> {price} </Typography>
        </Grid>  
    )
}
