import React from "react";
import { useState } from "react";
import { createSplash } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { SplashRange } from "api/types/price";
import { Splash } from "api/types/price";
import CreateBasicRange from "../shared_components/create_range";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { empty_splash } from "values/empty/price";
import { empty_splash_range } from "values/empty/price";

export default function CreateSplash() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [splash, setSplash] = useState<Splash>(empty_splash);
  const [error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);
  const [ranges, setRanges] = useState<SplashRange[]>([{ ...empty_splash_range, any_range: true }]);
  const { price_list } = useAppSelector((state) => state.price);

  const saveString = (key: string, value: string) => {
    setSplash({ ...splash, [key]: value });
    setError("");
    setHasError(false);
  }

  const saveBoolean = (key: string, value: boolean) => {
    setSplash({ ...splash, [key]: value });
    setError("");
    setHasError(false);
  }

  const handleRangePrice = (index: number, price: string) => {
    if (isNaN(Number(price)) === false) {
      setRanges([...ranges.map((range, inner_index) => inner_index === index ?
        { ...range, price: Number(price) } :
        range
      )]);
    }
  }

  const handleRangeMeasurement = (index: number, height: string) => {
    if (isNaN(Number(height)) === false) {
      setRanges([...ranges.map((range, inner_index) => inner_index === index ?
        { ...range, radius: Number(height) } :
        range
      )]);
    }
  }

  const addRange = () => {
    setRanges([{ ...empty_splash, height: 0 }, ...ranges]);
  }

  const handleCreate = () => {
    if (!price_list.id) {
      setError("An unknown error has occurred. Please contact the administrator");
      setHasError(true);
      return;
    }

    dispatch(createSplash({ ...splash, price_list: price_list.id, ranges: ranges }));
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setSplash(empty_splash);
    setRanges([{ ...empty_splash_range, any_range: true }]);
  }

  return (
    <React.Fragment>
      <IconButton onClick={(e) => setOpen(true)}>
        <AddCircleIcon
          color="primary" />
      </IconButton>
      <CreateBasicRange
        title="Create Splash Corner"
        open={open}
        error={error}
        has_error={has_error}
        ranges={ranges}
        close={handleClose}
        create={handleCreate}
        saveString={saveString}
        saveBoolean={saveBoolean}
        handleRangeMeasurement={handleRangeMeasurement}
        handleRangePrice={handleRangePrice}
        addRange={addRange} />
    </React.Fragment>
  )
}
