import { Fragment } from "react";
import Name from "../../shared/name";
import Checkboxes from "./checkboxes";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
    material_thickness: string,
    enabled: boolean,
    taxable: boolean
}

export default function BasicPlainRangeItem({material_thickness, enabled, taxable}: Props) {
    return(
        <Fragment>
            <Name 
                bold={false}
                name={getMaterialHeightDisplay(material_thickness)} 
                width={8} />
            <Checkboxes 
                grid_width={4}
                enabled={enabled}
                taxable={taxable} />
        </Fragment>
    )
}
