import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { tableHeaderStyle } from 'styles/style';

export default function RegionsHeader() {
    return(
        <Grid 
            sx={tableHeaderStyle}
            container 
            item 
            xs={12}>
            <Grid item xs={2}>
                <Typography variant="body2">
                    <b>Name</b>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" align="right">
                    <b>Default Quote Expiration</b>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" align="right">
                    <b>Default Tax Rate</b>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" align="right">
                    <b>Enabled</b>
                </Typography>
            </Grid>
        </Grid>
    )
}
