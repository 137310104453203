import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { deleteRadiusCornerRange } from "api/actions/price";
import RangeEditableItem from "./shared/range_item";
import { RadiusCornerRange } from "api/types/price";

interface Props {
    index: number,
    last: boolean,
    item: RadiusCornerRange,
    start_update: (update_item: RadiusCornerRange) => void,
    setLocked: (locked: boolean) => void
}

export default function RadiusCornerRangeEditableItem({last, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [radius_corner_range_update, setRadiusCornerRangeUpdate] = useState<RadiusCornerRange>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_radius_corner_range: RadiusCornerRange = {...radius_corner_range_update, [key]: Number(value)};
            setRadiusCornerRangeUpdate(new_radius_corner_range);
            start_update(new_radius_corner_range);
        }
    }

    const handleDelete = () => {
        if(item.id){
            dispatch(deleteRadiusCornerRange(item.id));
        }
    }

    return(
        <RangeEditableItem 
            price={item.price ? item.price : 0}
            rate="$"
            measurement={item.radius ? item.radius : 0}
            measurement_key="radius"
            any_range={item.any_range ? item.any_range : false}
            saveNumber={saveNumber}
            deleteRange={handleDelete}
            setLocked={setLocked} />
    )
}
