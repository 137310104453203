import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { error_color } from "styles/style";

const gridItemStyle = {paddingBottom: 1.5};

interface Props {
    price: number | string,
    rate: string,
    measurement: number | string,
    any_range: boolean,
    measurement_key: string,
    saveNumber: (key: string, value: string) => void,
    deleteRange: () => void,
    setLocked: (locked: boolean) => void
}

export default function RangeEditableItem({
    price,
    rate,
    measurement,
    any_range,
    measurement_key,
    saveNumber,
    deleteRange,
    setLocked
}: Props) {
    return(
        <Fragment>
            <Grid item xs={2} sx={gridItemStyle}>
                {
                    any_range ? 
                    <Typography variant="body2">
                        Any Radius
                    </Typography> :
                    <TextField
                        onFocus={() => setLocked(true)}
                        onBlur={() => setLocked(false)}
                        onChange={(e) => saveNumber(measurement_key, e.target.value)}
                        size="small"
                        variant="standard"
                        type="number"
                        InputLabelProps={{shrink: true}}
                        defaultValue={measurement}>
                    </TextField>
                } 
            </Grid>
            <Grid item xs={2} sx={gridItemStyle}>
                <TextField
                    onFocus={() => setLocked(true)}
                    onBlur={() => setLocked(false)}
                    onChange={(e) => saveNumber("price", e.target.value)}
                    InputLabelProps={{shrink: true}}
                    size="small"
                    variant="standard"
                    type="number"
                    defaultValue={price}>
                </TextField>
            </Grid>
            <Grid item xs={7} sx={gridItemStyle}>
                <Typography variant="body2">
                    {rate}
                </Typography>
            </Grid>
            <Grid item xs={1} sx={[{textAlign: "right"}]}>  
                {
                    !any_range ?
                    <IconButton  
                        size="small"
                        onClick={deleteRange}
                        sx={{color: error_color}}>
                        <DeleteIcon sx={{width: 20, height: 20}}/>
                    </IconButton> :
                    null
                }
            </Grid>
        </Fragment>
    )
}
