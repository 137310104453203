import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { gridSpacing } from "styles/style";

interface Props {
    price: number,
    setPrice: (key: string, price: string) => void,
    width: number,
    setLocked: (locked: boolean) => void
}

export default function Price({price, setPrice, width, setLocked}: Props) {

    return(
        <Grid item xs={width} sx={gridSpacing}>
            <TextField 
                onFocus={() => setLocked(true)}
                onBlur={() => setLocked(false)}
                onChange={(e) => setPrice("price", e.target.value)}
                size="small"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                type="number"
                defaultValue={price}>
            </TextField>
        </Grid>  
    )
}
