import List from "components/universal/list";
import Grid from "@mui/material/Grid";
import CooktopCutoutRangeItem from "./cooktop_cutout_range";
import { CooktopCutout } from "api/types/price";
import { getRowSecondStyle } from 'values/style';
import BasicPlainRangeItem from "./shared/basic_range";

interface Props {
    index: number,
    item: CooktopCutout
}

export default function CooktopCutoutPlainItem({index, item}: Props) {

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <BasicPlainRangeItem 
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false}/>
            <List 
                items={item.ranges ? item.ranges : []}
                Item={CooktopCutoutRangeItem} />
        </Grid>
    )
}
