import { useNavigate } from 'react-router-dom';
import { Feedback } from 'api/types/feedback';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getFeedbackTypeDisplay } from 'values/feedback';
import { getRowSecondStyle } from 'values/style';
import { rowItemStyle } from 'styles/style';
import { getDate } from 'functions/date';
import { feedback_url } from 'components/navigation/endpoints';

interface Props {
    index: number,
    item: Feedback
}

export default function FeedbackRow({index, item}: Props) {
    const navigate = useNavigate();

    return(
        <Grid 
            container
            item
            xs={12}
            sx={[getRowSecondStyle(index), rowItemStyle]} 
            onClick={() => navigate(`/${feedback_url}/${item.id}`, {replace: false})}>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {item.reported_by_user}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {getFeedbackTypeDisplay(item.feedback_type)}
                </Typography>
            </Grid>
            <Grid item xs={3.5}>
                <Typography variant="body2">
                    {item.description}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {getDate(item.date_added)}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {item.date_resolved ? getDate(item.date_resolved) : ""}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {item.resolved ? "Resolved" : "Not Resolved"}
                </Typography>
            </Grid>
        </Grid> 
    )
}
