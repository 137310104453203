import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getUploadInstances } from "api/actions/material_import";
import Title from "components/universal/title";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import List from "components/universal/list";
import FilterUploadInstances from "./filter/upload_instances";
import UploadInstanceHeader from "./header/upload_instance";
import CreateUpload from "./dialog/create_upload";
import UploadRow from "./row/upload";
import { mainContentAreaStyle } from "styles/style";
import { default_pagination_length } from "styles/table";

export default function MaterialImporter() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const { uploads } = useAppSelector((state) => state.material_import);

  useEffect(() => {
    dispatch(getUploadInstances());
  }, [dispatch]);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title="Upload Attempts"
            subtitle={`${uploads.length} Results`}
            update_pending={false}
            start_times={[]}
            update={() => void 0}
            endProps={<div></div>} />
        </Grid>
        <Grid item xs={12}>
          <CreateUpload />
        </Grid>
        <Grid item xs={8.5}>
          <Grid container>
            <UploadInstanceHeader />
            <List
              items={uploads.slice((page - 1) * default_pagination_length, page * default_pagination_length)}
              Item={UploadRow} />
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  onChange={changePage}
                  count={
                    uploads.length > default_pagination_length ?
                      Math.ceil(uploads.length / default_pagination_length) :
                      1
                  } shape="rounded" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <FilterUploadInstances />
        </Grid>
      </Grid>
    </Box>
  )
}
