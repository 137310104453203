import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { deleteMiterRange } from "api/actions/price";
import RangeEditableItem from "./shared/range_item";
import { MiterRange } from "api/types/price";

interface Props {
    index: number,
    item: MiterRange,
    start_update: (update_item: MiterRange) => void,
    setLocked: (locked: boolean) => void
}

export default function MiterRangeEditableItem({item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [miter_range_update, setMiterRangeUpdate] = useState<MiterRange>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_miter_range: MiterRange = {...miter_range_update, [key]: Number(value)};
            setMiterRangeUpdate(new_miter_range);
            start_update(new_miter_range);
        }
    }

    const handleDelete = () => {
        if(item.id){
            dispatch(deleteMiterRange(item.id));
        }
    }

    return(
        <RangeEditableItem 
            price={item.price ? item.price : 0}
            rate="$/LIN FT"
            measurement_key="height"
            measurement={item.height ? item.height : 0}
            any_range={item.any_range ? item.any_range : false}
            saveNumber={saveNumber}
            deleteRange={handleDelete}
            setLocked={setLocked} />
    )
}
