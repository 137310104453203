import { UploadInstance } from "api/types/material_import"

export enum ActionType {
    GET_UPLOAD_INSTANCES_SUCCESS = "GET_UPLOAD_INSTANCES_SUCCESS",
    GET_UPLOAD_INSTANCE_SUCCESS = "GET_UPLOAD_INSTANCE_SUCCESS",
    UPLOAD_INSTANCE_PENDING = "UPLOAD_INSTANCE_PENDING",
    UPLOAD_INSTANCE_FAIL = "UPLOAD_INSTANCE_FAIL",
    MATERIAL_UPLOAD_SUCCESS = "MATERIAL_UPLOAD_SUCCESS"
}

interface getUploadInstancesSuccess {
    type: ActionType.GET_UPLOAD_INSTANCES_SUCCESS,
    payload: UploadInstance[]
}

interface getUploadInstanceSuccess {
    type: ActionType.GET_UPLOAD_INSTANCE_SUCCESS,
    payload: UploadInstance
}

interface uploadInstancePending {
    type: ActionType.UPLOAD_INSTANCE_PENDING
}

interface uploadInstanceFail {
    type: ActionType.UPLOAD_INSTANCE_FAIL,
    payload: string
}

interface materialUploadSuccess {
    type: ActionType.MATERIAL_UPLOAD_SUCCESS,
    payload: UploadInstance
}

export type Action = 
    getUploadInstancesSuccess |
    getUploadInstanceSuccess |
    uploadInstancePending |
    uploadInstanceFail |
    materialUploadSuccess;