import { useNavigate } from "react-router-dom";
import { User } from "api/types/authentication";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { getRowSecondStyle } from "values/style";
import { rowItemStyle } from "styles/style";
import { account_url } from "components/navigation/endpoints";

interface Props {
    index: number,
    item: User
}

export default function AccountsRow({index, item}: Props) {
    const navigate = useNavigate();

    return(
        <Grid 
            container 
            item
            alignContent="center" 
            alignItems="center" 
            onClick={() => navigate(`/${account_url}/${item.id}`, {replace: false})}
            sx={[rowItemStyle, getRowSecondStyle(index)]}>
            <Grid item xs={4}>
                <Typography variant="body2">
                    {`${item.first_name} ${item.last_name}`}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    {`${item.address} ${item.city}`}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {item.phone_number}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" align="right">
                    {item.email}
                </Typography>
            </Grid>
        </Grid> 
    )
}
