import { CooktopCutout } from "api/types/price";
import { CooktopCutoutRange } from "api/types/price";

export enum ActionType {
    GET_COOKTOP_CUTOUTS_PENDING="GET_COOKTOP_CUTOUTS_PENDING",
    GET_COOKTOP_CUTOUTS_SUCCESS="GET_COOKTOP_CUTOUTS_SUCCESS",
    GET_COOKTOP_CUTOUTS_FAIL="GET_COOKTOP_CUTOUTS_FAIL",
    CREATE_COOKTOP_CUTOUT_SUCCESS = "CREATE_COOKTOP_CUTOUT_SUCCESS",
    CREATE_COOKTOP_CUTOUT_PENDING = "CREATE_COOKTOP_CUTOUT_PENDING",
    CREATE_COOKTOP_CUTOUT_FAIL = "CREATE_COOKTOP_CUTOUT_FAIL",
    UPDATE_COOKTOP_CUTOUT_PENDING = "UPDATE_COOKTOP_CUTOUT_PENDING",
    UPDATE_COOKTOP_CUTOUT_SUCCESS = "UPDATE_COOKTOP_CUTOUT_SUCCESS",
    UPDATE_COOKTOP_CUTOUT_FAIL = "UPDATE_COOKTOP_CUTOUT_FAIL",
    CREATE_COOKTOP_CUTOUT_RANGE_PENDING = "CREATE_COOKTOP_CUTOUT_RANGE_PENDING",
    CREATE_COOKTOP_CUTOUT_RANGE_SUCCESS = "CREATE_COOKTOP_CUTOUT_RANGE_SUCCESS",
    CREATE_COOKTOP_CUTOUT_RANGE_FAIL = "CREATE_COOKTOP_CUTOUT_RANGE_FAIL",
    UPDATE_COOKTOP_CUTOUT_RANGE_PENDING = "UPDATE_COOKTOP_CUTOUT_RANGE_PENDING",
    UPDATE_COOKTOP_CUTOUT_RANGE_SUCCESS = "UPDATE_COOKTOP_CUTOUT_RANGE_SUCCESS",
    UPDATE_COOKTOP_CUTOUT_RANGE_FAIL = "UPDATE_COOKTOP_CUTOUT_RANGE_FAIL",
    DELETE_COOKTOP_CUTOUT_RANGE_PENDING = "DELETE_COOKTOP_CUTOUT_RANGE_PENDING",
    DELETE_COOKTOP_CUTOUT_RANGE_SUCCESS = "DELETE_COOKTOP_CUTOUT_RANGE_SUCCESS",
    DELETE_COOKTOP_CUTOUT_RANGE_FAIL = "DELETE_COOKTOP_CUTOUT_RANGE_FAIL",
    UPDATE_LOCAL_COOKTOP_CUTOUT = "UPDATE_LOCAL_COOKTOP_CUTOUT",
    UPDATE_LOCAL_COOKTOP_CUTOUT_RANGE = "UPDATE_LOCAL_COOKTOP_CUTOUT_RANGE"
}

interface getCooktopCutoutsFail {
    type: ActionType.GET_COOKTOP_CUTOUTS_FAIL,
    payload: string
}

interface getCooktopCutoutsSuccess {
    type: ActionType.GET_COOKTOP_CUTOUTS_SUCCESS,
    payload: CooktopCutout[]
}

interface getCooktopCutoutsPending {
    type: ActionType.GET_COOKTOP_CUTOUTS_PENDING
}

interface createCooktopCutoutSuccess {
    type: ActionType.CREATE_COOKTOP_CUTOUT_SUCCESS,
    payload: CooktopCutout
}

interface createCooktopCutoutPending {
    type: ActionType.CREATE_COOKTOP_CUTOUT_PENDING
}

interface createCooktopCutoutFail {
    type: ActionType.CREATE_COOKTOP_CUTOUT_FAIL,
    payload: string
}

interface updateCooktopCutoutPending {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_PENDING
}

interface updateCooktopCutoutSuccess {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_SUCCESS,
    payload: CooktopCutout
}

interface updateCooktopCutoutFail {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_FAIL,
    payload: string
}

interface createCooktopCutoutRangePending {
    type: ActionType.CREATE_COOKTOP_CUTOUT_RANGE_PENDING
}

interface createCooktopCutoutRangeSuccess {
    type: ActionType.CREATE_COOKTOP_CUTOUT_RANGE_SUCCESS,
    payload: CooktopCutoutRange
}

interface createCooktopCutoutRangeFail {
    type: ActionType.CREATE_COOKTOP_CUTOUT_RANGE_FAIL,
    payload: string
}

interface updateCooktopCutoutRangePending {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_RANGE_PENDING
}

interface updateCooktopCutoutRangeSuccess {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_RANGE_SUCCESS,
    payload: CooktopCutoutRange
}

interface updateCooktopCutoutRangeFail {
    type: ActionType.UPDATE_COOKTOP_CUTOUT_RANGE_FAIL,
    payload: string
}

interface deleteCooktopCutoutRangePending {
    type: ActionType.DELETE_COOKTOP_CUTOUT_RANGE_PENDING
}

interface deleteCooktopCutoutRangeSuccess {
    type: ActionType.DELETE_COOKTOP_CUTOUT_RANGE_SUCCESS,
    payload: CooktopCutoutRange
}

interface deleteCooktopCutoutRangeFail {
    type: ActionType.DELETE_COOKTOP_CUTOUT_RANGE_FAIL,
    payload: string
}

interface updateLocalCooktopCutout {
    type: ActionType.UPDATE_LOCAL_COOKTOP_CUTOUT,
    payload: CooktopCutout
}

interface updateLocalCooktopCutoutRange {
    type: ActionType.UPDATE_LOCAL_COOKTOP_CUTOUT_RANGE,
    payload: CooktopCutoutRange
}

export type Action = 
    getCooktopCutoutsFail |
    getCooktopCutoutsPending |
    getCooktopCutoutsSuccess |
    createCooktopCutoutFail |
    createCooktopCutoutPending |
    createCooktopCutoutSuccess |
    updateCooktopCutoutFail |
    updateCooktopCutoutPending |
    updateCooktopCutoutSuccess |
    createCooktopCutoutRangePending |
    createCooktopCutoutRangeSuccess |
    createCooktopCutoutRangeFail |
    updateCooktopCutoutRangeFail |
    updateCooktopCutoutRangePending |
    updateCooktopCutoutRangeSuccess |
    deleteCooktopCutoutRangePending |
    deleteCooktopCutoutRangeSuccess |
    deleteCooktopCutoutRangeFail |
    updateLocalCooktopCutout |
    updateLocalCooktopCutoutRange;