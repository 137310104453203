import React from "react";
import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { createFeedback } from "api/actions/feedback";
import { CreateFeedback } from "api/types/feedback";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { BUG } from "values/feedback";
import { FEATURE } from "values/feedback";
import { OTHER } from "values/feedback";
import { getFeedbackTypeDisplay } from "values/feedback";
import { empty_create_feedback } from "values/empty/feedback";

interface Props {
    full_button?: boolean
}

export default function CreateFeedbackComponent({full_button}: Props) {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [create_feedback, setCreateFeedback] = useState<CreateFeedback>(empty_create_feedback);
    const [error, setError] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);

    const handleFeatureType = (feedback_type: string) => {
        setCreateFeedback({...create_feedback, feedback_type: feedback_type});
    }

    const handleDescription = (description: string) => {
        setCreateFeedback({...create_feedback, description: description});
        setError(false);
    }

    const handleCreate = () => {
        if(create_feedback.description === ""){
            setError(true);
            return;
        }

        dispatch(createFeedback(create_feedback));
        if(full_button){
            setCompleted(true);
        }
        else{
            handleClose();
        }
    }

    const handleClose = () => {
        setOpen(false);
        setCreateFeedback(empty_create_feedback);
        setError(false);
    }

    return(
        <React.Fragment>
            {
                full_button ?
                <Button endIcon={<CommentIcon />} onClick={(e) => setOpen(true)} sx={{marginRight: 4}}>
                    Beta Feedback
                </Button> :
                <IconButton onClick={(e) => setOpen(true)}>
                    <AddCircleIcon 
                        color="primary"/>
                </IconButton>
            }
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color, width: 500 }}>Create Feedback</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    {
                        !completed ?
                        <FormControl fullWidth>
                            <FormHelperText error id="error">{error ? "Description must not be blank" : ""}</FormHelperText>
                            <TextField
                                sx={textFieldStyle}
                                fullWidth
                                size="small"
                                variant="outlined"
                                select
                                defaultValue={BUG}
                                id="type"
                                label="Type"
                                onChange={(e) => handleFeatureType(e.target.value)}>
                                    <MenuItem key={BUG} value={BUG}>
                                        {getFeedbackTypeDisplay(BUG)}
                                    </MenuItem>
                                    <MenuItem key={FEATURE} value={FEATURE}>
                                        {getFeedbackTypeDisplay(FEATURE)}
                                    </MenuItem>
                                    <MenuItem key={OTHER} value={OTHER}>
                                        {getFeedbackTypeDisplay(OTHER)}
                                    </MenuItem>
                            </TextField>
                            <TextField
                                size="small"
                                sx={textFieldStyle}
                                fullWidth
                                variant="outlined"
                                id="description"
                                label="Description"
                                multiline
                                minRows={3}
                                error={error}
                                onChange={(e) => handleDescription(e.target.value)} />
                        </FormControl> :
                        <Stack direction="row" alignItems="center">
                            <IconButton color="primary">
                                <CheckCircleIcon />
                            </IconButton>
                            <Typography variant="body1" sx={{marginLeft: 1}}>
                                Thank you for the feedback
                            </Typography>
                        </Stack>
                    }
                </DialogContent>
                <DialogActions>
                    
                <Button onClick={handleClose}>{completed ? "Close" : "Cancel"}</Button>
                {
                    !completed ?
                        <Button onClick={handleCreate}>Create</Button> :
                        null
                }
                
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
