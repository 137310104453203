import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { getUploadInstances } from "api/actions/material_import";
import { UpdateTime } from "api/types/update";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import UpdateTimer from "components/universal/save_timer";
import { tableHeaderStyle } from 'styles/style';
import { filterItemStyle } from "styles/style";
import { COMPLETED } from "values/import_material";
import { COMPLETED_WITH_ERRORS } from "values/import_material";
import { UNSUCCESSFUL } from "values/import_material";
import { IN_PROGRESS } from "values/import_material";

export default function FilterUploadInstances() {
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState<string>("");
    const [uploader_last_name, setUploaderLastName] = useState<string>("");
    const [uploaded_after, setUploadedAfter] = useState<string>("");
    const [uploaded_before, setUploadedBefore] = useState<string>("");
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_time, setStartTime] = useState<UpdateTime[]>([]);

    const filter = () => {
        dispatch(getUploadInstances(
            status,
            uploader_last_name,
            uploaded_after,
            uploaded_before
        ));
        setUpdatePending(false);
    }

    const update = () => {
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleStatus = (new_status: string) => {
        setUpdatePending(true);
        if(new_status === "Any"){
            new_status = "";
        }
        setStatus(new_status);
        update();
    }

    const handleUploaderLastName = (new_last_name: string) => {
        setUpdatePending(true);
        setUploaderLastName(new_last_name);
        update();
    }

    const handleUploadedAfter = (new_uploaded_after: string) => {
        setUpdatePending(true);
        setUploadedAfter(new_uploaded_after);
        update();
    }

    const handledUploadedBefore = (new_uploaded_before: string) => {
        setUpdatePending(true);
        setUploadedBefore(new_uploaded_before);
        update();
    }
        
    return(
        <Grid 
            sx={[tableHeaderStyle, {paddingRight: 8}]}
            alignItems="center"
            container>
            <Grid item xs={10}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Filter</Typography>
            </Grid>
            <Grid item xs={2}>
                {
                    update_pending ? 
                    <UpdateTimer
                        update_text="Loading"
                        font_size={14}
                        size={12}
                        fixed={false}
                        start_times={start_time}
                        update={filter} /> :
                    null
                }
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    sx={filterItemStyle}
                    fullWidth
                    size="small"
                    label="Uploader Last Name"
                    id="uploaded_last_name"
                    InputLabelProps={{shrink: true}}
                    onChange={e => handleUploaderLastName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={filterItemStyle}
                    fullWidth
                    size="small"
                    id="uploaded_after"
                    type="date"
                    label="Uploaded After"
                    InputLabelProps={{shrink: true}}
                    onChange={e => handleUploadedAfter(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
               <TextField
                    sx={filterItemStyle}
                    fullWidth
                    size="small"
                    id="uploaded_before"
                    type="date"
                    label="Uploaded Before"
                    InputLabelProps={{shrink: true}}
                    onChange={e => handledUploadedBefore(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    sx={filterItemStyle}
                    fullWidth
                    size="small"
                    select
                    defaultValue="Any"
                    label="Uploader Last Name"
                    id="uploaded_last_name"
                    InputLabelProps={{shrink: true}}
                    onChange={e => handleStatus(e.target.value)}>
                    <MenuItem key="Any" value="Any">
                        Any
                    </MenuItem>
                    <MenuItem key={COMPLETED} value={COMPLETED}>
                        Completed
                    </MenuItem>
                    <MenuItem key={COMPLETED_WITH_ERRORS} value={COMPLETED_WITH_ERRORS}>
                        Completed With Errors
                    </MenuItem>
                    <MenuItem key={IN_PROGRESS} value={IN_PROGRESS}>
                        In Progress
                    </MenuItem>
                    <MenuItem key={UNSUCCESSFUL} value={UNSUCCESSFUL}>
                        Unsuccessful
                    </MenuItem>
                </TextField>
            </Grid>
        </Grid>
    )
}
