import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface Props {
    any_range: boolean,
    rate: string,
    price: number,
    measurement: number
}

export default function PlainRangeItem({any_range, rate, price, measurement}: Props) {
    return(
        <Fragment>
            <Grid item xs={2} sx={{paddingBottom: 1.5}}>
                {
                    any_range ? 
                    <Typography variant="body2">
                        Any Radius
                    </Typography>
                    :
                    <Typography variant="body2">
                        {any_range ? "Up to Max" : `Up to ${measurement}"`}
                    </Typography>
                } 
            </Grid>
            <Grid item xs={2} sx={{paddingBottom: 2.25}}>
                <Typography variant="body2">
                    {price}
                </Typography>   
            </Grid>
            <Grid item xs={8} sx={{paddingBottom: 2.25}}>
                <Typography variant="body2">
                    {rate}
                </Typography>
            </Grid>
        </Fragment>
    )
}
