import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createCooktopCutoutRange } from "api/actions/price";
import { CooktopCutout } from "api/types/price";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import BasicRangeEditableItem from "./shared/basic_range";
import CooktopCutoutRangeEditableItem from "./cooktop_cutout_range";
import EditableList from "components/universal/editable_list";
import { CreateCooktopCutoutRange } from "components/price/dialog/create";
import { getRowSecondStyle } from "values/style";
import { empty_cooktop_range } from "values/empty/price";

interface Props {
    index: number,
    item: CooktopCutout,
    start_update: (update_item: CooktopCutout) => void,
    setLocked: (locked: boolean) => void
}

export default function CooktopCutoutEditableItem({index, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [cooktop_cutout_update, setCooktopCutoutUpdate] = useState<CooktopCutout>(item);
    const [open, setOpen] = useState<boolean>(false);

    const saveBoolean = (key: string, value: boolean) => {
        const new_cooktop_cutout_update: CooktopCutout = {...cooktop_cutout_update, [key]: value};
        setCooktopCutoutUpdate(new_cooktop_cutout_update);
        start_update(new_cooktop_cutout_update);
    }

    return(
        <Grid container item sx={[{paddingLeft: 1, paddingTop: 1, paddingBottom: 1}, getRowSecondStyle(index)]} alignItems="center">
            <BasicRangeEditableItem
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.enabled ? item.enabled : false}
                saveBoolean={saveBoolean} /> 
            <EditableList 
                setLocked={setLocked}
                items={item.ranges ? item.ranges : []}
                Item={CooktopCutoutRangeEditableItem}
                start_update={start_update}/>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(createCooktopCutoutRange({...empty_cooktop_range, measurement: 0, cooktop_cutout: item.id}))}
                    size="small"
                    endIcon={<AddIcon />}
                    sx={{paddingLeft: 0, paddingRight: 0}}>
                    Range
                </Button>
            </Grid>
            <Grid item xs={12}>
                <CreateCooktopCutoutRange
                    open={open}
                    setOpen={setOpen}
                    cooktop_cutout={item} />
            </Grid>
        </Grid>
    )
}
