import { Fragment } from "react";
import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { createStripeSession } from "api/actions/billing";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PuffLoader from "animation/puff_loader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { primary_color } from "styles/style";
import { third_color } from "styles/style";

export default function StaffPaymentForm(){
    const dispatch = useAppDispatch();
    const [load, setLoad] = useState<boolean>(false);
    const { customer } = useAppSelector((state) => state.billing);
    const { price } = useAppSelector((state) => state.billing);
    const { session } = useAppSelector((state) => state.billing);

    const handleCreateSession = () => {
        setLoad(true);
        if(customer !== "" && price !== ""){
            dispatch(createStripeSession(customer, price));
        }
    }

    if(session && session.session_url){
        window.location.href = session.session_url;
    }

    return (
        <Fragment>
        {
            !load ?
            <Grid container item alignItems="center" sx={{width: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <b>Payment</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{paddingTop: 2, paddingBottom: 4.5}}>
                    Proceed to our secure payment portal to complete your subscription.
                    The customer will not be charged for 1 month during the free trial period.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{marginTop: 20}}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={handleCreateSession} variant="contained" endIcon={<ArrowForwardIosIcon />}>
                            Proceed to Payment
                        </Button>
                    </Box>
                </Grid>
            </Grid> :
            <Grid container item alignItems="center" justifyContent="center" sx={{height: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
                <Grid item xs={12}>
                    <PuffLoader 
                        color_1={primary_color}
                        color_2={third_color}
                        color_3={primary_color}
                        color_4={primary_color}
                        text="Creating Secure Gateway" />
                </Grid>
            </Grid>
        }
        </Fragment>
    );
}