import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/material";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/material";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Color } from "api/types/material";
import { url_prefix } from "settings";

const color_url: string = "color";

export const createColors = (colors: Color[]) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${color_url}/`;

    try {
      const { data } = await axios.post(url, colors, config);

      dispatch({
        type: ActionType.CREATE_COLOR,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getColors = (material_id: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${color_url}/?material_id=${material_id}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_COLORS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateColor = (color: Color, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING,
      payload: color
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${color_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, color, config);

      dispatch({
        type: ActionType.UPDATE_COLOR,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const deleteColor = (id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.MATERIAL_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${color_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_COLOR,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.MATERIAL_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateLocalColor = (color: Color) => {
  return async (dispatch: Dispatch<Action>) => {

    dispatch({
      type: ActionType.UPDATE_LOCAL_COLOR,
      payload: color
    });
  }
}



