import { ClippedCorner } from "api/types/price";

export enum ActionType {
    GET_CLIPPED_CORNERS_PENDING="GET_CLIPPED_CORNERS_PENDING",
    GET_CLIPPED_CORNERS_SUCCESS="GET_CLIPPED_CORNERS_SUCCESS",
    GET_CLIPPED_CORNERS_FAIL="GET_CLIPPED_CORNERS_FAIL",
    CREATE_CLIPPED_CORNER_PENDING = "CREATE_CLIPPED_CORNER_PENDING",
    CREATE_CLIPPED_CORNER_SUCCESS = "CREATE_CLIPPED_CORNER_SUCCESS",
    CREATE_CLIPPED_CORNER_FAIL = "CREATE_CLIPPED_CORNER_FAIL",
    UPDATE_CLIPPED_CORNER_PENDING = "UPDATE_CLIPPED_CORNER_PENDING",
    UPDATE_CLIPPED_CORNER_SUCCESS = "UPDATE_CLIPPED_CORNER_SUCCESS",
    UPDATE_CLIPPED_CORNER_FAIL = "UPDATE_CLIPPED_CORNER_FAIL",
    UPDATE_LOCAL_CLIPPED_CORNER = "UPDATE_LOCAL_CLIPPED_CORNER"
}

interface getClippedCornersFail {
    type: ActionType.GET_CLIPPED_CORNERS_FAIL,
    payload: string
}

interface getClippedCornersSuccess {
    type: ActionType.GET_CLIPPED_CORNERS_SUCCESS,
    payload: ClippedCorner[]
}

interface getClippedCornersPending {
    type: ActionType.GET_CLIPPED_CORNERS_PENDING
}

interface createClippedCornerPending {
  type: ActionType.CREATE_CLIPPED_CORNER_PENDING
}

interface createClippedCornerSuccess {
  type: ActionType.CREATE_CLIPPED_CORNER_SUCCESS,
  payload: ClippedCorner
}

interface createClippedCornerFail {
  type: ActionType.CREATE_CLIPPED_CORNER_FAIL,
  payload: string;
}

interface updateClippedCornerPending {
    type: ActionType.UPDATE_CLIPPED_CORNER_PENDING,
}

interface updateClippedCornerSuccess {
    type: ActionType.UPDATE_CLIPPED_CORNER_SUCCESS,
    payload: ClippedCorner;
}
  
interface updateClippedCornerFail {
    type: ActionType.UPDATE_CLIPPED_CORNER_FAIL,
    payload: string;
}

interface updateLocalClippedCorner {
    type: ActionType.UPDATE_LOCAL_CLIPPED_CORNER,
    payload: ClippedCorner
}
  
export type Action =
    getClippedCornersFail |
    getClippedCornersPending |
    getClippedCornersSuccess |
    createClippedCornerPending | 
    createClippedCornerSuccess | 
    createClippedCornerFail | 
    updateClippedCornerPending | 
    updateClippedCornerSuccess | 
    updateClippedCornerFail |
    updateLocalClippedCorner;
