import { Fragment } from "react";
import Checkboxes from "./checkboxes";
import Name from "../../shared/name";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
    material_thickness: string,
    enabled: boolean,
    taxable: boolean,
    saveBoolean: (key: string, value: boolean) => void
}

export default function BasicRangeEditableItem({
    material_thickness,
    enabled,
    taxable,
    saveBoolean
}: Props) {
    return(
        <Fragment>
            <Name 
                bold={false}
                name={getMaterialHeightDisplay(material_thickness)} 
                width={8} />
            <Checkboxes 
                grid_width={4}
                enabled={enabled}
                setEnabled={saveBoolean}
                taxable={taxable}
                setTaxable={saveBoolean} />
        </Fragment>
    )
}
