import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getWaterfalls } from "api/actions/price/waterfalls";
/// MUI ///
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateWaterfall } from "../dialog/create";
import WaterfallItem from "../row/waterfall";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { Waterfall } from "api/types/price";
import { WATERFALL } from "api/types/update";
import { WATERFALL_RANGE } from "api/types/update";

interface Props {
  start_update: (type: number, data: Waterfall) => void
}

export default function PriceListWaterfall({ start_update }: Props) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { waterfalls } = useAppSelector((state) => state.price);

  const handle_update = (update_item: any) => {
    if ("height" in update_item) {
      start_update(WATERFALL_RANGE, update_item);
    }
    else {
      start_update(WATERFALL, update_item);
    }
  }

  useEffect(() => {
    if (id && isNaN(Number(id)) === false) {
      dispatch(getWaterfalls(Number(id)));
    }
  }, [id, dispatch]);

  return (
    <Paper sx={islandStyle}>
      <Grid container alignItems="center">
        <Subtitle
          subtitle="Waterfalls"
          endProps={
            <CreateWaterfall />} />
        <Header
          items={[
            { text: "Height/Range", width: 2 },
            { text: "Price", width: 2 },
            { text: "Rate", width: 4 },
            { text: "Enabled", width: 2 },
            { text: "Taxable", width: 2 }
          ]} />
        <EditableList
          items={waterfalls}
          Item={WaterfallItem}
          start_update={handle_update} />
      </Grid>
    </Paper>
  )
}
