import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getAccounts } from "api/actions/account";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "components/universal/title";
import List from "components/universal/list";
import Pagination from "@mui/material/Pagination";
import { mainContentAreaStyle } from "styles/style";
import AccountsHeader from "./header/accounts";
import AccountsRow from "./row/accounts";
import { islandStyle } from "styles/style";
import { default_pagination_length } from "styles/table";

export default function Accounts() {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<number>(1);
    const { accounts } = useAppSelector((state) => state.account);

    useEffect(() => {
        dispatch(getAccounts());
    }, []);

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Box sx={[mainContentAreaStyle]}>
            <Grid container justifyContent="space-between">
                <Title 
                    title="Accounts" 
                    subtitle="Update an Account"
                    update_pending={false}
                    start_times={[]}
                    update={() => void 0}
                    endProps={<div></div>} />
                <Grid item xs={8.5}>
                    <Paper sx={[islandStyle]}>
                        <AccountsHeader />
                        <List 
                            items={accounts.slice((page - 1) * default_pagination_length, page * default_pagination_length)}
                            Item={AccountsRow}
                            />
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <Box display="flex" justifyContent="center">
                                <Pagination
                                    onChange={changePage} 
                                    count={
                                        accounts.length > default_pagination_length ? 
                                        Math.ceil(accounts.length / default_pagination_length) : 
                                        1
                                    } shape="rounded" />
                            </Box>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper sx={[islandStyle]}>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}