import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createRadiusCornerRange } from "api/actions/price";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import EditableList from "components/universal/editable_list";
import BasicRangeEditableItem from "./shared/basic_range";
import RadiusCornerRangeEditableItem from "./radius_corner_range";
import AddIcon from "@mui/icons-material/Add";
import { CreateRadiusCornerRange } from "components/price/dialog/create";
import { RadiusCorner } from "api/types/price";
import { getRowSecondStyle } from "values/style";
import { empty_radius_corner_range } from "values/empty/price";

interface Props {
    index: number,
    item: RadiusCorner,
    start_update: (update_item: RadiusCorner) => void,
    setLocked: (locked: boolean) => void
}

export default function RadiusCornerEditableItem({index, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [radius_corner_update, setRadiusCornerUpdate] = useState<RadiusCorner>(item);
    const [open, setOpen] = useState<boolean>(false);

    const saveBoolean = (key: string, value: boolean) => {
        const new_radius_corner: RadiusCorner = {...radius_corner_update, [key]: value};
        setRadiusCornerUpdate(new_radius_corner);
        start_update(new_radius_corner);
    }

    return(
        <Grid container item sx={[{paddingLeft: 1, paddingTop: 1, paddingBottom: 1}, getRowSecondStyle(index)]} alignItems="center">
            <BasicRangeEditableItem
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.enabled ? item.enabled : false}
                saveBoolean={saveBoolean} />
            <EditableList 
                setLocked={setLocked}
                items={item.ranges ? item.ranges : []}
                Item={RadiusCornerRangeEditableItem}
                start_update={start_update}/>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(createRadiusCornerRange({...empty_radius_corner_range, radius: 0, outside_radius_corner: item.id}))}
                    size="small"
                    endIcon={<AddIcon />}
                    sx={{paddingLeft: 0, paddingRight: 0}}>
                    Range
                </Button>
            </Grid>
            <Grid item xs={12}>
                <CreateRadiusCornerRange 
                    open={open}
                    setOpen={setOpen}
                    radius_corner={item} />
            </Grid>
        </Grid>
    )
}
