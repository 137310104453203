import { RadiusCorner, RadiusCornerRange } from "api/types/price";

export enum ActionType {
    GET_RADIUS_CORNERS_PENDING="GET_RADIUS_CORNERS_PENDING",
    GET_RADIUS_CORNERS_SUCCESS="GET_RADIUS_CORNERS_SUCCESS",
    GET_RADIUS_CORNERS_FAIL="GET_RADIUS_CORNERS_FAIL",
    CREATE_RADIUS_CORNER_PENDING = "CREATE_RADIUS_CORNER_PENDING",
    CREATE_RADIUS_CORNER_SUCCESS = "CREATE_RADIUS_CORNER_SUCCESS",
    CREATE_RADIUS_CORNER_FAIL = "CREATE_RADIUS_CORNER_FAIL",
    UPDATE_RADIUS_CORNER_PENDING = "UPDATE_RADIUS_CORNER_PENDING",
    UPDATE_RADIUS_CORNER_SUCCESS = "UPDATE_RADIUS_CORNER_SUCCESS",
    UPDATE_RADIUS_CORNER_FAIL = "UPDATE_RADIUS_CORNER_FAIL",
    UPDATE_LOCAL_RADIUS_CORNER = "UPDATE_LOCAL_RADIUS_CORNER",
    CREATE_RADIUS_CORNER_RANGE_PENDING = "CREATE_RADIUS_CORNER_RANGE_PENDING",
    CREATE_RADIUS_CORNER_RANGE_FAIL = "CREATE_RADIUS_CORNER_RANGE_FAIL",
    CREATE_RADIUS_CORNER_RANGE_SUCCESS = "CREATE_RADIUS_CORNER_RANGE_SUCCESS",
    UPDATE_RADIUS_CORNER_RANGE_PENDING = "UPDATE_RADIUS_CORNER_RANGE_PENDING",
    UPDATE_RADIUS_CORNER_RANGE_FAIL = "UPDATE_RADIUS_CORNER_RANGE_FAIL",
    UPDATE_RADIUS_CORNER_RANGE_SUCCESS = "UPDATE_RADIUS_CORNER_RANGE_SUCCESS",
    DELETE_RADIUS_CORNER_RANGE_PENDING = "DELETE_RADIUS_CORNER_RANGE_PENDING",
    DELETE_RADIUS_CORNER_RANGE_FAIL = "DELETE_RADIUS_CORNER_RANGE_FAIL",
    DELETE_RADIUS_CORNER_RANGE_SUCCESS = "DELETE_RADIUS_CORNER_RANGE_SUCCESS",
    UPDATE_LOCAL_RADIUS_CORNER_RANGE = "UPDATE_LOCAL_RADIUS_CORNER_RANGE"
}

interface getRadiusCornersFail {
    type: ActionType.GET_RADIUS_CORNERS_FAIL,
    payload: string
}

interface getRadiusCornersSuccess {
    type: ActionType.GET_RADIUS_CORNERS_SUCCESS,
    payload: RadiusCorner[]
}

interface getRadiusCornersPending {
    type: ActionType.GET_RADIUS_CORNERS_PENDING
}

interface createRadiusCornerPending {
    type: ActionType.CREATE_RADIUS_CORNER_PENDING
}

interface createRadiusCornerSuccess {
    type: ActionType.CREATE_RADIUS_CORNER_SUCCESS,
    payload: RadiusCorner
}

interface createRadiusCornerFail {
    type: ActionType.CREATE_RADIUS_CORNER_FAIL,
    payload: string
}

interface updateRadiusCornerPending {
    type: ActionType.UPDATE_RADIUS_CORNER_PENDING,
}

interface updateRadiusCornerSuccess {
    type: ActionType.UPDATE_RADIUS_CORNER_SUCCESS,
    payload: RadiusCorner
}
  
interface updateRadiusCornerFail {
    type: ActionType.UPDATE_RADIUS_CORNER_FAIL,
    payload: string
}

interface updateLocalRadiusCorner {
    type: ActionType.UPDATE_LOCAL_RADIUS_CORNER,
    payload: RadiusCorner
}

interface createRadiusCornerRangeFail {
    type: ActionType.CREATE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface createRadiusCornerRangePending {
    type: ActionType.CREATE_RADIUS_CORNER_RANGE_PENDING
}

interface createRadiusCornerRangeSuccess {
    type: ActionType.CREATE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: RadiusCornerRange
}

interface updateRadiusCornerRangeFail {
    type: ActionType.UPDATE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface updateRadiusCornerRangePending {
    type: ActionType.UPDATE_RADIUS_CORNER_RANGE_PENDING
}

interface updateRadiusCornerRangeSuccess {
    type: ActionType.UPDATE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: RadiusCornerRange
}

interface deleteRadiusCornerRangeFail {
    type: ActionType.DELETE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface deleteRadiusCornerRangePending {
    type: ActionType.DELETE_RADIUS_CORNER_RANGE_PENDING
}

interface deleteRadiusCornerRangeSuccess {
    type: ActionType.DELETE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: RadiusCornerRange
}

interface updateLocalRadiusCornerRange {
    type: ActionType.UPDATE_LOCAL_RADIUS_CORNER_RANGE,
    payload: RadiusCornerRange
}
  
export type Action =
    getRadiusCornersFail |
    getRadiusCornersPending |
    getRadiusCornersSuccess |
    createRadiusCornerPending | 
    createRadiusCornerSuccess | 
    createRadiusCornerFail | 
    updateRadiusCornerPending | 
    updateRadiusCornerSuccess | 
    updateRadiusCornerFail |
    updateLocalRadiusCorner |
    createRadiusCornerRangeFail |
    createRadiusCornerRangePending |
    createRadiusCornerRangeSuccess |
    updateRadiusCornerRangeFail |
    updateRadiusCornerRangePending |
    updateRadiusCornerRangeSuccess |
    deleteRadiusCornerRangeFail |
    deleteRadiusCornerRangePending |
    deleteRadiusCornerRangeSuccess |
    updateLocalRadiusCornerRange;
