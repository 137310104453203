import { useState } from "react";
import { createWaterfallRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { WaterfallRange } from "api/types/price";
import { Waterfall } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
  waterfall: Waterfall
}

export default function CreateWaterfallRange({ waterfall }: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<WaterfallRange>({});

  const handlePrice = (price: string) => {
    if (isNaN(Number(price)) === false) {
      setRange({ ...range, price: Number(price) });
    }
  }

  const handleHeight = (height: string) => {
    if (isNaN(Number(height)) === false) {
      setRange({ ...range, height: Number(height) });
    }
  }

  const handleCreate = () => {
    let error = false;

    if (error) {
      return;
    }

    if (waterfall.id) {
      const create_range = { ...range, waterfall: waterfall.id, any_range: false };
      dispatch(createWaterfallRange(create_range));
      handleClose();
    }
  }

  const handleClose = () => {
    setOpen(false);
    setRange({});
  }

  return (
    <CreateBasicRange
      title="Create Waterfall Range"
      open={open}
      measurement_type="Height"
      error=""
      has_error={false}
      close={() => setOpen(false)}
      create={handleCreate}
      handlePrice={handlePrice}
      handleMeasurement={handleHeight} />
  )
}
