export const ANY: number = -1;
export const MATERIAL: number = 0;
export const COLOR: number = 1;
export const PROFILE: number = 2;
export const REGION: number = 3;
export const PRICE_LIST: number = 4;
export const INSTALLATION: number = 5;
export const FABRICATION: number = 6;
export const SPLASH: number = 7;
export const FINISHED_EDGE: number = 8;
export const APPLIANCE_EDGE: number = 9;
export const SINK_CUTOUT: number = 10;
export const COOKTOP_CUTOUT: number = 11;
export const FAUCET_CUTOUT: number = 12;
export const OUTLET_CUTOUT: number = 13;
export const CLIPPED_CORNER: number = 14;
export const NOTCHED_CORNER: number = 15;
export const SPLASH_RANGE: number = 16;
export const RADIUS_CORNER: number = 17;
export const INSIDE_RADIUS_CORNER: number = 18;
export const INSIDE_DIAGONAL_CORNER: number = 19;
export const RECESSED_DIAGONAL_CORNER: number = 20;
export const BUMP_IN: number = 21;
export const BUMP_OUT: number = 22;
export const BUMP_IN_ARC: number = 23;
export const BUMP_OUT_ARC: number = 24;
export const FULL_ARC: number = 25;
export const BUMPED_OUT_CORNER: number = 26;
export const COOKTOP_CUTOUT_RANGE: number = 27;
export const SINK_CUTOUT_RANGE: number = 28;
export const RADIUS_CORNER_RANGE: number = 29;
export const INSIDE_RADIUS_CORNER_RANGE: number = 30;
export const END_CUSTOMER: number = 31;
export const STONE_COUNTERTOP_REMOVAL: number = 32;
export const LAMINATE_COUNTERTOP_REMOVAL: number = 33;
export const MITER: number = 34;
export const MITER_RANGE: number = 35;
export const WATERFALL: number = 36;
export const WATERFALL_RANGE: number = 37;

export interface UpdateTime {
  time: number,
  type?: number,
  data: any
}
