import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/feedback';
import { ActionType } from 'api/action_types/feedback';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { CreateFeedback } from 'api/types/feedback';
import { UpdateFeedback } from 'api/types/feedback';
import { FeedbackFilter } from 'api/types/feedback';
import { url_prefix } from 'settings';

const feedback_url: string = 'feedback';

export const getFeedbacks = (feedback_filter?: FeedbackFilter) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FEEDBACK_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${feedback_url}?`;
        
        if(feedback_filter){
            if(feedback_filter.description){
                url += `description=${feedback_filter.description}&`;
            }
            if(feedback_filter.feedback_type){
                url += `feedback_type=${feedback_filter.feedback_type}&`;
            }
            if(feedback_filter.resolution){
                url += `resolution=${feedback_filter.resolution}&`;
            }
            if(feedback_filter.resolved !== null && feedback_filter.resolved !== undefined){
                url += `resolved=${feedback_filter.resolved}&`;
            }
            if(feedback_filter.date_added_after){
                url += `date_added_after=${feedback_filter.date_added_after}&`;
            }
            if(feedback_filter.date_added_before){
                url += `date_added_before=${feedback_filter.date_added_before}&`;
            }
            if(feedback_filter.date_resolved_after){
                url += `date_resolved_after=${feedback_filter.date_resolved_after}&`;
            }
            if(feedback_filter.date_resolved_before){
                url += `date_resolved_before=${feedback_filter.date_resolved_before}&`;
            }
        }
        

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_FEEDBACKS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FEEDBACK_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const getFeedback = (id: number | string) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FEEDBACK_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${feedback_url}/${id}/`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_FEEDBACK_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FEEDBACK_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const createFeedback = (feedback: CreateFeedback) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FEEDBACK_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${feedback_url}/`;

        try
        {
            const {data} = await axios.post(url, {...feedback, reported_by: user.id}, config);

            dispatch({
                type: ActionType.CREATE_FEEDBACK_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FEEDBACK_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const updateFeedback = (feedback: UpdateFeedback, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.FEEDBACK_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${feedback_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, feedback, config);

            dispatch({
                type: ActionType.UPDATE_FEEDBACK_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.FEEDBACK_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const updateLocalFeedback = (feedback_update: UpdateFeedback) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_FEEDBACK,
            payload: feedback_update
        });
    }
}