import { MaterialToPriceList } from "api/types/price";

export enum ActionType {
    GET_MATERIAL_TO_PRICE_LISTS_SUCCESS="GET_MATERIAL_TO_PRICE_LISTS_SUCCESS",
    GET_MATERIAL_TO_PRICE_LISTS_FAIL="GET_MATERIAL_TO_PRICE_LISTS_FAI",
    GET_MATERIAL_TO_PRICE_LISTS_PENDING="GET_MATERIAL_TO_PRICE_LISTS_PENDING",
    CREATE_MATERIAL_TO_PRICE_LIST_PENDING="CREATE_MATERIAL_TO_PRICE_LIST_PENDING",
    CREATE_MATERIAL_TO_PRICE_LIST_SUCCESS="CREATE_MATERIAL_TO_PRICE_LIST_SUCCESS",
    CREATE_MATERIAL_TO_PRICE_LIST_FAIL="CREATE_MATERIAL_TO_PRICE_LIST_FAIL",
    UPDATE_MATERIAL_TO_PRICE_LIST_PENDING="UPDATE_MATERIAL_TO_PRICE_LIST_PENDING",
    UPDATE_MATERIAL_TO_PRICE_LIST_SUCCESS="UPDATE_MATERIAL_TO_PRICE_LIST_SUCCESS",
    UPDATE_MATERIAL_TO_PRICE_LIST_FAIL="UPDATE_MATERIAL_TO_PRICE_LIST_FAIL",
    UPDATE_LOCAL_MATERIAL_TO_PRICE_LIST="UPDATE_LOCAL_MATERIAL_TO_PRICE_LIST",
    DELETE_MATERIAL_TO_PRICE_LIST="DELETE_MATERIAL_TO_PRICE_LIST"
}

interface getMaterialToPriceListsSuccess {
    type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_SUCCESS,
    payload: MaterialToPriceList[]
}

interface getMaterialToPriceListsFail {
    type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_FAIL,
    payload: string
}

interface getMaterialToPriceListsPending {
    type: ActionType.GET_MATERIAL_TO_PRICE_LISTS_PENDING
}

interface createMaterialToPriceListFail {
    type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_FAIL,
    payload: string
}

interface createMaterialToPriceListSuccess {
    type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_SUCCESS,
    payload: MaterialToPriceList[]
}

interface createMaterialToPriceListPending {
    type: ActionType.CREATE_MATERIAL_TO_PRICE_LIST_PENDING
}

interface updateMaterialToPriceListFail {
    type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_FAIL,
    payload: string
}

interface updateMaterialToPriceListSuccess {
    type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_SUCCESS,
    payload: MaterialToPriceList
}

interface updateMaterialToPriceListPending {
    type: ActionType.UPDATE_MATERIAL_TO_PRICE_LIST_PENDING
}

interface updateLocalMaterialToPriceList {
    type: ActionType.UPDATE_LOCAL_MATERIAL_TO_PRICE_LIST,
    payload: MaterialToPriceList
}

interface deleteMaterialToPriceList {
    type: ActionType.DELETE_MATERIAL_TO_PRICE_LIST,
    payload: MaterialToPriceList
}

export type Action =
    getMaterialToPriceListsFail |
    getMaterialToPriceListsPending |
    getMaterialToPriceListsSuccess |
    createMaterialToPriceListPending |
    createMaterialToPriceListSuccess |
    createMaterialToPriceListFail |
    updateMaterialToPriceListFail |
    updateMaterialToPriceListSuccess |
    updateMaterialToPriceListPending |
    updateLocalMaterialToPriceList |
    deleteMaterialToPriceList;