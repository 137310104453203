import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { gridSpacing } from "styles/style";

interface Props {
    name: string,
    width: number,
    bold?: boolean,
    underline?: boolean
}

export default function Name({name, width, bold=true, underline=false}: Props) {
    return(
        <Grid item xs={width} sx={gridSpacing}>
            <Typography variant="body2" sx={{
                textDecoration: underline ? "underline" : "none",
                fontWeight: bold ? "bold" : "normal"
            }}>
                {name}
            </Typography>
        </Grid>
    )
}
