import { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { gridSpacing } from "styles/style";
import { listHeaderStyle } from 'styles/style';

export default function ColorHeader() {

    return(
        <Fragment>
            <Grid item xs={4} sx={[gridSpacing, listHeaderStyle]}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    <b>Color</b>
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={[gridSpacing, listHeaderStyle]}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    <b>Price</b>
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={[gridSpacing, listHeaderStyle]}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    <b>Length</b>
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={[gridSpacing, listHeaderStyle]}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    <b>Width</b>
                </Typography> 
            </Grid>
            <Grid item xs={2} sx={[gridSpacing, listHeaderStyle]}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    <b>Enabled</b>
                </Typography> 
            </Grid>
        </Fragment>
    )
}
