import { PriceList } from "api/types/price";

export enum ActionType {
    GET_PRICE_LISTS_PENDING = "GET_PRICE_LISTS_PENDING",
    GET_PRICE_LISTS_SUCCESS = "GET_PRICE_LISTS_SUCCESS",
    GET_PRICE_LISTS_FAIL = "GET_PRICE_LISTS_FAIL",
    GET_PRICE_LIST_PENDING = "GET_PRICE_LIST_PENDING",
    GET_PRICE_LIST_SUCCESS = "GET_PRICE_LIST_SUCCESS",
    GET_PRICE_LIST_FAIL = "GET_PRICE_LIST_FAIL",
    CREATE_PRICE_LIST_PENDING = "CREATE_PRICE_LIST_PENDING",
    CREATE_PRICE_LIST_SUCCESS = "CREATE_PRICE_LIST_SUCCESS",
    CREATE_PRICE_LIST_FAIL = "CREATE_PRICE_LIST_FAIL",
    UPDATE_PRICE_LIST_PENDING = "UPDATE_PRICE_LIST_PENDING",
    UPDATE_PRICE_LIST_SUCCESS = "UPDATE_PRICE_LIST_SUCCESS",
    UPDATE_PRICE_LIST_FAIL = "UPDATE_PRICE_LIST_FAIL",
    DELETE_PRICE_LIST_PENDING = "DELETE_PRICE_LIST_PENDING",
    DELETE_PRICE_LIST_SUCCESS = "DELETE_PRICE_LIST_SUCCESS",
    DELETE_PRICE_LIST_FAIL = "DELETE_PRICE_LIST_FAIL",
    CLEAR_PRICE_LIST="CLEAR_PRICE_LIST"
}

interface getPriceListsPending {
    type: ActionType.GET_PRICE_LISTS_PENDING
}

interface getPriceListsSuccess {
    type: ActionType.GET_PRICE_LISTS_SUCCESS,
    payload: PriceList[]
}

interface getPriceListsFail {
    type: ActionType.GET_PRICE_LISTS_FAIL,
    payload: string
}

interface getPriceListPending {
    type: ActionType.GET_PRICE_LIST_PENDING
}

interface getPriceListSuccess {
    type: ActionType.GET_PRICE_LIST_SUCCESS,
    payload: PriceList
}

interface getPriceListFail {
    type: ActionType.GET_PRICE_LIST_FAIL,
    payload: string
}

interface createPriceListPending {
    type: ActionType.CREATE_PRICE_LIST_PENDING
}

interface createPriceListSuccess {
    type: ActionType.CREATE_PRICE_LIST_SUCCESS,
    payload: PriceList
}

interface createPriceListFail {
    type: ActionType.CREATE_PRICE_LIST_FAIL,
    payload: string
}

interface updatePriceListPending {
    type: ActionType.UPDATE_PRICE_LIST_PENDING
}

interface updatePriceListSuccess {
    type: ActionType.UPDATE_PRICE_LIST_SUCCESS,
    payload: PriceList
}

interface updatePriceListFail {
    type: ActionType.UPDATE_PRICE_LIST_FAIL,
    payload: string
}

interface deletePriceListPending {
    type: ActionType.DELETE_PRICE_LIST_PENDING
}

interface deletePriceListSuccess {
    type: ActionType.DELETE_PRICE_LIST_SUCCESS,
    payload: PriceList
}

interface deletePriceListFail {
    type: ActionType.DELETE_PRICE_LIST_FAIL,
    payload: string
}

interface clearPriceList {
    type: ActionType.CLEAR_PRICE_LIST
}

export type Action = 
    getPriceListsFail |
    getPriceListsPending |
    getPriceListsSuccess |
    getPriceListFail |
    getPriceListPending |
    getPriceListSuccess |
    createPriceListPending |
    createPriceListSuccess |
    createPriceListFail |
    updatePriceListPending |
    updatePriceListSuccess |
    updatePriceListFail |
    deletePriceListPending |
    deletePriceListSuccess |
    deletePriceListFail |
    clearPriceList;