import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
    title: string,
    open: boolean,
    measurement_type: string,
    error: string,
    has_error: boolean,
    close: () => void,
    create: () => void,
    handlePrice: (price: string) => void,
    handleMeasurement: (measurement: string) => void
}

export default function CreateBasicRange({
    title,
    open,
    measurement_type,
    error,
    has_error,
    close,
    create,
    handlePrice,
    handleMeasurement
}: Props) {
    return(
        <Dialog 
            fullWidth={true}
            maxWidth="md"
            open={open}>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>{title}</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl>
                    <FormHelperText error id="error">{error}</FormHelperText>
                    <TextField
                        sx={[textFieldStyle]}
                        size="small"
                        variant="outlined"
                        id="radius"
                        error={has_error}
                        label={measurement_type}
                        onChange={(e) => handleMeasurement(e.target.value)} />
                    <TextField
                        sx={[textFieldStyle]}
                        size="small"
                        variant="outlined"
                        error={has_error}
                        id="price"
                        label="Price"
                        onChange={(e) => handlePrice(e.target.value)} />
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
