import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { NotchedCorner } from 'api/types/price';
import { url_prefix } from 'settings';

const notched_corner_url: string = 'notched_corner';

export const getNotchedCorners = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_NOTCHED_CORNERS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${notched_corner_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_NOTCHED_CORNERS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_NOTCHED_CORNERS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createNotchedCorner = (notched_corner: NotchedCorner) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_NOTCHED_CORNER_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${notched_corner_url}/`;

        try
        {
            const {data} = await axios.post(url, notched_corner, config);

            dispatch({
                type: ActionType.CREATE_NOTCHED_CORNER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_NOTCHED_CORNER_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}

export const updateNotchedCorner = (notched_corner: NotchedCorner, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_NOTCHED_CORNER_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${notched_corner_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, notched_corner, config);

            dispatch({
                type: ActionType.UPDATE_NOTCHED_CORNER_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_NOTCHED_CORNER_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}

export const updateLocalNotchedCorner = (notched_corner: NotchedCorner) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_NOTCHED_CORNER,
            payload: notched_corner
        });
    }
}
