/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// REACT COMPONENTS ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import IconButton from "@mui/material/IconButton";
/// CUSTOM COMPONENTS ///
import CreateBasicRange from "../shared_components/create_range";
/// ICONS ///
import AddCircleIcon from "@mui/icons-material/AddCircle";
/// TYPES ///
import { createMiter } from "api/actions/price";
import { MiterRange } from "api/types/price";
import { Miter } from "api/types/price";
/// EMPTY VALUES ///
import { empty_splash } from "values/empty/price";
import { empty_splash_range } from "values/empty/price";

export default function CreateMiter() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [splash, setMiter] = useState<Miter>(empty_splash);
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const [ranges, setRanges] = useState<MiterRange[]>([{...empty_splash_range, any_range: true}]);
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setMiter({...splash, [key]: value});
        setError("");
        setHasError(false);
    }

    const saveBoolean = (key: string, value: boolean) => {
        setMiter({...splash, [key]: value});
        setError("");
        setHasError(false);
    }

    const handleRangePrice = (index: number, price: string) => {
        if(isNaN(Number(price)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ?
                {...range, price: Number(price)} :
                range
            )]);
        }
    }

    const handleRangeMeasurement = (index: number, height: string) => {
        if(isNaN(Number(height)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ? 
                {...range, radius: Number(height)} :
                range
            )]);
        }
    }

    const addRange = () => {
        setRanges([{...empty_splash, height: 0}, ...ranges]);
    }

    const handleCreate = () => {
        if(!price_list.id){
            setError("An unknown error has occurred. Please contact the administrator");
            setHasError(true);
            return;
        }

        dispatch(createMiter({...splash, price_list: price_list.id, ranges: ranges}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setMiter(empty_splash);
        setRanges([{...empty_splash_range, any_range: true}]);
    }

    return(
        <Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <CreateBasicRange 
                title="Create Miter Corner"
                open={open}
                error={error}
                has_error={has_error}
                ranges={ranges}
                close={handleClose}
                create={handleCreate}
                saveString={saveString}
                saveBoolean={saveBoolean}
                handleRangeMeasurement={handleRangeMeasurement}
                handleRangePrice={handleRangePrice}
                addRange={addRange} />
        </Fragment>
    )
}
