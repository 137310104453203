import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/price";
import { ActionType } from "api/action_types/price";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { WaterfallRange } from "api/types/price";
import { url_prefix } from "settings";

const waterfall_range_url: string = "waterfall_range";

export const createWaterfallRange = (waterfall_range: WaterfallRange) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.CREATE_WATERFALL_RANGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${waterfall_range_url}/`;

    try {
      const { data } = await axios.post(url, waterfall_range, config);

      dispatch({
        type: ActionType.CREATE_WATERFALL_RANGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.CREATE_WATERFALL_RANGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateWaterfallRange = (waterfall_range: WaterfallRange, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.UPDATE_WATERFALL_RANGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${waterfall_range_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, waterfall_range, config);

      dispatch({
        type: ActionType.UPDATE_WATERFALL_RANGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.UPDATE_WATERFALL_RANGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const deleteWaterfallRange = (id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.DELETE_WATERFALL_RANGE_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${waterfall_range_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_WATERFALL_RANGE_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.DELETE_WATERFALL_RANGE_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateLocalWaterfallRange = (waterfall_range: WaterfallRange) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_WATERFALL_RANGE,
      payload: waterfall_range
    });
  }
}
