import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { grey_color } from "styles/style";

interface Props {
    subtitle: string
}

export default function IslandSubtitle({subtitle}: Props) {
    return(
        <Grid item xs={12}>
            <Typography variant="body2" sx={{color: grey_color }}>
                {subtitle}
            </Typography>
        </Grid>
    )
}


