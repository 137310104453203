import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import RegistrationComponent from "./tabs/account_details";
import StaffBilling from "./tabs/billing";
import StaffPaymentForm from "./tabs/payment";
import StaffChoosePlan from "./tabs/plan";
import Steppers from "components/universal/stepper";
import Title from "components/universal/title";
import { mainContentAreaStyle } from "styles/style";
import { islandStyle } from "styles/style";
import { staff_payment_setup_steps } from "values/steps";

export default function CreateAccount () {
    const [step, setStep] = useState<number>(0);

    return (
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                    <Title 
                        title="Create Customer" 
                        update_pending={false}
                        start_times={[]}
                        update={() => void 0}
                        endProps={<div></div>} />
                </Grid>
                <Grid item xs={8.5} sx={{marginTop: 4}}>
                    <Paper sx={[islandStyle]}>
                        <Grid container direction="row" justifyContent="space-between"> 
                        {
                            step === 0 ?
                            <RegistrationComponent setStep={setStep} /> :
                            null
                        }
                        {
                            step === 1 ?
                            <StaffBilling setStep={setStep} /> :
                            null
                        }
                        {
                            step === 2 ?
                            <StaffChoosePlan setStep={setStep} /> :
                            null
                        }{
                            step === 3 ?
                            <StaffPaymentForm /> :
                            null
                        }
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3} sx={{marginTop: 4}}>
                    <Paper sx={[islandStyle]}>
                        <Steppers current_step={step} steps={staff_payment_setup_steps} />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}