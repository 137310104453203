import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "api/hooks/apiHook";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PasswordIcon from "@mui/icons-material/Password";
import { change_password_url } from "components/navigation/endpoints";

export default function UserThumbnail() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { user } = useAppSelector((state) => state.authentication);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Button onClick={handleClick}>
                <Stack direction="row" spacing={2}>
                    <Stack justifyContent="center">
                        <Typography align="left" variant="body2" color="primary">{user.business_name}</Typography>
                        <Typography align="left" variant="body2" color="primary">{user.first_name} {user.last_name}</Typography>
                    </Stack>
                    {
                        typeof user.logo_icon === "string" ?
                        <Avatar src={user.logo_icon} variant="square" /> :
                        null
                    }
                </Stack>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem onClick={(e) => navigate(change_password_url, {replace: false})}>
                    <ListItemIcon>
                        <PasswordIcon fontSize="small"/>
                    </ListItemIcon>
                    Change Password
                </MenuItem>
            </Menu>
        </React.Fragment>
  );
}