import { CreateFeedback } from "api/types/feedback";
import { CreateBug } from "api/types/feedback";
import { BUG } from "values/feedback";
import { LOW } from "values/feedback";

export const empty_create_feedback: CreateFeedback = {
    reported_by: 0,
    description: "",
    feedback_type: BUG
}

export const empty_create_bug: CreateBug = {
    reported_by: 0,
    description: "",
    priority: LOW
}