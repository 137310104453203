import { Fragment } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { createPortalSession } from "api/actions/billing";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SectionHeader from "components/universal/section_header";

export default function AccountStripePortal() {
    const dispatch = useAppDispatch();
    const { account } = useAppSelector((state) => state.account);
    const { billing_customer } = useAppSelector((state) => state.billing);

    return (
        <Stack sx={{marginTop: 4}}>
            <SectionHeader text="Stripe Account & Payment" variant="outlined" />
            { 
                billing_customer ? 
                <Fragment>
                    <Typography variant="body2" sx={{marginTop: 2, marginLeft: 0.5}}>
                        {billing_customer.name ? billing_customer.name : ""}
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: 0.5}}>
                        {billing_customer.email ? billing_customer.email : ""}
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: 0.5}}>
                        {
                            billing_customer.address && billing_customer.address.line1 ? 
                            billing_customer.address.line1 : ""}
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: 0.5}}>
                        {
                            billing_customer.address && billing_customer.address.city ? 
                            billing_customer.address.city : ""}
                        {
                            billing_customer.address && billing_customer.address.city && billing_customer.address.city !== "" &&
                            billing_customer.address.state && billing_customer.address.state !== "" ? 
                            "," : ""}
                        {
                            billing_customer.address && billing_customer.address.state ? 
                            billing_customer.address.state : ""}
                    </Typography>
                    <Typography variant="body2" sx={{marginBottom: 2, marginLeft: 0.5}}>
                        {
                            billing_customer.address && billing_customer.address.postal_code ? 
                            billing_customer.address.postal_code : ""}
                    </Typography> 
                </Fragment> :
                null
            }
            <Button 
                size="small"
                onClick={() => dispatch(createPortalSession(account.customer_id))}
                variant="contained">
                Open Stripe Portal
            </Button>
        </Stack>
    )
}