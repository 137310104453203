import Name from "../shared/name";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { MaterialToPriceList } from "api/types/price";
import { getMaterialHeightDisplay } from "values/material";
import { getRowSecondStyle } from 'values/style';

interface Props {
  index: number,
  item: MaterialToPriceList
}

export default function MaterialToPriceListPlainItem({ index, item }: Props) {
  return (
    <Grid container item alignItems="center" sx={[{ padding: 1 }, getRowSecondStyle(index)]}>
      <Name
        bold={false}
        name={item.material_data && item.material_data.name ? item.material_data.name : ""}
        width={6} />
      <Grid item xs={6}>
        <Typography variant="body2">
          {getMaterialHeightDisplay(item.material_data ? item.material_data.thickness : "")}
        </Typography>
      </Grid>
    </Grid>
  )
}
