import { useState } from "react";
import { createSplashRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { SplashRange } from "api/types/price";
import { Splash } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
  splash: Splash
}

export default function CreateSplashRange({ splash }: Props) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<SplashRange>({});

  const handlePrice = (price: string) => {
    if (isNaN(Number(price)) === false) {
      setRange({ ...range, price: Number(price) });
    }
  }

  const handleHeight = (height: string) => {
    if (isNaN(Number(height)) === false) {
      setRange({ ...range, height: Number(height) });
    }
  }

  const handleCreate = () => {
    let error = false;

    if (error) {
      return;
    }

    if (splash.id) {
      const create_range = { ...range, splash: splash.id, any_range: false };
      dispatch(createSplashRange(create_range));
      handleClose();
    }
  }

  const handleClose = () => {
    setOpen(false);
    setRange({});
  }

  return (
    <CreateBasicRange
      title="Create Splash Range"
      open={open}
      measurement_type="Height"
      error=""
      has_error={false}
      close={() => setOpen(false)}
      create={handleCreate}
      handlePrice={handlePrice}
      handleMeasurement={handleHeight} />
  )
}
