import { useState } from "react";
import Grid from "@mui/material/Grid";
import ColorItemPlain from './color_item_plain';
import ColorEditingItem from './color_editing_item';
import { Color } from "api/types/material";

interface Props {
  hovered: number,
  start_update: (color: Color) => void,
  setHovered: (index: number) => void,
  index: number,
  item: Color
}

export default function ColorItem({ hovered, start_update, setHovered, index, item }: Props) {
  const [locked, setLocked] = useState<boolean>(false);

  return (
    <Grid container item onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)}>
      {
        index === hovered || locked ?
          <ColorEditingItem
            setLocked={setLocked}
            start_update={start_update}
            color={item} /> :
          <ColorItemPlain
            color={item} />
      }
    </Grid>
  )
}
