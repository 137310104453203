import axios from "axios";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/account";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType } from "api/action_types/account";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { url_prefix } from "settings";
import { profile_url } from "../endpoints";

export const getAccounts = (
    last_name?: string,
    address?: string,
    city?: string,
    province?: string,
    email?: string,
    phone_number?: string,
    postal?: string
) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.ACCOUNT_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${profile_url}/?`;
        if(last_name && last_name !== ""){
            url+=`last_name=${last_name}&`;
        }
        if(address && address !== ""){
            url+=`address=${address}&`;
        }
        if(city && city !== ""){
            url+=`city=${city}&`;
        }
        if(province && province !== ""){
            url+=`province=${province}&`;
        }
        if(email && email !== ""){
            url+=`email=${email}&`;
        }
        if(phone_number && phone_number !== ""){
            url+=`phone_number=${phone_number}&`;
        }
        if(postal && postal !== ""){
            url+=`postal=${postal}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_ACCOUNTS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {   
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.ACCOUNT_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
        }
    }
}