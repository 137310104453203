import React from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { gridSpacing } from "styles/style";

interface Props {
    enabled: boolean,
    taxable: boolean,
    grid_width?: number,
}

export default function Checkboxes(
    {
        enabled,
        taxable,
        grid_width
    }: Props
) {
    return(
        <React.Fragment>
            <Grid item xs={grid_width ? grid_width / 2 : 2} sx={gridSpacing}>
                <Checkbox 
                    sx={{marginLeft: 0.5}}
                    size="small"
                    disabled
                    defaultChecked={enabled} />
            </Grid>
            <Grid item xs={grid_width ? grid_width / 2 : 2} sx={gridSpacing}>
                <Checkbox 
                    sx={{marginLeft: 0.5}}
                    size="small"
                    disabled
                    defaultChecked={taxable} />
            </Grid>
        </React.Fragment>
    )
}
