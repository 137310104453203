export const COMPLETED: string = "C";
export const COMPLETED_WITH_ERRORS: string = "E";
export const UNSUCCESSFUL: string = "U";
export const IN_PROGRESS: string = "P";

export function getMaterialImportStatusDisplay(status: string): string {
    if(status === COMPLETED){
        return "Completed";
    }
    else if(status === COMPLETED_WITH_ERRORS){
        return "Completed with Errors";
    }
    else if(status === UNSUCCESSFUL){
        return "Unsuccessful";
    }
    else if(status === IN_PROGRESS){
        return "In Progress";
    }
    return "Unknown";
}