import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { HeaderItem } from "api/types/my_ui";
import { tableHeaderStyle } from 'styles/style';

interface Props {
    items: HeaderItem[]
}

export default function Header({items}: Props) {
    return(
        <Grid sx={tableHeaderStyle} container item xs={12}>
            {
                items.map((item, index) => {
                    return (
                        <Grid item xs={item.width} key={`header_${index}`}>
                            <Typography variant="body2" align={item.align_right ? "right" : "left"}>
                                <b>{item.text}</b>
                            </Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}
