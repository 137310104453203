import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { RadiusCornerRange } from "api/types/price";
import { url_prefix } from 'settings';

const radius_corner_range_url: string = "radius_corner_range";

export const createRadiusCornerRange = (radius_corner_range: RadiusCornerRange) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.CREATE_RADIUS_CORNER_RANGE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${radius_corner_range_url}/`;

        try
        {
            const {data} = await axios.post(url, radius_corner_range, config);

            dispatch({
                type: ActionType.CREATE_RADIUS_CORNER_RANGE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.CREATE_RADIUS_CORNER_RANGE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}

export const updateRadiusCornerRange = (radius_corner_range: RadiusCornerRange, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.UPDATE_RADIUS_CORNER_RANGE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${radius_corner_range_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, radius_corner_range, config);

            dispatch({
                type: ActionType.UPDATE_RADIUS_CORNER_RANGE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.UPDATE_RADIUS_CORNER_RANGE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const deleteRadiusCornerRange = (id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.DELETE_RADIUS_CORNER_RANGE_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${radius_corner_range_url}/${id}/`;

        try
        {
            const {data} = await axios.delete(url, config);

            dispatch({
                type: ActionType.DELETE_RADIUS_CORNER_RANGE_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.DELETE_RADIUS_CORNER_RANGE_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
            
        }
    }
}

export const updateLocalRadiusCornerRange = (radius_corner_range: RadiusCornerRange) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_RADIUS_CORNER_RANGE,
            payload: radius_corner_range
        });
    }
}