import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getBug } from "api/actions/feedback";
import { updateBug } from "api/actions/feedback";
import { updateLocalBug } from "api/actions/feedback";
import { UpdateBug } from "api/types/feedback";
import { UpdateTime } from "api/types/update";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Title from "components/universal/title";
import CreateBugLogComponent from "./dialog/create_bug_log";
import BugLogRow from "./row/bug_log";
import List from "components/universal/list";
import { getDate } from "functions/date";
import { getPriorityChoiceDisplay } from "values/feedback";
import { mainContentAreaStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

export default function BugComponent() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { bug } = useAppSelector((state) => state.feedback);
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_times, setStartTimes] = useState<UpdateTime[]>([]);

    const handleResolutionDescription = (resolution_statement: string) => {
        if(bug){
            const new_bug = {...bug, resolution_statement: resolution_statement};
            start_update(new_bug);
        }
    }

    const handleResolved = (resolved: boolean) => {
        if(bug){
            const new_bug = {...bug, resolved: resolved};
            start_update(new_bug);
        }
    }

    useEffect(() => {
        if(id){
            dispatch(getBug(id));
        }
    }, [dispatch, id]);

    const start_update = (bug_update: UpdateBug) => {
        dispatch(updateLocalBug(bug_update));
        setUpdatePending(true); 
        setStartTimes([...start_times.filter(item => item.data.id !== bug_update.id), {
            time: Math.round(new Date().getTime() / 1000),
            data: bug_update
        }]);
    }

    const update = (update_time: UpdateTime) => {
        const new_start_times = [...start_times.filter(item => item.data.id !== update_time.data.id)];
        setStartTimes(new_start_times);
        
        if(update_time.data.id){
            dispatch(updateBug(update_time.data, update_time.data.id));
        }
        
        if(new_start_times.length === 0){
            setUpdatePending(false);
        }
    }

    return(
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between" alignItems="start">
                <Grid item xs={12}>
                    <Title 
                        title="Bug"
                        update_pending={update_pending}
                        start_times={start_times}
                        update={update}
                        endProps={<div />} />
                </Grid>
                <Grid item container xs={6} sx={{paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Details</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>From:</b> {bug ? bug.reported_by_user : ""}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Priority:</b> {getPriorityChoiceDisplay(bug ? bug.priority : "")}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Date Added:</b> {bug ? bug.date_added : ""}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} sx={{paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Description</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                {bug ? bug.description : ""}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} sx={{marginTop: 4, paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Resolution</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Stack direction="row">
                                {
                                    bug ?
                                    <Checkbox 
                                        sx={{height: 16, width: 16}}
                                        onChange={(e) => handleResolved(e.target.checked)}
                                        defaultChecked={bug.resolved} /> :
                                    null
                                }
                                
                            </Stack>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Date Resolved: </b> {getDate(bug ? bug.date_added : "")}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Description:</b>
                            </Typography>
                            <TextField
                                sx={textFieldStyle}
                                fullWidth
                                size="small"
                                variant="outlined"
                                minRows={3}
                                InputLabelProps={{shrink: true}}
                                multiline
                                defaultValue={bug ? bug.resolution_statement : ""}
                                id="resolution_description"
                                onChange={(e) => handleResolutionDescription(e.target.value)} />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid container item xs={6} sx={{marginTop: 4, paddingLeft: 2.25}}>
                    <Grid item xs={9} sx={{marginBottom: 1}}>
                        <Stack 
                            justifyContent="space-between"
                            alignItems="center"
                            direction="row"
                            sx={{
                                paddingRight: 1,
                                backgroundColor: primary_color
                            }}>
                            <Typography variant="body1" sx={{color: white_color, padding: 0.5, paddingLeft: 1}} >
                                <b>Logs</b>
                            </Typography>
                            {bug ? <CreateBugLogComponent bug_id={bug.id} /> : <div></div>}
                        </Stack>
                        
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item container xs={9}>
                        <List
                            items={bug ? bug.bug_logs : []}
                            Item={BugLogRow} />
                    </Grid>
                    </Grid>
            </Grid>
        </Box>
    )
}