import { Feedback } from "api/types/feedback";
import { UpdateFeedback } from "api/types/feedback";
import { Bug } from "api/types/feedback";
import { UpdateBug } from "api/types/feedback";
import { BugLog } from "api/types/feedback";

export enum ActionType {
    GET_FEEDBACKS_SUCCESS="GET_FEEDBACKS_SUCCESS",
    GET_FEEDBACK_SUCCESS="GET_FEEDBACK_SUCCESS",
    CREATE_FEEDBACK_SUCCESS="CREATE_FEEDBACK_SUCCESS",
    UPDATE_FEEDBACK_SUCCESS="UPDATE_FEEDBACK_SUCCESS",
    UPDATE_LOCAL_FEEDBACK="UPDATE_LOCAL_FEEDBACK",
    FEEDBACK_PENDING="FEEDBACK_PENDING",
    FEEDBACK_FAIL="FEEDBACK_FAIL",
    GET_BUGS_SUCCESS="GET_BUGS_SUCCESS",
    GET_BUG_SUCCESS="GET_BUG_SUCCESS",
    CREATE_BUG_SUCCESS="CREATE_BUG_SUCCESS",
    UPDATE_BUG_SUCCESS="UPDATE_BUG_SUCCESS",
    UPDATE_LOCAL_BUG="UPDATE_LOCAL_BUG",
    BUG_PENDING="BUG_PENDING",
    BUG_FAIL="BUG_FAIL",
    CREATE_BUG_LOG_SUCCESS="CREATE_BUG_LOG_SUCCESS",
    BUG_LOG_PENDING="BUG_LOG_PENDING",
    BUG_LOG_FAIL="BUG_LOG_FAIL"
}

interface getFeedbacksSuccess {
    type: ActionType.GET_FEEDBACKS_SUCCESS,
    payload: Feedback[]
}

interface getFeedbackSuccess {
    type: ActionType.GET_FEEDBACK_SUCCESS,
    payload: Feedback
}

interface createFeedbackSuccess {
    type: ActionType.CREATE_FEEDBACK_SUCCESS,
    payload: Feedback
}

interface updateFeedbackSuccess {
    type: ActionType.UPDATE_FEEDBACK_SUCCESS,
    payload: Feedback
}

interface updateLocalFeedback {
    type: ActionType.UPDATE_LOCAL_FEEDBACK,
    payload: UpdateFeedback
}

interface feedbackPending {
    type: ActionType.FEEDBACK_PENDING
}

interface feedbackFail {
    type: ActionType.FEEDBACK_FAIL,
    payload: string
}

interface getBugsSuccess {
    type: ActionType.GET_BUGS_SUCCESS,
    payload: Bug[]
}

interface getBugSuccess {
    type: ActionType.GET_BUG_SUCCESS,
    payload: Bug
}

interface createBugSuccess {
    type: ActionType.CREATE_BUG_SUCCESS,
    payload: Bug
}

interface updateBugSuccess {
    type: ActionType.UPDATE_BUG_SUCCESS,
    payload: Bug
}

interface updateLocalBug {
    type: ActionType.UPDATE_LOCAL_BUG,
    payload: UpdateBug
}

interface bugPending {
    type: ActionType.BUG_PENDING
}

interface bugFail {
    type: ActionType.BUG_FAIL,
    payload: string
}

interface createBugLogSuccess {
    type: ActionType.CREATE_BUG_LOG_SUCCESS,
    payload: BugLog
}

interface bugLogPending {
    type: ActionType.BUG_LOG_PENDING
}

interface bugLogFail {
    type: ActionType.BUG_LOG_FAIL,
    payload: string
}

export type Action = 
    getFeedbacksSuccess |
    getFeedbackSuccess |
    createFeedbackSuccess |
    updateFeedbackSuccess |
    updateLocalFeedback |
    feedbackPending |
    feedbackFail |
    getBugsSuccess |
    getBugSuccess |
    createBugSuccess |
    updateBugSuccess |
    updateLocalBug |
    bugPending |
    bugFail |
    createBugLogSuccess |
    bugLogPending |
    bugLogFail;