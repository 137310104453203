import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { getRegions } from "api/actions/region";
import { UpdateTime } from "api/types/update";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import UpdateTimer from "components/universal/save_timer";
import { tableHeaderStyle } from 'styles/style';
import { filterItemStyle } from "styles/style";

export default function FilterRegions() {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [update_pending, setUpdatePending] = useState<boolean>(false);
  const [start_time, setStartTime] = useState<UpdateTime[]>([]);

  const filter = () => {
    dispatch(getRegions(
      false,
      name,
      status,
    ));
    setUpdatePending(false);
  }

  const handleName = (new_name: string) => {
    setUpdatePending(true);
    setName(new_name);
    const update_time: UpdateTime = {
      time: Math.round(new Date().getTime() / 1000),
      data: null
    }
    setStartTime([update_time]);
  }

  const handleStatus = (new_status: string) => {
    setUpdatePending(true);
    if (new_status === "Active") {
      setStatus("true");
    }
    else if (new_status === "Inactive") {
      setStatus("false");
    }
    else {
      setStatus("");
    }

    const update_time: UpdateTime = {
      time: Math.round(new Date().getTime() / 1000),
      data: null
    }
    setStartTime([update_time]);
  }

  return (
    <Grid
      sx={[tableHeaderStyle, { paddingRight: 8 }]}
      alignItems="center"
      container>
      <Grid item xs={10}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Filter</Typography>
      </Grid>
      <Grid item xs={2}>
        {
          update_pending ?
            <UpdateTimer
              update_text="Loading"
              font_size={14}
              size={12}
              fixed={false}
              start_times={start_time}
              update={filter} /> :
            null
        }
      </Grid>
      <Grid item xs={12}>
        <TextField
          sx={filterItemStyle}
          fullWidth
          size="small"
          label="Name"
          id="name"
          InputLabelProps={{ shrink: true }}
          onChange={e => handleName(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          sx={filterItemStyle}
          fullWidth
          size="small"
          label="Active"
          id="active"
          select
          defaultValue="Any"
          InputLabelProps={{ shrink: true }}
          onChange={e => handleStatus(e.target.value)}>
          <MenuItem key="Any" value="Any">
            Any
          </MenuItem>
          <MenuItem key="Active" value="Active">
            Active
          </MenuItem>
          <MenuItem key="Inactive" value="Inactive">
            Inactive
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  )
}
