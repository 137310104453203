import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { Customer } from "api/types/billing";
import { CustomerError } from "api/types/billing";
import { resetRegistrationComplete } from "api/actions/authentication";
import { setRegistrationComplete } from "api/actions/authentication";
import { setBillingCustomerCreated } from "api/actions/billing";
import { createStripeCustomer } from "api/actions/billing";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { validateEmail } from "functions/value";
import { validatePhoneNumber } from "functions/value";
import { validatePostalCode } from "functions/value";
import { text_primary } from "styles/style";
import { empty_customer_error } from "values/empty/billing";
import { grey_color } from "styles/style";

interface Props {
    setStep: (step: number) => void
}
  
export default function StaffBilling({setStep}: Props) {
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.authentication); 
    const { created_customer } = useAppSelector((state) => state.authentication);
    const { billing_customer_created } = useAppSelector((state) => state.billing);
    const [customer, setCustomer] = useState<Customer>({
        email: "",
        name: "",
        payment_method: "",
        phone: "",
        address: {
            city: "",
            country: "CA",
            postal_code: "",
            state: "AB",
            line1: "",
            line2: ""
        },
        tax: { 
            ip_address: "68.148.28.46"
        }
    });

    useEffect(() => {
        dispatch(resetRegistrationComplete());
    }, [dispatch]);

    useEffect(() => {
        setRegistrationComplete(false);
        setBillingCustomerCreated(false);
    }, []);

    if(billing_customer_created){
        setStep(2);
    }

    const [customer_error, setCustomerError] = useState<CustomerError>(empty_customer_error);

    const useAccountDetails = () => {
        if(created_customer){
            setCustomer({
                email: created_customer.email ? created_customer.email : "",
                name: `${created_customer.first_name} ${created_customer.last_name}`,
                payment_method: customer.payment_method,
                phone: created_customer.phone_number ? created_customer.phone_number : "",
                address: {
                    city: created_customer.city ? created_customer.city : "",
                    postal_code: created_customer.postal_code ? created_customer.postal_code : "",
                    line1: created_customer.address ? created_customer.address : "",
                    line2: "",
                    state: created_customer.province ? created_customer.province : "AB",
                    country: "CA"
                },
                tax: {
                    ip_address: "68.148.28.46"
                }
            });
        }
    }

    const changeCustomer = (key: string, value: string) => {
        setCustomer({...customer, [key]: value});
        setCustomerError({...customer_error, [`${key}_error`]: "", [`${key}_has_error`]: false});
    }
    
    const changeAddress = (key: string, value: string) => {
        setCustomer({...customer, address: {...customer.address, [key]: value}});
        setCustomerError({...customer_error, [`${key}_error`]: "", [`${key}_has_error`]: false});
    }

    const handleSubmitBilling = () => {
        let error: boolean = false;
        const new_errors: CustomerError = {...customer_error};

        if(!validateEmail(customer.email)){
            new_errors.email_error = "Must enter a valid email";
            new_errors.email_has_error = true;
            error = true;
        }

        if(customer.name === ""){
            new_errors.name_error = "Name must not be blank";
            new_errors.name_has_error = true;
            error = true;
        }

        if(!validatePhoneNumber(customer.phone)){
            new_errors.phone_error = "Must enter a valid phone number";
            new_errors.phone_has_error = true;
            error = true;
        }

        if(customer.address.city === ""){
            new_errors.city_error = "Must enter a city";
            new_errors.city_has_error = true;
            error = true;
        }

        if(!validatePostalCode(customer.address.postal_code)){
            new_errors.postal_code_error = "Must enter a valid postal code";
            new_errors.postal_code_has_error = true;
            error = true;
        }

        if(customer.address.line1 === "" && customer.address.line2 === ""){
            new_errors.address_error = "Must enter an address";
            new_errors.address_has_error = true;
            error = true;
        }

        if(error){
            setCustomerError(new_errors);
            return;
        }

        if(created_customer){
            dispatch(createStripeCustomer(customer, created_customer.id));
        }
    }
  
    return (
        <Grid container item alignItems="center" sx={{width: "100%", paddingLeft: 3.5, paddingRight: 3.5}}>
            <Grid item xs={6}>
                <Typography variant="h6" sx={{color: text_primary}}>
                    Billing Details
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button size="small" onClick={useAccountDetails}>
                        Use Account Details
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{marginBottom: customer_error.name_has_error ? 0 : 0.5 }}>
                <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5}}>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormHelperText error>{customer_error.name_error}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="Full Name"
                    onChange={(e) => changeCustomer("name", e.target.value)}
                    error={customer_error.name_has_error}
                    value={customer.name}
                    size="small" />
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={12} sx={{marginBottom: customer_error.address_has_error ? 0 : 0.5 }}>
                <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                    Address
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormHelperText error>{customer_error.address_error}</FormHelperText>
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={3} sx={{paddingRight: 1, marginBottom: 1}}>
                <TextField
                    fullWidth
                    label="Address 1"
                    onChange={(e) => changeAddress("line1", e.target.value)}
                    error={customer_error.address_has_error}
                    value={customer.address.line1}
                    size="small" />
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1, marginBottom: 1}}>
                <TextField
                    fullWidth
                    label="Address 2"
                    onChange={(e) => changeAddress("line2", e.target.value)}
                    error={customer_error.address_has_error}
                    value={customer.address.line2}
                    size="small" />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
                <FormHelperText error>{customer_error.city_error}</FormHelperText>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
                <FormHelperText error>{customer_error.postal_code_error}</FormHelperText>
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    label="City"
                    onChange={(e) => changeAddress("city", e.target.value)}
                    error={customer_error.city_has_error}
                    value={customer.address.city}
                    size="small" />
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    select
                    value={customer.address.state}
                    size="small"
                    label="Province"
                    id="state"
                    onChange={e => changeAddress("state", e.target.value)}>
                    <MenuItem key="AB" value="AB">
                        AB
                    </MenuItem>
                    <MenuItem key="ON" value="ON">
                        ON
                    </MenuItem>
                    <MenuItem key="BC" value="BC">
                        BC
                    </MenuItem>
                    <MenuItem key="MB" value="MB">
                        MB
                    </MenuItem>
                    <MenuItem key="SK" value="SK">
                        SK
                    </MenuItem>
                    <MenuItem key="QC" value="QC">
                        QC
                    </MenuItem>
                    <MenuItem key="NS" value="NS">
                        NS
                    </MenuItem>
                    <MenuItem key="PE" value="PE">
                        PE
                    </MenuItem>
                    <MenuItem key="NL" value="NL">
                        NL
                    </MenuItem>
                    <MenuItem key="NB" value="NB">
                        NB
                    </MenuItem>
                    <MenuItem key="YT" value="YT">
                        YT
                    </MenuItem>
                    <MenuItem key="NU" value="NU">
                        NU
                    </MenuItem>
                    <MenuItem key="NT" value="NT">
                        NT
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    label="Country"
                    disabled
                    value="CA"
                    size="small" />
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    label="Postal Code"
                    onChange={(e) => changeAddress("postal_code", e.target.value)}
                    error={customer_error.postal_code_has_error}
                    value={customer.address.postal_code}
                    size="small" />
            </Grid>
            <Grid item xs={12} sx={{marginBottom: customer_error.email_has_error || customer_error.phone_has_error ? 0 : 0.5 }}>
                <Typography variant="body2" sx={{color: grey_color, paddingLeft: 0.5, paddingTop: 1}}>
                    Contact
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormHelperText error>{customer_error.phone_error}</FormHelperText>
            </Grid>
            <Grid item xs={3}>
                <FormHelperText error>{customer_error.email_error}</FormHelperText>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    label="Phone (555-555-5555)"
                    onChange={(e) => changeCustomer("phone", e.target.value)}
                    error={customer_error.phone_has_error}
                    value={customer.phone}
                    size="small" />
            </Grid>
            <Grid item xs={3} sx={{paddingRight: 1}}>
                <TextField
                    fullWidth
                    label="Email"
                    onChange={(e) => changeCustomer("email", e.target.value)}
                    error={customer_error.email_has_error}
                    value={customer.email}
                    size="small" />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{marginTop: 20}}>
                    {
                        loading ? 
                        <CircularProgress /> :
                        <Button variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={handleSubmitBilling} >
                            Submit
                        </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    );
}