import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { UserStatus } from "api/types/authentication";
import { updateAccount } from "api/actions/account";
import { resendEmailConfirmation } from "api/actions/account";
import { clearEmailConfirmation } from "api/actions/account";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import SectionHeader from "components/universal/section_header";
import SendIcon from "@mui/icons-material/Send";
import { StringField } from "components/universal/textfield";
import { SelectField } from "components/universal/textfield";
import { BOOLEAN_CHOICES } from "values/textfield";
import { ACCOUNT_CHOICES } from "values/accounts";
import { YES } from "values/textfield";
import { NO } from "values/textfield";
import { FABRICATOR } from "values/accounts";
import { DEALER } from "values/accounts";
import { primary_color } from "styles/style";
import { secondary_color } from "styles/style";
import { JSONIsEmpty } from "functions/value";
import { white_color } from "styles/style";
import { black_color } from "styles/style";

export default function AccountStatus(){
    const dispatch = useAppDispatch();
    const { account } = useAppSelector((state) => state.account);
    const { confirm_email_sending } = useAppSelector((state) => state.account);
    const { confirm_email_sent } = useAppSelector((state) => state.account);
    const [status, setStatus] = useState<UserStatus>({});
    const [email_confirmation_open, setEmailConfirmationOpen] = useState<boolean>(false);

    const save = () => {
        dispatch(updateAccount(status, account.id));
        setStatus({});
    }

    const update = (key: string, value: string) => {
        setStatus({...status, [key]: value});
    }

    const booleanUpdate = (key: string, value: string) => {
        setStatus({...status, [key]: value === YES ? true : false})
    }

    const accountUpdate = (key: string, value: string) => {
        if(value === FABRICATOR){
            setStatus({...status, is_fabricator: true, is_distributor: false});
        }
        else {
            setStatus({...status, is_fabricator: false, is_distributor: true});
        }
    }

    if(confirm_email_sent && !email_confirmation_open){
        dispatch(clearEmailConfirmation());
        setEmailConfirmationOpen(true);
    }

    return (
        <Stack>
            <SectionHeader text="Status" variant="outlined" />
            <SelectField 
                init_select={account.is_fabricator ? FABRICATOR : DEALER}
                data_key="account_type"
                label="Account Type"
                items={ACCOUNT_CHOICES}
                update={accountUpdate} />
            <Stack direction="row" alignItems="center">
                <SelectField 
                    readonly={true}
                    init_select={account.email_confirmed ? YES : NO}
                    data_key="email_confirmed"
                    label="Email Confirmed"
                    items={BOOLEAN_CHOICES}
                    update={booleanUpdate} />
                <Tooltip title="Resend Confirmation Email">
                    <IconButton sx={{marginTop: 0.75}} onClick={() => dispatch(() => dispatch(resendEmailConfirmation(account.email)))}>
                        <SendIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <SelectField 
                init_select={account.payment_initialized ? YES : NO}
                data_key="payment_initialized"
                label="Payment Initialized"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.pending_account_confirmation ? YES : NO}
                data_key="pending_account_confirmation"
                label="Pending Account Confirmation"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.is_active ? YES : NO}
                data_key="is_active"
                label="Account Active"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.subscription_active ? YES : NO}
                data_key="subscription_active"
                label="Subscription Active"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.past_due ? YES : NO}
                data_key="past_due"
                label="Past Due"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.unpaid ? YES : NO}
                data_key="unpaid"
                label="Unpaid"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.canceled ? YES : NO}
                data_key="canceled"
                label="Canceled"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.paused ? YES : NO}
                data_key="paused"
                label="Paused"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.quote_only ? YES : NO}
                data_key="quote_only"
                label="Quote Only"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <SelectField 
                init_select={account.market_only ? YES : NO}
                data_key="market_only"
                label="Market Only"
                items={BOOLEAN_CHOICES}
                update={booleanUpdate} />
            <StringField init_value={account.customer_id} data_key="customer_id" label="Stripe Customer Id" update={update} />
            <Button 
                size="small"
                sx={{marginTop: 2, backgroundColor: JSONIsEmpty(status) ? primary_color : secondary_color}}
                onClick={save}
                variant="contained">
                Save Status
            </Button>
            <Snackbar 
                open={email_confirmation_open}
                autoHideDuration={5000}
                message={`Email confirmation sent to ${account.email}`}>
                <Alert 
                    icon={false}
                    sx={{backgroundColor: black_color, color: white_color}}
                    onClose={() => setEmailConfirmationOpen(false)}>
                    Email confirmation sent to {account.email}
                </Alert>
            </Snackbar>
            <Snackbar 
                open={confirm_email_sending}
                message={`Email confirmation sending to ${account.email}`}>
                    <Alert
                        icon={false} 
                        sx={{backgroundColor: black_color, color: white_color}}>
                        <LinearProgress sx={{height: 2, marginBottom: 1, backgroundColor: white_color}} color="secondary"/>
                        Email confirmation sending to {account.email}
                    </Alert>
                </Snackbar>
        </Stack>
    )
}