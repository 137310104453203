import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { dark_grey_color } from "styles/style";

export const drawerWidth = 240;
export const miniDrawerWidth = 60;

export const drawerIconStyle = {
    height: 20,
    width: 20
}

export const miniDrawerIconStyle = {
    color: dark_grey_color
}

export const miniListItemButton = {
    width: 30,
    height: 40,
    paddingLeft: 0.25,
    paddingRight: 0.25,
    marginTop: 0.25,
    marginBottom: 0.25
}

export const drawerListItemStyle = {
    paddingTop: 0, 
    paddingBottom: 0,
    height: 35
}

export const drawerHighlightStyle = {
    backgroundColor: primary_color
}

export const drawerHighlightTextStyle = {
    color: white_color
}

export const drawerListButtonStyle = {
    paddingTop: 0.5,
    paddingBottom: 0.5
}


export const drawerHighlightIconStyle = {
    color: white_color
}