/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
/// TYPES ///
import { FABRICATION } from "api/types/update";
import { FabricationCharge } from "api/types/price";
/// ACTIONS ///
import { getFabricationCharges } from "api/actions/price/fabrication_charge";
/// COMPONENTS ///
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateFabricationCharge } from "../dialog/create";
import FabricationChargeItem from "../row/fabrication_charge";
/// STYLES ///
import { islandStyle } from "styles/style";

interface Props {
    start_update: (type: number, data: FabricationCharge) => void
}
export default function PriceListFabrication({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { fabrication_charges } = useAppSelector((state) => state.price);

    const handle_update = (update_item: FabricationCharge) => {
        start_update(FABRICATION, update_item);
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getFabricationCharges(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Fabrication Charges"
                    endProps={
                        <CreateFabricationCharge />} />
                <Header 
                    items={[
                        {text: "Height", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={fabrication_charges}
                    Item={FabricationChargeItem}
                    start_update={handle_update} />
            </Grid>
        </Paper> 
    )
}
