import { InstallationCharge } from "api/types/price";

export enum ActionType {
    GET_INSTALLATION_CHARGES_PENDING="GET_INSTALLATION_CHARGES_PENDING",
    GET_INSTALLATION_CHARGES_SUCCESS="GET_INSTALLATION_CHARGES_SUCCESS",
    GET_INSTALLATION_CHARGES_FAIL="GET_INSTALLATION_CHARGES_FAIL",
    CREATE_INSTALLATION_CHARGE_PENDING = "CREATE_INSTALLATION_CHARGE_PENDING",
    CREATE_INSTALLATION_CHARGE_SUCCESS = "CREATE_INSTALLATION_CHARGE_SUCCESS",
    CREATE_INSTALLATION_CHARGE_FAIL = "CREATE_INSTALLATION_CHARGE_FAIL",
    UPDATE_INSTALLATION_CHARGE_PENDING = "UPDATE_INSTALLATION_CHARGE_PENDING",
    UPDATE_INSTALLATION_CHARGE_SUCCESS = "UPDATE_INSTALLATION_CHARGE_SUCCESS",
    UPDATE_INSTALLATION_CHARGE_FAIL = "UPDATE_INSTALLATION_CHARGE_FAIL",
    UPDATE_LOCAL_INSTALLATION_CHARGE = "UPDATE_LOCAL_INSTALLATION_CHARGE"
}

interface getInstallationChargesFail {
    type: ActionType.GET_INSTALLATION_CHARGES_FAIL,
    payload: string
}

interface getInstallationChargesSuccess {
    type: ActionType.GET_INSTALLATION_CHARGES_SUCCESS,
    payload: InstallationCharge[]
}

interface getInstallationChargesPending {
    type: ActionType.GET_INSTALLATION_CHARGES_PENDING
}

interface createInstallationChargeFail {
    type: ActionType.CREATE_INSTALLATION_CHARGE_FAIL,
    payload: string
}

interface createInstallationChargeSuccess {
    type: ActionType.CREATE_INSTALLATION_CHARGE_SUCCESS,
    payload: InstallationCharge
}

interface createInstallationChargePending {
    type: ActionType.CREATE_INSTALLATION_CHARGE_PENDING
}

interface updateInstallationChargeFail {
    type: ActionType.UPDATE_INSTALLATION_CHARGE_FAIL,
    payload: string
}

interface updateInstallationChargeSuccess {
    type: ActionType.UPDATE_INSTALLATION_CHARGE_SUCCESS,
    payload: InstallationCharge
}

interface updateInstallationChargePending {
    type: ActionType.UPDATE_INSTALLATION_CHARGE_PENDING
}

interface updateLocalInstallationCharge {
    type: ActionType.UPDATE_LOCAL_INSTALLATION_CHARGE,
    payload: InstallationCharge
}

export type Action =
    getInstallationChargesFail |
    getInstallationChargesPending |
    getInstallationChargesSuccess |
    createInstallationChargePending |
    createInstallationChargeSuccess |
    createInstallationChargeFail |
    updateInstallationChargeFail |
    updateInstallationChargeSuccess |
    updateInstallationChargePending |
    updateLocalInstallationCharge;