/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/region";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/region";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { Region } from "api/types/region";
/// URLS ///
import { url_prefix } from "settings";
const sub_region_url: string = "sub_region";
const region_url: string = "region";

export const getRegions = (for_fabricators: boolean, name?: string, status?: string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${region_url}/?`;

    if (for_fabricators) {
      url += `for_fabricators=${for_fabricators}&`;
    }

    if (name && name !== "") {
      url += `name=${name}&`;
    }

    if (status && status !== "") {
      url += `active=${status}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_REGIONS,
        regions: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getRegion = (id: string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${region_url}/${id}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_REGION,
        region: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const createRegion = (region: Region) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${region_url}/`;

    try {
      const { data } = await axios.post(url, region, config);

      dispatch({
        type: ActionType.CREATE_REGION,
        region: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateRegion = (region: Region, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${region_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, region, config);

      dispatch({
        type: ActionType.UPDATE_REGION,
        region: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const deleteRegion = (id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${region_url}/${id}/`;

    try {
      const { data } = await axios.delete(url, config);

      dispatch({
        type: ActionType.DELETE_REGION,
        id: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });

        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const getSubRegions = () => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${sub_region_url}/?`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SUB_REGIONS,
        sub_regions: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const getSubRegion = (id?: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.REGION_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${sub_region_url}/${id}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SUB_REGION,
        sub_region: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.REGION_ERROR,
          error: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
