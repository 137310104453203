import { useAppSelector } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MiniLogout from "components/authentication/mini_logout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewListIcon from "@mui/icons-material/ViewList";
import LanguageIcon from "@mui/icons-material/Language";
import CategoryIcon from "@mui/icons-material/Category";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import TextureIcon from "@mui/icons-material/Texture";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CommentIcon from "@mui/icons-material/Comment";
import BugReportIcon from "@mui/icons-material/BugReport";
import { create_account_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
import { region_url } from "components/navigation/endpoints";
import { manage_suppliers_url } from "components/navigation/endpoints";
import { home_url } from "components/navigation/endpoints";
import { feedback_url } from "components/navigation/endpoints";
import { bug_url } from "components/navigation/endpoints";
import { account_url } from "components/navigation/endpoints";
import { material_import_url } from "components/navigation/endpoints";
import { miniListItemButton } from "styles/drawer";
import { miniDrawerIconStyle } from "styles/drawer";
import { drawerListItemStyle } from "styles/drawer";
import { drawerHighlightStyle } from "styles/drawer";
import { drawerHighlightIconStyle } from "styles/drawer";
import { miniDrawerWidth } from "styles/drawer";

export default function MiniDrawer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAppSelector((state) => state.authentication);

    const buildPath = (): string => {
        const paths = location.pathname.split("/");
        let current_path = "";
        for(let i = 0; i < paths.length; i++){
            if(i === 0){
                continue;
            }
            if(i === 1){
                current_path = paths[i];
                continue;
            }
            current_path += `/${paths[i]}`;
        }
        return current_path;
    }

    const getListItemStyle = (url: string) => { 
        const current_path = buildPath();
        if(current_path === url){
            return drawerHighlightStyle;
        }
        return null;
    }

    const getListItemIconStyle = (url: string) => {
        const current_path = buildPath();
        if(current_path === url){
            return drawerHighlightIconStyle;
        }
        return null;
    }

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: miniDrawerWidth,
                flexShrink: 0,
                ["& .MuiDrawer-paper"]: { width: miniDrawerWidth, boxSizing: "border-box" },
            }}>
            <Toolbar />
        {
            user.id ?
            <Box sx={{ overflow: "auto" }}>
                <List>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle("")]}
                        onClick={(e) => navigate(home_url, {replace: false})}
                        key="Dashboard">
                        <ListItemButton sx={miniListItemButton}>
                            <DashboardIcon sx={[miniDrawerIconStyle, getListItemIconStyle("")]} />
                        </ListItemButton>
                    </ListItem>  
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(material_url)]}
                        onClick={(e) => navigate(material_url, {replace: false})} 
                        key="Materials">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Materials" placement="right">
                                <TextureIcon sx={[miniDrawerIconStyle, getListItemIconStyle(material_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(material_import_url)]}
                        onClick={(e) => navigate(material_import_url, {replace: false})} 
                        key="ImportMaterials">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Import Materials" placement="right">
                                <CategoryIcon sx={[miniDrawerIconStyle, getListItemIconStyle(material_import_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem> 
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(price_list_url)]}
                        onClick={(e) => navigate(price_list_url, {replace: false})} 
                        key="PriceList">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Price Lists" placement="right">
                                <ViewListIcon sx={[miniDrawerIconStyle, getListItemIconStyle(price_list_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(create_account_url)]}
                        onClick={(e) => navigate(create_account_url, {replace: false})} 
                        key="CreateAccount">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Create Account" placement="right">
                                <PersonAddIcon sx={[miniDrawerIconStyle, getListItemIconStyle(create_account_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(account_url)]}
                        onClick={(e) => navigate(account_url, {replace: false})} 
                        key="ManageAccounts">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Manage Accounts" placement="right">
                                <GroupIcon sx={[miniDrawerIconStyle, getListItemIconStyle(account_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(manage_suppliers_url)]}
                        onClick={(e) => navigate(manage_suppliers_url, {replace: false})} 
                        key="ManageSuppliers">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Manage Suppliers" placement="right">
                                <ManageAccountsIcon sx={[miniDrawerIconStyle, getListItemIconStyle(manage_suppliers_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(region_url)]}
                        onClick={(e) => navigate(region_url, {replace: false})} 
                        key="Regions">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="Manage Regions" placement="right">
                                <LanguageIcon sx={[miniDrawerIconStyle, getListItemIconStyle(region_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(feedback_url)]}
                        onClick={(e) => navigate(feedback_url, {replace: false})} 
                        key="Feedback">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="View Feedback" placement="right">
                                <CommentIcon sx={[miniDrawerIconStyle, getListItemIconStyle(feedback_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                    <ListItem 
                        sx={[drawerListItemStyle, getListItemStyle(bug_url)]}
                        onClick={(e) => navigate(bug_url, {replace: false})} 
                        key="Bug">
                        <ListItemButton sx={miniListItemButton}>
                            <Tooltip title="View Bugs" placement="right">
                                <BugReportIcon sx={[miniDrawerIconStyle, getListItemIconStyle(bug_url)]} />
                            </Tooltip>
                        </ListItemButton>
                    </ListItem>
                </List>
                <ListItem 
                    sx={{ position: "fixed", bottom: 0, left: 0, width: miniDrawerWidth - 2 }}
                    key="Logout">
                    <MiniLogout />
                </ListItem>
            </Box>
            : null
        }   
        </Drawer>
    );
}
