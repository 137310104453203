import { InsideRadiusCorner } from "api/types/price";
import { InsideRadiusCornerRange } from "api/types/price";

export enum ActionType {
    GET_INSIDE_RADIUS_CORNERS_PENDING="GET_INSIDE_RADIUS_CORNERS_PENDING",
    GET_INSIDE_RADIUS_CORNERS_SUCCESS="GET_INSIDE_RADIUS_CORNERS_SUCCESS",
    GET_INSIDE_RADIUS_CORNERS_FAIL="GET_INSIDE_RADIUS_CORNERS_FAIL",
    CREATE_INSIDE_RADIUS_CORNER_PENDING = "CREATE_INSIDE_RADIUS_CORNER_PENDING",
    CREATE_INSIDE_RADIUS_CORNER_SUCCESS = "CREATE_INSIDE_RADIUS_CORNER_SUCCESS",
    CREATE_INSIDE_RADIUS_CORNER_FAIL = "CREATE_INSIDE_RADIUS_CORNER_FAIL",
    UPDATE_INSIDE_RADIUS_CORNER_PENDING = "UPDATE_INSIDE_RADIUS_CORNER_PENDING",
    UPDATE_INSIDE_RADIUS_CORNER_SUCCESS = "UPDATE_INSIDE_RADIUS_CORNER_SUCCESS",
    UPDATE_INSIDE_RADIUS_CORNER_FAIL = "UPDATE_INSIDE_RADIUS_CORNER_FAIL",
    UPDATE_LOCAL_INSIDE_RADIUS_CORNER = "UPDATE_LOCAL_INSIDE_RADIUS_CORNER",
    CREATE_INSIDE_RADIUS_CORNER_RANGE_PENDING = "CREATE_INSIDE_RADIUS_CORNER_RANGE_PENDING",
    CREATE_INSIDE_RADIUS_CORNER_RANGE_FAIL = "CREATE_INSIDE_RADIUS_CORNER_RANGE_FAIL",
    CREATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS = "CREATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS",
    UPDATE_INSIDE_RADIUS_CORNER_RANGE_PENDING = "UPDATE_INSIDE_RADIUS_CORNER_RANGE_PENDING",
    UPDATE_INSIDE_RADIUS_CORNER_RANGE_FAIL = "UPDATE_INSIDE_RADIUS_CORNER_RANGE_FAIL",
    UPDATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS = "UPDATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS",
    DELETE_INSIDE_RADIUS_CORNER_RANGE_PENDING = "DELETE_INSIDE_RADIUS_CORNER_RANGE_PENDING",
    DELETE_INSIDE_RADIUS_CORNER_RANGE_FAIL = "DELETE_INSIDE_RADIUS_CORNER_RANGE_FAIL",
    DELETE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS = "DELETE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS",
    UPDATE_LOCAL_INSIDE_RADIUS_CORNER_RANGE = "UPDATE_LOCAL_INSIDE_RADIUS_CORNER_RANGE"
}

interface getInsideRadiusCornersFail {
    type: ActionType.GET_INSIDE_RADIUS_CORNERS_FAIL,
    payload: string
}

interface getInsideRadiusCornersSuccess {
    type: ActionType.GET_INSIDE_RADIUS_CORNERS_SUCCESS,
    payload: InsideRadiusCorner[]
}

interface getInsideRadiusCornersPending {
    type: ActionType.GET_INSIDE_RADIUS_CORNERS_PENDING
}
  
interface createInsideRadiusCornerPending {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_PENDING,
}
  
interface createInsideRadiusCornerSuccess {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_SUCCESS,
    payload: InsideRadiusCorner
}
  
interface createInsideRadiusCornerFail {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_FAIL,
    payload: string
}
  
interface updateInsideRadiusCornerPending {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_PENDING,
}
  
interface updateInsideRadiusCornerSuccess {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_SUCCESS,
    payload: InsideRadiusCorner
}
  
interface updateInsideRadiusCornerFail {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_FAIL,
    payload: string
}

interface updateLocalInsideRadiusCorner {
    type: ActionType.UPDATE_LOCAL_INSIDE_RADIUS_CORNER,
    payload: InsideRadiusCorner
}

interface createInsideRadiusCornerRangeFail {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface createInsideRadiusCornerRangePending {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_PENDING
}

interface createInsideRadiusCornerRangeSuccess {
    type: ActionType.CREATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: InsideRadiusCornerRange
}

interface updateInsideRadiusCornerRangeFail {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface updateInsideRadiusCornerRangePending {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_RANGE_PENDING
}

interface updateInsideRadiusCornerRangeSuccess {
    type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: InsideRadiusCornerRange
}

interface deleteInsideRadiusCornerRangeFail {
    type: ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_FAIL,
    payload: string
}

interface deleteInsideRadiusCornerRangePending {
    type: ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_PENDING
}

interface deleteInsideRadiusCornerRangeSuccess {
    type: ActionType.DELETE_INSIDE_RADIUS_CORNER_RANGE_SUCCESS,
    payload: InsideRadiusCornerRange
}

interface updateLocalInsideRadiusCornerRange {
    type: ActionType.UPDATE_LOCAL_INSIDE_RADIUS_CORNER_RANGE,
    payload: InsideRadiusCornerRange
}
   

export type Action = 
    getInsideRadiusCornersFail |
    getInsideRadiusCornersPending |
    getInsideRadiusCornersSuccess |
    createInsideRadiusCornerFail |
    createInsideRadiusCornerPending |
    createInsideRadiusCornerSuccess |
    updateInsideRadiusCornerFail |
    updateInsideRadiusCornerPending |
    updateInsideRadiusCornerSuccess |
    updateLocalInsideRadiusCorner |
    createInsideRadiusCornerRangeFail |
    createInsideRadiusCornerRangePending |
    createInsideRadiusCornerRangeSuccess |
    updateInsideRadiusCornerRangeFail |
    updateInsideRadiusCornerRangePending |
    updateInsideRadiusCornerRangeSuccess |
    deleteInsideRadiusCornerRangeFail |
    deleteInsideRadiusCornerRangePending |
    deleteInsideRadiusCornerRangeSuccess |
    updateLocalInsideRadiusCornerRange;