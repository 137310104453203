import { SinkCutout } from "api/types/price";
import { SinkCutoutRange } from "api/types/price";

export enum ActionType {
    GET_SINK_CUTOUTS_PENDING="GET_SINK_CUTOUTS_PENDING",
    GET_SINK_CUTOUTS_SUCCESS="GET_SINK_CUTOUTS_SUCCESS",
    GET_SINK_CUTOUTS_FAIL="GET_SINK_CUTOUTS_FAIL",
    CREATE_SINK_CUTOUT_SUCCESS = "CREATE_SINK_CUTOUT_SUCCESS",
    CREATE_SINK_CUTOUT_FAIL = "CREATE_SINK_CUTOUT_FAIL",
    CREATE_SINK_CUTOUT_PENDING = "CREATE_SINK_CUTOUT_PENDING",
    UPDATE_SINK_CUTOUT_PENDING = "UPDATE_SINK_CUTOUT_PENDING",
    UPDATE_SINK_CUTOUT_SUCCESS = "UPDATE_SINK_CUTOUT_SUCCESS",
    UPDATE_SINK_CUTOUT_FAIL = "UPDATE_SINK_CUTOUT_FAIL",
    CREATE_SINK_CUTOUT_RANGE_PENDING = "CREATE_SINK_CUTOUT_RANGE_PENDING",
    CREATE_SINK_CUTOUT_RANGE_SUCCESS = "CREATE_SINK_CUTOUT_RANGE_SUCCESS",
    CREATE_SINK_CUTOUT_RANGE_FAIL = "CREATE_SINK_CUTOUT_RANGE_FAIL",
    UPDATE_SINK_CUTOUT_RANGE_PENDING = "UPDATE_SINK_CUTOUT_RANGE_PENDING",
    UPDATE_SINK_CUTOUT_RANGE_SUCCESS = "UPDATE_SINK_CUTOUT_RANGE_SUCCESS",
    UPDATE_SINK_CUTOUT_RANGE_FAIL = "UPDATE_SINK_CUTOUT_RANGE_FAIL",
    DELETE_SINK_CUTOUT_RANGE_PENDING = "DELETE_SINK_CUTOUT_RANGE_PENDING",
    DELETE_SINK_CUTOUT_RANGE_SUCCESS = "DELETE_SINK_CUTOUT_RANGE_SUCCESS",
    DELETE_SINK_CUTOUT_RANGE_FAIL = "DELETE_SINK_CUTOUT_RANGE_FAIL",
    UPDATE_LOCAL_SINK_CUTOUT = "UPDATE_LOCAL_SINK_CUTOUT",
    UPDATE_LOCAL_SINK_CUTOUT_RANGE = "UPDATE_LOCAL_SINK_CUTOUT_RANGE"
}

interface getSinkCutoutsFail {
    type: ActionType.GET_SINK_CUTOUTS_FAIL,
    payload: string
}

interface getSinkCutoutsSuccess {
    type: ActionType.GET_SINK_CUTOUTS_SUCCESS,
    payload: SinkCutout[]
}

interface getSinkCutoutsPending {
    type: ActionType.GET_SINK_CUTOUTS_PENDING
}

interface createSinkCutoutSuccess {
    type: ActionType.CREATE_SINK_CUTOUT_SUCCESS,
    payload: SinkCutout
}

interface createSinkCutoutFail {
    type: ActionType.CREATE_SINK_CUTOUT_FAIL,
    payload: string
}

interface createSinkCutoutPending {
    type: ActionType.CREATE_SINK_CUTOUT_PENDING
}

interface updateSinkCutoutPending {
    type: ActionType.UPDATE_SINK_CUTOUT_PENDING
}

interface updateSinkCutoutSuccess {
    type: ActionType.UPDATE_SINK_CUTOUT_SUCCESS,
    payload: SinkCutout
}

interface updateSinkCutoutFail {
    type: ActionType.UPDATE_SINK_CUTOUT_FAIL,
    payload: string
}

interface createSinkCutoutRangePending {
    type: ActionType.CREATE_SINK_CUTOUT_RANGE_PENDING
}

interface createSinkCutoutRangeSuccess {
    type: ActionType.CREATE_SINK_CUTOUT_RANGE_SUCCESS,
    payload: SinkCutoutRange
}

interface createSinkCutoutRangeFail {
    type: ActionType.CREATE_SINK_CUTOUT_RANGE_FAIL,
    payload: string
}

interface updateSinkCutoutRangePending {
    type: ActionType.UPDATE_SINK_CUTOUT_RANGE_PENDING
}

interface updateSinkCutoutRangeSuccess {
    type: ActionType.UPDATE_SINK_CUTOUT_RANGE_SUCCESS,
    payload: SinkCutoutRange
}

interface updateSinkCutoutRangeFail {
    type: ActionType.UPDATE_SINK_CUTOUT_RANGE_FAIL,
    payload: string
}

interface deleteSinkCutoutRangePending {
    type: ActionType.DELETE_SINK_CUTOUT_RANGE_PENDING
}

interface deleteSinkCutoutRangeSuccess {
    type: ActionType.DELETE_SINK_CUTOUT_RANGE_SUCCESS,
    payload: SinkCutoutRange
}

interface deleteSinkCutoutRangeFail {
    type: ActionType.DELETE_SINK_CUTOUT_RANGE_FAIL,
    payload: string
}

interface updateLocalSinkCutout {
    type: ActionType.UPDATE_LOCAL_SINK_CUTOUT,
    payload: SinkCutout
}

interface updateLocalSinkCutoutRange {
    type: ActionType.UPDATE_LOCAL_SINK_CUTOUT_RANGE,
    payload: SinkCutoutRange
}

export type Action =
    getSinkCutoutsFail |
    getSinkCutoutsPending |
    getSinkCutoutsSuccess |
    createSinkCutoutPending |
    createSinkCutoutFail |
    createSinkCutoutSuccess |
    updateSinkCutoutFail |
    updateSinkCutoutPending |
    updateSinkCutoutSuccess |
    createSinkCutoutRangePending |
    createSinkCutoutRangeSuccess |
    createSinkCutoutRangeFail |
    updateSinkCutoutRangeFail |
    updateSinkCutoutRangePending |
    updateSinkCutoutRangeSuccess |
    deleteSinkCutoutRangePending |
    deleteSinkCutoutRangeSuccess |
    deleteSinkCutoutRangeFail |
    updateLocalSinkCutout |
    updateLocalSinkCutoutRange;