import { useAppSelector } from "api/hooks/apiHook";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerMaterialItem from "components/material/row/drawer_material_item";

interface Props {
  current_index: number,
  type: string,
  name: string,
  setNavigation: (navigation: number) => void
}

export default function DrawerMaterialList({ name, type, current_index, setNavigation }: Props) {
  const { materials } = useAppSelector((state) => state.material);
  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body1" sx={{ fontWeight: '600' }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {
          materials.map((material, index) => {
            if (material.type === type) {
              return (
                <DrawerMaterialItem
                  key={index}
                  index={index}
                  current_index={current_index}
                  material={material}
                  setNavigation={setNavigation} />
              )
            }
            return null;
          })
        }
      </AccordionDetails>
    </Accordion>
  )
}
