import { Fragment } from "react";
import { useState } from "react";
import { createSinkCutout } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { SinkCutout } from "api/types/price";
import { SinkCutoutRange } from "api/types/price";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ApplicationScope from "components/price/dialog/shared_components/application_scope";
import Options from "components/price/dialog/shared_components/options";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getSinkTypes } from "values/values";
import { empty_sink_cutout } from "values/empty/price";
import { empty_sink_cutout_range } from "values/empty/price";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { UNDERMOUNT } from "values/values";
import { DROP_IN } from "values/values";
import { FARMER } from "values/values";

export default function CreateSinkCutoutType() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [sink_cutout, setSinkCutout] = useState<SinkCutout>(empty_sink_cutout);
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const [ranges, setRanges] = useState<SinkCutoutRange[]>([{...empty_sink_cutout_range, any_range: true}]);
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setSinkCutout({...sink_cutout, [key]: value});
        setError("");
        setHasError(false);
    }

    const saveBoolean = (key: string, value: boolean) => {
        setSinkCutout({...sink_cutout, [key]: value});
        setError("");
        setHasError(false);
    }

    const handleRangePrice = (index: number, price: string) => {
        if(isNaN(Number(price)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ?
                {...range, price: Number(price)} :
                range
            )]);
        }
    }

    const handleRangeMeasurement = (index: number, measurement: string) => {
        if(isNaN(Number(measurement)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ? 
                {...range, measurement: Number(measurement)} :
                range
            )]);
        }
    }

    const addRange = () => {
        setRanges([{...empty_sink_cutout_range, measurement: 0}, ...ranges]);
    }

    const create = () => {
        if(!price_list.id){
            setError("An unknown error has occurred. Please contact the administrator");
            return;
        }

        dispatch(createSinkCutout({...sink_cutout, price_list: price_list.id, ranges: ranges}));
        close();
    }

    const close = () => {
        setOpen(false);
        setSinkCutout(empty_sink_cutout);
        setError("");
        setHasError(false);
        setRanges([{...empty_sink_cutout_range, any_range: true}]);
    }

    return(
        <Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog 
            fullWidth={true}
            maxWidth="md"
            open={open}>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Sink Cutout</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl fullWidth sx={{paddingTop: 1}}>
                    <ApplicationScope saveString={saveString} />
                    <FormHelperText error id="error">{error}</FormHelperText>
                    <Options 
                        saveBoolean={saveBoolean} />
                    <TextField
                        sx={{marginTop: 2, marginBottom: 2}}
                        size="small"
                        variant="outlined"
                        select
                        defaultValue={UNDERMOUNT}
                        id="sink_type"
                        label="Sink Type"
                        onChange={(e) => saveString("sink_type", e.target.value)}>
                            <MenuItem key={UNDERMOUNT} value={UNDERMOUNT}>
                                {getSinkTypes(UNDERMOUNT)}
                            </MenuItem>
                            <MenuItem key={FARMER} value={FARMER}>
                                {getSinkTypes(FARMER)}
                            </MenuItem>
                            <MenuItem key={DROP_IN} value={DROP_IN}>
                                {getSinkTypes(DROP_IN)}
                            </MenuItem>
                    </TextField>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{color: primary_color, marginLeft: 1}}>
                                Ranges
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <IconButton size="small" color="primary" onClick={addRange}>
                                <AddCircleIcon sx={{height: 20, width: 20}} />
                            </IconButton>
                        </Grid>
                        {
                            ranges.map((range, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                sx={[textFieldStyle]}
                                                disabled={index === ranges.length - 1 ? true : false}
                                                size="small"
                                                type="number"
                                                error={has_error}
                                                id={`measurement_${index}`}
                                                label={range.any_range ? "Up to max" : 'Under "'}
                                                onChange={(e) => handleRangeMeasurement(index, e.target.value)} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                sx={[textFieldStyle]}
                                                fullWidth
                                                type="number"
                                                size="small"
                                                error={has_error}
                                                id={`price_${index}`}
                                                label="Price"
                                                onChange={(e) => handleRangePrice(index, e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Fragment>
                                )
                            })
                        }
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
        </Fragment>
    )
}
