import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { deleteCooktopCutoutRange } from "api/actions/price";
import { CooktopCutoutRange } from "api/types/price";
import RangeEditableItem from "./shared/range_item";

interface Props {
    index: number,
    last: boolean,
    item: CooktopCutoutRange,
    start_update: (update_item: CooktopCutoutRange) => void,
    setLocked: (locked: boolean) => void
}

export default function CooktopCutoutRangeEditableItem({last, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [cooktop_cutout_range_update, setCooktopCutoutRangeUpdate] = useState<CooktopCutoutRange>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_cooktop_cutout_range: CooktopCutoutRange = {...cooktop_cutout_range_update, [key]: Number(value)};
            setCooktopCutoutRangeUpdate(new_cooktop_cutout_range);
            start_update(new_cooktop_cutout_range);
        }
    }

    const handleDelete = () => {
        if(item.id){
            dispatch(deleteCooktopCutoutRange(item.id));
        }
    }

    return(
        <RangeEditableItem 
            price={item.price ? item.price : 0}
            rate="$"
            measurement={item.measurement ? item.measurement : 0}
            measurement_key="measurement"
            any_range={item.any_range ? item.any_range : false}
            saveNumber={saveNumber}
            deleteRange={handleDelete}
            setLocked={setLocked} />
    )
}
