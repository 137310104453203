import BasicPlainItem from "./shared/basic";
import { FaucetCutout } from "api/types/price";

interface Props {
    index: number,
    item: FaucetCutout
}

export default function FaucetCutoutPlainItem({index, item}: Props) {
    return(
        <BasicPlainItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false} />
    )
}
