import React from "react";
import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { createBugLog } from "api/actions/feedback";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
    bug_id: number
}

export default function CreateBugLogComponent({bug_id}: Props) {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [text, setText] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    const handleText = (text: string) => {
        setText(text);
    }

    const handleCreate = () => {
        if(text === ""){
            setError(true);
            return;
        }

        dispatch(createBugLog({text: text, bug: bug_id}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setText("");
        setError(false);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)} sx={{width: 20, height: 20}}>
                <AddCircleIcon 
                    sx={{backgroundColor: primary_color, color: white_color, width: 20, height: 20}} />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color, width: 500 }}>Create Log Entry</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth>
                        <FormHelperText error id="error">{error ? "Entry must not be blank" : ""}</FormHelperText>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="medium"
                            variant="outlined"
                            id="entry"
                            label="Entry"
                            multiline
                            minRows={3}
                            error={error}
                            onChange={(e) => handleText(e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
