import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { logout } from "api/actions/authentication";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { miniDrawerIconStyle } from "styles/drawer";
import { miniListItemButton } from "styles/drawer";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { JSONIsEmpty } from "functions/value";
import { login_url } from "components/navigation/endpoints";

export default function MiniLogout() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { error } = useAppSelector((state) => state.authentication);
    const { user } = useAppSelector((state) => state.authentication);
    const [open, setOpen] = useState(false);
    const [pendingLogout, setPendingLogout] = useState(false);

    if(pendingLogout && JSONIsEmpty(user)){
        setOpen(false);
        setPendingLogout(false);
    }

    if(JSONIsEmpty(user)){
        return null;
    }

    const close = () => {
        setOpen(false);
        setPendingLogout(false);
    }

    const logoutUser = () => {
        setPendingLogout(true);
        dispatch(logout());
        navigate(login_url, {replace: true});
    }

    return (
        <React.Fragment>
            <ListItemButton onClick={e => setOpen(true)} sx={miniListItemButton}>
                <LogoutIcon sx={miniDrawerIconStyle} color="primary" />
            </ListItemButton>
            <Dialog 
                open={open} 
                fullWidth={true}
                maxWidth="sm">
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>
                    Logout
                </DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    {
                        error === null || "" ? 
                        null :
                        <Typography
                            variant="body2">
                                {error}
                        </Typography>
                    }
                    
                    <Typography
                        variant="body1">
                        Are you sure you want to logout?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={close}>
                        Cancel
                    </Button>
                    <Button
                        onClick={logoutUser}>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
