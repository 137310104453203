import { useState } from "react";
import { createRadiusCornerRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { RadiusCornerRange } from "api/types/price";
import { RadiusCorner } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
    radius_corner: RadiusCorner
}

export default function CreateRadiusCornerRange({open, setOpen, radius_corner}: Props) {
    const dispatch = useAppDispatch();
    const [range, setRange] = useState<RadiusCornerRange>({});
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);

    const handlePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setRange({...range, price: Number(price)});
            setError("");
            setHasError(false);
        }
    }

    const handleMeasurement = (radius: string) => {
        if(isNaN(Number(radius)) === false){
            setRange({...range, radius: Number(radius)});
            setError("");
            setHasError(false);
        }
    }

    const handleCreate = () => {
        if(!range.price){
            setError("Price must be greater than 0");
            setHasError(true);
            return;
        }

        if(!range.radius){
            setError("Radius must be greater than 0");
            setHasError(true);
            return;
        }

        if(radius_corner.id){
            const create_range = {...range, radius_corner: radius_corner.id, any_range: false};
            dispatch(createRadiusCornerRange(create_range));
            handleClose();
        }   
    }

    const handleClose = () => {
        setOpen(false);
        setRange({});
    }

    return(
        <CreateBasicRange 
            title="Create Radius Corner Range"
            open={open}
            measurement_type="Radius"
            error={error}
            has_error={has_error}
            close={() => setOpen(false)}
            create={handleCreate}
            handlePrice={handlePrice}
            handleMeasurement={handleMeasurement}/>
    )
}
