import { useState } from "react";
import SplashEditableItem from "./editable/splash";
import SplashPlainItem from "./plain/splash";
import Grid from "@mui/material/Grid";
import { Splash } from "api/types/price";

interface Props {
  index: number,
  item: Splash,
  start_update: (update_item: Splash) => void,
  hovered: number,
  setHovered: (index: number) => void
}

export default function SplashItem({ index, item, start_update, hovered, setHovered }: Props) {
  const [locked, setLocked] = useState<boolean>(false);

  return (
    <Grid container item onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)}>
      {
        index === hovered || locked ?
          <SplashEditableItem
            index={index}
            setLocked={setLocked}
            start_update={start_update}
            item={item} /> :
          <SplashPlainItem
            index={index}
            item={item} />
      }
    </Grid>
  )
}
