import React from "react";
import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { createStoneCountertopRemoval } from "api/actions/price";
import { createLaminateCountertopRemoval } from "api/actions/price";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { textFieldStyle } from "styles/style";

export default function CreateCountertopRemoval() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const { price_list } = useAppSelector((state) => state.price);
    const [stone_countertop_price, setStoneCountertopPrice] = useState<number>(0);
    const [laminate_countertop_price, setLaminateCountertopPrice] = useState<number>(0);

    const handleCreate = () => {
        let stone_price = stone_countertop_price;
        let laminate_price = laminate_countertop_price;

        if(!stone_price){
            stone_price = 0;
        }

        if(!laminate_price){
            laminate_price = 0;
        }

        if(price_list.id){
            dispatch(createStoneCountertopRemoval({price_list: price_list.id, price: stone_price}));
            dispatch(createLaminateCountertopRemoval({price_list: price_list.id, price: laminate_price}));
        }        

        handleClose();
    }

    const handleStoneCountertopPrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setStoneCountertopPrice(Number(price));
        }
    }

    const handleLaminateCountertopPrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setLaminateCountertopPrice(Number(price));
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog 
                fullWidth={true}
                maxWidth="md"
                open={open} 
                onClose={handleClose}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Countertop Removal Price</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl sx={{marginTop: 2, marginBottom: 2, minWidth: 200}}>
                        <TextField
                            fullWidth
                            sx={[textFieldStyle, {marginBottom: 2}]}
                            size="small"
                            variant="outlined"
                            id="stone_countertop_removal"
                            label="Stone Countertop Removal Price"
                            onChange={(e) => handleStoneCountertopPrice(e.target.value)} />
                        <TextField
                            fullWidth
                            sx={textFieldStyle}
                            size="small"
                            variant="outlined"
                            id="laminate_counterotp_removal"
                            label="Laminate Countertop Removal Price"
                            onChange={(e) => handleLaminateCountertopPrice(e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
