import Name from "../shared/name";
import Checkboxes from "./shared/checkboxes";
import List from "components/universal/list";
import Grid from "@mui/material/Grid";
import SinkCutoutRangeItem from "./sink_cutout_range";
import { SinkCutout } from "api/types/price";
import { getRowSecondStyle } from 'values/style';
import { getSinkTypes } from "values/values";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
    index: number,
    item: SinkCutout
}

export default function SinkCutoutPlainItem({index, item}: Props) {

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <Name 
                bold={false}
                name={`${getMaterialHeightDisplay(item.material_thickness)} ${getSinkTypes(item.sink_type)}`} 
                width={8} />
            <Checkboxes
                grid_width={4} 
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false} />
            <List 
                items={item.ranges ? item.ranges : []}
                Item={SinkCutoutRangeItem} />
        </Grid>
    )
}
