import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkboxes from "./editable/shared/checkboxes";
import { StoneCountertopRemoval } from "api/types/price";
import { getRowSecondStyle } from 'values/style';

interface Props {
    index: number,
    item: StoneCountertopRemoval,
    start_update: (update_item: StoneCountertopRemoval) => void
}

export default function StoneCountertopRemovalItem({index, item, start_update}: Props) {
    const [stone_countertop_removal, setStoneCountertopRemoval] = useState<StoneCountertopRemoval>(item);

    const handlePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            const new_stone_removal: StoneCountertopRemoval = {...stone_countertop_removal, price: Number(price)};
            setStoneCountertopRemoval(new_stone_removal);
            start_update(new_stone_removal);
        }
    }

    const handleEnabled = (key: string, enabled: boolean) => {
        const new_stone_removal: StoneCountertopRemoval = {...stone_countertop_removal, enabled: enabled};
        setStoneCountertopRemoval(new_stone_removal);
        start_update(new_stone_removal);
    }

    const handleTaxable = (key: string, taxable: boolean) => {
        const new_stone_removal: StoneCountertopRemoval = {...stone_countertop_removal, taxable: taxable};
        setStoneCountertopRemoval(new_stone_removal);
        start_update(new_stone_removal);
    }

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <Grid item xs={2}>
                <Typography variant="body2">Stone</Typography>
            </Grid>
            <Grid item xs={2}>
                <TextField 
                    onChange={(e) => handlePrice(e.target.value)}
                    size="small"
                    variant="standard"
                    type="number"
                    label="Price ($)"
                    defaultValue={item.price}>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    $/SQ FT
                </Typography>
            </Grid>
            <Checkboxes 
                grid_width={4}
                enabled={item.enabled ? item.enabled : false}
                setEnabled={handleEnabled}
                taxable={item.taxable ? item.taxable : false}
                setTaxable={handleTaxable} />
        </Grid>
    )
}
