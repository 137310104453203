import { useState } from "react";
import { createMiterRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { MiterRange } from "api/types/price";
import { Miter } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
    miter: Miter
}

export default function CreateMiterRange({miter}: Props) {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const [range, setRange] = useState<MiterRange>({});

    const handlePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setRange({...range, price: Number(price)});
        }
    }

    const handleHeight = (height: string) => {
        if(isNaN(Number(height)) === false){
            setRange({...range, height: Number(height)});
        }
    }

    const handleCreate = () => {
        let error = false;

        if(error){
            return;
        }

        if(miter.id){
            const create_range = {...range, miter: miter.id, any_range: false};
            dispatch(createMiterRange(create_range));
            handleClose();
        }   
    }

    const handleClose = () => {
        setOpen(false);
        setRange({});
    }

    return(
        <CreateBasicRange 
            title="Create Miter Range"
            open={open}
            measurement_type="Height"
            error={error}
            has_error={has_error}
            close={() => setOpen(false)}
            create={handleCreate}
            handlePrice={handlePrice}
            handleMeasurement={handleHeight}/>
    )
}
