import { User } from "api/types/authentication";
import { Action } from "api/action_types/authentication";
import { ActionType } from "api/action_types/authentication";

const USER: string = "user";
const emptyUser: User = {};

interface State {
    user: User,
    suppliers: User[],
    auth_failed: boolean,
    login_success: boolean,
    password_change_success: boolean,
    registration_complete: boolean,
    created_customer: User | null,
    loading: boolean,
    error: string | null
}

const initialState = {
    user: getUser(),
    suppliers: [],
    auth_failed: false,
    login_success: false,
    registration_complete: false,
    password_change_success: false,
    created_customer: null,
    loading: false,
    error: null
}

export const authenticationReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.LOGIN_SUCCESS:
            localStorage.setItem(USER, JSON.stringify(action.payload));
            getUser();
            return {
                ...state,
                loading: false,
                auth_failed: false,
                login_success: true,
                user: getUser(),
                error: null
            }
        case ActionType.LOGIN_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                user: emptyUser
            }
        case ActionType.REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                registration_complete: true,
                created_customer: action.payload,
                error: null
            }
        case ActionType.RESET_REGISTRATION_COMPLETE:
            return {
                ...state,
                registration_complete: false
            }
        case ActionType.REGISTER_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ActionType.CLEAR_ERRORS:
            return {
                ...state,
                error: "",
                loading: false,
                auth_failed: false
            }
        case ActionType.LOGOUT_SUCCESS:
            localStorage.removeItem(USER);
            return {
                ...state,
                user: emptyUser
            }
        case ActionType.GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                suppliers: action.payload
            }
        case ActionType.GET_PROFILE_SUCCESS:
            localStorage.setItem(USER, JSON.stringify({...state.user, ...action.payload}));
            return {
                ...state,
                user: {...state.user, ...action.payload}
            }
        case ActionType.UPDATE_PROFILE_SUCCESS:
            const new_user: User = {...state.user, ...action.payload};
            localStorage.setItem(USER, JSON.stringify(new_user));
            return {
                ...state,
                user: new_user,
                loading: false,
                error: ""
            }
        case ActionType.UPDATE_LOCAL_USER:
            return {
                ...state,
                user: action.payload
            }
        case ActionType.CHANGE_PASSWORD:
            return {
                ...state,
                password_change_success: true,
                loading: false,
                error: ""
            }
        case ActionType.SET_PAYMENT_INITIALIZED:
            return {
                ...state,
                user: {...state.user, payment_initialized: true}
            }
        case ActionType.SET_REGISTRATION_COMPLETE:
            return {
                ...state,
                registration_complete: action.payload
            }
        case ActionType.STORE_TOKEN:
            localStorage.setItem(USER, JSON.stringify({...state.user, token: action.payload}));
            return {
                ...state,
                user: {...state.user, token: action.payload}
            }
        case ActionType.UPDATE_PROFILE_PENDING:
        case ActionType.GET_SUPPLIERS_PENDING:
        case ActionType.CHANGE_PASSWORD_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.UPDATE_PROFILE_FAIL:
        case ActionType.GET_SUPPLIERS_FAIL:
        case ActionType.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case ActionType.AUTH_FAILED:
            return {
                ...state,
                auth_failed: true,
                loading: false
            }
        default:
            return state;
    }
}

function getUser(): User {
    const storage = localStorage.getItem(USER);

    if(storage){
        const user: User | null = JSON.parse(localStorage.getItem(USER) || "");
        if(!user){
            return emptyUser;
        }

        return user;
    }
    return emptyUser;
}



