import { ComponentType } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { containedHeaderBackground } from "styles/universal/section_header";
import { containedHeaderText } from "styles/universal/section_header";
import { outlinedHeaderBackground } from "styles/universal/section_header";
import { outlinedHeaderText } from "styles/universal/section_header";
import { iconStyle } from "styles/universal/section_header";

interface Props {
    text: string,
    show_icon?: boolean,
    tooltip?: string,
    variant?: string,
    FirstIcon?: ComponentType<any>,
    first_icon_action?: () => void
}

export default function SectionHeader({text, tooltip, variant="contained", first_icon_action, FirstIcon, show_icon=false}: Props){
    return (
        <Stack direction="row"justifyContent="space-between" sx={variant==="contained" ? containedHeaderBackground : outlinedHeaderBackground}>
            <Typography variant="body1" sx={variant==="contained" ? containedHeaderText : outlinedHeaderText}>
                <b>{text}</b>
            </Typography>
            {
                show_icon ?
                <Tooltip title={tooltip}>
                    <IconButton 
                        onClick={first_icon_action}
                        color={variant==="contained" ? "default" : "primary"}
                        sx={iconStyle}>
                        <FirstIcon />
                    </IconButton>
                </Tooltip> :
                null
            }
        </Stack>
    )
}
