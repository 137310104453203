import { Fragment } from "react";
import { RadiusCornerRange } from "api/types/price";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ApplicationScope from "components/price/dialog/shared_components/application_scope";
import Options from "components/price/dialog/shared_components/options";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
    title: string,
    open: boolean,
    ranges: RadiusCornerRange[],
    error: string,
    has_error: boolean,
    close: () => void,
    create: () => void,
    saveString: (key: string, value: string) => void,
    saveBoolean: (key: string, value: boolean) => void,
    handleRangePrice(index: number, price: string): void,
    handleRangeMeasurement(index: number, measurement: string): void,
    addRange(): void
}

export default function CreateBasicRange({
    title,
    open,
    error,
    has_error,
    ranges,
    close,
    create,
    saveString,
    saveBoolean,
    handleRangePrice,
    handleRangeMeasurement,
    addRange
}: Props) {
    return(
        <Dialog 
            fullWidth={true}
            maxWidth="md"
            open={open}>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>{title}</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl fullWidth sx={{paddingTop: 1}}>
                    <ApplicationScope saveString={saveString} />
                    <FormHelperText error id="error">{error}</FormHelperText>
                    <Options 
                        saveBoolean={saveBoolean} />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="body2" sx={{color: primary_color, marginLeft: 1}}>
                                Ranges
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <IconButton size="small" color="primary" onClick={addRange}>
                                <AddCircleIcon sx={{height: 20, width: 20}} />
                            </IconButton>
                        </Grid>
                        {
                            ranges.map((range, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                sx={[textFieldStyle]}
                                                disabled={index === ranges.length - 1 ? true : false}
                                                size="small"
                                                type="number"
                                                error={has_error}
                                                id={`measurement_${index}`}
                                                label={range.any_range ? "Up to max" : 'Under "'}
                                                onChange={(e) => handleRangeMeasurement(index, e.target.value)} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                sx={[textFieldStyle]}
                                                fullWidth
                                                type="number"
                                                size="small"
                                                error={has_error}
                                                id={`price_${index}`}
                                                label="Price"
                                                onChange={(e) => handleRangePrice(index, e.target.value)} />
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                    </Fragment>
                                )
                            })
                        }
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={create}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
