import { Material } from 'api/types/material';
import { Color } from 'api/types/material';
import { Action } from 'api/action_types/material';
import { ActionType } from 'api/action_types/material';

interface State {
  materials: Material[],
  material: Material,
  colors: Color[],
  color: Color,
  loading: boolean,
  error: string | null
}

const initialState = {
  colors: [],
  color: {},
  materials: [],
  material: {},
  loading: false,
  error: null
}

export const materialReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.CLEAR_MATERIALS:
      return {
        ...state,
        materials: [],
        material: {}
      }
    case ActionType.GET_MATERIALS:
      return {
        ...state,
        materials: action.payload,
        loading: false,
        error: ''
      }
    case ActionType.GET_MATERIAL:
      return {
        ...state,
        material: action.payload,
        loading: false,
        error: ''
      }
    case ActionType.GET_COLORS:
      return {
        ...state,
        colors: action.payload,
        loading: false,
        error: ""
      }
    case ActionType.CREATE_MATERIAL:
      return {
        ...state,
        material: action.payload,
        materials: [...state.materials, action.payload],
        loading: false,
        error: ''
      }
    case ActionType.CREATE_COLOR:
      console.log(action.payload);
      return {
        ...state,
        colors: [...state.colors, ...action.payload],
        loading: false,
        error: ''
      }
    case ActionType.UPDATE_MATERIAL:
      return {
        ...state,
        material: action.payload,
        loading: false,
        error: ''
      }
    case ActionType.UPDATE_COLOR:
      return {
        ...state,
        loading: false,
        color: action.payload
      }
    case ActionType.DELETE_MATERIAL:
      return {
        ...state,
        loading: false
      }
    case ActionType.DELETE_COLOR:
      return {
        ...state,
        loading: false
      }
    case ActionType.UPDATE_LOCAL_COLOR:
      return {
        ...state,
        loading: true,
        colors: [...state.colors.map(color => action.payload.id === color.id ? action.payload : color)]
      }
    case ActionType.SET_MATERIAL:
      return {
        ...state,
        material: action.payload
      }
    case ActionType.MATERIAL_UPDATING:
      return {
        ...state,
        loading: true
      }
    case ActionType.MATERIAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  }
}



