import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createSplashRange } from "api/actions/price";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import EditableList from "components/universal/editable_list";
import SplashRangeItem from "./splash_range";
import BasicRangeEditableItem from "./shared/basic_range";
import AddIcon from "@mui/icons-material/Add";
import { CreateSplashRange } from "components/price/dialog/create";
import { Splash } from "api/types/price";
import { getRowSecondStyle } from "values/style";
import { empty_splash_range } from "values/empty/price";

interface Props {
  index: number,
  item: Splash,
  start_update: (update_item: Splash) => void,
  setLocked: (locked: boolean) => void
}

export default function SplashEditableItem({ index, item, start_update, setLocked }: Props) {
  const dispatch = useAppDispatch();
  const [splash_update, setSplashUpdate] = useState<Splash>(item);

  const saveBoolean = (key: string, value: boolean) => {
    const new_splash: Splash = { ...splash_update, [key]: value };
    setSplashUpdate(new_splash);
    start_update(new_splash);
  }

  return (
    <Grid container item sx={[{ paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }, getRowSecondStyle(index)]} alignItems="center">
      <BasicRangeEditableItem
        material_thickness={item.material_thickness ? item.material_thickness : ""}
        enabled={item.enabled ? item.enabled : false}
        taxable={item.enabled ? item.enabled : false}
        saveBoolean={saveBoolean} />
      <EditableList
        setLocked={setLocked}
        items={item.ranges ? item.ranges : []}
        Item={SplashRangeItem}
        start_update={start_update} />
      <Grid item xs={12}>
        <Button
          onClick={() => dispatch(createSplashRange({ ...empty_splash_range, height: 0, splash: item.id }))}
          size="small"
          endIcon={<AddIcon />}
          sx={{ paddingLeft: 0, paddingRight: 0 }}>
          Range
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CreateSplashRange
          splash={item} />
      </Grid>
    </Grid>
  )
}
