import { RecessedDiagonalCorner } from "api/types/price";

export enum ActionType {
    GET_RECESSED_DIAGONAL_CORNERS_PENDING="GET_RECESSED_DIAGONAL_CORNERS_PENDING",
    GET_RECESSED_DIAGONAL_CORNERS_SUCCESS="GET_RECESSED_DIAGONAL_CORNERS_SUCCESS",
    GET_RECESSED_DIAGONAL_CORNERS_FAIL="GET_RECESSED_DIAGONAL_CORNERS_FAIL",
    CREATE_RECESSED_DIAGONAL_CORNER_PENDING = "CREATE_RECESSED_DIAGONAL_CORNER_PENDING",
    CREATE_RECESSED_DIAGONAL_CORNER_SUCCESS = "CREATE_RECESSED_DIAGONAL_CORNER_SUCCESS",
    CREATE_RECESSED_DIAGONAL_CORNER_FAIL = "CREATE_RECESSED_DIAGONAL_CORNER_FAIL",
    UPDATE_RECESSED_DIAGONAL_CORNER_PENDING = "UPDATE_RECESSED_DIAGONAL_CORNER_PENDING",
    UPDATE_RECESSED_DIAGONAL_CORNER_SUCCESS = "UPDATE_RECESSED_DIAGONAL_CORNER_SUCCESS",
    UPDATE_RECESSED_DIAGONAL_CORNER_FAIL = "UPDATE_RECESSED_DIAGONAL_CORNER_FAIL",
    UPDATE_LOCAL_RECESSED_DIAGONAL_CORNER = "UPDATE_LOCAL_RECESSED_DIAGONAL_CORNER"
}

interface getRecessedDiagonalCornersFail {
    type: ActionType.GET_RECESSED_DIAGONAL_CORNERS_FAIL,
    payload: string
}

interface getRecessedDiagonalCornersSuccess {
    type: ActionType.GET_RECESSED_DIAGONAL_CORNERS_SUCCESS,
    payload: RecessedDiagonalCorner[]
}

interface getRecessedDiagonalCornersPending {
    type: ActionType.GET_RECESSED_DIAGONAL_CORNERS_PENDING
}
  
interface createRecessedDiagonalCornerPending {
    type: ActionType.CREATE_RECESSED_DIAGONAL_CORNER_PENDING,
}
  
interface createRecessedDiagonalCornerSuccess {
    type: ActionType.CREATE_RECESSED_DIAGONAL_CORNER_SUCCESS,
    payload: RecessedDiagonalCorner
}
  
interface createRecessedDiagonalCornerFail {
    type: ActionType.CREATE_RECESSED_DIAGONAL_CORNER_FAIL,
    payload: string
}
  
interface updateRecessedDiagonalCornerPending {
    type: ActionType.UPDATE_RECESSED_DIAGONAL_CORNER_PENDING,
}
  
interface updateRecessedDiagonalCornerSuccess {
    type: ActionType.UPDATE_RECESSED_DIAGONAL_CORNER_SUCCESS,
    payload: RecessedDiagonalCorner
}
  
interface updateRecessedDiagonalCornerFail {
    type: ActionType.UPDATE_RECESSED_DIAGONAL_CORNER_FAIL,
    payload: string
}

interface updateLocalRecessedDiagonalCorner {
    type: ActionType.UPDATE_LOCAL_RECESSED_DIAGONAL_CORNER,
    payload: RecessedDiagonalCorner
}

export type Action = 
    getRecessedDiagonalCornersFail |
    getRecessedDiagonalCornersPending |
    getRecessedDiagonalCornersSuccess |
    createRecessedDiagonalCornerFail |
    createRecessedDiagonalCornerPending |
    createRecessedDiagonalCornerSuccess |
    updateRecessedDiagonalCornerFail |
    updateRecessedDiagonalCornerPending |
    updateRecessedDiagonalCornerSuccess |
    updateLocalRecessedDiagonalCorner;