import { useState } from "react";
import { createInsideRadiusCornerRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { InsideRadiusCornerRange } from "api/types/price";
import { InsideRadiusCorner } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
    inside_radius_corner: InsideRadiusCorner
}

export default function CreateInsideRadiusCornerRange({open, setOpen, inside_radius_corner}: Props) {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const [range, setRange] = useState<InsideRadiusCornerRange>({});

    const handlePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setRange({...range, price: Number(price)});
        }
    }

    const handleMeasurement = (radius: string) => {
        if(isNaN(Number(radius)) === false){
            setRange({...range, radius: Number(radius)});
        }
    }

    const handleCreate = () => {
        if(!range.price){
            setError("Price must be greater than 0");
            setHasError(true);
            return;
        }

        if(!range.radius){
            setError("Radius must be greater than 0");
            setHasError(true);
            return;
        }

        if(inside_radius_corner.id){
            const create_range = {...range, inside_radius_corner: inside_radius_corner.id, any_range: false};
            dispatch(createInsideRadiusCornerRange(create_range));
            handleClose();
        }   
    }

    const handleClose = () => {
        setOpen(false);
        setRange({});
    }

    return(
        <CreateBasicRange 
            title="Create Inside Radius Corner Range"
            open={open}
            measurement_type="Radius"
            error={error}
            has_error={has_error}
            close={() => setOpen(false)}
            create={handleCreate}
            handlePrice={handlePrice}
            handleMeasurement={handleMeasurement}/>
    )
}
