import { ApplianceEdge } from "api/types/price";

export enum ActionType {
    GET_APPLIANCE_EDGES_PENDING="GET_APPLIANCE_EDGES_PENDING",
    GET_APPLIANCE_EDGES_SUCCESS="GET_APPLIANCE_EDGES_SUCCESS",
    GET_APPLIANCE_EDGES_FAIL="GET_APPLIANCE_EDGES_FAIL",
    CREATE_APPLIANCE_EDGE_PENDING = "CREATE_APPLIANCE_EDGE_PENDING",
    CREATE_APPLIANCE_EDGE_SUCCESS = "CREATE_APPLIANCE_EDGE_SUCCESS",
    CREATE_APPLIANCE_EDGE_FAIL = "CREATE_APPLIANCE_EDGE_FAIL",
    UPDATE_APPLIANCE_EDGE_PENDING = "UPDATE_APPLIANCE_EDGE_PENDING",
    UPDATE_APPLIANCE_EDGE_SUCCESS = "UPDATE_APPLIANCE_EDGE_SUCCESS",
    UPDATE_APPLIANCE_EDGE_FAIL = "UPDATE_APPLIANCE_EDGE_FAIL",
    UPDATE_LOCAL_APPLIANCE_EDGE = "UPDATE_LOCAL_APPLIANCE_EDGE"
}

interface getApplianceEdgesFail {
    type: ActionType.GET_APPLIANCE_EDGES_FAIL,
    payload: string
}

interface getApplianceEdgesSuccess {
    type: ActionType.GET_APPLIANCE_EDGES_SUCCESS,
    payload: ApplianceEdge[]
}

interface getApplianceEdgesPending {
    type: ActionType.GET_APPLIANCE_EDGES_PENDING
}

interface createApplianceEdgePending {
    type: ActionType.CREATE_APPLIANCE_EDGE_PENDING
}

interface createApplianceEdgeSuccess {
    type: ActionType.CREATE_APPLIANCE_EDGE_SUCCESS,
    payload: ApplianceEdge
}

interface createApplianceEdgeFail {
    type: ActionType.CREATE_APPLIANCE_EDGE_FAIL,
    payload: string
}

interface updateApplianceEdgePending {
    type: ActionType.UPDATE_APPLIANCE_EDGE_PENDING
}

interface updateApplianceEdgeSuccess {
    type: ActionType.UPDATE_APPLIANCE_EDGE_SUCCESS,
    payload: ApplianceEdge
}

interface updateApplianceEdgeFail {
    type: ActionType.UPDATE_APPLIANCE_EDGE_FAIL,
    payload: string
}

interface updateLocalApplianceEdge {
    type: ActionType.UPDATE_LOCAL_APPLIANCE_EDGE,
    payload: ApplianceEdge
}

export type Action = 
    getApplianceEdgesFail |
    getApplianceEdgesPending |
    getApplianceEdgesSuccess |
    createApplianceEdgeFail |
    createApplianceEdgePending |
    createApplianceEdgeSuccess |
    updateApplianceEdgeFail |
    updateApplianceEdgePending |
    updateApplianceEdgeSuccess |
    updateLocalApplianceEdge;