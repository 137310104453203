/// HOOKS ///
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { useState } from "react";
/// ACTIONS ///
import { updateMaterial } from "api/actions/material";
import { Material } from "api/types/material";
/// COMPONENTS ///
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
/// STYLES ///
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
/// COLORS ///
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM12 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { MM6 } from "values/material";
import { MM8 } from "values/material";
import { MM20 } from "values/material";
import { MM30 } from "values/material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

export default function EditMaterial({ open, setOpen }: Props) {
  const dispatch = useAppDispatch();
  const { material } = useAppSelector((state) => state.material);
  const [new_material, setNewMaterial] = useState<Material>({});

  const setValue = (key: string, value: string | boolean) => {
    setNewMaterial({ ...new_material, [key]: value });
  }

  const update = () => {
    if (material.id) {
      dispatch(updateMaterial(new_material, material.id));
    }
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
    setNewMaterial({});
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Material</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <FormControl fullWidth>
          <TextField
            sx={textFieldStyle}
            fullWidth
            size="small"
            variant="outlined"
            autoFocus
            id="name"
            label="Name"
            defaultValue={material.name}
            onChange={(e) => setValue("name", e.target.value)} />
          <TextField
            sx={textFieldStyle}
            fullWidth
            size="small"
            variant="outlined"
            select
            defaultValue={material.thickness}
            id="thickness"
            label="Thickness"
            onChange={(e) => setValue("thickness", e.target.value)}>
            <MenuItem key={CM2} value={CM2}>
              {getMaterialHeightDisplay(CM2)}
            </MenuItem>
            <MenuItem key={CM3} value={CM3}>
              {getMaterialHeightDisplay(CM3)}
            </MenuItem>
            <MenuItem key={CM12} value={CM12}>
              {getMaterialHeightDisplay(CM12)}
            </MenuItem>
            <MenuItem key={CM1} value={CM1}>
              {getMaterialHeightDisplay(CM1)}
            </MenuItem>
            <MenuItem key={CM60} value={CM60}>
              {getMaterialHeightDisplay(CM60)}
            </MenuItem>
            <MenuItem key={MM6} value={MM6}>
              {getMaterialHeightDisplay(MM6)}
            </MenuItem>
            <MenuItem key={MM8} value={MM8}>
              {getMaterialHeightDisplay(MM8)}
            </MenuItem>
            <MenuItem key={MM20} value={MM20}>
              {getMaterialHeightDisplay(MM20)}
            </MenuItem>
            <MenuItem key={MM30} value={MM30}>
              {getMaterialHeightDisplay(MM30)}
            </MenuItem>
          </TextField>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ marginLeft: 1 }}
                onChange={(e) => setValue("taxable", e.target.checked)}
                defaultChecked />
            }
            label="Taxable" />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={update}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}
