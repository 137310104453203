/// HOOKS ///
import { useState } from "react";
/// CUSTOM COMPONENTS ///
import MiterEditableItem from "./editable/miter";
import MiterPlainItem from "./plain/miter";
/// MUI COMPONENTS ///
import Grid from "@mui/material/Grid";
/// TYPES ///
import { Miter } from "api/types/price";

interface Props {
    index: number,
    item: Miter,
    start_update: (update_item: Miter) => void,
    hovered: number,
    setHovered: (index: number) => void
}

export default function MiterItem({index, item, start_update, hovered, setHovered}: Props) {
    const [locked, setLocked] = useState<boolean>(false);

    return(
        <Grid container item onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)}>
            {
                index === hovered || locked ?
                <MiterEditableItem
                    index={index}
                    setLocked={setLocked}
                    start_update={start_update}
                    item={item} /> :
                <MiterPlainItem
                    index={index}
                    item={item} />
            }
        </Grid>
    )
}
