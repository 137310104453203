import { Feedback } from 'api/types/feedback';
import { Bug } from 'api/types/feedback';
import { Action } from 'api/action_types/feedback';
import { ActionType } from 'api/action_types/feedback';

interface State {
    bugs: Bug[],
    bug: Bug | null,
    feedbacks: Feedback[],
    feedback: Feedback | null,
    feedback_loading: boolean,
    feedback_error: string,
    bug_loading: boolean,
    bug_error: string
}

const initialState = {
    bugs: [],
    bug: null,
    feedbacks: [],
    feedback: null,
    bug_loading: false,
    feedback_loading: false,
    bug_error: "",
    feedback_error: ""
}

export const feedbackReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.GET_FEEDBACKS_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload,
                feedback_loading: false,
                feedback_error: ''
            }
        case ActionType.GET_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedback: action.payload,
                feedback_loading: false,
                feedback_error: ''
            } 
        case ActionType.CREATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: [action.payload, ...state.feedbacks],
                feedback_loading: false,
                feedback_error: ''
            }
        case ActionType.UPDATE_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: [...state.feedbacks.map(feedback => feedback.id === action.payload.id ? 
                    action.payload :
                    feedback
                )],
                feedback_loading: false,
                feedback_error: ''
            }
        case ActionType.UPDATE_LOCAL_FEEDBACK:
            return {
                ...state,
                feedbacks: [...state.feedbacks.map(feedback => feedback.id === action.payload.id ?
                    {...feedback, resolved: action.payload.resolved }: 
                    feedback
                )]
            }
        case ActionType.GET_BUGS_SUCCESS:
            return {
                ...state,
                bugs: action.payload,
                bug_loading: false,
                bug_error: ""
            } 
        case ActionType.GET_BUG_SUCCESS:
            return {
                ...state,
                bug: action.payload,
                bug_loading: false,
                bug_error: ""
            }     
        case ActionType.CREATE_BUG_SUCCESS:
            return {
                ...state,
                bugs: [action.payload, ...state.bugs],
                bug_loading: false,
                bug_error: ""
            }
        case ActionType.UPDATE_BUG_SUCCESS:
            return {
                ...state,
                bugs: [...state.bugs.map(bug => bug.id === action.payload.id ? 
                    action.payload :
                    bug
                )],
                bug_loading: false,
                bug_error: ""
            }
        case ActionType.UPDATE_LOCAL_BUG:
            return {
                ...state,
                bugs: [...state.bugs.map(bug => bug.id === action.payload.id ?
                    {...bug, resolved: action.payload.resolved} :
                    bug
                )]
            }
        case ActionType.CREATE_BUG_LOG_SUCCESS:
            if(state.bug){
                return {
                    ...state,
                    bug: {...state.bug, bug_logs: [...state.bug.bug_logs, action.payload]}
                }
            }
            else{
                return {
                    ...state
                }
            }
        case ActionType.FEEDBACK_PENDING:
            return {
                ...state,
                feedback_loading: true
            }
        case ActionType.BUG_PENDING:
        case ActionType.BUG_LOG_PENDING:
            return {
                ...state,
                bug_loading: true
            }
        case ActionType.FEEDBACK_FAIL:
            return {
                ...state,
                feedback_loading: false,
                feedback_error: action.payload
            }
        case ActionType.BUG_FAIL:
        case ActionType.BUG_LOG_FAIL:
            return {
                ...state,
                bug_loading: false,
                bug_error: action.payload
            }  
        default:
            return state;
    }
}



