import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/price';
import { ActionType } from 'api/action_types/price';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { StoneCountertopRemoval } from "api/types/price";
import { LaminateCountertopRemoval } from 'api/types/price';
import { url_prefix } from 'settings';

const stone_countertop_removal_url: string = "stone_countertop_removal";
const laminate_countertop_removal_url: string = "laminate_countertop_removal";

export const getStoneCountertopRemovals = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${stone_countertop_removal_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_STONE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createStoneCountertopRemoval = (stone_countertop_removal: StoneCountertopRemoval) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${stone_countertop_removal_url}/`;

        try
        {
            const {data} = await axios.post(url, stone_countertop_removal, config);

            dispatch({
                type: ActionType.CREATE_STONE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateStoneCountertopRemoval = (stone_countertop_removal: StoneCountertopRemoval, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${stone_countertop_removal_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, stone_countertop_removal, config);

            dispatch({
                type: ActionType.UPDATE_STONE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }  
        }
    }
}

export const getLaminateCountertopRemovals = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${laminate_countertop_removal_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createLaminateCountertopRemoval = (laminate_countertop_removal: LaminateCountertopRemoval) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${laminate_countertop_removal_url}/`;

        try
        {
            const {data} = await axios.post(url, laminate_countertop_removal, config);

            dispatch({
                type: ActionType.CREATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const updateLaminateCountertopRemoval = (laminate_countertop_removal: LaminateCountertopRemoval, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.COUNTERTOP_REMOVAL_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${laminate_countertop_removal_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, laminate_countertop_removal, config);

            dispatch({
                type: ActionType.UPDATE_LAMINATE_COUNTERTOP_REMOVAL_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.COUNTERTOP_REMOVAL_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }  
        }
    }
}

export const updateLocalStoneCountertopRemoval = (stone_countertop_removal: StoneCountertopRemoval) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_STONE_COUNTERTOP_REMOVAL,
            payload: stone_countertop_removal
        });
    }
}

export const updateLocalLaminateCountertopRemoval = (laminate_countertop_removal: LaminateCountertopRemoval) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_LAMINATE_COUNTERTOP_REMOVAL,
            payload: laminate_countertop_removal
        });
    }
}