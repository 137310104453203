import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { UploadError } from 'api/types/material_import';
import { getRowSecondStyle } from 'values/style';
import { rowItemStyle } from 'styles/style';

interface Props {
    item: UploadError,
    index: number
}

export default function ErrorRow({item, index}: Props) {
    return(
        <Grid 
            container
            item
            sx={[getRowSecondStyle(index), rowItemStyle]}>
            <Grid item xs={1}>
                <Typography variant="body2">
                    {item.line}
                </Typography>
            </Grid>
            <Grid item xs={11}>
                <Typography variant="body2">
                    {item.message}
                </Typography>
            </Grid>
        </Grid>
    )
}
