/// REACT ///
import { Fragment } from "react";
/// TYPES ///
import { Color } from "api/types/material";
import { ColorError } from "api/types/price";
/// MUI ///
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

interface Props {
  index: number,
  color: Color,
  color_error: ColorError | null,
  updateColor: (color: Color, index: number) => void
}

export default function CreateColorItem({ index, color, color_error, updateColor }: Props) {
  return (
    <Fragment>
      <Grid item xs={6} sx={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <TextField
          error={color_error?.name_has_error ?? false}
          helperText={color_error?.name_error ?? ""}
          sx={{ width: "100%" }}
          value={color?.name ?? ""}
          onChange={e => updateColor({ ...color, name: e.target.value }, index)} />
      </Grid>
      <Grid item xs={2} sx={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <TextField
          error={color_error?.length_has_error ?? false}
          helperText={color_error?.length_error ?? ""}
          sx={{ width: "100%" }}
          value={color?.length ?? 0}
          onChange={e => updateColor({ ...color, length: Number(e.target.value) }, index)} />
      </Grid>
      <Grid item xs={2} sx={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <TextField
          fullWidth
          error={color_error?.width_has_error ?? false}
          helperText={color_error?.width_error ?? ""}
          value={color?.width ?? 0}
          onChange={e => updateColor({ ...color, width: Number(e.target.value) }, index)} />
      </Grid>
      <Grid item xs={2} sx={{ paddingLeft: "2px", paddingRight: "2px" }}>
        <TextField
          fullWidth
          error={color_error?.price_has_error ?? false}
          helperText={color_error?.price_error ?? ""}
          sx={{ width: "100%" }}
          value={color?.price ?? 0}
          onChange={e => updateColor({ ...color, price: Number(e.target.value) }, index)} />
      </Grid>
    </Fragment>
  )
}
