import { BumpIn } from "api/types/price";

export enum ActionType {
    GET_BUMP_INS_PENDING="GET_BUMP_INS_PENDING",
    GET_BUMP_INS_SUCCESS="GET_BUMP_INS_SUCCESS",
    GET_BUMP_INS_FAIL="GET_BUMP_INS_FAIL",
    CREATE_BUMP_IN_PENDING = "CREATE_BUMP_IN_PENDING",
    CREATE_BUMP_IN_SUCCESS = "CREATE_BUMP_IN_SUCCESS",
    CREATE_BUMP_IN_FAIL = "CREATE_BUMP_IN_FAIL",
    UPDATE_BUMP_IN_PENDING = "UPDATE_BUMP_IN_PENDING",
    UPDATE_BUMP_IN_SUCCESS = "UPDATE_BUMP_IN_SUCCESS",
    UPDATE_BUMP_IN_FAIL = "UPDATE_BUMP_IN_FAIL",
    UPDATE_LOCAL_BUMP_IN = "UPDATE_LOCAL_BUMP_IN"
}

interface getBumpInsFail {
    type: ActionType.GET_BUMP_INS_FAIL,
    payload: string
}

interface getBumpInsSuccess {
    type: ActionType.GET_BUMP_INS_SUCCESS,
    payload: BumpIn[]
}

interface getBumpInsPending {
    type: ActionType.GET_BUMP_INS_PENDING
}

interface createBumpInPending {
  type: ActionType.CREATE_BUMP_IN_PENDING,
}

interface createBumpInSuccess {
  type: ActionType.CREATE_BUMP_IN_SUCCESS,
  payload: BumpIn
}

interface createBumpInFail {
  type: ActionType.CREATE_BUMP_IN_FAIL,
  payload: string
}

interface updateBumpInPending {
  type: ActionType.UPDATE_BUMP_IN_PENDING,
}

interface updateBumpInSuccess {
  type: ActionType.UPDATE_BUMP_IN_SUCCESS,
  payload: BumpIn
}

interface updateBumpInFail {
  type: ActionType.UPDATE_BUMP_IN_FAIL,
  payload: string
}

interface updateLocalBumpIn {
    type: ActionType.UPDATE_LOCAL_BUMP_IN,
    payload: BumpIn
}

export type Action =
    getBumpInsFail |
    getBumpInsPending |
    getBumpInsSuccess |
    createBumpInPending | 
    createBumpInSuccess | 
    createBumpInFail |
    updateBumpInPending | 
    updateBumpInSuccess | 
    updateBumpInFail |
    updateLocalBumpIn;
