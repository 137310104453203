import React from "react";
import { useState } from "react";
import { createSinkCutoutRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { SinkCutoutRange } from "api/types/price";
import { SinkCutout } from "api/types/price";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { dialogContentStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
    sink_cutout: SinkCutout
}

export default function CreateSinkCutoutRange({sink_cutout}: Props) {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const [range, setRange] = useState<SinkCutoutRange>({});

    const handleRangePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setRange({...range, price: Number(price)});
        }
    }

    const handleRangeMeasurement = (measurement: string) => {
        if(isNaN(Number(measurement)) === false){
            setRange({...range, measurement: Number(measurement)});
        }
    }

    const handleCreate = () => {
        let error = false;

        if(error){
            return;
        }

        if(sink_cutout.id){
            const create_range = {...range, sink_cutout: sink_cutout.id, any_range: false};
            dispatch(createSinkCutoutRange(create_range));
            handleClose();
        }   
    }

    const handleClose = () => {
        setOpen(false);
        setRange({});
    }

    return(
        <Dialog 
            fullWidth={true}
            maxWidth={"md"}
            open={open} 
            onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Sink Cutout Range</DialogTitle>
            <DialogContent sx={dialogContentStyle}>
                <FormControl key={range.id}>
                    <TextField
                        sx={[textFieldStyle]}
                        size="medium"
                        variant="outlined"
                        id="measurement"
                        label="Size (inches)"
                        onChange={(e) => handleRangeMeasurement(e.target.value)} />
                    <TextField
                        sx={[textFieldStyle]}
                        size="medium"
                        variant="outlined"
                        id="price"
                        label="Price"
                        onChange={(e) => handleRangePrice(e.target.value)} />
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}
