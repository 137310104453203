import { Miter } from "api/types/price";
import { MiterRange } from "api/types/price";

export enum ActionType { 
    GET_MITERS_PENDING="GET_MITERS_PENDING",
    GET_MITERS_SUCCESS="GET_MITERS_SUCCESS",
    GET_MITERS_FAIL="GET_MITERS_FAIL",
    CREATE_MITER_PENDING = "CREATE_MITER_PENDING",
    CREATE_MITER_SUCCESS = "CREATE_MITER_SUCCESS",
    CREATE_MITER_FAIL = "CREATE_MITER_FAIL",
    UPDATE_MITER_PENDING = "UPDATE_MITER_PENDING",
    UPDATE_MITER_SUCCESS = "UPDATE_MITER_SUCCESS",
    UPDATE_MITER_FAIL = "UPDATE_MITER_FAIL",
    UPDATE_LOCAL_MITER = "UPDATE_LOCAL_MITER",
    CREATE_MITER_RANGE_PENDING = "CREATE_MITER_RANGE_PENDING",
    CREATE_MITER_RANGE_SUCCESS = "CREATE_MITER_RANGE_SUCCESS",
    CREATE_MITER_RANGE_FAIL = "CREATE_MITER_RANGE_FAIL",
    UPDATE_MITER_RANGE_PENDING = "UPDATE_MITER_RANGE_PENDING",
    UPDATE_MITER_RANGE_SUCCESS = "UPDATE_MITER_RANGE_SUCCESS",
    UPDATE_MITER_RANGE_FAIL = "UPDATE_MITER_RANGE_FAIL",
    UPDATE_LOCAL_MITER_RANGE = "UPDATE_LOCAL_MITER_RANGE",
    DELETE_MITER_RANGE_PENDING = "DELETE_MITER_RANGE_PENDING",
    DELETE_MITER_RANGE_SUCCESS = "DELETE_MITER_RANGE_SUCCESS",
    DELETE_MITER_RANGE_FAIL = "DELETE_MITER_RANGE_FAIL"
}

interface getMitersFail {
    type: ActionType.GET_MITERS_FAIL,
    payload: string
}

interface getMitersSuccess {
    type: ActionType.GET_MITERS_SUCCESS,
    payload: Miter[]
}

interface getMitersPending {
    type: ActionType.GET_MITERS_PENDING
}

interface createMiterFail {
    type: ActionType.CREATE_MITER_FAIL,
    payload: string
}

interface createMiterSuccess {
    type: ActionType.CREATE_MITER_SUCCESS,
    payload: Miter[]
}

interface createMiterPending {
    type: ActionType.CREATE_MITER_PENDING
}

interface updateMiterFail {
    type: ActionType.UPDATE_MITER_FAIL,
    payload: string
}

interface updateMiterSuccess {
    type: ActionType.UPDATE_MITER_SUCCESS,
    payload: Miter
}

interface updateMiterPending {
    type: ActionType.UPDATE_MITER_PENDING
}

interface updateLocalMiter {
    type: ActionType.UPDATE_LOCAL_MITER,
    payload: Miter
}

interface createMiterRangePending {
    type: ActionType.CREATE_MITER_RANGE_PENDING
}

interface createMiterRangeSuccess {
    type: ActionType.CREATE_MITER_RANGE_SUCCESS,
    payload: MiterRange
}

interface createMiterRangeFail {
    type: ActionType.CREATE_MITER_RANGE_FAIL,
    payload: string
}

interface updateMiterRangeFail {
    type: ActionType.UPDATE_MITER_RANGE_FAIL,
    payload: string
}

interface updateMiterRangeSuccess {
    type: ActionType.UPDATE_MITER_RANGE_SUCCESS,
    payload: MiterRange
}

interface updateMiterRangePending {
    type: ActionType.UPDATE_MITER_RANGE_PENDING
}

interface updateLocalMiterRange {
    type: ActionType.UPDATE_LOCAL_MITER_RANGE,
    payload: MiterRange
}

interface deleteMiterRangeFail {
    type: ActionType.DELETE_MITER_RANGE_FAIL,
    payload: string
}

interface deleteMiterRangeSuccess {
    type: ActionType.DELETE_MITER_RANGE_SUCCESS,
    payload: MiterRange
}

interface deleteMiterRangePending {
    type: ActionType.DELETE_MITER_RANGE_PENDING
}

export type Action =
    getMitersFail |
    getMitersPending |
    getMitersSuccess |
    createMiterPending |
    createMiterSuccess |
    createMiterFail |
    updateMiterPending |
    updateMiterSuccess |
    updateMiterFail |
    updateLocalMiter |
    createMiterRangePending |
    createMiterRangeSuccess |
    createMiterRangeFail |
    updateMiterRangeFail |
    updateMiterRangePending |
    updateMiterRangeSuccess |
    updateLocalMiterRange |
    deleteMiterRangeFail |
    deleteMiterRangePending |
    deleteMiterRangeSuccess;