import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Steppers from "components/universal/stepper";
import { staff_payment_setup_steps } from "values/steps";
import {DateTime} from "luxon";
import { create_account_url } from "components/navigation/endpoints";
import { mainContentAreaStyle } from "styles/style";
import { text_primary } from "styles/style";

export default function StaffSubscriptionSetupConfirmation () {
    const date = DateTime.now().plus({months: 1});
    const navigate = useNavigate();
    return (
        <Grid container sx={mainContentAreaStyle}>
            <Grid item xs={10} sx={{marginTop: 10}}>
                <Box display="flex" alignItems="center" sx={{paddingLeft: "10%"}}>
                    <Stack>
                        <Typography variant="h6" sx={{color: text_primary}}>
                            Activated!
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: 1}}>
                            The customer account will be fully activated once they confirm using the link in their email.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: 1}}>
                            Their first billing date will be {`${date.toLocaleString(DateTime.DATE_MED)}`}.
                        </Typography>
                        <Stack direction="row" sx={{marginTop: 5}}>
                            <Button onClick={() => navigate(`/${create_account_url}`, {replace: true})}>
                                Add Another Customer
                            </Button>
                            <Button sx={{marginLeft: 4}} onClick={() => navigate("/", {replace: true})}>
                                Return to Dashboard
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={2} sx={{marginTop: 10}}>
                <Steppers current_step={4} steps={staff_payment_setup_steps}/>
            </Grid>
        </Grid>
    )
}