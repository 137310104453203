import { BumpOutArc } from "api/types/price";

export enum ActionType {
    GET_BUMP_OUT_ARCS_PENDING="GET_BUMP_OUT_ARCS_PENDING",
    GET_BUMP_OUT_ARCS_SUCCESS="GET_BUMP_OUT_ARCS_SUCCESS",
    GET_BUMP_OUT_ARCS_FAIL="GET_BUMP_OUT_ARCS_FAIL",
    CREATE_BUMP_OUT_ARC_PENDING = "CREATE_BUMP_OUT_ARC_PENDING",
    CREATE_BUMP_OUT_ARC_SUCCESS = "CREATE_BUMP_OUT_ARC_SUCCESS",
    CREATE_BUMP_OUT_ARC_FAIL = "CREATE_BUMP_OUT_ARC_FAIL",
    UPDATE_BUMP_OUT_ARC_PENDING = "UPDATE_BUMP_OUT_ARC_PENDING",
    UPDATE_BUMP_OUT_ARC_SUCCESS = "UPDATE_BUMP_OUT_ARC_SUCCESS",
    UPDATE_BUMP_OUT_ARC_FAIL = "UPDATE_BUMP_OUT_ARC_FAIL",
    UPDATE_LOCAL_BUMP_OUT_ARC = "UPDATE_LOCAL_BUMP_OUT_ARC"
}

interface getBumpOutArcsFail {
    type: ActionType.GET_BUMP_OUT_ARCS_FAIL,
    payload: string
}

interface getBumpOutArcsSuccess {
    type: ActionType.GET_BUMP_OUT_ARCS_SUCCESS,
    payload: BumpOutArc[]
}

interface getBumpOutArcsPending {
    type: ActionType.GET_BUMP_OUT_ARCS_PENDING
}

interface createBumpOutArcPending {
  type: ActionType.CREATE_BUMP_OUT_ARC_PENDING
}

interface createBumpOutArcSuccess {
  type: ActionType.CREATE_BUMP_OUT_ARC_SUCCESS,
  payload: BumpOutArc
}

interface createBumpOutArcFail {
  type: ActionType.CREATE_BUMP_OUT_ARC_FAIL,
  payload: string
}

interface updateBumpOutArcPending {
  type: ActionType.UPDATE_BUMP_OUT_ARC_PENDING,
}

interface updateBumpOutArcSuccess {
  type: ActionType.UPDATE_BUMP_OUT_ARC_SUCCESS,
  payload: BumpOutArc
}

interface updateBumpOutArcFail {
  type: ActionType.UPDATE_BUMP_OUT_ARC_FAIL,
  payload: string
}

interface updateLocalBumpOutArc {
    type: ActionType.UPDATE_LOCAL_BUMP_OUT_ARC,
    payload: BumpOutArc
}

export type Action = 
    getBumpOutArcsFail |
    getBumpOutArcsPending |
    getBumpOutArcsSuccess |
    createBumpOutArcFail |
    createBumpOutArcPending |
    createBumpOutArcSuccess |
    updateBumpOutArcFail |
    updateBumpOutArcPending |
    updateBumpOutArcSuccess |
    updateLocalBumpOutArc;