import { FinishedEdge } from "api/types/price";

export enum ActionType {
    GET_FINISHED_EDGES_PENDING="GET_FINISHED_EDGES_PENDING",
    GET_FINISHED_EDGES_SUCCESS="GET_FINISHED_EDGES_SUCCESS",
    GET_FINISHED_EDGES_FAIL="GET_FINISHED_EDGES_FAIL",
    CREATE_FINISHED_EDGE_PENDING = "CREATE_FINISHED_EDGE_PENDING",
    CREATE_FINISHED_EDGE_SUCCESS = "CREATE_FINISHED_EDGE_SUCCESS",
    CREATE_FINISHED_EDGE_FAIL = "CREATE_FINISHED_EDGE_FAIL",
    UPDATE_FINISHED_EDGE_PENDING = "UPDATE_FINISHED_EDGE_PENDING",
    UPDATE_FINISHED_EDGE_SUCCESS = "UPDATE_FINISHED_EDGE_SUCCESS",
    UPDATE_FINISHED_EDGE_FAIL = "UPDATE_FINISHED_EDGE_FAIL",
    DELETE_FINISHED_EDGE_PENDING = "DELETE_FINISHED_EDGE_PENDING",
    DELETE_FINISHED_EDGE_SUCCESS = "DELETE_FINISHED_EDGE_SUCCESS",
    DELETE_FINISHED_EDGE_FAIL = "DELETE_FINISHED_EDGE_FAIL",
    UPDATE_LOCAL_FINISHED_EDGE = "UPDATE_LOCAL_FINISHED_EDGE"
}

interface getFinishedEdgesFail {
    type: ActionType.GET_FINISHED_EDGES_FAIL,
    payload: string
}

interface getFinishedEdgesSuccess {
    type: ActionType.GET_FINISHED_EDGES_SUCCESS,
    payload: FinishedEdge[]
}

interface getFinishedEdgesPending {
    type: ActionType.GET_FINISHED_EDGES_PENDING
}

interface createFinishedEdgePending {
    type: ActionType.CREATE_FINISHED_EDGE_PENDING
}

interface createFinishedEdgeSuccess {
    type: ActionType.CREATE_FINISHED_EDGE_SUCCESS,
    payload: FinishedEdge[]
}

interface createFinishedEdgeFail {
    type: ActionType.CREATE_FINISHED_EDGE_FAIL,
    payload: string
}

interface updateFinishedEdgePending {
    type: ActionType.UPDATE_FINISHED_EDGE_PENDING
}

interface updateFinishedEdgeSuccess {
    type: ActionType.UPDATE_FINISHED_EDGE_SUCCESS,
    payload: FinishedEdge
}

interface updateFinishedEdgeFail {
    type: ActionType.UPDATE_FINISHED_EDGE_FAIL,
    payload: string
}

interface deleteFinishedEdgePending {
    type: ActionType.DELETE_FINISHED_EDGE_PENDING
}

interface deleteFinishedEdgeSuccess {
    type: ActionType.DELETE_FINISHED_EDGE_SUCCESS,
    payload: FinishedEdge
}

interface deleteFinishedEdgeFail {
    type: ActionType.DELETE_FINISHED_EDGE_FAIL,
    payload: string
}

interface updateLocalFinishedEdge {
    type: ActionType.UPDATE_LOCAL_FINISHED_EDGE,
    payload: FinishedEdge
}

export type Action =
    getFinishedEdgesFail |
    getFinishedEdgesPending |
    getFinishedEdgesSuccess |
    createFinishedEdgePending |
    createFinishedEdgeSuccess |
    createFinishedEdgeFail |
    updateFinishedEdgePending |
    updateFinishedEdgeSuccess |
    updateFinishedEdgeFail |
    deleteFinishedEdgePending |
    deleteFinishedEdgeSuccess |
    deleteFinishedEdgeFail |
    updateLocalFinishedEdge;