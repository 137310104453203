import { Fragment } from "react";
import Name from "../../shared/name";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import FinishedEdgeImage from "components/price/dialog/shared_components/finished_edge_image";
import { gridSpacing } from "styles/style";

interface Props {
    name: string,
    type: string,
    price: number,
    enabled: boolean,
    saveNumber: (key: string, value: string) => void,
    saveBoolean: (key: string, value: boolean) => void,
    setLocked: (locked: boolean) => void
}

export default function FinishedEdgeTypeItem({name, type, price, enabled, saveNumber, saveBoolean, setLocked}: Props) {
    return(
        <Fragment>
            <Name 
                bold={false}
                underline={true}
                name={name} 
                width={1} />
            <Grid item xs={1.5}>
                <FinishedEdgeImage 
                    height={30}
                    width={60}
                    type={type} />
            </Grid>
            <Grid item xs={1.5} sx={gridSpacing}>
                <TextField 
                    onFocus={() => setLocked(true)}
                    onBlur={() => setLocked(false)}
                    onChange={(e) => saveNumber(`${name.toLowerCase()}_price`, e.target.value)}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    type="number"
                    defaultValue={price}>
                </TextField>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="body2">
                    $/LIN FT
                </Typography>
            </Grid>
        </Fragment>
    )
}
