import { BugLog } from 'api/types/feedback';
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material';
import { getDate } from 'functions/date';
import { getRowSecondStyle } from 'values/style';

interface Props {
    index: number,
    item: BugLog
}

export default function BugLogRow({index, item}: Props) {
    return(
        <Grid container item xs={12} sx={[getRowSecondStyle(index), {padding: 1}]}>
            <Grid item xs={8}>
                <Typography variant="body2">
                    {item.username}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" align="right">
                    {getDate(item.date_added)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">
                    {item.text}
                </Typography>
            </Grid>
        </Grid>
    )
}
