import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getRegion } from "api/actions/region";
import { updateRegion } from "api/actions/region";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import Title from "components/universal/title";
import TitleSpacer from "components/universal/title_spacer";
import IslandSubtitle from "components/universal/island_subtitle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { UpdateTime } from "api/types/update";
import { Region } from "api/types/region";
import { gridSpacing } from "styles/style";
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";
import { JSONIsEmpty } from "functions/value";

export default function RegionComponent() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { region } = useAppSelector((state) => state.region);
  const [update_pending, setUpdatePending] = useState<boolean>(false);
  const [start_times, setStartTimes] = useState<UpdateTime[]>([]);
  const [region_update, setRegionUpdate] = useState<Region>({});
  const region_loaded = JSONIsEmpty(region) === false;

  useEffect(() => {
    if (id) {
      dispatch(getRegion(id));
    }
  }, [dispatch, id]);

  const updateValue = (key: string, value: string | boolean | number) => {
    setRegionUpdate({ ...region_update, [key]: value });
    start_update();
  }

  const start_update = () => {
    setUpdatePending(true);
    setStartTimes([{
      time: Math.round(new Date().getTime() / 1000),
      data: region_update
    }]);
  }

  const update = () => {
    if (region.id) {
      dispatch(updateRegion(region_update, region.id));
    }
    setUpdatePending(false);
  }

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title="Region"
            update_pending={update_pending}
            start_times={start_times}
            update={update}
            endProps={<TitleSpacer />} />
        </Grid>
        <Grid item xs={8.5}>
          <Paper sx={islandStyle}>
            <Grid container alignItems="center" sx={[{ padding: 1 }]}>
              <IslandSubtitle
                subtitle="Details" />
              {
                region_loaded ?
                  <Grid item xs={12} sx={gridSpacing}>
                    <TextField
                      onChange={(e) => updateValue("name", e.target.value)}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      label="Name"
                      defaultValue={region.name}>
                    </TextField>
                  </Grid> :
                  null
              }

              {
                region_loaded ?
                  <Grid item xs={2} sx={gridSpacing}>
                    <TextField
                      onChange={(e) => updateValue("default_tax_rate", e.target.value)}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Tax Rate"
                      defaultValue={region.default_tax_rate}>
                    </TextField>
                  </Grid> :
                  null
              }
              {
                region_loaded ?
                  <Grid item xs={2}>
                    <TextField
                      onChange={(e) => updateValue("default_tax_rate_name", e.target.value)}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Tax Rate Type"
                      select
                      defaultValue={region.default_tax_rate_name}>
                      <MenuItem key="HST" value="HST">
                        HST
                      </MenuItem>
                      <MenuItem key="GST" value="GST">
                        GST
                      </MenuItem>
                      <MenuItem key="PST" value="PST">
                        PST
                      </MenuItem>
                    </TextField>
                  </Grid> :
                  null
              }
              {
                region_loaded ?
                  <Grid item xs={2} sx={gridSpacing}>
                    <TextField
                      onChange={(e) => updateValue("second_tax_rate", e.target.value)}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Tax Rate 2"
                      defaultValue={region.second_tax_rate}>
                    </TextField>
                  </Grid> :
                  null
              }
              {
                region_loaded ?
                  <Grid item xs={2}>
                    <TextField
                      onChange={(e) => updateValue("second_tax_rate_name", e.target.value)}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Tax Rate Type 2"
                      select
                      defaultValue={region.second_tax_rate_name}>
                      <MenuItem key="HST" value="HST">
                        HST
                      </MenuItem>
                      <MenuItem key="GST" value="GST">
                        GST
                      </MenuItem>
                      <MenuItem key="PST" value="PST">
                        PST
                      </MenuItem>
                    </TextField>
                  </Grid> :
                  null
              }
              {
                region_loaded ?
                  <Grid item xs={2}>
                    <TextField
                      onChange={(e) => updateValue("has_second_tax", e.target.value)}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Use second tax"
                      select
                      defaultValue={region.has_second_tax}>
                      <MenuItem key="Yes" value={true as any}>
                        Yes
                      </MenuItem>
                      <MenuItem key="No" value={false as any}>
                        No
                      </MenuItem>
                    </TextField>
                  </Grid> :
                  null
              }
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>

        </Grid>
      </Grid>
    </Box>
  )
}

