import { User } from "api/types/authentication";

export enum ActionType {
    CLEAR_EMAIL_CONFIRMATION="CLEAR_EMAIL_CONFIRMATION",
    RESEND_CONFIRMATION_PENDING="RESEND_CONFIRMATION_PENDING",
    RESEND_CONFIRMATION_COMPLETE="RESEND_CONFIRMATION_COMPLETE",
    RESEND_CONFIRMATION_ERROR="RESEND_CONFIRMATION_ERROR",
    CLEAR_ACCOUNT="CLEAR_ACCOUNT",
    ACCOUNT_PENDING="ACCOUNT_PENDING",
    ACCOUNT_FAIL="ACCOUNT_FAIL",
    GET_ACCOUNTS_SUCCESS="GET_ACCOUNTS_SUCCESS",
    GET_ACCOUNT_SUCCESS="GET_ACCOUNT_SUCCESS",
    UPDATE_ACCOUNT="UPDATE_ACCOUNT"
}

interface clearEmailConfirmation {
    type: ActionType.CLEAR_EMAIL_CONFIRMATION
}

interface resendConfirmationPending {
    type: ActionType.RESEND_CONFIRMATION_PENDING
}

interface resendConfirmationComplete {
    type: ActionType.RESEND_CONFIRMATION_COMPLETE
}

interface resendConfirmationError {
    type: ActionType.RESEND_CONFIRMATION_ERROR,
    payload: string
}

interface clearAccount {
    type: ActionType.CLEAR_ACCOUNT
}

interface accountPending {
    type: ActionType.ACCOUNT_PENDING
}

interface accountFail {
    type: ActionType.ACCOUNT_FAIL,
    payload: string
}

interface getAccountsSuccess {
    type: ActionType.GET_ACCOUNTS_SUCCESS,
    payload: User[]
}

interface getAccountSuccess {
    type: ActionType.GET_ACCOUNT_SUCCESS,
    payload: User
}

interface updateAccount {
    type: ActionType.UPDATE_ACCOUNT,
    payload: User
}

export type Action =
    clearEmailConfirmation |
    resendConfirmationComplete |
    resendConfirmationError |
    resendConfirmationPending |
    clearAccount |
    accountPending |
    accountFail |
    getAccountsSuccess |
    getAccountSuccess |
    updateAccount;