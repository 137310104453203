import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getMiters } from "api/actions/price/miter";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateMiter } from "../dialog/create";
import MiterItem from "../row/miter";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { Miter } from "api/types/price";
import { MITER } from "api/types/update";
import { MITER_RANGE } from "api/types/update";

interface Props {
    start_update: (type: number, data: Miter) => void
}

export default function PriceListMiter({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { miters } = useAppSelector((state) => state.price);

    const handle_update = (update_item: any) => {
        if("height" in update_item){
            start_update(MITER_RANGE, update_item);
        }
        else{
            start_update(MITER, update_item);
        }
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getMiters(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Miters"
                    endProps={
                        <CreateMiter />} />
                <Header 
                    items={[
                        {text: "Height/Range", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={miters}
                    Item={MiterItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}