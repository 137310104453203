import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { setProduct } from "api/actions/billing";
import { setBillingCustomerCreated } from "api/actions/billing";
import { setRegistrationComplete } from "api/actions/authentication";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SUBSCRIPTION_PRODUCT_ID } from "settings";
import { MONTHLY_PRICE_ID } from "settings";
import { YEARLY_PRICE_ID } from "settings";
import { QUOTING_ONLY_PRODUCT_ID } from "settings";
import { MONTHLY_QUOTING_ONLY_PRICE_ID } from "settings";
import { MARKET_ONLY_PRODUCT_ID } from "settings";
import { MONTHLY_MARKET_ONLY_PRICE_ID } from "settings";
import { primary_color } from "styles/style";
import { text_primary } from "styles/style";
import { white_color } from "styles/style";
import { marketing_well_color } from "styles/style";
import { planCardStyle } from "styles/marketing";
import { planCardWithWellStyle } from "styles/marketing";

interface Props {
    setStep: (step: number) => void
}
  
export default function StaffChoosePlan({setStep}: Props) {
    const dispatch = useAppDispatch();
    const [show_alts, setShowAlts] = useState<boolean>(false);

    useEffect(() => {
        setRegistrationComplete(false);
        setBillingCustomerCreated(false);
    }, []);

    const choosePlan = (product: string, price: string) => {
        dispatch(setProduct(product, price));
        setStep(3);
    }
    <Box>
</Box>

    return (
        <Grid container item justifyContent="start" alignItems="center">
            {
                !show_alts ?
                <Fragment>
                    <Grid item xs={0.25}></Grid>
                    <Grid item xs={5.25} md={5.5} xl={4.5} sx={{paddingTop: "1vh"}}>
                        <Paper sx={planCardStyle} elevation={5}>
                            <Stack alignItems="center">
                                <Typography variant="h6" sx={{color: text_primary}} textAlign="center">
                                    Flexible Plan
                                </Typography>
                                <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                    <Typography variant="body2">
                                        $
                                    </Typography>
                                    <Typography variant="h1" sx={{padding: 0, margin: 0}}>
                                        199
                                    </Typography>
                                </Stack>
                                <Typography variant="body2" textAlign="center">
                                    Every month
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                    Billed Monthly
                                </Typography>
                                <Typography variant="body2" textAlign="center" sx={{paddingTop: 2}}>
                                    30 day free trial
                                </Typography>
                                <Button 
                                    onClick={() => choosePlan(SUBSCRIPTION_PRODUCT_ID, MONTHLY_PRICE_ID)}
                                    variant="contained" 
                                    sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                    Select Plan
                                </Button>
                            </Stack>
                            <Stack alignItems="left">
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Access to all drawing and cost projection tools Zero fees
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Zero fees for setup or implementation
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Comprehensive support via phone, email, and Zoom without any
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        A vast repository of knowledge and resources
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Complete features for task scheduling and project management
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Freedom to cancel at any time
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}}>
                                    <Typography variant="body2" textAlign="center">
                                    <span>&nbsp;</span>{"\n"}
                                    <span>&nbsp;</span>{"\n"}
                                    <span>&nbsp;</span>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={0.25}></Grid>
                    <Grid item xs={5.25} md={5.5} xl={4.5} sx={{paddingTop: "1vh"}}>
                        <Paper sx={planCardWithWellStyle} elevation={5}>
                            <Box sx={{position: "absolute"}}>
                                <Box 
                                    sx={{
                                        position: "relative",
                                        left: 160,
                                        top: -20,
                                        width: 100,
                                        height: 35,
                                        backgroundColor: primary_color,
                                        marginBottom: 2
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Typography variant="body2" sx={{color: white_color}}>
                                        Best Value
                                    </Typography>
                                </Box>
                            </Box>
                            <Stack 
                                alignItems="center"
                                sx={{
                                    backgroundColor: marketing_well_color,
                                    paddingBottom: 1.5,
                                    paddingTop: 4,
                                    paddingRight: 4,
                                    paddingLeft: 4
                                }}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    Momentum Plan
                                </Typography>
                                <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                    <Typography variant="body2">
                                        $
                                    </Typography>
                                    <Typography variant="h1" sx={{padding: 0, margin: 0}}>
                                        165
                                    </Typography>
                                </Stack>
                                <Typography variant="body2" textAlign="center">
                                    Every month
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                    Billed Yearly
                                </Typography>
                                <Typography variant="body2" textAlign="center" sx={{paddingTop: 2}}>
                                    30 day free trial
                                </Typography>
                                <Button
                                    onClick={() => choosePlan(SUBSCRIPTION_PRODUCT_ID, YEARLY_PRICE_ID)}
                                    variant="contained"
                                    sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                    Select Plan
                                </Button>
                            </Stack>
                            <Stack sx={{paddingBottom: 4, paddingRight: 4, paddingLeft: 4}} justifyContent="flex-start">
                                <Stack direction="row" sx={{paddingTop: 1.5}}>
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Get 2 months FREE, when you pay annually ($408 in SAVINGS)
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Access to all drawing and cost projection tools
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Zero fees for setup or implementation
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Comprehensive support via phone, email, and Zoom without any
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        A vast repository of knowledge and resources
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Complete features for task scheduling and project management
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Freedom to cancel at any time
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={0.5}>
                                <IconButton onClick={() => setShowAlts(true)} sx={{color: white_color, backgroundColor: primary_color}}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                    </Grid>
                </Fragment> :
                <Fragment>
                    <Grid item xs={0.25}>
                        <Box display="flex" justifyContent="center">
                            <Box sx={{paddingRight: 5}} display="flex" justifyContent="flex-end">
                                <IconButton onClick={() => setShowAlts(false)} sx={{color: white_color, backgroundColor: primary_color}}>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={5.25} md={5.5} xl={4.5} sx={{paddingTop: "1vh"}}>
                        <Paper sx={planCardStyle} elevation={5}>
                            <Stack alignItems="center">
                                <Typography variant="h6" sx={{color: text_primary}} textAlign="center">
                                    Quote Only Plan
                                </Typography>
                                <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                    <Typography variant="body2">
                                        $
                                    </Typography>
                                    <Typography variant="h1" sx={{padding: 0, margin: 0}}>
                                        119
                                    </Typography>
                                </Stack>
                                <Typography variant="body2" textAlign="center">
                                    Every month
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                    Billed Monthly
                                </Typography>
                                <Typography variant="body2" textAlign="center" sx={{paddingTop: 2}}>
                                    30 day free trial
                                </Typography>
                                <Button 
                                    onClick={() => choosePlan(QUOTING_ONLY_PRODUCT_ID, MONTHLY_QUOTING_ONLY_PRICE_ID)}
                                    variant="contained" 
                                    sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                    Select Plan
                                </Button>
                            </Stack>
                            <Stack alignItems="left">
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Access to all drawing and cost projection tools Zero fees
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Zero fees for setup or implementation
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Comprehensive support via phone, email, and Zoom without any
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        A vast repository of knowledge and resources
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Freedom to cancel at any time
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}}>
                                    <Typography variant="body2" textAlign="center">
                                    <span>&nbsp;</span>{"\n"}
                                    <span>&nbsp;</span>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={0.25}></Grid>
                    <Grid item xs={5.25} md={5.5} xl={4.5} sx={{paddingTop: "1vh"}}>
                        <Paper sx={[planCardWithWellStyle, {paddingBottom: 17.5}]} elevation={5}>
                            <Stack 
                                alignItems="center"
                                sx={{
                                    backgroundColor: marketing_well_color,
                                    paddingBottom: 1.5,
                                    paddingTop: 4,
                                    paddingRight: 4,
                                    paddingLeft: 4
                                }}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    Market Only Plan
                                </Typography>
                                <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                    <Typography variant="body2">
                                        $
                                    </Typography>
                                    <Typography variant="h1" sx={{padding: 0, margin: 0}}>
                                        119
                                    </Typography>
                                </Stack>
                                <Typography variant="body2" textAlign="center">
                                    Every month
                                </Typography>
                                <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                    Billed Yearly
                                </Typography>
                                <Typography variant="body2" textAlign="center" sx={{paddingTop: 2}}>
                                    30 day free trial
                                </Typography>
                                <Button
                                    onClick={() => choosePlan(MARKET_ONLY_PRODUCT_ID, MONTHLY_MARKET_ONLY_PRICE_ID)}
                                    variant="contained"
                                    sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                    Select Plan
                                </Button>
                            </Stack>
                            <Stack sx={{paddingBottom: 4, paddingRight: 4, paddingLeft: 4}} justifyContent="flex-start">
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Comprehensive support via phone, email, and Zoom without any
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        A vast repository of knowledge and resources
                                    </Typography>
                                </Stack>
                                <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                    <Icon color="primary" fontSize="small">
                                        <CheckCircleIcon fontSize="small"/>
                                    </Icon>
                                    <Typography variant="body2" sx={{marginLeft: 2}}>
                                        Freedom to cancel at any time
                                    </Typography>
                                </Stack>
                                <span>&nbsp;</span>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={0.5}></Grid>
                </Fragment>
            }
            
        </Grid>
    );
}