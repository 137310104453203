import { Material } from "api/types/material";
import { Color } from "api/types/material";

export enum ActionType {
  GET_MATERIALS = "GET_MATERIALS",
  GET_MATERIAL = "GET_MATERIAL",
  GET_COLORS = "GET_COLORS",
  CREATE_MATERIAL = "CREATE_MATERIAL",
  UPDATE_MATERIAL = "UPDATE_MATERIAL",
  DELETE_MATERIAL = "DELETE_MATERIAL",
  CREATE_COLOR = "CREATE_COLOR",
  UPDATE_COLOR = "UPDATE_COLOR",
  DELETE_COLOR = "DELETE_COLOR",
  MATERIAL_UPDATING = "MATERIAL_UPDATING",
  MATERIAL_ERROR = "MATERIAL_ERROR",
  SET_MATERIAL = "SET_MATERIAL",
  UPDATE_LOCAL_COLOR = "UPDATE_LOCAL_COLOR",
  CLEAR_MATERIALS = "CLEAR_MATERIALS"
}

interface getMaterialsSuccess {
  type: ActionType.GET_MATERIALS,
  payload: Material[]
}

interface getMaterialSuccess {
  type: ActionType.GET_MATERIAL,
  payload: Material
}

interface IGetColorsSuccess {
  type: ActionType.GET_COLORS,
  payload: Color[]
}

interface createMaterialSuccess {
  type: ActionType.CREATE_MATERIAL,
  payload: Material
}

interface updateMaterialSuccess {
  type: ActionType.UPDATE_MATERIAL,
  payload: Material
}

interface deleteMaterialSuccess {
  type: ActionType.DELETE_MATERIAL,
  payload: Material
}

interface createColorSuccess {
  type: ActionType.CREATE_COLOR,
  payload: Color[]
}

interface updateColorSuccess {
  type: ActionType.UPDATE_COLOR,
  payload: Color
}

interface deleteColorSuccess {
  type: ActionType.DELETE_COLOR,
  payload: Color
}

interface setMaterial {
  type: ActionType.SET_MATERIAL,
  payload: Material
}

interface updateLocalColor {
  type: ActionType.UPDATE_LOCAL_COLOR,
  payload: Color
}

interface clearMaterials {
  type: ActionType.CLEAR_MATERIALS
}

interface IMaterialUpdating {
  type: ActionType.MATERIAL_UPDATING
}

interface IMaterialError {
  type: ActionType.MATERIAL_ERROR,
  error: string
}

export type Action =
  getMaterialsSuccess |
  getMaterialSuccess |
  IGetColorsSuccess |
  createMaterialSuccess |
  updateMaterialSuccess |
  deleteMaterialSuccess |
  createColorSuccess |
  updateColorSuccess |
  deleteColorSuccess |
  setMaterial |
  updateLocalColor |
  clearMaterials |
  IMaterialUpdating |
  IMaterialError;
