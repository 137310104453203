import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ManageSuppliersHeader() {
    return(
        <Grid container item sx={{marginTop: 4, marginLeft: 0.5, marginRight: 0.5}}>
            <Grid item xs={6}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    Material
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    Supplier
                </Typography>
            </Grid>
        </Grid>
    )
}
