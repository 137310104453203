import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createInsideRadiusCornerRange } from "api/actions/price";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import BasicRangeEditableItem from "./shared/basic_range";
import EditableList from "components/universal/editable_list";
import InsideRadiusCornerRangeEditableItem from "./inside_radius_corner_range";
import { CreateInsideRadiusCornerRange } from "components/price/dialog/create";
import AddIcon from "@mui/icons-material/Add";
import { InsideRadiusCorner } from "api/types/price";
import { getRowSecondStyle } from 'values/style';
import { empty_inside_radius_corner_range } from "values/empty/price";

interface Props {
    index: number,
    item: InsideRadiusCorner,
    start_update: (update_item: InsideRadiusCorner) => void,
    setLocked: (locked: boolean) => void
}

export default function InsideRadiusCornerEditableItem({index, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [inside_radius_corner_update, setInsideRadiusCornerUpdate] = useState<InsideRadiusCorner>(item);
    const [open, setOpen] = useState<boolean>(false);

    const saveBoolean = (key: string, value: boolean) => {
        const new_inside_radius_corner: InsideRadiusCorner = {...inside_radius_corner_update, [key]: value};
            setInsideRadiusCornerUpdate(new_inside_radius_corner);
            start_update(new_inside_radius_corner);
    }

    return(
        <Grid container item alignItems="center" sx={[{padding: 1}, getRowSecondStyle(index)]}>
            <BasicRangeEditableItem 
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.enabled ? item.enabled : false}
                saveBoolean={saveBoolean} />
            <EditableList 
                setLocked={setLocked}
                items={item.ranges ? item.ranges : []}
                Item={InsideRadiusCornerRangeEditableItem}
                start_update={start_update}/>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(createInsideRadiusCornerRange({...empty_inside_radius_corner_range, radius: 0, inside_radius_corner: item.id}))}
                    size="small"
                    endIcon={<AddIcon />}
                    sx={{paddingLeft: 0, paddingRight: 0}}>
                    Range
                </Button>
            </Grid>
            <Grid item xs={12}>
                <CreateInsideRadiusCornerRange 
                    open={open}
                    setOpen={setOpen}
                    inside_radius_corner={item} />
            </Grid>
        </Grid>
    )
}
