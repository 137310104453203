import { CustomerError } from "api/types/billing";

export const empty_customer_error: CustomerError = {
    city_has_error: false,
    city_error: "",
    address_has_error: false,
    address_error: "",
    postal_code_has_error: false,
    postal_code_error: "",
    state_has_error: false,
    state_error: "",
    email_has_error: false,
    email_error: "",
    name_has_error: false,
    name_error: "",
    phone_has_error: false,
    phone_error: ""
}