import List from "components/universal/list";
import Grid from '@mui/material/Grid';
import MiterRangePlainItem from "./miter_range";
import BasicPlainRangeItem from "./shared/basic_range";
import { Miter } from "api/types/price";
import { getRowSecondStyle } from "values/style";

interface Props {
    index: number,
    item: Miter
}

export default function MiterPlainItem({index, item}: Props) {
    return(
        <Grid container item sx={[{padding: 1}, getRowSecondStyle(index)]} alignItems="center">
            <BasicPlainRangeItem 
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.taxable ? item.taxable : false}/>
            <List 
                items={item.ranges ? item.ranges : []}
                Item={MiterRangePlainItem} />
        </Grid>
    )
}
