import { IWarning } from "api/types/account";

export const EmailConfirmation: IWarning = {
    title: "Email Not Confirmed",
    description: "Customer sets their password for the first time when email is confirmed. Without confirmation they cannot log in. Please resend email confirmation."
}

export const PaymentInitialize: IWarning = {
    title: "Payment Not Initialized",
    description: "Payment has not been initialized. Add a subscription if necessary, and then go to the stripe portal to add a payment method, then set payment initialized as 'Yes'."
}

export const PendingAccountConfirmation: IWarning = {
    title: "Account Not Confirmed by Arris",
    description: "If this is a dealer account move Pending Account Confirmation to 'No' and save. If this is a fabricator account, if the account is approved as a genuine fabricator, move the account to 'No' and save."
}

export const AccountActive: IWarning = {
    title: "Account Not Active",
    description: "If they account is not active the customer will not be able to login. Generally, all accounts should be active, even if their subscription is inactive. Move to active unless this account was deliberately moved to inactive by admin."
}

export const SubscriptionActive: IWarning = {
    title: "Subscription Not Active",
    description: "Customer needs an active subscription to use some features. If a subscription is not active, check the status of the subscription in Stripe. If the status is 'active' or 'trialing' in Stripe, move the Subscription Active to 'Yes'."
}

export const PastDue: IWarning = {
    title: "Past Due",
    description: "Payment is past due in Stripe. This means one or more payments have failed. After 3 attempts the status will move to unpaid. Past due status should not affect account operation."
}

export const Unpaid: IWarning = {
    title: "Unpaid",
    description: "Account is unpaid status. Check the status in the Stripe portal. Update payment method and try processing again."
}

export const Canceled: IWarning = {
    title: "Canceled",
    description: "Customer has canceled their account voluntarily. It can be reactivated by entering payment in the Stripe portal."
}

export const Paused: IWarning = {
    title: "Paused",
    description: "Customer has paused their subscription voluntarily."
}

export const CustomerId: IWarning = {
    title: "No Stripe Id",
    description: "No Stripe customer id on the account. This is required for the account to work. Check in Stripe if the customer has an associated account. If not an account should be manually created in Stripe, then payment should be initialized. If they have an account in Stripe, add the customer id and save."
}