import Paper from "@mui/material/Paper";
import { islandStyle } from "styles/style";
import DrawerMaterialList from "../list/drawer_material_list";
import { QUARTZ } from "values/material";
import { NATURAL_STONE } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { getMaterialTypeDisplay } from "values/material";

interface Props {
    current_index: number,
    setNavigation: (navigation: number) => void
}

export default function MaterialsDrawer({current_index, setNavigation}: Props) {
    return (
        <Paper sx={[islandStyle, {paddingTop: 1}]}>
            <DrawerMaterialList
                name={getMaterialTypeDisplay(QUARTZ)}
                type={QUARTZ}
                current_index={current_index}
                setNavigation={setNavigation}/>
            <DrawerMaterialList
                name={getMaterialTypeDisplay(NATURAL_STONE)}
                type={NATURAL_STONE}
                current_index={current_index}
                setNavigation={setNavigation}/>
            <DrawerMaterialList
                name={getMaterialTypeDisplay(ULTRA_COMPACT)}
                type={ULTRA_COMPACT}
                current_index={current_index}
                setNavigation={setNavigation}/>
        </Paper>
    );
}
