import { SelectItem } from "api/types/my_ui";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { textFieldStyle } from "styles/style";

interface Props {
    init_select: string | number,
    data_key: string,
    label: string,
    items: SelectItem[],
    update: (key: string, value: string) => void,
    readonly?: boolean
}

export default function SelectField({init_select, data_key, label, items, update, readonly}: Props){
    return (
        <TextField
            fullWidth
            disabled={readonly}
            select
            defaultValue={init_select}
            key={data_key}
            label={label}
            sx={textFieldStyle}
            onChange={(e) => update(data_key, e.target.value)}>
            {
                items.map(item => {
                    return (
                        <MenuItem key={item.key} value={item.value}>
                            {item.label}
                        </MenuItem>
                    )
                })
            }
        </TextField>
    )
}