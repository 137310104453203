import React from "react";
import { useState } from "react";
import { useAppDispatch } from 'api/hooks/apiHook';
import { createBug } from "api/actions/feedback";
import { CreateBug } from "api/types/feedback";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { LOW } from "values/feedback";
import { MEDIUM } from "values/feedback";
import { HIGH } from "values/feedback";
import { getPriorityChoiceDisplay } from "values/feedback";
import { empty_create_bug } from "values/empty/feedback";

export default function CreateBugComponent() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [create_bug, setCreateBug] = useState<CreateBug>(empty_create_bug);
    const [error, setError] = useState<boolean>(false);

    const handlePriority = (priority: string) => {
        setCreateBug({...create_bug, priority: priority});
    }

    const handleDescription = (description: string) => {
        setCreateBug({...create_bug, description: description});
        setError(false);
    }

    const handleCreate = () => {
        if(create_bug.description === ""){
            setError(true);
            return;
        }

        dispatch(createBug(create_bug));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setCreateBug(empty_create_bug);
        setError(false);
    }

    return(
        <React.Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                <DialogTitle sx={{ backgroundColor: primary_color, color: white_color, width: 500 }}>Create Feedback</DialogTitle>
                <DialogContent sx={dialogContentStyle}>
                    <FormControl fullWidth>
                        <FormHelperText error id="error">{error ? "Description must not be blank" : ""}</FormHelperText>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="medium"
                            variant="outlined"
                            select
                            defaultValue={LOW}
                            id="priority"
                            label="Priority"
                            onChange={(e) => handlePriority(e.target.value)}>
                                <MenuItem key={LOW} value={LOW}>
                                    {getPriorityChoiceDisplay(LOW)}
                                </MenuItem>
                                <MenuItem key={MEDIUM} value={MEDIUM}>
                                    {getPriorityChoiceDisplay(MEDIUM)}
                                </MenuItem>
                                <MenuItem key={HIGH} value={HIGH}>
                                    {getPriorityChoiceDisplay(HIGH)}
                                </MenuItem>
                        </TextField>
                        <TextField
                            sx={textFieldStyle}
                            fullWidth
                            size="medium"
                            variant="outlined"
                            id="description"
                            label="Description"
                            multiline
                            minRows={3}
                            error={error}
                            onChange={(e) => handleDescription(e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
