import { InsideDiagonalCorner } from "api/types/price";

export enum ActionType {
    GET_INSIDE_DIAGONAL_CORNERS_PENDING="GET_INSIDE_DIAGONAL_CORNERS_PENDING",
    GET_INSIDE_DIAGONAL_CORNERS_SUCCESS="GET_INSIDE_DIAGONAL_CORNERS_SUCCESS",
    GET_INSIDE_DIAGONAL_CORNERS_FAIL="GET_INSIDE_DIAGONAL_CORNERS_FAIL",
    CREATE_INSIDE_DIAGONAL_CORNER_PENDING = "CREATE_INSIDE_DIAGONAL_CORNER_PENDING",
    CREATE_INSIDE_DIAGONAL_CORNER_SUCCESS = "CREATE_INSIDE_DIAGONAL_CORNER_SUCCESS",
    CREATE_INSIDE_DIAGONAL_CORNER_FAIL = "CREATE_INSIDE_DIAGONAL_CORNER_FAIL",
    UPDATE_INSIDE_DIAGONAL_CORNER_PENDING = "UPDATE_INSIDE_DIAGONAL_CORNER_PENDING",
    UPDATE_INSIDE_DIAGONAL_CORNER_SUCCESS = "UPDATE_INSIDE_DIAGONAL_CORNER_SUCCESS",
    UPDATE_INSIDE_DIAGONAL_CORNER_FAIL = "UPDATE_INSIDE_DIAGONAL_CORNER_FAIL",
    UPDATE_LOCAL_INSIDE_DIAGONAL_CORNER = "UPDATE_LOCAL_INSIDE_DIAGONAL_CORNER"
  }

interface getInsideDiagonalCornersFail {
    type: ActionType.GET_INSIDE_DIAGONAL_CORNERS_FAIL,
    payload: string
}

interface getInsideDiagonalCornersSuccess {
    type: ActionType.GET_INSIDE_DIAGONAL_CORNERS_SUCCESS,
    payload: InsideDiagonalCorner[]
}

interface getInsideDiagonalCornersPending {
    type: ActionType.GET_INSIDE_DIAGONAL_CORNERS_PENDING
}
  
  interface createInsideDiagonalCornerPending {
    type: ActionType.CREATE_INSIDE_DIAGONAL_CORNER_PENDING,
  }
  
  interface createInsideDiagonalCornerSuccess {
    type: ActionType.CREATE_INSIDE_DIAGONAL_CORNER_SUCCESS,
    payload: InsideDiagonalCorner
  }
  
  interface createInsideDiagonalCornerFail {
    type: ActionType.CREATE_INSIDE_DIAGONAL_CORNER_FAIL,
    payload: string
  }
  
  interface updateInsideDiagonalCornerPending {
    type: ActionType.UPDATE_INSIDE_DIAGONAL_CORNER_PENDING,
  }
  
interface updateInsideDiagonalCornerSuccess {
    type: ActionType.UPDATE_INSIDE_DIAGONAL_CORNER_SUCCESS,
    payload: InsideDiagonalCorner
}
  
interface updateInsideDiagonalCornerFail {
    type: ActionType.UPDATE_INSIDE_DIAGONAL_CORNER_FAIL,
    payload: string
}

interface updateLocalInsideDiagonalCorner {
    type: ActionType.UPDATE_LOCAL_INSIDE_DIAGONAL_CORNER,
    payload: InsideDiagonalCorner
}

export type Action = 
    getInsideDiagonalCornersFail |
    getInsideDiagonalCornersPending |
    getInsideDiagonalCornersSuccess |
    createInsideDiagonalCornerFail |
    createInsideDiagonalCornerPending |
    createInsideDiagonalCornerSuccess |
    updateInsideDiagonalCornerFail |
    updateInsideDiagonalCornerPending |
    updateInsideDiagonalCornerSuccess |
    updateLocalInsideDiagonalCorner;