import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createSinkCutoutRange } from "api/actions/price";
import Name from "../shared/name";
import Button from "@mui/material/Button";
import Checkboxes from "./shared/checkboxes";
import Grid from "@mui/material/Grid";
import EditableList from "components/universal/editable_list";
import AddIcon from "@mui/icons-material/Add";
import SinkCutoutRangeEditableItem from "./sink_cutout_range";
import { CreateSinkCutoutRange } from "components/price/dialog/create";
import { SinkCutout } from "api/types/price";
import { getSinkTypes } from "values/values";
import { getRowSecondStyle } from "values/style";
import { getMaterialHeightDisplay } from "values/material";
import { empty_sink_cutout_range } from "values/empty/price";

interface Props {
    index: number,
    item: SinkCutout,
    start_update: (update_item: SinkCutout) => void,
    setLocked: (locked: boolean) => void
}

export default function SinkCutoutEditableItem({index, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [sink_cutout_update, setSinkCutoutUpdate] = useState<SinkCutout>(item);

    const saveBoolean = (key: string, value: boolean) => {
        const new_sink_cutout_update: SinkCutout = {...sink_cutout_update, [key]: value};
        setSinkCutoutUpdate(new_sink_cutout_update);
        start_update(new_sink_cutout_update);
    }

    return(
        <Grid container item sx={[{paddingLeft: 1, paddingTop: 1, paddingBottom: 1}, getRowSecondStyle(index)]} alignItems="center">
            <Name 
                bold={false}
                name={`${getMaterialHeightDisplay(item.material_thickness)} ${getSinkTypes(item.sink_type)}`} 
                width={8} />
            <Checkboxes
                grid_width={4}
                enabled={item.enabled ? item.enabled : false}
                setEnabled={saveBoolean}
                taxable={item.taxable ? item.taxable : false}
                setTaxable={saveBoolean} />
            <EditableList 
                setLocked={setLocked}
                items={item.ranges ? item.ranges : []}
                Item={SinkCutoutRangeEditableItem}
                start_update={start_update}/>
            <Grid item xs={12}>
                <Button
                    onClick={() => dispatch(createSinkCutoutRange({...empty_sink_cutout_range, measurement: 0, sink_cutout: item.id}))}
                    size="small"
                    endIcon={<AddIcon />}
                    sx={{paddingLeft: 0, paddingRight: 0}}>
                    Range
                </Button>
            </Grid>
            <Grid item xs={12}>
                <CreateSinkCutoutRange 
                    sink_cutout={item} />
            </Grid>
        </Grid>
    )
}
