import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { tableHeaderStyle } from 'styles/style';

export default function FeedbackHeader() {
    return(
        <Grid 
            sx={tableHeaderStyle}
            container 
            item 
            xs={12}>
            <Grid item xs={2}>
                <Typography variant="body2">
                    <b>Submitted</b>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">
                    <b>Type</b>
                </Typography>
            </Grid>
            <Grid item xs={3.5}>
                <Typography variant="body2">
                    <b>Description</b>
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    <b>Reported</b>
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    <b>Resolved</b>
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    <b>Status</b>
                </Typography>
            </Grid>
        </Grid>
    )
}
