import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/price";
import { ActionType } from "api/action_types/price";
import { AxiosError } from "axios";
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { Waterfall } from "api/types/price";
import { url_prefix } from "settings";

const waterfall_url: string = "waterfall";

export const getWaterfalls = (price_list_id?: string | number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.GET_WATERFALLS_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    let url = `${url_prefix}${waterfall_url}/?`;

    if (price_list_id) {
      url += `price_list=${price_list_id}&`;
    }

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_WATERFALLS_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.GET_WATERFALLS_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const createWaterfall = (waterfall: Waterfall) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.CREATE_WATERFALL_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${waterfall_url}/`;

    try {
      const { data } = await axios.post(url, waterfall, config);

      dispatch({
        type: ActionType.CREATE_WATERFALL_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.CREATE_WATERFALL_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }

    }
  }
}

export const updateWaterfall = (waterfall: Waterfall, id: number) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.UPDATE_WATERFALL_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${waterfall_url}/${id}/`;

    try {
      const { data } = await axios.patch(url, waterfall, config);

      dispatch({
        type: ActionType.UPDATE_WATERFALL_SUCCESS,
        payload: data
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.UPDATE_WATERFALL_FAIL,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}

export const updateLocalWaterfall = (waterfall: Waterfall) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_LOCAL_WATERFALL,
      payload: waterfall
    });
  }
}
