import { useState } from "react";
import TextField from "@mui/material/TextField";
import { textFieldStyle } from "styles/style";

interface Props {
    init_value: number,
    data_key: string,
    label: string,
    update: (key: string, value: number) => void
}

export default function NumberField({init_value, data_key, label, update}: Props){
    const [has_error, setHasError] = useState<boolean>(false);
    
    const verify = (e: string) => {
        if(!isNaN(Number(e))){
            update(data_key, Number(e));
            setHasError(false);
        }
        else{
            setHasError(true);
        }
    }

    return (
        <TextField 
            sx={textFieldStyle}
            type="number"
            error={has_error}
            defaultValue={init_value}
            label={label}
            id={data_key}
            onChange={(e) => verify(e.target.value)} />
    )
}