import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getFaucetCutouts } from "api/actions/price/faucet_cutout";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateFaucetCutout } from "../dialog/create";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { FaucetCutout } from "api/types/price";
import { FAUCET_CUTOUT } from "api/types/update";
import FaucetCutouttItem from "../row/faucet_cutout";

interface Props {
    start_update: (type: number, data: FaucetCutout) => void
}

export default function PriceListFaucetCutouts({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { faucet_cutouts } = useAppSelector((state) => state.price);

    const handle_update = (update_item: FaucetCutout) => {
        start_update(FAUCET_CUTOUT, update_item);
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getFaucetCutouts(Number(id)));
        }
    }, [id, dispatch]);

    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Faucet Cutouts"
                    endProps={
                        <CreateFaucetCutout />} />
                <Header 
                    items={[
                        {text: "Height", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={faucet_cutouts}
                    Item={FaucetCutouttItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}
