import { CSSProperties } from "react";
import { calculateRgba } from "./helpers/colors";
import { parseLengthAndUnit, cssValue } from "./helpers/unit_converter";
import { LoaderSizeProps } from "./helpers/props";
import { createAnimation } from "./helpers/animation";

function HashLoader({
    loading = true,
    fixed = false,
    text = "Saving",
    color_1 = "#000000",
    color_2 = "#000000",
    color_3 = "#000000",
    color_4 = "#000000",
    speedMultiplier = 1,
    cssOverride = {},
    size = 50,
    font_size = 20,
    ...additionalprops
}: LoaderSizeProps): JSX.Element | null {
    const { value, unit } = parseLengthAndUnit(size);

    const wrapper: CSSProperties = {
        display: "inherit",
        position: "relative",
        width: cssValue(size),
        height: cssValue(size),
        transform: "rotate(165deg)",
        ...cssOverride,
    };
    const thickness = value / 5;
    const lat = (value - thickness) / 2;
    const offset = lat - thickness;
    const color_1_value = calculateRgba(color_1, 0.75);
    const color_2_value = calculateRgba(color_2, 0.75);
    const color_3_value = calculateRgba(color_3, 0.75);
    const color_4_value = calculateRgba(color_4, 0.75);

    const before = createAnimation(
        "HashLoader",
        `0% {width: ${thickness}px; box-shadow: ${lat}px ${-offset}px ${color_1_value}, ${-lat}px ${offset}px ${color_1_value}}
        35% {width: ${cssValue(size)}; box-shadow: 0 ${-offset}px ${color_2_value}, 0 ${offset}px ${color_2_value}}
        70% {width: ${thickness}px; box-shadow: ${-lat}px ${-offset}px ${color_3_value}, ${lat}px ${offset}px ${color_3_value}}
        100% {box-shadow: ${lat}px ${-offset}px ${color_4_value}, ${-lat}px ${offset}px ${color_4_value}}`,
        "before"
    );

    const after = createAnimation(
        "HashLoader",
        `0% {height: ${thickness}px; box-shadow: ${offset}px ${lat}px ${color_1}, ${-offset}px ${-lat}px ${color_1}}
        35% {height: ${cssValue(size)}; box-shadow: ${offset}px 0 ${color_2}, ${-offset}px 0 ${color_2}}
        70% {height: ${thickness}px; box-shadow: ${offset}px ${-lat}px ${color_3}, ${-offset}px ${lat}px ${color_3}}
        100% {box-shadow: ${offset}px ${lat}px ${color_4}, ${-offset}px ${-lat}px ${color_4}}`,
        "after"
    );

    const textBefore = createAnimation(
        "HashLoaderText",
        `0% {color: ${color_1}}
        35% {color: ${color_2}}
        70% {color: ${color_3}}
        100% {color: ${color_4}}`,
        "before"
    );

    const textAfter = createAnimation(
        "HashLoaderText",
        `0% {color: ${color_1}}
        35% {color: ${color_2}}
        70% {color: ${color_3}}
        100% {color: ${color_4}}`,
        "after"
    );

    const style = (i: number): CSSProperties => {
        return {
            position: "absolute",
            top: "50%",
            left: "50%",
            display: "block",
            width: `${value / 5}${unit}`,
            height: `${value / 5}${unit}`,
            borderRadius: `${value / 10}${unit}`,
            transform: "translate(-50%, -50%)",
            animationFillMode: "none",
            animation: `${i === 1 ? before : after} ${2 / speedMultiplier}s infinite`,
        };
    };

    const textStyle = (i: number): CSSProperties => {
        return {
            marginRight: 15,
            fontSize: font_size,
            fontFamily: "Roboto",
            animation: `${i === 1 ? textBefore : textAfter} ${2 / speedMultiplier}s infinite`
        }
    }

    if (!loading) {
        return null;
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: fixed ? "fixed" : "static",
            zIndex: 8
        }}>
            <div style={textStyle(1)}>{text}</div>
            <span style={wrapper} {...additionalprops}>
            <span style={style(1)} />
            <span style={style(2)} />
            </span>
        </div>
    );
}

export default HashLoader;