import axios from 'axios';
import { Dispatch } from 'redux';
import { RootState } from 'api/reducers';
import { Action } from 'api/action_types/feedback';
import { ActionType } from 'api/action_types/feedback';
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { CreateBug } from 'api/types/feedback';
import { UpdateBug } from 'api/types/feedback';
import { BugFilter } from 'api/types/feedback';
import { url_prefix } from 'settings';

const bug_url: string = 'bug';

export const getBugs = (bug_filter?: BugFilter) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.BUG_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${bug_url}?`; 

        if(bug_filter){
            if(bug_filter.date_added_after){
                url += `date_added_after=${bug_filter.date_added_after}&`;
            }
            if(bug_filter.date_added_before){
                url += `date_added_before=${bug_filter.date_added_before}&`;
            }
            if(bug_filter.date_resolved_after){
                url += `date_resolved_after=${bug_filter.date_resolved_after}&`;
            }
            if(bug_filter.date_resolved_before){
                url += `date_resolved_before=${bug_filter.date_resolved_before}&`;
            }
            if(bug_filter.description){
                url += `description=${bug_filter.description}&`;
            }
            if(bug_filter.priority){
                url += `priority=${bug_filter.priority}&`;
            }
            if(bug_filter.resolved){
                url += `resolved=${bug_filter.resolved}&`;
            }
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_BUGS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.BUG_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const getBug = (id: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.BUG_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bug_url}/${id}/`;

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_BUG_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.BUG_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const createBug = (bug: CreateBug) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
        const { user } = getState().authentication;

        dispatch({
            type: ActionType.BUG_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bug_url}/`;

        try
        {
            const {data} = await axios.post(url, {...bug, reported_by: user.id}, config);

            dispatch({
                type: ActionType.CREATE_BUG_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.BUG_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const updateBug = (bug: UpdateBug, id: number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.BUG_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        const url = `${url_prefix}${bug_url}/${id}/`;

        try
        {
            const {data} = await axios.patch(url, bug, config);

            dispatch({
                type: ActionType.UPDATE_BUG_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.BUG_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                } 
            }
            
        }
    }
}

export const updateLocalBug = (bug_update: UpdateBug) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_BUG,
            payload: bug_update
        });
    }
}