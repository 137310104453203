import { CSSProperties } from "react";
import { cssValue } from "./helpers/unit_converter";
import { LoaderSizeProps } from "./helpers/props";
import { createAnimation } from "./helpers/animation";

function PuffLoader({
    loading = true,
    speedMultiplier = 1,
    color_1 = "#000000",
    color_2 = "#000000",
    color_3 = "#000000",
    color_4 = "#000000",
    text = "Loading",
    cssOverride = {},
    size = 60,
    font_size = 17,
    ...additionalprops
}: LoaderSizeProps): JSX.Element | null {
    const wrapper: CSSProperties = {
        display: "inherit",
        position: "relative",
        width: cssValue(size),
        height: cssValue(size),
        ...cssOverride,
    };

    const puff = [
        createAnimation(
            "PuffLoader",
            `0% {opacity: 1}
            0% {transform: scale(0)}
            0% {color: ${color_2}}
            35% {color: ${color_2}}
            70% {color: ${color_3}}
            100% {color:  ${color_3}}
            100% {transform: scale(1.0)}
            100% {opacity: 0}`,
            "puff-1"
        )
    ];

    const textBefore = createAnimation(
        "HashLoaderText",
        `0% {color: ${color_2}}
        35% {color: ${color_2}}
        70% {color: ${color_3}}
        100% {color: ${color_3}}`,
        "before"
    );

    const style = (i: number): CSSProperties => {
        return {
            position: "absolute",
            height: cssValue(size),
            width: cssValue(size),
            border: `thick solid`,
            borderRadius: "50%",
            opacity: "1",
            top: "0",
            left: "0",
            animationFillMode: "none",
            animation:  `${puff[0]} ${2 / speedMultiplier}s infinite`,
            animationDuration: `${2 / speedMultiplier}s`,
            animationIterationCount: "infinite",
            animationTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.3, 0.61, 0.355, 1)",
            animationDelay: i === 1 ? "-1s" : "0s",
        };
    };

    const textStyle = (i: number): CSSProperties => {
        return {
            paddingTop: 12,
            paddingLeft: 3.5,
            fontSize: font_size,
            fontFamily: "Roboto",
            animation: `${textBefore} ${2 / speedMultiplier}s infinite`
        }
    }

    if(!loading) {
        return null;
    }

    return (
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <span style={wrapper} {...additionalprops}>
                <span style={style(1)} />
                <span style={style(2)} />
            </span>
            <div style={textStyle(1)}>{text}</div>
        </div>
    );
}

export default PuffLoader;