import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { InstallationCharge } from "api/types/price";

interface Props {
    index: number,
    item: InstallationCharge,
    start_update: (update_item: InstallationCharge) => void,
    setLocked: (locked: boolean) => void
}

export default function InstallationChargeEditableItem({index, item, start_update, setLocked}: Props) {
    const [installation_charge_update, setInstallationChargeUpdate] = useState<InstallationCharge>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_installation_charge: InstallationCharge = {...installation_charge_update, [key]: Number(value)};
            setInstallationChargeUpdate(new_installation_charge);
            start_update(new_installation_charge);
        }
    }

    const saveBoolean = (key: string, value: boolean) => {
        const new_installation_charge: InstallationCharge = {...installation_charge_update, [key]: value};
        setInstallationChargeUpdate(new_installation_charge);
        start_update(new_installation_charge);
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$/SQ FT"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
