import React from "react";
import { useState } from "react";
import { ComponentType } from "react";

interface Props {
    items: any[],
    Item: ComponentType<any>,
    start_update: (update_item: any, type?: number) => void,
    last?: boolean,
    setLocked?: (locked: boolean) => void
}

export default function EditableList({items, Item, start_update, last=false, setLocked=()=> void 0}: Props) {
    const [hovered, setHovered] = useState<number>(0);

    return(
        <React.Fragment>
            {
                items.map((item, index) => {
                    return (
                        <Item 
                            key={index}
                            hovered={hovered}
                            setHovered={setHovered}
                            start_update={start_update}
                            index={index}
                            item={item}
                            last={last}
                            setLocked={setLocked} />
                    )
                })
            }
        </React.Fragment>
        
    )
}
