/// HOOKS ///
import React from "react";
import { useState } from "react";
/// ACTIONS ///
import { createColors } from "api/actions/material/color";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
/// TYPES ///
import { Color } from "api/types/material";
import { ColorError } from "api/types/price";
import { Material } from "api/types/material";
/// MUI ///
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography"
/// ICONS ///
import AddCircleIcon from "@mui/icons-material/AddCircle";
/// COMPONENTS ///
import CreateColorItem from "./color";
/// STYLES ///
import { dialogContentStyle } from "styles/style";
/// COLORS ///
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

interface Props {
  material: Material
}

export default function CreateColors({ material }: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [colors, setColors] = useState<Color[]>([]);
  const [color_errors, setColorErrors] = useState<ColorError[]>([]);

  const handleCreate = () => {
    let error: boolean = false;
    const color_errors: ColorError[] = [];
    for (let i = 0; i < colors.length; i++) {
      const color_error: ColorError = {};
      if (!colors[i].name || colors[i].name === "") {
        error = true;
        color_error.name_error = "Must enter a name";
        color_error.name_has_error = true;
      }
      if (!colors[i]?.width || !(colors[i].width > 0)) {
        error = true;
        color_error.width_error = "Must have a width greater than 0";
        color_error.width_has_error = true;
      }
      if (!colors[i]?.length || !(colors[i].length > 0)) {
        error = true;
        color_error.length_error = "Must have a length greater than 0";
        color_error.length_has_error = true;
      }
      if (!colors[i]?.price || !(colors[i].price > 0)) {
        error = true;
        color_error.price_error = "Must have a price greater than 0";
        color_error.price_has_error = true;
      }
      color_errors.push(color_error);
    }

    setColorErrors(color_errors);

    if (error) {
      return;
    }

    dispatch(createColors(colors));
    handleClose();
  }

  const createColor = () => {
    setColors([...colors, { name: "", width: 0, length: 0, price: 0, material: material?.id, enabled: true }]);
    setColorErrors([...color_errors, {}]);
  }

  const updateColor = (color: Color, index: number) => {
    setColors([...colors.slice(0, index), color, ...colors.slice(index + 1)]);
  }

  const handleClose = () => {
    setOpen(false);
    setColors([]);
    setColorErrors([]);
  }

  return (
    <React.Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <AddCircleIcon
          color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>Create Material</DialogTitle>
        <DialogContent sx={dialogContentStyle}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <IconButton onClick={createColor}>
                  <AddCircleIcon
                    color="primary" />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <b>Name</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                <b>Length</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                <b>Width</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                <b>Price</b>
              </Typography>
            </Grid>
            {
              colors.map((color: Color, index: number) => <CreateColorItem color={color} color_error={color_errors?.[index] ?? null} index={index} updateColor={updateColor} />)
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
