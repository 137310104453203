import { useAppSelector } from "api/hooks/apiHook";
import Stack from "@mui/material/Stack";
import AccountWarning from "./warning";
import { EmailConfirmation } from "values/text/account";
import { PaymentInitialize } from "values/text/account";
import { PendingAccountConfirmation } from "values/text/account";
import { AccountActive } from "values/text/account";
import { SubscriptionActive } from "values/text/account";
import { PastDue } from "values/text/account";
import { Unpaid } from "values/text/account";
import { Canceled } from "values/text/account";
import { Paused } from "values/text/account";
import { CustomerId } from "values/text/account";

export default function AccountWarnings(){
    const { account } = useAppSelector((state) => state.account);

    return (
        <Stack sx={{marginBottom: 2}}>
            <AccountWarning 
                active={!account.email_confirmed} 
                title={EmailConfirmation.title}
                description={EmailConfirmation.description} />
            <AccountWarning 
                active={!account.payment_initialized} 
                title={PaymentInitialize.title}
                description={PaymentInitialize.description} />
            <AccountWarning 
                active={account.pending_account_confirmation} 
                title={PendingAccountConfirmation.title}
                description={PendingAccountConfirmation.description} />
            <AccountWarning 
                active={!account.is_active} 
                title={AccountActive.title}
                description={AccountActive.description} />
            <AccountWarning 
                active={!account.subscription_active} 
                title={SubscriptionActive.title}
                description={SubscriptionActive.description} />
            <AccountWarning 
                active={account.past_due} 
                title={PastDue.title}
                description={PastDue.description} />
            <AccountWarning 
                active={account.unpaid} 
                title={Unpaid.title}
                description={Unpaid.description} />
            <AccountWarning 
                active={account.canceled} 
                title={Canceled.title}
                description={Canceled.description} />
            <AccountWarning 
                active={account.paused} 
                title={Paused.title}
                description={Paused.description} />
            <AccountWarning 
                active={!account.customer_id || account.customer_id === ""} 
                title={CustomerId.title}
                description={CustomerId.description} />
        </Stack>
    )
}