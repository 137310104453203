import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { createMiterRange } from "api/actions/price";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import EditableList from "components/universal/editable_list";
import MiterRangeItem from "./miter_range";
import BasicRangeEditableItem from "./shared/basic_range";
import AddIcon from "@mui/icons-material/Add";
import { CreateMiterRange } from "components/price/dialog/create";
import { Miter } from "api/types/price";
import { getRowSecondStyle } from "values/style";
import { empty_miter_range } from "values/empty/price";

interface Props {
    index: number,
    item: Miter,
    start_update: (update_item: Miter) => void,
    setLocked: (locked: boolean) => void
}

export default function MiterEditableItem({index, item, start_update, setLocked}: Props) {
    const dispatch = useAppDispatch();
    const [miter_update, setMiterUpdate] = useState<Miter>(item);

    const saveBoolean = (key: string, value: boolean) => {
        const new_miter: Miter = {...miter_update, [key]: value};
        setMiterUpdate(new_miter);
        start_update(new_miter);
    }

    return(
        <Grid container item sx={[{paddingLeft: 1, paddingTop: 1, paddingBottom: 1}, getRowSecondStyle(index)]} alignItems="center">
            <BasicRangeEditableItem
                material_thickness={item.material_thickness ? item.material_thickness : ""}
                enabled={item.enabled ? item.enabled : false}
                taxable={item.enabled ? item.enabled : false}
                saveBoolean={saveBoolean} />
            <EditableList 
                setLocked={setLocked}
                items={item.ranges ? item.ranges : []}
                Item={MiterRangeItem}
                start_update={start_update}/>
            <Grid item xs={12}>
                <Button 
                    onClick={() => dispatch(createMiterRange({...empty_miter_range, height: 0, miter: item.id}))}
                    size="small"
                    endIcon={<AddIcon />} 
                    sx={{paddingLeft: 0, paddingRight: 0}}>
                    Range
                </Button>
            </Grid>
            <Grid item xs={12}>
                <CreateMiterRange 
                    miter={item} />
            </Grid>
        </Grid>
    )
}
