export const slider_minimum: number = -50;
export const slider_maximum: number = 50;
export const inch_to_pixel: number = 4;
export const SLAB_ZOOM: number = 1;

export const tiny_textfield_height: number = 10;

export const SQ_FT: string = "SQ";
export const LIN_FT: string = "LI";
export const FLAT: string = "FL";

export const NONE: string = "NO";
export const DOUBLE: string = "DO";
export const TRIPLE: string = "TR";

export const NEAREST_CENT: string = "C";
export const NEAREST_DOLLAR: string  = "D";

export const INCHES: string = "IN";
export const MILLIMETERS: string = "MI";

export const CIRCLE: string = "CI";
export const RECTANGLE: string = "RE";
export const OVAL: string = "OV";
export const SIXTY_OVER_FORTY: string = "64";
export const FORTY_OVER_SIXTY: string = "46";
export const SEVENTY_OVER_THIRTY: string = "73";
export const THIRTY_OVER_SEVENTY: string = "37";

export const UNDERMOUNT: string = "UN";
export const DROP_IN: string = "DI";
export const FARMER: string = "FA";

export const FINISHED: string = "F";
export const UNFINISHED: string = "U";
export const SPLASH: string = "S";
export const APPLIANCE: string = "A";

export const NO_BUMP: string = "NO";
export const BUMP_OUT: string = "BO";
export const BUMP_IN: string = "BI";
export const BUMP_OUT_ARC: string = "OA";
export const BUMP_IN_ARC: string = "IN";
export const FULL_ARC: string = "FA";

export const LEFT: string = "L";
export const CENTER: string = "C";
export const RIGHT: string = "R";

export const BEVEL: string = "BE";
export const BULLNOSE: string = "BN";
export const COVE: string = "CO";
export const DEMI_BULLNOSE: string = "DB";
export const DOUBLE_BEVEL: string = "2B";
export const DUPONT: string = "DU";
export const EASED: string = "EA";
export const HALF_BULLNOSE: string = "HB";
export const MITER: string = "MI";
export const SPECIALTY: string = "SP";
export const SQUARE: string = "SQ";

export function getRateTypeDisplay(rate_type: string | undefined){
    if(!rate_type){
        return "";
    }
    
    switch(rate_type){
        case SQ_FT:
            return "$/sq ft";
        case LIN_FT:
            return "$/lin ft";
        case FLAT:
            return "$";
        default:
            return "";
    }
}

export function getRoundQuotesDisplay(round_quotes: string | undefined){
    if(!round_quotes){
        return "";
    }
    
    switch(round_quotes){
        case NEAREST_CENT:
            return "0.01";
        case NEAREST_DOLLAR:
            return "1.00";
        default:
            return "Unknown";
    }
}

export function getMeasurementType(measurement_type: string | undefined){

    if(measurement_type === undefined || measurement_type === null){
        return "Unknown";
    }

    switch(measurement_type){
        case INCHES:
            return "Inches";
        case MILLIMETERS:
            return "Millimeters";
        default:
            return "Unknown";
    }
}

export function getLamination(lamination: string | undefined){
    if(lamination === undefined || lamination === null){
        return "Unknown";
    }

    switch(lamination){
        case NONE:
            return "None";
        case DOUBLE:
            return "Double";
        case TRIPLE:
            return "Triple";
        default:
            return "Unknown";
    }
}

export function getSinkTypes(sink_type: string | undefined){
    if(sink_type === undefined || sink_type === null){
        return "Unknown";
    }

    switch(sink_type){
        case UNDERMOUNT:
            return "Undermount";
        case DROP_IN:
            return "Drop-in";
        case FARMER:
            return "Farmer";
        default:
            return "Unknown";
    }
}

export function getCutoutShapes(cutout_shape: string | undefined){
    if(cutout_shape === undefined || cutout_shape === null){
        return "Unknown";
    }

    switch(cutout_shape){
        case CIRCLE:
            return "Circle";
        case RECTANGLE:
            return "Rectangle";
        case OVAL:
            return "Oval";
        case DOUBLE:
            return "Double";
        case SIXTY_OVER_FORTY:
            return "60/40";
        case FORTY_OVER_SIXTY:
            return "40/60";
        case SEVENTY_OVER_THIRTY:
            return "70/30";
        case THIRTY_OVER_SEVENTY:
            return "30/70";
        default:
            return "Unknown";
    }
}

export function getBump(bump: string | undefined){
    if(!bump){
        return "Unknown";
    }

    switch(bump){
        case NO_BUMP: 
            return "No Bump";
        case BUMP_OUT:
            return "Bump Out";
        case BUMP_IN: 
            return "Bump In";
        case BUMP_OUT_ARC:
            return "Bump Out Arc";
        case BUMP_IN_ARC:
            return "Bump In Arc";
        case FULL_ARC:
            return "Full Arc";
        default:
            return "Unknown";
    }
}

export function getFinishedEdgeType(finished_edge_type: string | undefined){
    if(!finished_edge_type){
        return "Unknown";
    }

    switch(finished_edge_type){
        case BEVEL:
            return "Bevel";
        case BULLNOSE:
            return "Bullnose";
        case COVE:
            return "Cove";
        case DEMI_BULLNOSE:
            return "Demi Bullnose";
        case DOUBLE_BEVEL:
            return "Double Bevel";
        case DUPONT:
            return "DuPont";
        case EASED:
            return "Eased";
        case HALF_BULLNOSE:
            return "Half Bullnose";
        case MITER:
            return "Miter";
        case SPECIALTY:
            return "Specialty";
        case SQUARE:
            return "Square";
        default:
            return "Unknown";
    }
}

