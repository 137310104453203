import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "api/hooks/apiHook";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import ViewListIcon from "@mui/icons-material/ViewList";
import LanguageIcon from "@mui/icons-material/Language";
import CategoryIcon from "@mui/icons-material/Category";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TextureIcon from "@mui/icons-material/Texture";
import CommentIcon from "@mui/icons-material/Comment";
import BugReportIcon from "@mui/icons-material/BugReport";
import Title from "components/universal/title";
import TitleSpacer from "components/universal/title_spacer";
import { mainContentAreaStyle } from "styles/style";
import { islandStyle } from "styles/style";
import { rowItemStyle } from "styles/style";
import { dashboardItemIconStyle } from "styles/dashboard";
import { dashboardItemSvgIconStyle } from "styles/dashboard";
import { dashboardItemTitleStyle } from "styles/dashboard";
import { dashboardItemSubtitleStyle } from "styles/dashboard";
import { login_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
import { region_url } from "components/navigation/endpoints";
import { manage_suppliers_url } from "components/navigation/endpoints";
import { material_import_url } from "components/navigation/endpoints";
import { feedback_url } from "components/navigation/endpoints";
import { bug_url } from "components/navigation/endpoints";
import { account_url } from "components/navigation/endpoints";
import { create_account_url } from "components/navigation/endpoints";

export default function Home() {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authentication);

    if(!user.id){
        navigate(login_url, {replace: false});
    }

    return(
        <Fragment>
        {
            user.id ?
            <Box sx={mainContentAreaStyle}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Title 
                            title="Dashboard" 
                            update_pending={false}
                            start_times={[]}
                            update={() => void 0}
                            endProps={<TitleSpacer />} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(material_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <TextureIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Materials
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Add & Update Materials
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(material_import_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <CategoryIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Import Materials
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Import Materials From Spreadsheets
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(price_list_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <ViewListIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Price Lists
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Admin Price Lists
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(create_account_url, {replace: false})} >
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <PersonAddIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Create Account
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Create New Account
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(account_url, {replace: false})} >
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <GroupIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Manage Accounts
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Update Existing Account
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(manage_suppliers_url, {replace: false})} >
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <ManageAccountsIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Manage Suppliers
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Assign Suppliers To Products
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(region_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <LanguageIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Manage Regions
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        Create & Update Regions
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(feedback_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <CommentIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Feedback
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        View & Update Feedback Status
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={3} onClick={(e) => navigate(bug_url, {replace: false})}>
                        <Paper sx={[rowItemStyle, islandStyle]}>
                            <Stack direction="row" alignItems="center">
                                <Icon sx={dashboardItemIconStyle}>
                                    <BugReportIcon sx={dashboardItemSvgIconStyle} />
                                </Icon>
                                <Stack sx={{marginLeft: 2}}>
                                    <Typography variant="body1" sx={dashboardItemTitleStyle}>
                                        Bug
                                    </Typography>
                                    <Typography variant="body2" sx={dashboardItemSubtitleStyle}>
                                        View & Update Bug Status
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Box> :
            null
        }
        </Fragment>
    )
}
