import { BumpOut } from "api/types/price";

export enum ActionType {
    GET_BUMP_OUTS_PENDING="GET_BUMP_OUTS_PENDING",
    GET_BUMP_OUTS_SUCCESS="GET_BUMP_OUTS_SUCCESS",
    GET_BUMP_OUTS_FAIL="GET_BUMP_OUTS_FAIL",
    CREATE_BUMP_OUT_PENDING = "CREATE_BUMP_OUT_PENDING",
    CREATE_BUMP_OUT_SUCCESS = "CREATE_BUMP_OUT_SUCCESS",
    CREATE_BUMP_OUT_FAIL = "CREATE_BUMP_OUT_FAIL",
    UPDATE_BUMP_OUT_PENDING = "UPDATE_BUMP_OUT_PENDING",
    UPDATE_BUMP_OUT_SUCCESS = "UPDATE_BUMP_OUT_SUCCESS",
    UPDATE_BUMP_OUT_FAIL = "UPDATE_BUMP_OUT_FAIL",
    UPDATE_LOCAL_BUMP_OUT = "UPDATE_LOCAL_BUMP_OUT"
}

interface getBumpOutsFail {
    type: ActionType.GET_BUMP_OUTS_FAIL,
    payload: string
}

interface getBumpOutsSuccess {
    type: ActionType.GET_BUMP_OUTS_SUCCESS,
    payload: BumpOut[]
}

interface getBumpOutsPending {
    type: ActionType.GET_BUMP_OUTS_PENDING
}

interface createBumpOutPending {
    type: ActionType.CREATE_BUMP_OUT_PENDING,
}

interface createBumpOutSuccess {
    type: ActionType.CREATE_BUMP_OUT_SUCCESS,
    payload: BumpOut
}

interface createBumpOutFail {
    type: ActionType.CREATE_BUMP_OUT_FAIL,
    payload: string
}

interface updateBumpOutPending {
    type: ActionType.UPDATE_BUMP_OUT_PENDING,
}

interface updateBumpOutSuccess {
    type: ActionType.UPDATE_BUMP_OUT_SUCCESS,
    payload: BumpOut
}

interface updateBumpOutFail {
    type: ActionType.UPDATE_BUMP_OUT_FAIL,
    payload: string
}

interface updateLocalBumpOut {
    type: ActionType.UPDATE_LOCAL_BUMP_OUT,
    payload: BumpOut
}

export type Action =
    getBumpOutsFail |
    getBumpOutsPending |
    getBumpOutsSuccess |
    createBumpOutPending | 
    createBumpOutSuccess | 
    createBumpOutFail | 
    updateBumpOutPending | 
    updateBumpOutSuccess | 
    updateBumpOutFail |
    updateLocalBumpOut;
