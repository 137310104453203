import { SubRegion } from "api/types/region";
import { Region } from "api/types/region";

export enum ActionType {
  GET_SUB_REGIONS = "GET_SUB_REGIONS",
  GET_SUB_REGION = "GET_SUB_REGION",
  GET_REGIONS = "GET_REGIONS_SUCCESS",
  GET_REGION = "GET_REGION_SUCCESS",
  CREATE_REGION = "CREATE_REGION_SUCCESS",
  UPDATE_REGION = "UPDATE_REGION_SUCCESS",
  DELETE_REGION = "DELETE_REGION_SUCCESS",
  REGION_PENDING = "REGION_PENDING",
  REGION_ERROR = "REGION_ERROR"
}

interface IGetSubRegions {
  type: ActionType.GET_SUB_REGIONS,
  sub_regions: SubRegion[]
}

interface IGetSubRegion {
  type: ActionType.GET_SUB_REGION,
  sub_region: SubRegion
}

interface IGetRegions {
  type: ActionType.GET_REGIONS,
  regions: Region[]
}

interface IGetRegion {
  type: ActionType.GET_REGION,
  region: Region
}

interface ICreateRegion {
  type: ActionType.CREATE_REGION,
  region: Region
}

interface IUpdateRegion {
  type: ActionType.UPDATE_REGION,
  region: Region
}

interface IDeleteRegion {
  type: ActionType.DELETE_REGION,
  id: number
}

interface IRegionPending {
  type: ActionType.REGION_PENDING
}

interface IRegionError {
  type: ActionType.REGION_ERROR,
  error: string
}

export type Action =
  IGetSubRegions |
  IGetSubRegion |
  IGetRegions |
  IGetRegion |
  ICreateRegion |
  IUpdateRegion |
  IDeleteRegion |
  IRegionPending |
  IRegionError;

