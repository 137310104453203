import { UploadInstance } from 'api/types/material_import';
import { Action } from "api/action_types/material_import";
import { ActionType } from 'api/action_types/material_import';
import { empty_upload_instance } from 'values/empty/material_import';

interface State {
  uploads: UploadInstance[],
  upload: UploadInstance,
  loading: boolean,
  error: string | null
}

const initialState = {
  uploads: [],
  upload: empty_upload_instance,
  loading: false,
  error: null
}

export const materialImportReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_UPLOAD_INSTANCES_SUCCESS:
      return {
        ...state,
        uploads: action.payload,
        loading: false,
        error: ''
      }
    case ActionType.GET_UPLOAD_INSTANCE_SUCCESS:
      return {
        ...state,
        upload: action.payload,
        loading: false,
        error: ''
      }
    case ActionType.MATERIAL_UPLOAD_SUCCESS:
      return {
        ...state,
        uploads: [action.payload, ...state.uploads],
        loading: false,
        error: ''
      }
    case ActionType.UPLOAD_INSTANCE_PENDING:
      return {
        ...state,
        loading: true
      }
    case ActionType.UPLOAD_INSTANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}



