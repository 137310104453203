import { Splash } from "api/types/price";
import { SplashRange } from "api/types/price";

export enum ActionType { 
    GET_SPLASHES_PENDING="GET_SPLASHES_PENDING",
    GET_SPLASHES_SUCCESS="GET_SPLASHES_SUCCESS",
    GET_SPLASHES_FAIL="GET_SPLASHES_FAIL",
    CREATE_SPLASH_PENDING = "CREATE_SPLASH_PENDING",
    CREATE_SPLASH_SUCCESS = "CREATE_SPLASH_SUCCESS",
    CREATE_SPLASH_FAIL = "CREATE_SPLASH_FAIL",
    UPDATE_SPLASH_PENDING = "UPDATE_SPLASH_PENDING",
    UPDATE_SPLASH_SUCCESS = "UPDATE_SPLASH_SUCCESS",
    UPDATE_SPLASH_FAIL = "UPDATE_SPLASH_FAIL",
    UPDATE_LOCAL_SPLASH = "UPDATE_LOCAL_SPLASH",
    CREATE_SPLASH_RANGE_PENDING = "CREATE_SPLASH_RANGE_PENDING",
    CREATE_SPLASH_RANGE_SUCCESS = "CREATE_SPLASH_RANGE_SUCCESS",
    CREATE_SPLASH_RANGE_FAIL = "CREATE_SPLASH_RANGE_FAIL",
    UPDATE_SPLASH_RANGE_PENDING = "UPDATE_SPLASH_RANGE_PENDING",
    UPDATE_SPLASH_RANGE_SUCCESS = "UPDATE_SPLASH_RANGE_SUCCESS",
    UPDATE_SPLASH_RANGE_FAIL = "UPDATE_SPLASH_RANGE_FAIL",
    UPDATE_LOCAL_SPLASH_RANGE = "UPDATE_LOCAL_SPLASH_RANGE",
    DELETE_SPLASH_RANGE_PENDING = "DELETE_SPLASH_RANGE_PENDING",
    DELETE_SPLASH_RANGE_SUCCESS = "DELETE_SPLASH_RANGE_SUCCESS",
    DELETE_SPLASH_RANGE_FAIL = "DELETE_SPLASH_RANGE_FAIL"
}

interface getSplashesFail {
    type: ActionType.GET_SPLASHES_FAIL,
    payload: string
}

interface getSplashesSuccess {
    type: ActionType.GET_SPLASHES_SUCCESS,
    payload: Splash[]
}

interface getSplashesPending {
    type: ActionType.GET_SPLASHES_PENDING
}

interface createSplashFail {
    type: ActionType.CREATE_SPLASH_FAIL,
    payload: string
}

interface createSplashSuccess {
    type: ActionType.CREATE_SPLASH_SUCCESS,
    payload: Splash[]
}

interface createSplashPending {
    type: ActionType.CREATE_SPLASH_PENDING
}

interface updateSplashFail {
    type: ActionType.UPDATE_SPLASH_FAIL,
    payload: string
}

interface updateSplashSuccess {
    type: ActionType.UPDATE_SPLASH_SUCCESS,
    payload: Splash
}

interface updateSplashPending {
    type: ActionType.UPDATE_SPLASH_PENDING
}

interface updateLocalSplash {
    type: ActionType.UPDATE_LOCAL_SPLASH,
    payload: Splash
}

interface createSplashRangePending {
    type: ActionType.CREATE_SPLASH_RANGE_PENDING
}

interface createSplashRangeSuccess {
    type: ActionType.CREATE_SPLASH_RANGE_SUCCESS,
    payload: SplashRange
}

interface createSplashRangeFail {
    type: ActionType.CREATE_SPLASH_RANGE_FAIL,
    payload: string
}

interface updateSplashRangeFail {
    type: ActionType.UPDATE_SPLASH_RANGE_FAIL,
    payload: string
}

interface updateSplashRangeSuccess {
    type: ActionType.UPDATE_SPLASH_RANGE_SUCCESS,
    payload: SplashRange
}

interface updateSplashRangePending {
    type: ActionType.UPDATE_SPLASH_RANGE_PENDING
}

interface updateLocalSplashRange {
    type: ActionType.UPDATE_LOCAL_SPLASH_RANGE,
    payload: SplashRange
}

interface deleteSplashRangeFail {
    type: ActionType.DELETE_SPLASH_RANGE_FAIL,
    payload: string
}

interface deleteSplashRangeSuccess {
    type: ActionType.DELETE_SPLASH_RANGE_SUCCESS,
    payload: SplashRange
}

interface deleteSplashRangePending {
    type: ActionType.DELETE_SPLASH_RANGE_PENDING
}

export type Action =
    getSplashesFail |
    getSplashesPending |
    getSplashesSuccess |
    createSplashPending |
    createSplashSuccess |
    createSplashFail |
    updateSplashPending |
    updateSplashSuccess |
    updateSplashFail |
    updateLocalSplash |
    createSplashRangePending |
    createSplashRangeSuccess |
    createSplashRangeFail |
    updateSplashRangeFail |
    updateSplashRangePending |
    updateSplashRangeSuccess |
    updateLocalSplashRange |
    deleteSplashRangeFail |
    deleteSplashRangePending |
    deleteSplashRangeSuccess;