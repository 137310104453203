/// HOOKS ///
import { useState } from "react";
/// COMPONENTS ///
import WaterfallEditableItem from "./editable/waterfall";
import WaterfallPlainItem from "./plain/waterfall";
/// COMPONENTS ///
import Grid from "@mui/material/Grid";
/// TYPES ///
import { Waterfall } from "api/types/price";

interface Props {
  index: number,
  item: Waterfall,
  start_update: (update_item: Waterfall) => void,
  hovered: number,
  setHovered: (index: number) => void
}

export default function WaterfallItem({ index, item, start_update, hovered, setHovered }: Props) {
  const [locked, setLocked] = useState<boolean>(false);

  return (
    <Grid container item onMouseOver={() => setHovered(index)} onMouseLeave={() => setHovered(-1)}>
      {
        index === hovered || locked ?
          <WaterfallEditableItem
            index={index}
            setLocked={setLocked}
            start_update={start_update}
            item={item} /> :
          <WaterfallPlainItem
            index={index}
            item={item} />
      }
    </Grid>
  )
}
