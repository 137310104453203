import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { InsideDiagonalCorner } from "api/types/price";

interface Props {
    index: number,
    item: InsideDiagonalCorner,
    start_update: (update_item: InsideDiagonalCorner) => void,
    setLocked: (locked: boolean) => void
}

export default function InsideDiagonalCornerEditableItem({index, item, start_update, setLocked}: Props) {
    const [inside_diagonal_corner_update, setInsideDiagonalCornerUpdate] = useState<InsideDiagonalCorner>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_inside_diagonal_corner: InsideDiagonalCorner = {...inside_diagonal_corner_update, [key]: Number(value)};
            setInsideDiagonalCornerUpdate(new_inside_diagonal_corner);
            start_update(new_inside_diagonal_corner);
        }
    }

    const saveBoolean = (key: string, value: boolean) => {
        const new_inside_diagonal_corner: InsideDiagonalCorner = {...inside_diagonal_corner_update, [key]: value};
            setInsideDiagonalCornerUpdate(new_inside_diagonal_corner);
            start_update(new_inside_diagonal_corner);
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
