import { NotchedCorner } from "api/types/price";

export enum ActionType {
    GET_NOTCHED_CORNERS_PENDING="GET_NOTCHED_CORNERS_PENDING",
    GET_NOTCHED_CORNERS_SUCCESS="GET_NOTCHED_CORNERS_SUCCESS",
    GET_NOTCHED_CORNERS_FAIL="GET_NOTCHED_CORNERS_FAIL",
    CREATE_NOTCHED_CORNER_PENDING = "CREATE_NOTCHED_CORNER_PENDING",
    CREATE_NOTCHED_CORNER_SUCCESS = "CREATE_NOTCHED_CORNER_SUCCESS",
    CREATE_NOTCHED_CORNER_FAIL = "CREATE_NOTCHED_CORNER_FAIL",
    UPDATE_NOTCHED_CORNER_PENDING = "UPDATE_NOTCHED_CORNER_PENDING",
    UPDATE_NOTCHED_CORNER_SUCCESS = "UPDATE_NOTCHED_CORNER_SUCCESS",
    UPDATE_NOTCHED_CORNER_FAIL = "UPDATE_NOTCHED_CORNER_FAIL",
    UPDATE_LOCAL_NOTCHED_CORNER = "UPDATE_LOCAL_NOTCHED_CORNER"
}

interface getNotchedCornersFail {
    type: ActionType.GET_NOTCHED_CORNERS_FAIL,
    payload: string
}

interface getNotchedCornersSuccess {
    type: ActionType.GET_NOTCHED_CORNERS_SUCCESS,
    payload: NotchedCorner[]
}

interface getNotchedCornersPending {
    type: ActionType.GET_NOTCHED_CORNERS_PENDING
}
  
interface createNotchedCornerPending {
    type: ActionType.CREATE_NOTCHED_CORNER_PENDING,
}
  
interface createNotchedCornerSuccess {
    type: ActionType.CREATE_NOTCHED_CORNER_SUCCESS,
    payload: NotchedCorner
}
  
interface createNotchedCornerFail {
    type: ActionType.CREATE_NOTCHED_CORNER_FAIL,
    payload: string
}
  
interface updateNotchedCornerPending {
    type: ActionType.UPDATE_NOTCHED_CORNER_PENDING,
}
  
interface updateNotchedCornerSuccess {
    type: ActionType.UPDATE_NOTCHED_CORNER_SUCCESS,
    payload: NotchedCorner
}
  
interface updateNotchedCornerFail {
    type: ActionType.UPDATE_NOTCHED_CORNER_FAIL,
    payload: string
}

interface updateLocalNotchedCorner {
    type: ActionType.UPDATE_LOCAL_NOTCHED_CORNER,
    payload: NotchedCorner
}

export type Action = 
    getNotchedCornersFail |
    getNotchedCornersPending |
    getNotchedCornersSuccess |
    createNotchedCornerFail |
    createNotchedCornerPending |
    createNotchedCornerSuccess |
    updateNotchedCornerFail |
    updateNotchedCornerPending |
    updateNotchedCornerSuccess |
    updateLocalNotchedCorner;