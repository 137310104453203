import { FaucetCutout } from "api/types/price";

export enum ActionType {
    GET_FAUCET_CUTOUTS_PENDING="GET_FAUCET_CUTOUTS_PENDING",
    GET_FAUCET_CUTOUTS_SUCCESS="GET_FAUCET_CUTOUTS_SUCCESS",
    GET_FAUCET_CUTOUTS_FAIL="GET_FAUCET_CUTOUTS_FAIL",
    CREATE_FAUCET_CUTOUT_PENDING = "CREATE_FAUCET_CUTOUT_PENDING",
    CREATE_FAUCET_CUTOUT_SUCCESS = "CREATE_FAUCET_CUTOUT_SUCCESS",
    CREATE_FAUCET_CUTOUT_FAIL = "CREATE_FAUCET_CUTOUT_FAIL",
    UPDATE_FAUCET_CUTOUT_PENDING = "UPDATE_FAUCET_CUTOUT_PENDING",
    UPDATE_FAUCET_CUTOUT_SUCCESS = "UPDATE_FAUCET_CUTOUT_SUCCESS",
    UPDATE_FAUCET_CUTOUT_FAIL = "UPDATE_FAUCET_CUTOUT_FAIL",
    UPDATE_LOCAL_FAUCET_CUTOUT = "UPDATE_LOCAL_FAUCET_CUTOUT"
}

interface getFaucetCutoutsFail {
    type: ActionType.GET_FAUCET_CUTOUTS_FAIL,
    payload: string
}

interface getFaucetCutoutsSuccess {
    type: ActionType.GET_FAUCET_CUTOUTS_SUCCESS,
    payload: FaucetCutout[]
}

interface getFaucetCutoutsPending {
    type: ActionType.GET_FAUCET_CUTOUTS_PENDING
}

interface createFaucetCutoutPending {
    type: ActionType.CREATE_FAUCET_CUTOUT_PENDING
}

interface createFaucetCutoutSuccess {
    type: ActionType.CREATE_FAUCET_CUTOUT_SUCCESS,
    payload: FaucetCutout
}

interface createFaucetCutoutFail {
    type: ActionType.CREATE_FAUCET_CUTOUT_FAIL,
    payload: string
}

interface updateFaucetCutoutPending {
    type: ActionType.UPDATE_FAUCET_CUTOUT_PENDING
}

interface updateFaucetCutoutSuccess {
    type: ActionType.UPDATE_FAUCET_CUTOUT_SUCCESS,
    payload: FaucetCutout
}

interface updateFaucetCutoutFail {
    type: ActionType.UPDATE_FAUCET_CUTOUT_FAIL,
    payload: string
}

interface updateLocalFaucetCutout {
    type: ActionType.UPDATE_LOCAL_FAUCET_CUTOUT,
    payload: FaucetCutout
}

export type Action =
    getFaucetCutoutsFail |
    getFaucetCutoutsPending |
    getFaucetCutoutsSuccess |
    createFaucetCutoutFail |
    createFaucetCutoutPending |
    createFaucetCutoutSuccess |
    updateFaucetCutoutFail |
    updateFaucetCutoutPending |
    updateFaucetCutoutSuccess |
    updateLocalFaucetCutout;