import { useNavigate } from 'react-router-dom';
import { Bug } from 'api/types/feedback';
import Grid from "@mui/material/Grid";
import { Typography } from '@mui/material';
import { getPriorityChoiceDisplay } from 'values/feedback';
import { getRowSecondStyle } from 'values/style';
import { getDate } from 'functions/date';
import { rowItemStyle } from "styles/style";
import { bug_url } from 'components/navigation/endpoints';

interface Props {
    index: number,
    item: Bug
}

export default function BugRow({index, item}: Props) {
    const navigate = useNavigate();

    return(
        <Grid 
            container 
            item
            alignContent="center" 
            alignItems="center" 
            onClick={() => navigate(`/${bug_url}/${item.id}`, {replace: false})}
            sx={[rowItemStyle, getRowSecondStyle(index)]}>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {item.reported_by_user}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">
                    {getPriorityChoiceDisplay(item.priority)}
                </Typography>
            </Grid>
            <Grid item xs={3.5}>
                <Typography variant="body2">
                    {item.description}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {getDate(item.date_added)}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {item.date_resolved ? getDate(item.date_resolved) : ""}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Typography variant="body2" align="right">
                    {item.resolved ? "Resolved" : "Not Resolved"}
                </Typography>
            </Grid>
        </Grid> 
    )
}
