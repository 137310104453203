import { Action } from 'api/action_types/billing';
import { ActionType } from 'api/action_types/billing';
import { Session } from 'api/types/billing';
import { Customer } from 'api/types/billing';

interface State {
    session: Session | null,
    customer: string,
    billing_customer: Customer | null,
    product: string,
    price: string,
    loading: boolean,
    success: string,
    billing_customer_created: boolean,
    error: string | null
}

const initialState = {
    customer: "",
    billing_customer: null,
    billing_customer_created: false,
    product: "",
    price: "",
    session: null,
    loading: false,
    success: "",
    error: null
}

export const billingReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.CREATE_STRIPE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
                loading: false,
                billing_customer_created: true,
                error: ""
            }
        case ActionType.CREATE_STRIPE_SESSION_SUCCESS:
            return {
                ...state,
                session: action.payload
            }
        case ActionType.CREATE_STRIPE_SUBSCRIPTION_SUCCESS:
            return {
                ...state
            }
        case ActionType.GET_STRIPE_CUSTOMER_SUCCESS:
            return {
                ...state,
                billing_customer: action.payload
            }
        case ActionType.UPDATE_STRIPE_CUSTOMER_SUCCESS:
            return {
                ...state,
                success: "Your billing details have been successfully updated."
            };
        case ActionType.SESSION_FAIL:
            return state;
        case ActionType.SESSION_PENDING:
            return {
                ...state,
                loading: true
            };
        case ActionType.STRIPE_CUSTOMER_FAIL:
            return {
                ...state,
                error: "There was an error updating your billing, please try again."
            };
        case ActionType.SET_BILLING_CUSTOMER_CREATED:
            return {
                ...state,
                billing_customer_created: action.payload
            }
        case ActionType.STRIPE_CUSTOMER_PENDING:
            return state;
        case ActionType.SUBSCRIPTION_FAIL:
            return state;
        case ActionType.SUBSCRIPTION_PENDING:
            return state;
        case ActionType.SET_PRODUCT:
            return {
                ...state,
                product: action.product_id,
                price: action.price_id
            }
        case ActionType.SET_UPDATE_SUCCESS:
            return {
                ...state,
                success: action.payload,
                session: null
            }
        case ActionType.SET_UPDATE_FAIL:
            return {
                ...state,
                error: action.payload,
                session: null
            }
        default:
            return state;
    }
}



