import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getFeedback } from "api/actions/feedback";
import { updateFeedback } from "api/actions/feedback";
import { updateLocalFeedback } from "api/actions/feedback";
import { UpdateFeedback } from "api/types/feedback";
import { UpdateTime } from "api/types/update";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Title from "components/universal/title";
import TitleSpacer from "components/universal/title_spacer";
import { getDate } from "functions/date";
import { getResolutionChoiceDisplay } from "values/feedback";
import { mainContentAreaStyle } from "styles/style";
import { textFieldStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";

export default function FeedbackComponent() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { feedback } = useAppSelector((state) => state.feedback);
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_times, setStartTimes] = useState<UpdateTime[]>([]);

    const handleResolutionDescription = (resolution_statement: string) => {
        if(feedback){
            const new_feedback = {...feedback, resolution_statement: resolution_statement};
            start_update(new_feedback);
        }
    }

    const handleResolved = (resolved: boolean) => {
        if(feedback){
            const new_feedback = {...feedback, resolved: resolved};
            start_update(new_feedback);
        }
    }

    useEffect(() => {
        if(id){
            dispatch(getFeedback(id));
        }
    }, [dispatch, id]);

    const start_update = (feedback_update: UpdateFeedback) => {
        dispatch(updateLocalFeedback(feedback_update));
        setUpdatePending(true); 
        setStartTimes([...start_times.filter(item => item.data.id !== feedback_update.id), {
            time: Math.round(new Date().getTime() / 1000),
            data: feedback_update
        }]);
    }

    const update = (update_time: UpdateTime) => {
        const new_start_times = [...start_times.filter(item => item.data.id !== update_time.data.id)];
        setStartTimes(new_start_times);
        
        if(update_time.data.id){
            dispatch(updateFeedback(update_time.data, update_time.data.id));
        }
        
        if(new_start_times.length === 0){
            setUpdatePending(false);
        }
    }

    return(
        <Box sx={mainContentAreaStyle}>
            <Grid container justifyContent="space-between" alignItems="start">
                <Grid item xs={12}>
                    <Title 
                        title="Feedback"
                        update_pending={update_pending}
                        start_times={start_times}
                        update={update}
                        endProps={<TitleSpacer/>} />
                </Grid>
                <Grid item container xs={6} sx={{paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Details</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>From:</b> {feedback ? feedback.reported_by_user : ""}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Priority:</b> {getResolutionChoiceDisplay(feedback ? feedback.resolution : "")}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Date Added:</b> {getDate(feedback ? feedback.date_added : "")}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} sx={{paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Description</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                {feedback ? feedback.description : ""}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} sx={{marginTop: 4, paddingLeft: 2.25}}>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{
                            backgroundColor: primary_color,
                            color: white_color,
                            padding: 0.5,
                            paddingLeft: 1,
                            marginBottom: 0.5
                        }}>
                            <b>Resolution</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                        <Stack sx={{marginTop: 0.5}}>
                            <Stack direction="row">
                                <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                    <b>Resolved: </b> {getResolutionChoiceDisplay(feedback ? feedback.resolution : "")}
                                </Typography>
                                {
                                    feedback ?
                                    <Checkbox 
                                        sx={{height: 16, width: 16}}
                                        onChange={(e) => handleResolved(e.target.checked)}
                                        defaultChecked={feedback.resolved} /> :
                                    null
                                }
                                
                            </Stack>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Date Resolved: </b> {getDate(feedback ? feedback.date_resolved : "")}
                            </Typography>
                            <Typography variant="body2" sx={{paddingLeft: 0.5, paddingRight: 0.5}}>
                                <b>Description:</b>
                            </Typography>
                            <TextField
                                sx={textFieldStyle}
                                fullWidth
                                size="small"
                                variant="outlined"
                                minRows={3}
                                InputLabelProps={{shrink: true}}
                                multiline
                                defaultValue={feedback ? feedback.resolution_statement : ""}
                                id="resolution_description"
                                onChange={(e) => handleResolutionDescription(e.target.value)} />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}