import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { RecessedDiagonalCorner } from "api/types/price";

interface Props {
    index: number,
    item: RecessedDiagonalCorner,
    start_update: (update_item: RecessedDiagonalCorner) => void,
    setLocked: (locked: boolean) => void
}

export default function RecessedDiagonalCornerEditableItem({index, item, start_update, setLocked}: Props) {
    const [recessed_diagonal_corner_update, setRecessedDiagonalCornerUpdate] = useState<RecessedDiagonalCorner>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_recessed_diagonal_corner: RecessedDiagonalCorner = {...recessed_diagonal_corner_update, [key]: Number(value)};
            setRecessedDiagonalCornerUpdate(new_recessed_diagonal_corner);
            start_update(new_recessed_diagonal_corner);
        }
    }

    const saveBoolean = (key: string, value: boolean) => {
        const new_recessed_diagonal_corner: RecessedDiagonalCorner = {...recessed_diagonal_corner_update, [key]: value};
            setRecessedDiagonalCornerUpdate(new_recessed_diagonal_corner);
            start_update(new_recessed_diagonal_corner);
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
