import { useState } from "react";
import { createCooktopCutoutRange } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { CooktopCutoutRange } from "api/types/price";
import { CooktopCutout } from "api/types/price";
import CreateBasicRange from "../shared_components/basic_range";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void,
    cooktop_cutout: CooktopCutout
}

export default function CreateCooktopCutoutRange({open, setOpen, cooktop_cutout}: Props) {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const [range, setRange] = useState<CooktopCutoutRange>({});

    const handlePrice = (price: string) => {
        if(isNaN(Number(price)) === false){
            setRange({...range, price: Number(price)});
        }
    }

    const handleMeasurement = (measurement: string) => {
        if(isNaN(Number(measurement)) === false){
            setRange({...range, measurement: Number(measurement)});
        }
    }

    const handleCreate = () => {
        if(!range.price){
            setError("Price must be greater than 0");
            setHasError(true);
            return;
        }

        if(!range.measurement){
            setError("Radius must be greater than 0");
            setHasError(true);
            return;
        }

        if(cooktop_cutout.id){
            const create_range = {...range, cooktop_cutout: cooktop_cutout.id, any_range: false};
            dispatch(createCooktopCutoutRange(create_range));
            handleClose();
        }   
    }

    const handleClose = () => {
        setOpen(false);
    }

    return(
        <CreateBasicRange 
            title="Create Cooktop Cutout Range"
            open={open}
            measurement_type="Measurement"
            error={error}
            has_error={has_error}
            close={() => setOpen(false)}
            create={handleCreate}
            handlePrice={handlePrice}
            handleMeasurement={handleMeasurement}/>
    )
}
