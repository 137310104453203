import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { drawerListItemStyle } from "styles/drawer";
import { drawerListButtonStyle } from "styles/drawer";
import { drawerHighlightStyle } from "styles/drawer";
import { drawerHighlightTextStyle } from "styles/drawer";
import { islandStyle } from "styles/style";
import { empty_price_navigation } from "values/empty/price";
import { PriceNavigation } from "api/types/price";

interface Props {
  navigation: PriceNavigation,
  setNavigation: (navigation: PriceNavigation) => void
}

export default function PriceListDrawer({ navigation, setNavigation }: Props) {

  const getListItemStyle = (active: boolean) => {
    if (active) {
      return drawerHighlightStyle;
    }
    return null;
  }

  const getListItemTextStyle = (active: boolean) => {
    if (active) {
      return drawerHighlightTextStyle;
    }
    return null;
  }

  return (
    <Paper sx={[islandStyle, { paddingTop: 1 }]}>
      <List sx={{ padding: 0 }}>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_price_list)]}
          key="Details">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_price_list: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_price_list), { fontWeight: '600' }]}>Details</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_material)]}
          key="Materials">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_material: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_material), { fontWeight: '600' }]}>Materials</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_installation)]}
          key="Installation Charge">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_installation: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_installation), { fontWeight: '600' }]}>Installation Charges</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_fabrication)]}
          key="Fabrication Charge">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_fabrication: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_fabrication), { fontWeight: '600' }]}>Fabrication Charges</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_splash)]}
          key="Splash">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_splash: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_splash), { fontWeight: '600' }]}>Splashes</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_miter)]}
          key="Miter">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_miter: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_miter), { fontWeight: '600' }]}>Miters</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_waterfall)]}
          key="Waterfall">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_waterfall: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_waterfall), { fontWeight: '600' }]}>Waterfall</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_finished_edge)]}
          key="Finished Edge">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_finished_edge: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_finished_edge), { fontWeight: '600' }]}>Finished Edge</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_appliance_edge)]}
          key="Appliance Edge">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_appliance_edge: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_appliance_edge), { fontWeight: '600' }]}>Appliance Edge</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_sink_cutouts)]}
          key="Sink Cutouts">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_sink_cutouts: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_sink_cutouts), { fontWeight: '600' }]}>Sink Cutouts</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_cooktop_cutouts)]}
          key="Cooktop Cutouts">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_cooktop_cutouts: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_cooktop_cutouts), { fontWeight: '600' }]}>Cooktop Cutouts</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_faucet_cutouts)]}
          key="Faucet Cutouts">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_faucet_cutouts: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_faucet_cutouts), { fontWeight: '600' }]}>Faucet Cutouts</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_outlet_cutouts)]}
          key="Outlet Cutouts">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_outlet_cutouts: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_outlet_cutouts), { fontWeight: '600' }]}>Outlet Cutouts</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_notched_corners)]}
          key="Notched Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_notched_corners: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_notched_corners), { fontWeight: '600' }]}>Notched Corners</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_clipped_corners)]}
          key="Clipped Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_clipped_corners: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_clipped_corners), { fontWeight: '600' }]}>Clipped Corners</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_bumped_out_corners)]}
          key="Bumped Out Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_bumped_out_corners: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_bumped_out_corners), { fontWeight: '600' }]}>Bumped Out Corners</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_radius_corners)]}
          key="Radius Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_radius_corners: true })}>
            <ListItemText
              primary={
                <Typography variant="body2" sx={[getListItemTextStyle(navigation.show_radius_corners), { fontWeight: '600' }]}>Radius Corners</Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_inside_radius_corners)]}
          key="Inside Radius Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_inside_radius_corners: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_inside_radius_corners), { fontWeight: '600' }]}>
                  Inside Radius Corners
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_inside_diagonal_corners)]}
          key="Inside Diagonal Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_inside_diagonal_corners: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_inside_diagonal_corners), { fontWeight: '600' }]}>
                  Inside Diagonal Corners
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_recessed_diagonal_corners)]}
          key="Recessed Diagonal Corners">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_recessed_diagonal_corners: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_recessed_diagonal_corners), { fontWeight: '600' }]}>
                  Recessed Diagonal Corners
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_bump_in)]}
          key="Bump In">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_bump_in: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_bump_in), { fontWeight: '600' }]}>
                  Bump In
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_bump_out)]}
          key="Bump Out">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_bump_out: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_bump_out), { fontWeight: '600' }]}>
                  Bump Out
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_bump_in_arc)]}
          key="Bump In Arc">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_bump_in_arc: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_bump_in_arc), { fontWeight: '600' }]}>
                  Bump In Arc
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_bump_out_arc)]}
          key="Bump Out Arc">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_bump_out_arc: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_bump_out_arc), { fontWeight: '600' }]}>
                  Bump Out Arc
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_full_arc)]}
          key="Full Arc">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_full_arc: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_full_arc), { fontWeight: '600' }]}>
                  Full Arc
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={[drawerListItemStyle, getListItemStyle(navigation.show_countertop_removal)]}
          key="Countertop Removal">
          <ListItemButton sx={drawerListButtonStyle} onClick={(e) => setNavigation({ ...empty_price_navigation, show_countertop_removal: true })}>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={[getListItemTextStyle(navigation.show_countertop_removal), { fontWeight: '600' }]}>
                  Countertop Removal
                </Typography>
              } />
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  );
}
