import { Session } from "api/types/billing";
import { Customer } from "api/types/billing";
import { User } from "api/types/authentication";

export enum ActionType {
    CREATE_STRIPE_SUBSCRIPTION_SUCCESS="SUBSCRIPTION_SUCCESS",
    SUBSCRIPTION_FAIL="SUBSCRIPTION_FAIL",
    SUBSCRIPTION_PENDING="SUBSCRIPTION_PENDING",
    CREATE_STRIPE_CUSTOMER_SUCCESS="CREATE_STRIPE_CUSTOMER_SUCCESS",
    UPDATE_STRIPE_CUSTOMER_SUCCESS="UPDATE_STRIPE_CUSTOMER_SUCCESS",
    GET_STRIPE_CUSTOMER_SUCCESS="GET_STRIPE_CUSTOMER_SUCCESS",
    STRIPE_CUSTOMER_FAIL="STRIPE_CUSTOMER_FAIL",
    STRIPE_CUSTOMER_PENDING="STRIPE_CUSTOMER_PENDING",
    CREATE_STRIPE_SESSION_SUCCESS="CREATE_STRIPE_SESSION_SUCCESS",
    SET_PRODUCT="SET_PRODUCT",
    SET_UPDATE_SUCCESS="SET_UPDATE_SUCCESS",
    SET_UPDATE_FAIL="SET_UPDATE_FAIL",
    SESSION_FAIL="SESSION_FAIL",
    SESSION_PENDING="SESSION_PENDING",
    SET_BILLING_CUSTOMER_CREATED="SET_BILLING_CUSTOMER_CREATED"
}

interface createStripeSubscription {
    type: ActionType.CREATE_STRIPE_SUBSCRIPTION_SUCCESS
}

interface subscriptionFail {
    type: ActionType.SUBSCRIPTION_FAIL
}

interface subscriptionPending {
    type: ActionType.SUBSCRIPTION_PENDING
}

interface getStripeCustomerSuccess {
    type: ActionType.GET_STRIPE_CUSTOMER_SUCCESS,
    payload: Customer
}

interface createStripeCustomerSuccess {
    type: ActionType.CREATE_STRIPE_CUSTOMER_SUCCESS,
    payload: string
}

interface updateStripeCustomerSuccess {
    type: ActionType.UPDATE_STRIPE_CUSTOMER_SUCCESS
}

interface stripeCustomerFail {
    type: ActionType.STRIPE_CUSTOMER_FAIL
}

interface stripeCustomerPending {
    type: ActionType.STRIPE_CUSTOMER_PENDING
}

interface createStripeSessionSuccess {
    type: ActionType.CREATE_STRIPE_SESSION_SUCCESS,
    payload: Session
}

interface setProduct {
    type: ActionType.SET_PRODUCT,
    product_id: string,
    price_id: string
}

interface setUpdateSuccess {
    type: ActionType.SET_UPDATE_SUCCESS,
    payload: string
}

interface setUpdateFail {
    type: ActionType.SET_UPDATE_FAIL,
    payload: string
}

interface sessionFail {
    type: ActionType.SESSION_FAIL
}

interface sessionPending {
    type: ActionType.SESSION_PENDING
}

interface setBillingCustomerCreated {
    type: ActionType.SET_BILLING_CUSTOMER_CREATED,
    payload: boolean
}

export type Action =
    createStripeCustomerSuccess |
    updateStripeCustomerSuccess |
    getStripeCustomerSuccess |
    stripeCustomerFail |
    stripeCustomerPending |
    createStripeSubscription |
    subscriptionFail |
    subscriptionPending | 
    createStripeSessionSuccess |
    setProduct |
    setUpdateSuccess |
    setUpdateFail |
    sessionFail |
    sessionPending |
    setBillingCustomerCreated;