import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getClippedCorners } from "api/actions/price/clipped_corner";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import ClippedCornerItem from "../row/clipped_corner";
import { CreateClippedCorner } from "../dialog/create";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { ClippedCorner } from "api/types/price";
import { CLIPPED_CORNER } from "api/types/update";

interface Props {
    start_update: (type: number, data: ClippedCorner) => void
}
export default function PriceListClippedCorners({start_update}: Props) {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { clipped_corners } = useAppSelector((state) => state.price);

    const handle_update = (update_item: ClippedCorner) => {
        start_update(CLIPPED_CORNER, update_item);
    }

    useEffect(() => {
        if(id && isNaN(Number(id)) === false){
            dispatch(getClippedCorners(Number(id)));
        }
    }, [id, dispatch]);
    
    return(
        <Paper sx={islandStyle}>
            <Grid container alignItems="center">
                <Subtitle 
                    subtitle="Clipped Corners"
                    endProps={
                        <CreateClippedCorner />} />
                <Header 
                    items={[
                        {text: "Height", width: 2},
                        {text: "Price", width: 2},
                        {text: "Rate", width: 4},
                        {text: "Enabled", width: 2},
                        {text: "Taxable", width: 2}
                    ]}/>
                <EditableList 
                    items={clipped_corners}
                    Item={ClippedCornerItem}
                    start_update={handle_update} />
            </Grid>
        </Paper>
    )
}
