import { User } from "api/types/authentication";

export enum ActionType {
    LOGIN_PENDING="LOGIN_PENDING",
    LOGIN_SUCCESS="LOGIN_SUCCESS",
    LOGIN_FAIL="LOGIN_FAIL",
    REGISTER_PENDING="REGISTER_PENDING",
    REGISTER_SUCCESS="REGISTER_SUCCESS",
    REGISTER_FAIL="REGISTER_FAIL",
    CHANGE_PASSWORD="CHANGE_PASSWORD",
    CHANGE_PASSWORD_FAIL="CHANGE_PASSWORD_FAIL",
    CHANGE_PASSWORD_PENDING="CHANGE_PASSWORD_PENDING",
    RESET_REGISTRATION_COMPLETE="RESET_REGISTRATION_COMPLETE",
    CLEAR_ERRORS="CLEAR_ERRORS",
    LOGOUT_SUCCESS="LOGOUT_SUCCESS",
    GET_PROFILE_SUCCESS="GET_PROFILE_SUCCESS",
    UPDATE_PROFILE_PENDING="UPDATE_PROFLIE_PENDING",
    UPDATE_PROFILE_SUCCESS="UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_FAIL="UPDATE_PROFILE_FAIL",
    UPDATE_LOCAL_USER="UPDATE_LOCAL_USER",
    AUTH_FAILED="AUTH_FAILED",
    GET_SUPPLIERS_PENDING="GET_SUPPLIERS_PENDING",
    GET_SUPPLIERS_SUCCESS="GET_SUPPLIERS_SUCCESS",
    GET_SUPPLIERS_FAIL="GET_SUPPLIERS_FAIL",
    SET_PAYMENT_INITIALIZED="SET_PAYMENT_INITIALIZED",
    SET_REGISTRATION_COMPLETE="SET_REGISTRATION_COMPLETE",
    STORE_TOKEN="STORE_TOKEN"
}

interface loginPending {
    type: ActionType.LOGIN_PENDING
}

interface loginSuccess {
    type: ActionType.LOGIN_SUCCESS,
    payload: User
}

interface loginFail {
    type: ActionType.LOGIN_FAIL,
    payload: string
}

interface registerPending {
    type: ActionType.REGISTER_PENDING
}

interface registerSuccess {
    type: ActionType.REGISTER_SUCCESS,
    payload: User
}

interface resetRegistrationComplete {
    type: ActionType.RESET_REGISTRATION_COMPLETE
}

interface registerFail {
    type: ActionType.REGISTER_FAIL,
    payload: string
}

interface changePasswordSuccess {
    type: ActionType.CHANGE_PASSWORD
}

interface changePasswordFail {
    type: ActionType.CHANGE_PASSWORD_FAIL,
    payload: string
}

interface changePasswordPending {
    type: ActionType.CHANGE_PASSWORD_PENDING
}

interface clearErrors {
    type: ActionType.CLEAR_ERRORS
}

interface logoutSuccess {
    type: ActionType.LOGOUT_SUCCESS
}

interface getProfileSuccess {
    type: ActionType.GET_PROFILE_SUCCESS,
    payload: User
}

interface updateProfilePending {
    type: ActionType.UPDATE_PROFILE_PENDING
}

interface updateProfileSuccess {
    type: ActionType.UPDATE_PROFILE_SUCCESS,
    payload: User
}

interface updateProfileFail {
    type: ActionType.UPDATE_PROFILE_FAIL,
    payload: string
}

interface updateLocalUser {
    type: ActionType.UPDATE_LOCAL_USER,
    payload: User
}

interface authFailed {
    type: ActionType.AUTH_FAILED
}

interface getSuppliersPending {
    type: ActionType.GET_SUPPLIERS_PENDING
}

interface getSuppliersSuccess {
    type: ActionType.GET_SUPPLIERS_SUCCESS,
    payload: User[]
}

interface getSuppliersFail {
    type: ActionType.GET_SUPPLIERS_FAIL,
    payload: string
}

interface setPaymentInitialized {
    type: ActionType.SET_PAYMENT_INITIALIZED
}

interface setRegistrationComplete {
    type: ActionType.SET_REGISTRATION_COMPLETE,
    payload: boolean
}

interface storeToken {
    type: ActionType.STORE_TOKEN,
    payload: string
}

export type Action =
    loginPending |
    loginSuccess |
    loginFail |
    registerPending |
    registerSuccess |
    registerFail |
    changePasswordSuccess |
    changePasswordFail |
    changePasswordPending |
    resetRegistrationComplete |
    clearErrors |
    logoutSuccess |
    getProfileSuccess |
    updateProfileFail |
    updateProfilePending |
    updateProfileSuccess |
    updateLocalUser |
    authFailed |
    getSuppliersPending |
    getSuppliersSuccess |
    getSuppliersFail |
    setPaymentInitialized |
    setRegistrationComplete |
    storeToken;