export const BUG: string = "B";
export const FEATURE: string = "F";
export const OTHER: string = "O";

export function getFeedbackTypeDisplay(feature_type: string): string {
    if(feature_type === BUG){
        return "Bug";
    }
    else if(feature_type === FEATURE){
        return "Feature";
    }
    else if(feature_type === OTHER){
        return "Other";
    }
    return "Other";
}

export const ACCEPTED: string = "A";
export const REJECTED: string = "R";
export const TRACKER_CREATED: string = "T";
export const SUBMITTED: string = "S";

export function getResolutionChoiceDisplay(resolution_choice: string): string {
    if(resolution_choice === ACCEPTED){
        return "Accepted";
    }
    else if(resolution_choice === REJECTED){
        return "Rejected";
    }
    else if(resolution_choice === TRACKER_CREATED){
        return "Bug Tracker Created";
    }
    else if(resolution_choice === SUBMITTED){
        return "Submitted";
    }
    return "Unknown";
}

export const LOW: string = "L";
export const MEDIUM: string = "M";
export const HIGH: string = "H";

export function getPriorityChoiceDisplay(priority: string): string {
    if(priority === LOW){
        return "Low";
    }
    else if(priority === MEDIUM){
        return "Medium";
    }
    else if(priority === HIGH){
        return "High";
    }
    return "Unknown";
}