import { FabricationCharge } from "api/types/price";

export enum ActionType {
    GET_FABRICATION_CHARGES_PENDING="GET_FABRICATION_CHARGES_PENDING",
    GET_FABRICATION_CHARGES_SUCCESS="GET_FABRICATION_CHARGES_SUCCESS",
    GET_FABRICATION_CHARGES_FAIL="GET_FABRICATION_CHARGES_FAIL",
    CREATE_FABRICATION_CHARGE_PENDING = "CREATE_FABRICATION_CHARGE_PENDING",
    CREATE_FABRICATION_CHARGE_SUCCESS = "CREATE_FABRICATION_CHARGE_SUCCESS",
    CREATE_FABRICATION_CHARGE_FAIL = "CREATE_FABRICATION_CHARGE_FAIL",
    UPDATE_FABRICATION_CHARGE_PENDING = "UPDATE_FABRICATION_CHARGE_PENDING",
    UPDATE_FABRICATION_CHARGE_SUCCESS = "UPDATE_FABRICATION_CHARGE_SUCCESS",
    UPDATE_FABRICATION_CHARGE_FAIL = "UPDATE_FABRICATION_CHARGE_FAIL",
    UPDATE_LOCAL_FABRICATION_CHARGE = "UPDATE_LOCAL_FABRICATION_CHARGE"
}

interface getFabricationChargesFail {
    type: ActionType.GET_FABRICATION_CHARGES_FAIL,
    payload: string
}

interface getFabricationChargesSuccess {
    type: ActionType.GET_FABRICATION_CHARGES_SUCCESS,
    payload: FabricationCharge[]
}

interface getFabricationChargesPending {
    type: ActionType.GET_FABRICATION_CHARGES_PENDING
}

interface createFabricationChargeFail {
    type: ActionType.CREATE_FABRICATION_CHARGE_FAIL,
    payload: string
}

interface createFabricationChargeSuccess {
    type: ActionType.CREATE_FABRICATION_CHARGE_SUCCESS,
    payload: FabricationCharge
}

interface createFabricationChargePending {
    type: ActionType.CREATE_FABRICATION_CHARGE_PENDING
}

interface updateFabricationChargeFail {
    type: ActionType.UPDATE_FABRICATION_CHARGE_FAIL,
    payload: string
}

interface updateFabricationChargeSuccess {
    type: ActionType.UPDATE_FABRICATION_CHARGE_SUCCESS,
    payload: FabricationCharge
}

interface updateFabricationChargePending {
    type: ActionType.UPDATE_FABRICATION_CHARGE_PENDING
}

interface updateLocalFabricationCharge {
    type: ActionType.UPDATE_LOCAL_FABRICATION_CHARGE,
    payload: FabricationCharge
}

export type Action = 
    getFabricationChargesFail |
    getFabricationChargesPending |
    getFabricationChargesSuccess |
    createFabricationChargePending |
    createFabricationChargeSuccess |
    createFabricationChargeFail |
    updateFabricationChargeFail |
    updateFabricationChargePending |
    updateFabricationChargeSuccess |
    updateLocalFabricationCharge;