import axios from "axios";
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
import { Action } from "api/action_types/price";
import { ActionType } from "api/action_types/price";
import { AxiosError } from 'axios';
import { Action as AuthAction } from "api/action_types/authentication";
import { ActionType as AuthActionType } from "api/action_types/authentication";
import { BumpIn } from "api/types/price";
import { url_prefix } from "settings";

const bump_in_url: string = "bump_in";

export const getBumpIns = (price_list_id?: string | number) => {
    return async(dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

        const { user } = getState().authentication;

        dispatch({
            type: ActionType.GET_BUMP_INS_PENDING
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${user.token}`
            }
        }

        let url = `${url_prefix}${bump_in_url}/?`;

        if(price_list_id){
            url+=`price_list=${price_list_id}&`;
        }

        try
        {
            const {data} = await axios.get(url, config);

            dispatch({
                type: ActionType.GET_BUMP_INS_SUCCESS,
                payload: data
            });
        }
        catch(err)
        {
            if(err instanceof AxiosError){
                dispatch({
                    type: ActionType.GET_BUMP_INS_FAIL,
                    payload: err.message
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
        }
    }
}

export const createBumpIn = (bump_in: BumpIn) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;

    dispatch({
      type: ActionType.CREATE_BUMP_IN_PENDING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user.token}`,
      },
    };

    const url = `${url_prefix}${bump_in_url}/`;

    try {
      const { data } = await axios.post(url, bump_in, config);

        dispatch({
            type: ActionType.CREATE_BUMP_IN_SUCCESS,
            payload: data,
        });
    } catch (err) {
        if (err instanceof AxiosError) {
            dispatch({
                type: ActionType.CREATE_BUMP_IN_FAIL,
                payload: err.message,
            });
            if(err && err.response && err.response.status === 401){
                dispatch({
                    type: AuthActionType.AUTH_FAILED
                });
            }
        }
    }
  };
};

export const updateBumpIn = (bump_in: BumpIn, id: number) => {
    return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
      const { user } = getState().authentication;
  
      dispatch({
        type: ActionType.UPDATE_BUMP_IN_PENDING,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
  
      const url = `${url_prefix}${bump_in_url}/${id}/`;
  
      try {
        const { data } = await axios.patch(url, bump_in, config);
        dispatch({
            type: ActionType.UPDATE_BUMP_IN_SUCCESS,
            payload: data,
        });
      } catch (err) {
            if (err instanceof AxiosError) {
                dispatch({
                    type: ActionType.UPDATE_BUMP_IN_FAIL,
                    payload: err.message,
                });
                if(err && err.response && err.response.status === 401){
                    dispatch({
                        type: AuthActionType.AUTH_FAILED
                    });
                }
            }
      }
    };
};

export const updateLocalBumpIn = (bump_in: BumpIn) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_LOCAL_BUMP_IN,
            payload: bump_in
        });
    }
}
  
