import { OutletCutout } from "api/types/price";

export enum ActionType {
    GET_OUTLET_CUTOUTS_PENDING="GET_OUTLET_CUTOUTS_PENDING",
    GET_OUTLET_CUTOUTS_SUCCESS="GET_OUTLET_CUTOUTS_SUCCESS",
    GET_OUTLET_CUTOUTS_FAIL="GET_OUTLET_CUTOUTS_FAIL",
    CREATE_OUTLET_CUTOUT_PENDING = "CREATE_OUTLET_CUTOUT_PENDING",
    CREATE_OUTLET_CUTOUT_SUCCESS = "CREATE_OUTLET_CUTOUT_SUCCESS",
    CREATE_OUTLET_CUTOUT_FAIL = "CREATE_OUTLET_CUTOUT_FAIL",
    UPDATE_OUTLET_CUTOUT_PENDING = "UPDATE_OUTLET_CUTOUT_PENDING",
    UPDATE_OUTLET_CUTOUT_SUCCESS = "UPDATE_OUTLET_CUTOUT_SUCCESS",
    UPDATE_OUTLET_CUTOUT_FAIL = "UPDATE_OUTLET_CUTOUT_FAIL",
    UPDATE_LOCAL_OUTLET_CUTOUT = "UPDATE_LOCAL_OUTLET_CUTOUT"
}

interface getOutletCutoutsFail {
    type: ActionType.GET_OUTLET_CUTOUTS_FAIL,
    payload: string
}

interface getOutletCutoutsSuccess {
    type: ActionType.GET_OUTLET_CUTOUTS_SUCCESS,
    payload: OutletCutout[]
}

interface getOutletCutoutsPending {
    type: ActionType.GET_OUTLET_CUTOUTS_PENDING
}

interface createOutletCutoutPending {
    type: ActionType.CREATE_OUTLET_CUTOUT_PENDING
}

interface createOutletCutoutSuccess {
    type: ActionType.CREATE_OUTLET_CUTOUT_SUCCESS,
    payload: OutletCutout
}

interface createOutletCutoutFail {
    type: ActionType.CREATE_OUTLET_CUTOUT_FAIL,
    payload: string
}

interface updateOutletCutoutPending {
    type: ActionType.UPDATE_OUTLET_CUTOUT_PENDING
}

interface updateOutletCutoutSuccess {
    type: ActionType.UPDATE_OUTLET_CUTOUT_SUCCESS,
    payload: OutletCutout
}

interface updateOutletCutoutFail {
    type: ActionType.UPDATE_OUTLET_CUTOUT_FAIL,
    payload: string
}

interface updateLocalOutletCutout {
    type: ActionType.UPDATE_LOCAL_OUTLET_CUTOUT,
    payload: OutletCutout
}

export type Action =
    getOutletCutoutsFail |
    getOutletCutoutsPending |
    getOutletCutoutsSuccess |
    createOutletCutoutFail |
    createOutletCutoutPending |
    createOutletCutoutSuccess |
    updateOutletCutoutFail |
    updateOutletCutoutPending |
    updateOutletCutoutSuccess |
    updateLocalOutletCutout;