import { Fragment } from "react";
import { useState } from "react";
import { createCooktopCutout } from "api/actions/price";
import { useAppDispatch } from 'api/hooks/apiHook';
import { useAppSelector } from "api/hooks/apiHook";
import { CooktopCutout } from "api/types/price";
import { CooktopCutoutRange } from "api/types/price";
import CreateBasicRange from "../shared_components/create_range";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { empty_cooktop_cutout } from "values/empty/price";
import { empty_cooktop_range } from "values/empty/price";

export default function CreateCooktopCutout() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [cooktop_cutout, setCooktopCutout] = useState<CooktopCutout>(empty_cooktop_cutout);
    const [error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);
    const [ranges, setRanges] = useState<CooktopCutoutRange[]>([{...empty_cooktop_range, any_range: true}]);
    const { price_list } = useAppSelector((state) => state.price);

    const saveString = (key: string, value: string) => {
        setCooktopCutout({...cooktop_cutout, [key]: value});
        setError("");
        setHasError(false);
    }

    const saveBoolean = (key: string, value: boolean) => {
        setCooktopCutout({...cooktop_cutout, [key]: value});
        setError("");
        setHasError(false);
    }

    const handleRangePrice = (index: number, price: string) => {
        if(isNaN(Number(price)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ?
                {...range, price: Number(price)} :
                range
            )]);
        }
    }

    const handleRangeMeasurement = (index: number, measurement: string) => {
        if(isNaN(Number(measurement)) === false){
            setRanges([...ranges.map((range, inner_index) => inner_index === index ? 
                {...range, measurement: Number(measurement)} :
                range
            )]);
        }
    }

    const addRange = () => {
        setRanges([{...empty_cooktop_range, measurement: 0}, ...ranges]);
    }

    const handleCreate = () => {
        if(!price_list.id){
            setError("An unknown error has occurred. Please contact the administrator");
            return;
        }

        dispatch(createCooktopCutout({...cooktop_cutout, price_list: price_list.id, ranges: ranges}));
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setCooktopCutout(empty_cooktop_cutout);
        setRanges([{...empty_cooktop_range, any_range: true}]);
    }

    return(
        <Fragment>
            <IconButton onClick={(e) => setOpen(true)}>
                <AddCircleIcon 
                    color="primary"/>
            </IconButton>
            <CreateBasicRange 
                title="Create Cooktop Cutout"
                open={open}
                error={error}
                has_error={has_error}
                ranges={ranges}
                close={handleClose}
                create={handleCreate}
                saveString={saveString}
                saveBoolean={saveBoolean}
                handleRangeMeasurement={handleRangeMeasurement}
                handleRangePrice={handleRangePrice}
                addRange={addRange} />
        </Fragment>
    )
}
