import { FullArc } from "api/types/price";

export enum ActionType {
    GET_FULL_ARCS_PENDING="GET_FULL_ARCS_PENDING",
    GET_FULL_ARCS_SUCCESS="GET_FULL_ARCS_SUCCESS",
    GET_FULL_ARCS_FAIL="GET_FULL_ARCS_FAIL",
    CREATE_FULL_ARC_PENDING = 'CREATE_FULL_ARC_PENDING',
    CREATE_FULL_ARC_SUCCESS = 'CREATE_FULL_ARC_SUCCESS',
    CREATE_FULL_ARC_FAIL = 'CREATE_FULL_ARC_FAIL',
    UPDATE_FULL_ARC_PENDING = 'UPDATE_FULL_ARC_PENDING',
    UPDATE_FULL_ARC_SUCCESS = 'UPDATE_FULL_ARC_SUCCESS',
    UPDATE_FULL_ARC_FAIL = 'UPDATE_FULL_ARC_FAIL',
    UPDATE_LOCAL_FULL_ARC = "UPDATE_LOCAL_FULL_ARC"
}

interface getFullArcsFail {
    type: ActionType.GET_FULL_ARCS_FAIL,
    payload: string
}

interface getFullArcsSuccess {
    type: ActionType.GET_FULL_ARCS_SUCCESS,
    payload: FullArc[]
}

interface getFullArcsPending {
    type: ActionType.GET_FULL_ARCS_PENDING
}

interface createFullArcPending {
    type: ActionType.CREATE_FULL_ARC_PENDING,
}
  
interface createFullArcSuccess {
    type: ActionType.CREATE_FULL_ARC_SUCCESS,
    payload: FullArc
}
  
interface createFullArcFail {
    type: ActionType.CREATE_FULL_ARC_FAIL,
    payload: string;
}
  
interface updateFullArcPending {
    type: ActionType.UPDATE_FULL_ARC_PENDING,
}
  
interface updateFullArcSuccess {
    type: ActionType.UPDATE_FULL_ARC_SUCCESS,
    payload: FullArc;
}
  
interface updateFullArcFail {
    type: ActionType.UPDATE_FULL_ARC_FAIL,
    payload: string;
}

interface updateLocalFullArc {
    type: ActionType.UPDATE_LOCAL_FULL_ARC,
    payload: FullArc
}

export type Action =
    getFullArcsFail |
    getFullArcsPending |
    getFullArcsSuccess |
    createFullArcPending |
    createFullArcSuccess |
    createFullArcFail |
    updateFullArcPending |
    updateFullArcSuccess |
    updateFullArcFail |
    updateLocalFullArc;