import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Steppers from "components/universal/stepper";
import { text_primary } from "styles/style";
import { staff_payment_setup_steps } from "values/steps";
import { mainContentAreaStyle } from "styles/style";
  
export default function StaffSubscriptionSetupCancellation() {
    return (
        <Grid container sx={mainContentAreaStyle}>
            <Grid item xs={10} sx={{marginTop: 10}}>
                <Box display="flex" alignItems="center" sx={{paddingLeft: "10%"}}>
                    <Stack>
                        <Typography variant="h6" sx={{color: text_primary}}>
                            Something went wrong
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: 1, marginRight: 10}}>
                            It looks like your payment setup is incomplete. The customer will not be charged. If they activate their account from the link in the email they will be able to add payment themselves through their account.
                        </Typography>
                        <Typography variant="body1" sx={{marginTop: 1, marginRight: 10}}>
                            Once payment is set up, you will receive 1 month free trial and will not be charged until the trial is complete.
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={2} sx={{marginTop: 10}}>
                <Steppers current_step={4} steps={staff_payment_setup_steps}/>
            </Grid>
    </Grid>
    );
}