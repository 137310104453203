import { rowItemEverySecondStyle } from "styles/style";
import { drawerHighlightStyle } from "styles/drawer";
import { drawerHighlightTextStyle } from "styles/drawer";

export const getRowSecondStyle = (index: number) => {
    if(index % 2 === 0){
        return rowItemEverySecondStyle;
    }
    return null;
}

export const getListItemStyle = (active: boolean) => {
    if(active){
        return drawerHighlightStyle;
    }
    return null;
}

export const getListItemTextStyle = (active: boolean) => {
    if(active){
        return drawerHighlightTextStyle;
    }
    return null;
}