import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { getMaterials } from "api/actions/material";
import { getSuppliers } from "api/actions/authentication";
import { updateMaterial } from "api/actions/material";
import { Material } from "api/types/material";
import { User } from "api/types/authentication";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Title from "components/universal/title";
import TitleSpacer from "components/universal/title_spacer";
import List from "components/universal/list";
import ManageSuppliersHeader from "./header/manage_suppliers";
import ManageSuppliersItem from "./row/manage_suppliers";
import { getMaterialHeightDisplay } from "values/material";
import { mainContentAreaStyle } from "styles/style";
import { islandStyle } from "styles/style";
import { textFieldStyle } from "styles/style";

export default function ManageSuppliers() {
  const dispatch = useAppDispatch();
  const [current_material, setCurrentMaterial] = useState<Material>();
  const [supplier, setSupplier] = useState<User>();
  const [error, setError] = useState<string>("");
  const [has_error, setHasError] = useState<boolean>(false);
  const { materials } = useAppSelector((state) => state.material);
  const { suppliers } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(getMaterials());
    dispatch(getSuppliers());
  }, [dispatch]);

  const handleMaterial = (id: string) => {
    if (isNaN(Number(id)) === false) {
      for (let i = 0; i < materials.length; i++) {
        if (materials[i].id === Number(id)) {
          setCurrentMaterial(materials[i]);
          break;
        }
      }
      setError("");
      setHasError(false);
    }
  }

  const handleSupplier = (id: string) => {
    if (isNaN(Number(id)) === false) {
      for (let i = 0; i < suppliers.length; i++) {
        if (suppliers[i].id === Number(id)) {
          setSupplier(suppliers[i]);
          break;
        }
      }
      setError("");
      setHasError(false);
    }
  }

  const assignUser = () => {
    if (supplier && supplier.id && current_material && current_material.id) {
      dispatch(updateMaterial({ ...current_material, supplier: supplier.id }, current_material.id));
    }
    else {
      setError("Set both a supplier and a material");
      setHasError(true);
    }
  }

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title="Manage Suppliers"
            subtitle="Assign Suppliers to a Material"
            update_pending={false}
            start_times={[]}
            update={() => void 0}
            endProps={<TitleSpacer />} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={[islandStyle]}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Stack>
                  <Typography variant="body1" color="primary">
                    Selected Material
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                    <b>Material:</b> {current_material ?
                      current_material.name : ""} {getMaterialHeightDisplay(current_material ?
                        current_material.thickness : "")}
                  </Typography>
                  <Typography variant="body2">
                    <b>Current Supplier:</b> {
                      current_material && current_material.supplier_data ?
                        current_material.supplier_data.business_name : ""
                    }
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={7} sx={{ paddingLeft: 4 }}>
                <Stack sx={{ height: 100 }}>
                  <Typography variant="body1" color="primary">
                    Selected Supplier
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                    <b>Business Name: </b>{supplier ? supplier.business_name : ""}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 0.5 }}>
                    <b>Email: </b>{supplier ? supplier.email : ""}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: 2 }}>
                <FormHelperText error>{error}</FormHelperText>
              </Grid>
              <Grid item xs={5} sx={{ paddingRight: 8 }}>
                <TextField
                  sx={textFieldStyle}
                  fullWidth
                  size="small"
                  variant="outlined"
                  id="material"
                  label="Material"
                  error={has_error}
                  select
                  onChange={(e) => handleMaterial(e.target.value)}>
                  {
                    materials.map(material => {
                      return (
                        <MenuItem key={material.id} value={material.id}>
                          {material.name} {getMaterialHeightDisplay(material.thickness)}
                        </MenuItem>
                      )
                    })
                  }
                  <MenuItem>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={5} sx={{ paddingLeft: 4, paddingRight: 4 }}>
                <TextField
                  sx={textFieldStyle}
                  fullWidth
                  variant="outlined"
                  error={has_error}
                  select
                  size="small"
                  id="supplier"
                  label="User"
                  onChange={(e) => handleSupplier(e.target.value)}>
                  {
                    suppliers.map(supplier => {
                      return (
                        <MenuItem key={supplier.id} value={supplier.id}>
                          {`${supplier.business_name} - ${supplier.email}`}
                        </MenuItem>
                      )
                    })
                  }
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" onClick={assignUser} size="small" sx={{ marginTop: 0.5 }}>
                  Assign Supplier
                </Button>
              </Grid>
              <ManageSuppliersHeader />
              <List
                items={materials}
                Item={ManageSuppliersItem} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
