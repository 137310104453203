import { Fragment } from "react";
import Name from "../../shared/name";
import Price from "./price";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import FinishedEdgeImage from "components/price/dialog/shared_components/finished_edge_image";

interface Props {
    name: string,
    type: string,
    price: number,
    enabled: boolean
}

export default function FinishedEdgeTypeItem({name, type, price, enabled}: Props) {
    return(
        <Fragment>
            <Name
                bold={false}
                underline={true}
                name={name} 
                width={1} />
            <Grid item xs={1.5}>
                <FinishedEdgeImage 
                    height={30}
                    width={60}
                    type={type} />
            </Grid>
            <Price
                price={price}
                width={1.5}/>
            <Grid item xs={8}>
                <Typography variant="body2">
                    $/LIN FT
                </Typography>
            </Grid>
        </Fragment>
    )
}
