import React from "react";
import { ComponentType } from "react";

interface Props {
    items: any[],
    Item: ComponentType<any>
}

export default function List({items, Item}: Props) {

    return(
        <React.Fragment>
            {
                items.map((item, index) => {
                    return (
                        <Item 
                            key={index}
                            index={index}
                            item={item} />
                    )
                })
            }
        </React.Fragment>
        
    )
}
