import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { resetPassword } from "api/actions/authentication";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { validateEmail } from "functions/value";
import { error_color, text_primary } from "styles/style";

export default function ForgotPassword() {
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [complete, setComplete] = useState<boolean>(false);

    const handleEmail = (input: string) => {
        setEmail(input);
        setError("");
    }

    const handleSend = () => {
        if(!validateEmail(email)){
            setError("Please enter a valid email");
            return;
        }

        dispatch(resetPassword(email));
        setComplete(true);
    }

    return (
        <Box sx={{padding: "20vh"}}>
            {
                complete ? 
                <Stack>
                    <Typography variant="h6" sx={{color: text_primary}}>
                        If the email is in our records you will receive an email shortly with a link to reset your password.
                    </Typography>
                </Stack> :
                <Stack>
                    <Typography variant="h6" sx={{paddingLeft: 4.5, marginBottom: 0.5, color: text_primary}}>
                        Forgot Password
                    </Typography>
                    {
                        error !== "" ?
                        <Typography variant="body2" sx={{color: error_color, marginLeft: 4.5}}>
                            {error}
                        </Typography> :
                        null
                    }
                    <TextField 
                        type="email"
                        sx={{marginLeft: 3, marginBottom: 3, width: 400, marginTop: 1}}
                        size="small"
                        error={email !== ""}
                        onChange={(e) => handleEmail(e.target.value)}
                        label="Email" />
                    <Button 
                        onClick={handleSend}
                        variant="contained" 
                        sx={{width: 150, marginLeft: 3}}>
                        Send Recovery
                    </Button>
                </Stack>
            }
        </Box>
    );
}
