import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { tableHeaderStyle } from 'styles/style';
import { tableHeaderItemStyle } from "styles/style";
import { tableHeaderTypographyStyle } from "styles/style";

export default function UploadErrorHeader() {  
    return(
        <Grid sx={[tableHeaderStyle]} container item xs={12}>
            <Grid item xs={1} sx={tableHeaderItemStyle}>
                    <Typography variant="body2" sx={tableHeaderTypographyStyle}>
                        Line
                    </Typography>
            </Grid>
            <Grid item xs={11} sx={tableHeaderItemStyle}>
                    <Typography variant="body2" sx={tableHeaderTypographyStyle}>
                        Error
                    </Typography>
            </Grid>
        </Grid>
    )
}
