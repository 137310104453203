import { combineReducers } from "redux";
import { authenticationReducer } from "./authentication";
import { accountReducer } from "./account";
import { billingReducer } from "./billing";
import { materialReducer } from "./material";
import { priceListReducer } from "./price";
import { feedbackReducer } from "./feedback";
import { materialImportReducer } from "./material_import";
import { regionReducer } from "./region";

const reducers = combineReducers({
  authentication: authenticationReducer,
  account: accountReducer,
  billing: billingReducer,
  feedback: feedbackReducer,
  material: materialReducer,
  price: priceListReducer,
  material_import: materialImportReducer,
  region: regionReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
