import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { UpdateTime } from "api/types/update";
import { BugFilter } from "api/types/feedback";
import { getBugs } from "api/actions/feedback";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import UpdateTimer from "components/universal/save_timer";
import MenuItem from "@mui/material/MenuItem";
import { filterItemStyle } from "styles/style";
import { getPriorityChoiceDisplay } from "values/feedback";
import { LOW } from "values/feedback";
import { MEDIUM } from "values/feedback";
import { HIGH } from "values/feedback";
import { tableHeaderStyle } from "styles/style";

export default function BugFilterComponent() {
    const dispatch = useAppDispatch();
    const [bug_filter, setBugFilter] = useState<BugFilter>({});
    const [update_pending, setUpdatePending] = useState<boolean>(false);
    const [start_time, setStartTime] = useState<UpdateTime[]>([]);

    const filter = () => {
        dispatch(getBugs(bug_filter));
        setUpdatePending(false);
        setStartTime([]);
    }

    const update = () => {
        const update_time: UpdateTime = {
            time: Math.round(new Date().getTime() / 1000),
            data: null
        }
        setStartTime([update_time]);
    }

    const handleDescription = (new_description: string) => {
        setUpdatePending(true);
        setBugFilter({...bug_filter, description: new_description});
        update();
    }

    const handlePriority = (new_priority: string) => {
        setUpdatePending(true);
        if(new_priority === ""){
            if(bug_filter.priority){
                const {priority, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            setBugFilter({...bug_filter, priority: new_priority});
        }
        update();
    }

    const handleResolved = (new_resolved: string) => {
        setUpdatePending(true);
        if(new_resolved === ""){
            if(bug_filter.resolved){
                const {resolved, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            if(new_resolved === "Resolved"){
                setBugFilter({...bug_filter, resolved: true});
            }
            else if(new_resolved === "Not Resolved"){
                setBugFilter({...bug_filter, resolved: false});
            }
        }
        update();
    }

    const handleDateAddedAfter = (new_date_added_after: string) => {
        setUpdatePending(true);
        if(!new_date_added_after || new_date_added_after === ""){
            if(bug_filter.date_resolved_after){
                const {date_resolved_after, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            setBugFilter({...bug_filter, date_resolved_after: new_date_added_after});
        }
        update();
    }

    const handleDateAddedBefore = (new_date_added_before: string) => {
        setUpdatePending(true);
        if(!new_date_added_before || new_date_added_before === ""){
            if(bug_filter.date_resolved_after){
                const {date_resolved_after, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            setBugFilter({...bug_filter, date_resolved_after: new_date_added_before});
        }
        update();
    }

    const handleDateResolvedAfter = (new_date_resolved_after: string) => {
        setUpdatePending(true);
        if(!new_date_resolved_after || new_date_resolved_after === ""){
            if(bug_filter.date_resolved_after){
                const {date_resolved_after, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            setBugFilter({...bug_filter, date_resolved_after: new_date_resolved_after});
        }
        update();
    }

    const handleDateResolvedBefore = (new_date_resolved_before: string) => {
        setUpdatePending(true);
        if(!new_date_resolved_before || new_date_resolved_before === ""){
            if(bug_filter.date_resolved_after){
                const {date_resolved_after, ...rest} = bug_filter;
                setBugFilter(rest);
            }
        }
        else{
            setBugFilter({...bug_filter, date_resolved_after: new_date_resolved_before});
        }
        update();
    }

    return(
        <Grid 
            container 
            sx={[tableHeaderStyle, {paddingRight: 8}]}
            alignItems="center">
            <Grid item xs={10}>
                <Typography variant="body2" sx={{padding: 0.5}}>
                    <b>Filter</b>
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {
                    update_pending ? 
                    <UpdateTimer 
                        update_text="Loading"
                        font_size={14}
                        size={12}
                        fixed={false}
                        start_times={start_time}
                        update={filter} /> :
                    null
                }
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        size="small"
                        variant="outlined"
                        id="description"
                        label="Description Search"
                        onChange={(e) => handleDescription(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        select
                        id="priority"
                        label="Priority"
                        defaultValue=""
                        onChange={(e) => handlePriority(e.target.value)}>
                            <MenuItem key={""} value={""}>
                                {"         "}
                            </MenuItem>
                            <MenuItem key={LOW} value={LOW}>
                                {getPriorityChoiceDisplay(LOW)}
                            </MenuItem>
                            <MenuItem key={MEDIUM} value={MEDIUM}>
                                {getPriorityChoiceDisplay(MEDIUM)}
                            </MenuItem>
                            <MenuItem key={HIGH} value={HIGH}>
                                {getPriorityChoiceDisplay(HIGH)}
                            </MenuItem>
                    </TextField>
                    <TextField
                        sx={filterItemStyle}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        size="small"
                        variant="outlined"
                        select
                        id="resolved"
                        label="Status"
                        defaultValue=""
                        onChange={(e) => handleResolved(e.target.value)}>
                            <MenuItem key={""} value={""}>
                                {"         "}
                            </MenuItem>
                            <MenuItem key={"Resolved"} value={"Resolved"}>
                                Resolved
                            </MenuItem>
                            <MenuItem key={"Not Resolved"} value={"Not Resolved"}>
                                Not Resolved
                            </MenuItem>
                    </TextField>
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Added After"
                        id="date_added_after"
                        onChange={(e) => handleDateAddedAfter(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Added Before"
                        id="date_added_before"
                        onChange={(e) => handleDateAddedBefore(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        label="Date Resolved After"
                        variant="outlined"
                        id="date_resolved_after"
                        onChange={(e) => handleDateResolvedAfter(e.target.value)} />
                    <TextField
                        sx={filterItemStyle}
                        InputLabelProps={{shrink: true}}
                        type="date"
                        size="small"
                        variant="outlined"
                        label="Date Resolved Before"
                        id="date_resolved_before"
                        onChange={(e) => handleDateResolvedBefore(e.target.value)} />
                </FormControl>
            </Grid>
        </Grid>
    )
}