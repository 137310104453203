import Price from './price';
import Checkboxes from './checkboxes';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Name from "../../shared/name";
import { gridSpacing } from "styles/style";
import { getRowSecondStyle } from 'values/style';
import { getMaterialHeightDisplay } from 'values/material';

interface Props {
    index: number,
    material_thickness: string,
    price: number,
    measurement: string,
    enabled: boolean,
    taxable: boolean
}

export default function BasicPlainItem({index, material_thickness, price, measurement, enabled, taxable}: Props) {

    return(
        <Grid container item sx={[{padding: 1}, getRowSecondStyle(index)]} alignItems="center">
            <Name
                bold={false}
                name={getMaterialHeightDisplay(material_thickness)} 
                width={2} />
            <Price 
                price={price}
                width={2}/>
            <Grid item xs={4} sx={gridSpacing}>
                <Typography variant="body2">
                    {measurement}
                </Typography>
            </Grid>
            <Checkboxes 
                grid_width={4}
                enabled={enabled}
                taxable={taxable} />
        </Grid>
    )
}
