import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { getMaterialHeightDisplay } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM12 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { CM08 } from "values/material";
import { MM6 } from "values/material";
import { MM8 } from "values/material";
import { MM20 } from "values/material";

interface Props {
  saveString: (key: string, value: string) => void
}

export default function ApplicationScope({ saveString }: Props) {
  return (
    <FormControl fullWidth>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        select
        defaultValue={CM2}
        id="height"
        label="Material Height"
        onChange={(e) => saveString("material_thickness", e.target.value)}>
        <MenuItem key={CM2} value={CM2}>
          {getMaterialHeightDisplay(CM2)}
        </MenuItem>
        <MenuItem key={CM3} value={CM3}>
          {getMaterialHeightDisplay(CM3)}
        </MenuItem>
        <MenuItem key={CM12} value={CM12}>
          {getMaterialHeightDisplay(CM12)}
        </MenuItem>
        <MenuItem key={CM1} value={CM1}>
          {getMaterialHeightDisplay(CM1)}
        </MenuItem>
        <MenuItem key={CM60} value={CM60}>
          {getMaterialHeightDisplay(CM60)}
        </MenuItem>
        <MenuItem key={CM08} value={CM08}>
          {getMaterialHeightDisplay(CM08)}
        </MenuItem>
        <MenuItem key={MM6} value={MM6}>
          {getMaterialHeightDisplay(MM6)}
        </MenuItem>
        <MenuItem key={MM8} value={MM8}>
          {getMaterialHeightDisplay(MM8)}
        </MenuItem>
        <MenuItem key={MM20} value={MM20}>
          {getMaterialHeightDisplay(MM20)}
        </MenuItem>
      </TextField>
    </FormControl>
  )
}
