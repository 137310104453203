import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { changePassword } from "api/actions/authentication";
import { ChangePassword } from "api/types/authentication";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Title from "components/universal/title";
import TitleSpacer from "components/universal/title_spacer";
import Stack from "@mui/material/Stack";
import { islandStyle, third_color } from "styles/style";
import { mainContentAreaStyle } from "styles/style";

export default function ChangePasswordComponent() {
    const dispatch = useAppDispatch();
    const { password_change_success } = useAppSelector((state) => state.authentication);
    const { loading } = useAppSelector((state) => state.authentication);
    const { error } = useAppSelector((state) => state.authentication);

    const [change_password, setChangePassword] = useState<ChangePassword>({
        old_password: "",
        password: "",
        password2: ""
    });
    const [local_error, setLocalError] = useState<string>("");

    const update = () => {
        if(change_password.password.length < 8){
            setLocalError("Password must be longer than 8 characters");
            return;
        }

        if(change_password.password !== change_password.password2){
            setLocalError("Password and Repeated Password are not the same");
            return
        }

        dispatch(changePassword(change_password));
    }

    const handleOldPassword = (password: string) => {
        setChangePassword({...change_password, old_password: password});
        setLocalError("");
    }

    const handleNewPassword = (password: string) => {
        setChangePassword({...change_password, password: password});
        setLocalError("");
    }

    const handleNewPassword2 = (password: string) => {
        setChangePassword({...change_password, password2: password});
        setLocalError("");
    }

    return(
        <Box sx={mainContentAreaStyle}>
            <Title 
                title="Change Password"
                update_pending={false}
                start_times={[]}
                update={() => void 0}
                endProps={<TitleSpacer />} />
            <Paper sx={[islandStyle, {marginBottom: 2, paddingTop: 2}]}>
                <Stack sx={{width: 300}} spacing={2}>
                    <Typography variant="body2" sx={{color: third_color, paddingLeft: 0.5}}>
                        {password_change_success ? "Password has been successfully updated" : ""}
                    </Typography>
                    <Typography variant="body2" color="error" sx={{paddingLeft: 0.5}}>
                        {error !== "" ? error : local_error}
                    </Typography>
                    <TextField 
                        type="password"
                        size="small"
                        label="Old Password"
                        id="old_password"
                        onChange={(e) => handleOldPassword(e.target.value)} />
                    <TextField 
                        type="password"
                        size="small"
                        label="New Password"
                        id="new_password"
                        onChange={(e) => handleNewPassword(e.target.value)} />
                    <TextField 
                        type="password"
                        size="small"
                        label="Repeat Password"
                        id="repeat_password"
                        onChange={(e) => handleNewPassword2(e.target.value)} />
                        {
                            loading ?
                            <Box display="flex" justifyContent="center">
                                <CircularProgress size={28} sx={{padding: 0.5}}/>
                            </Box> : 
                            <Button
                                onClick={update}>
                                    Update Password
                            </Button>
                        }
                   
                </Stack>
            </Paper>
        </Box>
    )
}
