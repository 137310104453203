import { Fragment } from "react";
import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface Props {
    subtitle: string,
    endProps: ReactElement
}

export default function Subtitle({subtitle, endProps}: Props) {
    return(
        <Fragment>
            <Grid item xs={8}>
                <Typography variant="h4" sx={{paddingLeft: 0.5}}>
                    {subtitle}
                </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
                {endProps}
            </Grid>
        </Fragment>
    )
}


