import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { deleteSplashRange } from "api/actions/price";
import RangeEditableItem from "./shared/range_item";
import { SplashRange } from "api/types/price";

interface Props {
  index: number,
  item: SplashRange,
  start_update: (update_item: SplashRange) => void,
  setLocked: (locked: boolean) => void
}

export default function SplashRangeEditableItem({ item, start_update, setLocked }: Props) {
  const dispatch = useAppDispatch();
  const [splash_range_update, setSplashRangeUpdate] = useState<SplashRange>(item);

  const saveNumber = (key: string, value: string) => {
    if (isNaN(Number(value)) === false) {
      const new_splash_range: SplashRange = { ...splash_range_update, [key]: Number(value) };
      setSplashRangeUpdate(new_splash_range);
      start_update(new_splash_range);
    }
  }

  const handleDelete = () => {
    if (item.id) {
      dispatch(deleteSplashRange(item.id));
    }
  }

  return (
    <RangeEditableItem
      price={item.price ? item.price : 0}
      rate="$/LIN FT"
      measurement_key="height"
      measurement={item.height ? item.height : 0}
      any_range={item.any_range ? item.any_range : false}
      saveNumber={saveNumber}
      deleteRange={handleDelete}
      setLocked={setLocked} />
  )
}
