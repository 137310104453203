import { useState } from "react";
import BasicEditableItem from "./shared/basic";
import { FaucetCutout } from "api/types/price";

interface Props {
    index: number,
    item: FaucetCutout,
    start_update: (update_item: FaucetCutout) => void,
    setLocked: (locked: boolean) => void
}

export default function FaucetCutoutEditableItem({index, item, start_update, setLocked}: Props) {
    const [faucet_cutout_update, setFaucetCutoutUpdate] = useState<FaucetCutout>(item);

    const saveNumber = (key: string, value: string) => {
        if(isNaN(Number(value)) === false){
            const new_faucet_cutout: FaucetCutout = {...faucet_cutout_update, [key]: Number(value)};
            setFaucetCutoutUpdate(new_faucet_cutout);
            start_update(new_faucet_cutout);
        }
    }

    const saveBoolean = (key: string, value: boolean) => {
        const new_faucet_cutout: FaucetCutout = {...faucet_cutout_update, [key]: value};
            setFaucetCutoutUpdate(new_faucet_cutout);
            start_update(new_faucet_cutout);
    }

    return(
        <BasicEditableItem 
            index={index}
            material_thickness={item.material_thickness ? item.material_thickness : ""}
            price={item.price ? item.price : 0}
            measurement="$"
            enabled={item.enabled ? item.enabled : false}
            taxable={item.taxable ? item.taxable : false}
            saveBoolean={saveBoolean}
            saveNumber={saveNumber}
            setLocked={setLocked}/>
    )
}
