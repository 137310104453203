import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "api/hooks/apiHook";
import { getSplashes } from "api/actions/price/splash";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "components/universal/header";
import Subtitle from "components/universal/subtitle";
import EditableList from "components/universal/editable_list";
import { CreateSplash } from "../dialog/create";
import SplashItem from "../row/splash";
import { useAppSelector } from "api/hooks/apiHook";
import { islandStyle } from "styles/style";
import { Splash } from "api/types/price";
import { SPLASH } from "api/types/update";
import { SPLASH_RANGE } from "api/types/update";

interface Props {
  start_update: (type: number, data: Splash) => void
}

export default function PriceListSplash({ start_update }: Props) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { splashes } = useAppSelector((state) => state.price);

  const handle_update = (update_item: any) => {
    if ("height" in update_item) {
      start_update(SPLASH_RANGE, update_item);
    }
    else {
      start_update(SPLASH, update_item);
    }
  }

  useEffect(() => {
    if (id && isNaN(Number(id)) === false) {
      dispatch(getSplashes(Number(id)));
    }
  }, [id, dispatch]);

  return (
    <Paper sx={islandStyle}>
      <Grid container alignItems="center">
        <Subtitle
          subtitle="Splashes"
          endProps={
            <CreateSplash />} />
        <Header
          items={[
            { text: "Height/Range", width: 2 },
            { text: "Price", width: 2 },
            { text: "Rate", width: 4 },
            { text: "Enabled", width: 2 },
            { text: "Taxable", width: 2 }
          ]} />
        <EditableList
          items={splashes}
          Item={SplashItem}
          start_update={handle_update} />
      </Grid>
    </Paper>
  )
}
