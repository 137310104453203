import BullnoseImage from "static/finished_edge/bullnose.png";
import OgeeImage from "static/finished_edge/ogee.png";
import MiterImage from "static/finished_edge/miter.png";
import EasedImage from "static/finished_edge/eased.png";
import BevelImage from "static/finished_edge/bevel.png";
import { EASED } from "values/values";
import { BEVEL } from "values/values";
import { MITER } from "values/values";
import { SPECIALTY } from "values/values";
import { BULLNOSE } from "values/values";

interface Props {
    type: string,
    width?: number,
    height?: number
}

export default function FinishedEdgeImage({type, width, height}: Props) {
    let source = "";
    switch(type){
        case EASED:
            source = EasedImage;
            break;
        case BEVEL:
            source = BevelImage;
            break;
        case MITER:
            source = MiterImage;
            break;
        case SPECIALTY:
            source = OgeeImage;
            break;
        case BULLNOSE:
            source = BullnoseImage;
            break;
        default:
            source = EasedImage;          
    }

    if(width && height){
        return (
            <img src={source} width={width} height={height} alt="Finished Edge" />
        )
    }

    return (
        <img src={source} alt="Finished Edge" />
    )  
}
